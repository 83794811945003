<template>
  <section>
    <div :class="{'library-dimensions-height' : isLibrary}">
      <div :class="{'flex-end' : !isMobile}">
        <slot v-if="canHideColumns" name="columnFilter">
          <columns-filter class="mr-2"
                          :columns="hideableColumns"
                          @updateColumn="updateColumn"/>
        </slot>
        <slot name="filterAndAdd" v-if="!hideFilter">
          <virtual-filter-bar :filters="virtualFilters"
                              :gridName="gridName"
                              :has-selections="Object.keys(selectedItemsMap).length > 0"
                              @addSelected="$emit('addSelected')"
                              @deleteSelected="$emit('deleteSelected')"
                              @expandedFilterBar="val => $emit('expandedFilterBar', val)"
                              @update:selectedDD="$emit('update:selectedDD', $event)"/>
        </slot>
      </div>
      <section v-if="hasNoForms || showSpinners">
        <slot name="header">
          <virtual-scroll-titles :columns="visibleColumns"
                               :gridName="gridName"
                               :isMultiSelect="isMultiSelect"
                               :multiSelectLimit="multiSelectLimit"
                               :wrapperDisableSelectAll="true"
                               @toggleSelectAll="updateSelectAll"/>
        </slot>
        <slot name="loading-icon">
          <utility-block v-if="showSpinners"/>
        </slot>
        <slot v-if="!hideEmptyFormsMessage" name="emptyFormMsg">
          <!--          v-show still shows this block when showSpinners is true .... using v-if which shows correct DOM         -->
          <article v-if="hasNoForms && !showSpinners"
                   class="is-flex"
                   data-unit="no-forms-msg">
            <p class="mr-1">
              {{ $t('noFormsMessage') }}
            </p>
            <router-link class="mr-1"
                         :to="emptyListTranslations.route">
              {{ $t('addAFormOnly') }}
            </router-link>
            {{ $t('toGetStarted') }}
          </article>
        </slot>
      </section>
      <section v-else
               class="virtual-wrap"
               :class="{'library-virtual-wrap' : isLibrary}">
        <virtual-list wrap-class="virtual-wrap-list"
                      header-class="virtual-titles"
                      ref="virtualList"
                      :style="scroll"
                      data-key="uid"
                      :data-sources="configuredData"
                      :data-component="$options.VirtualScrollListRow"
                      :bottom-threshold="bottomDetection"
                      :keeps="5000"
                      :extra-props="{ buttons, columns: visibleColumns, hasButtons, isMultiSelect, selectedText, selectedItemsMap, useWhiteText, isSelectingAll,
                      isWidget }"
                      @tobottom="scrollThreshold('tobottom')">
          <template #header>
            <slot name="header">
              <virtual-scroll-titles :columns="visibleColumns"
                                     :gridName="gridName"
                                     :isMultiSelect="isMultiSelect"
                                     :multiSelectLimit="multiSelectLimit"
                                     :selectedItemsMap="selectedItemsMap"
                                     @toggleSelectAll="updateSelectAll"/>
            </slot>
          </template>
          <template #footer>
            <utility-block v-show="!hasAllResults"/>
          </template>
        </virtual-list>
        <slot name="footer" v-if="!hideFooter">
          <footer v-if="!hasNoForms">
            <article class="has-text-weight-bold"
                     data-unit="footer-count">
              <span v-if="hasQueryDebounce && itemsFound !== totalCount">
                {{ itemsFound }} of {{ totalCount }} {{ title }}
              </span>
              <span v-else>
                {{ `${totalCount} ${title}` }}
              </span>
            </article>
          </footer>
        </slot>
      </section>
    </div>
  </section>
</template>

<script>
// 3rd Party Libraries
import { mapState, mapActions, mapGetters } from 'vuex'
import VirtualList from "vue3-virtual-scroll-list"
// Components
import ColumnsFilter from './ColumnsFilter'
import UtilityBlock from '@/components/common/UtilityBlock'
import VirtualScrollListRow from './VirtualScrollListRow'
import VirtualScrollTitles from './VirtualScrollTitles'
import VirtualFilterBar from '@/components/admin/content/VirtualFilterBar'
// Local Helpers
import contrastColors from '@/helpers/contrastColors'
import { getColorValue } from '@/store/modules/theme/getColorValue'

import { VirtualListButtons, VirtualListFilters } from '@/classes/virtualListObjects'
import { isEqual } from "lodash";

export default {
  name: 'VirtualScrollList',
  components: {
    ColumnsFilter,
    UtilityBlock,
    VirtualFilterBar,
    VirtualList,
    VirtualScrollTitles
  },
  VirtualScrollListRow,
  props: {
    virtualFilters: {
      type: VirtualListFilters,
      default: () => new VirtualListFilters({
        tagsMatching: null,
        disableAdd: false,
        hasAdd: false,
        hasDeleteBtn: false,
        hasPartCode: false,
        hasSupplier: false,
        hasCreated: false,
        hasUpdated: false,
        hasMediaType: false,
        newMediaRedirect: null,
        hasSearchTerm: false
      })
    },
    buttons: {
      type: VirtualListButtons,
      default: () => new VirtualListButtons({
        setAsDefault: false,
        hasCopy: false,
        hasDelete: false,
        hasDetails: false
      }),
    },
    bottomDetection: {
      type: Number,
      default: 3000
    },
    defaultColumn: {
      type: String,
      default: ''
    },
    gridName: {
      type: String,
      required: true
    },
    isMultiSelect: {
      type: Boolean,
      default: false
    },
    tableColumns: {
      type: Array,
      default: () => []
    },
    tableData: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    itemsFound: {
      type: Number,
      default: 0
    },
    viewHeight: {
      type: Number,
      default: 75
    },
    queryCount: {
      type: Number,
      default: 40
    },
    overflowX: {
      type: String,
      default: 'hidden'
    },
    scrollToTop: {
      type: Boolean,
      default: false
    },
    selectedItemsMap: {
      type: Object,
      default: () => ({})
    },
    newMediaRedirect: {
      type: String,
      default: ''
    },
    emptyListTranslations: {
      type: Object,
      default: () => ({
        itemTranslation: '',
        addItemTranslation: '',
        route: { name: '' }
      })
    },
    multiSelectLimit: {
      type: Number,
      default: 250
    },
    wrapperIsLoading: {
      type: Boolean,
      default: false
    },
    isSelectingAll: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    hideFooter: {
      type: Boolean,
      default: false
    },
    hideFilter: {
      type: Boolean,
      default: false
    },
    hideEmptyFormsMessage: {
      type: Boolean,
      default: false
    },
    isLibrary: {
      type: Boolean,
      default: false
    },
    wrapperTotalCount: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    columns: [],
    configuredData: [],
    hasQueryDebounce: true
  }),
  watch: {
    // This pause is necessary to keep reactivity of m of n footer in sync with debounce
    // of API call on updates to filter bar
    async hasQuery (newVal, oldVal) {
      if (isEqual(newVal, oldVal)) return
      await this.$wait(500)
      this.hasQueryDebounce = newVal
    },
    scrollToTop: {
      immediate: true,
      async handler (value) {
        if (value && this.$refs.virtualList) {
          this.$refs.virtualList.scrollToIndex(0)
        }
      }
    },
    tableData: {
      immediate: true,
      async handler () {
        this.configuredData = await this.addProps(this.tableData)
      }
    },
    tableColumns(updated, previous) {
      if (JSON.stringify(updated) === JSON.stringify(previous)) return
      this.refreshColumnArray()
    }
  },
  computed: {
    ...mapGetters({
      isWidget: 'widgets/isWidget'
    }),
    ...mapState({
      activeColumn ({ grids }) {
        return grids.grids[this.gridName]?.activeColumn ?? ''
      },
      filterTerm ({ grids }) {
        return grids.grids[this.gridName]?.filterTerm ?? ''
      },
      isLoading: (({ grids }) => grids.isLoading),
      sortInvert ({ grids }) {
        return grids.grids[this.gridName]?.sortInvert ?? true
      },
    }),
    offset () {
      return this.$store.getters['grids/offset'](this.gridName)
    },
    hasQuery () {
      return this.$store.getters['grids/hasQuery'](this.gridName)
    },
    totalCount () {
      return this.wrapperTotalCount || this.$store.getters['grids/totalCount'](this.gridName)
    },
    pagination () {
      return this.$store.getters['grids/pagination'](this.gridName)
    },
    canHideColumns () {
      return this.columns.some(it => it.canHide)
    },
    clearFiltersEnabled () {
      return !!this.filterTerm?.length
    },
    hasButtons () {
      return Object.values(this.buttons).some(it => !!it)
    },
    hasNoForms () {
      return this.itemsFound !== undefined && !this.itemsFound
    },
    hideableColumns () {
      return this.columns.filter(it => it.hasOwnProperty('canHide'))
    },
    scroll () {
      if (this.isLibrary) {
          return `
            max-height: 100%;
            overflow-y: 'scroll';
            overflow-x: ${this.overflowX};
            margin-bottom: 3em;
          `
      } else {
        return `
        max-height: ${this.viewHeight}vh;
        overflow-y: 'scroll';
        overflow-x: ${this.overflowX};
        margin-bottom: 3em;
      `
      }
    },
    selectedText () {
      const primaryColor = getColorValue('$primary')
      return contrastColors.colorText(primaryColor)
    },
    showSpinners () {
      return this.isLoading || this.wrapperIsLoading
    },
    sortAndFilterString () {
      return '' + this.activeColumn + this.filterTerm + this.sortInvert
    },
    useWhiteText () {
      const primaryColor = getColorValue('$primary')
      return contrastColors.colorText(primaryColor) === '#ffffff'
    },
    visibleColumns () {
      return this.columns.filter(it => !it.isHidden)
    },
    hasAllResults() {
      if(!this.pagination) return
      return this.pagination.pageCount === this.pagination.pageNum
    }
  },
  methods: {
    ...mapActions({
      setFilterTerm: 'grids/setGridFilter',
      setIsLoading: 'grids/setIsLoading'
    }),
    scrollThreshold (eventType) {
      this.$emit(eventType)
    },
    async addProps (list) {
      return list.map((it, i) => {
        let newObj = {}
        for (const prop in this.buttons) {
          if (this.buttons[prop]) {
            newObj[prop] = true
          }
        }
        return {
          ...it,
          ...newObj,
          uid: i
        }
      })
    },
    updateColumn (field) {
      const idx = this.columns.findIndex(it => it.field === field)
      this.columns.splice(idx, 1, { ...this.columns[idx], isHidden: !this.columns[idx].isHidden })
    },
    refreshColumnArray () {
      //allows a reset of all columns when something dramatic happens like a meteor strike or resizing screen size
      this.columns = this.tableColumns.map(it => {
        return it.hasOwnProperty('canHide') ? { ...it, isHidden: false } : { ...it }
      })
    },
    updateSelectAll (val) {
      this.$emit(val ? 'checkAll' : 'unCheckAll')
    }
  },
  async mounted () {
    // Format columns
    this.columns = this.tableColumns.map(it => {
      return it.hasOwnProperty('canHide') ? { ...it, isHidden: false } : { ...it }
    })
  }
}
</script>
<style lang="scss">
.header-thumbnail .thumbnail {
  width: 32px;
  height: 32px;
}

.column-shrink {
  width: 3.5em;
  padding: 0.75rem;
}

.column-grow-1 {
  width: 9em;
  padding: 0.75rem;
}

.no-forms {
  height: 10em;
}

.virtual-wrap {
  padding: 0 !important;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  scrollbar-color: lightgrey white;

  footer {
    position: sticky;
    bottom: 0;
    left: 0;
    height: 3em;
    background-color: white;
    width: 100%;
    padding: 1em;
    margin: -1em;
  }
}

::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 5px;

}

::-webkit-scrollbar {
  background-color: white;
  width: 8px;
}

.library-dimensions-height {
  height: 100%;
}
.library-virtual-wrap {
  height: 75%;
  @media only screen and (max-width: 768px) {
    max-height: 65%;
  }
}
</style>
