import { notify } from "@kyvg/vue3-notification"
import http from '@/http'
import i18n from '@/locales'
import bowser from 'bowser'
import router from '@/router'
import { store } from '@/store'
import labels from './labels'
import pricing from './pricing'
import preferences from './preferences'
import tenant from './tenant'
import { LicensePackage } from '@/interfaces/global/licensePackage'
import { postError } from '@/helpers/notification'

const USER_CREDENTIALS_RECEIVED = 'USER_CREDENTIALS_RECEIVED'
const USER_RECEIVED = 'USER_RECEIVED'
const USER_CLASSIC_TOKEN_READY = 'USER_CLASSIC_TOKEN_READY'
const UPDATE_LOCALE = 'UPDATE_LOCALE'
const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'

const state = {
  acceptedCookies: null,
  id: '',
  username: '',
  email: '',
  firstName: '',
  lastName: '',
  tenantKey: '',
  organizationId: '',
  organizationKey: '',
  accessPrivileges: [],
  superAdmin: false,
  erpEnabled: false,
  enable3dFeatures: false,
  remember: false,
  contactOwnerEnabled: false,
  credentialsReceived: false,
  classicTokenReady: false,
  enableProcessPlzToDraft: false,
  searchExport: false,
  searchHidden: false,
  hideIdentifier: false,
  locale: 'en-US',
  shortLocale: 'en',
  generic: false,
  ssoLoginSystem: '',
  betaAccessKeys: [],
  passwordUpdatedDate: null,
  systemConfigs: {},
  hasNotifiedUserForCookies: false,
  tenantDefaultSupplier: null,
  tenantDefaultUom: null,
  daysUntilPasswordExpires: null,
  passwordUpdateSuccess: null,
  tenantLocale: null,
  hideBooksInSearch: false,
  hidePagesInSearch: false,
  hidePartsInSearch: false,
  showPartInformationBanner: true
}

const getters = {
  canViewComments() {
    return state.accessPrivileges.includes('VIEW_COMMENTS')
  },
  canViewRelated() {
    return state.accessPrivileges.includes('VIEW_RELATED_ITEMS')
  },
  displayAvailability() {
    return state.accessPrivileges.includes('SHOPPING_CART_ERP_AVAILABILITY_ENABLED') && state.erpEnabled
  },
  isCartEnabled() {
    return state.accessPrivileges.includes('SHOPPING_CART_ENABLED')
  },
  isExporter() {
    return state.accessPrivileges.includes('EXPORTER')
  },
  isOrganizationAdmin() {
    return state.accessPrivileges.includes('ADMINISTRATION')
  },
  isPublisher() {
    return state.accessPrivileges.includes('PUBLISH')
  },
  isQuickAddEnabled() {
    return state.accessPrivileges.includes('QUICK_ADD_IN_CART_ENABLED')
  },
  isSavedCartEnabled() {
    return state.accessPrivileges.includes('SAVE_SHOPPING_CARTS')
  },
  isSharedCartEnabled() {
    return state.accessPrivileges.includes('PUBLIC_SHOPPING_CARTS_ENABLED')
  },
  isSharedCartMaintainer() {
    return state.accessPrivileges.includes('ADD_EDIT_PUBLIC_SHOPPING_CARTS')
  },
  isTenantAdmin() {
    return state.accessPrivileges.includes('TENANT_ADMINISTRATION')
  },
  isOrderApprover() {
    return state.accessPrivileges.includes('ORDER_APPROVER')
  },
  isOrderViewer() {
    return state.accessPrivileges.includes('ORDER_VIEWER')
  },
  isSuperAdmin() {
    return state.superAdmin
  },
  has3dFeatures () {
    return state.enable3dFeatures
  },
  hasAccessToOrders() {
    return !state.accessPrivileges.includes('DISABLE_ORDER_HISTORY')
  },
  hasBulletins() {
    return state.accessPrivileges.includes('BULLETINS')
  },
  /** Requirement: 4131
   * These wo values: hasDisableBookPrinting & hasDisablePrinting control different access in the UI and cannot be combined.
   * if(hasDisableBookPrinting) {
   *   Then they can no longer Export / print Books in their entirety
   *   But they can Export / print Chapters
   *   And they can Export / print Parts Pages
   *   And they can Download static content (including. Document, Video and Images type media or pages)
   * }
   * */

  hasDisableBookPrinting() {
    return state.accessPrivileges.includes('DISABLE_BOOK_PRINTING')
  },
  hasDisablePrinting() {
    return state.accessPrivileges.includes('DISABLE_PRINTING')
  },
  hasHelp() {
    return state.accessPrivileges.includes('VIEW_HELP')
  },
  hasPartOrderSuggestions() {
    return state.accessPrivileges.includes('PART_ORDER_SUGGESTIONS_ENABLED')
  },
  hasUserPreferences() {
    return !state.accessPrivileges.includes('DISABLE_USER_PREFERENCES')
  },
  isCartOrErpCartEnabled() {
    const hasErp = state.accessPrivileges.includes('ADD_TO_CART_ERP_ENABLED') && state.erpEnabled

    return state.accessPrivileges.includes('SHOPPING_CART_ENABLED') || hasErp
  },
  hasCartPromptForQtyEnabled() {
    return state.accessPrivileges.includes('SHOPPING_CART_ADD_TO_CART_PROMPT_FOR_QTY_ENABLED')
  },
  hasHidePrice() {
    return state.accessPrivileges.includes('HIDE_PRICE_VALUES_ENABLED')
  },
  displayRetail() {
    return state.accessPrivileges.includes('DISPLAY_RETAIL_PRICES')
  },
  displayWholeSale() {
    return state.accessPrivileges.includes('DISPLAY_WHOLESALE_PRICES')
  },
  displayDiscounted() {
    return state.accessPrivileges.includes('DISPLAY_DISCOUNTED_PRICES')
  },
  displayEta() {
    return state.accessPrivileges.includes('SHOPPING_CART_ERP_ETA_ENABLED') && state.erpEnabled
  },
  isAdmin() {
    return getters.isTenantAdmin() || getters.isOrganizationAdmin()
  },
  /*
   * This check examines the user group-level API_KEY_ADMIN permission,
   * which determines whether the user can access API Key management content.
   * In contrast, the tenant-level API_KEY_ADMIN permission in the tenant module
   * governs whether the user can provision the user group-level permission
   * in Admin UserGroups via the "API Key Admin" checkbox.
   */
  hasAccessToAPIKeys() {
    return state.accessPrivileges.includes('API_KEY_ADMIN')
  },
  hasAccessToWidgetKeys(state, getters) {
    return state.accessPrivileges.includes('WIDGET_KEY_ADMIN')
      && getters.isTenantAdmin
      && getters.hasEnabledWidgets
  },
  hasAccessToWidgetUserGroups(state, getters) {
    return getters.isTenantAdmin
      && getters.hasEnabledWidgets
  },
  isReporter() {
    return state.accessPrivileges.includes('REPORTING')
  },
  useTurboForSso() {
    return state.ssoLoginSystem.toLowerCase() === 'turbo'
  },
  hasStaticHotpointSize() {
    return preferences.state.staticHotpointSize
  },
  overrideHotpointScaleValue() {
    return preferences.state.overrideHotpointScaleValue
  },
  maxPageBuilderImageSize: () => state.systemConfigs?.maxPageBuilderImageSize,
  maxPageBuilderSvgSize: () => state.systemConfigs?.maxPageBuilderSvgSize,
  isGenericUser(state) {
    return state.generic
  },
  isEssentialsTenant(state) {
    return state.licensePackage === LicensePackage.Essentials
  },
  enableHotpointZoom(state) {
    return state.hotpointZoomBehavior === "on"
  },
  canAddAndEditComments({ accessPrivileges }) {
    return accessPrivileges.includes('ADD_AND_EDIT_USERS_COMMENTS')
  },
  canEditAllComments({ accessPrivileges }) {
    return accessPrivileges.includes('EDIT_ALL_COMMENTS')
  },
  hasEcommerceOrderMgmt({ accessPrivileges }) {
    return accessPrivileges
      .includes('ECOMMERCE_ORDER_MANAGEMENT_ENABLED')
  },
  hasPurchaseOrder({ accessPrivileges }, getters) {
    return getters.hasEcommerceOrderMgmt &&
      accessPrivileges.includes('HTML_PO_TEMPLATE_ENABLED')
  },
  hasRequestQuote({ accessPrivileges }, getters) {
    return getters.hasEcommerceOrderMgmt &&
      accessPrivileges.includes('HTML_RFQ_TEMPLATE_ENABLED')
  },
  hasCartSendToErp({ accessPrivileges }) {
    return accessPrivileges
      .includes('SHOPPING_CART_SEND_TO_ERP_ENABLED')
  },
  enableLibraryBetaFeature({ accessPrivileges }) {
    return accessPrivileges.includes('ENABLE_LIBRARY_BETA_FEATURES')
  },
  tenantId (state) {
    return state.id
  },
  getUserId() {
    return state.id
  },
  hasFeedbackAccess(state, getters) {
    return getters.isPublisher
      || getters.isAdmin
      || getters.isOrganizationAdmin
  },
  hasProductPagesBetaAccessKey(state) {
    return state.betaAccessKeys.includes("Product_Page_MVP")
  }
}

const actions = {
  async getUser ({ commit, state, dispatch, rootState, getters }) {
    try {
      const { data } = await http.get('auth/whoami')
      await commit(USER_RECEIVED, { user: data })
      // Fetch tenant-level access privileges for the tenant module
      // These privileges are different from the usergroup-level ones saved in this module
      await dispatch('loadTenantPrivileges', data.tenantKey)
      await dispatch('loadTenantProperties', data.tenantKey)

      // This value is retrieved when user is on login page to pre-populate the Tenant Input Field
      localStorage.setItem('tenantKey', data.tenantKey)

      await commit('auth/AUTHENTICATED', { authenticated: true }, { root: true })

      dispatch('getUserPreferences')
      dispatch('getTenantLabels')

      /*
       * whoami provides 'contains' and 'exact'; Rocket uses boolean
       * This action should handle both but keep to boolean-values elsewhere
       */

      const defSearchMode = localStorage.getItem("defaultSearchMode")
      if (defSearchMode == null) {
        localStorage.setItem("defaultSearchMode", data.defaultSearchMode)
        data.defaultSearchMode
      }

      if (data.defaultSortMode) {
        dispatch('search/setDefaultSort', data.defaultSortMode, { root: true })
        dispatch('browse/setDefaultSort', data.defaultSortMode, { root: true })
      }

      if (bowser.check({ msie: '11' }, true)) {
        dispatch('notifications/getNotificationCounts', true, { root: true })

          postError({
            title: i18n.global.t('browserDeprecation.title'),
            text: i18n.global.t('browserDeprecation.text'),
          })
        } else if (bowser.check({ msie: '10' }, true)) {
          router.replace({ path: '/unsupported-browser' })
        } else {
          dispatch('notifications/getNotificationCounts', true, { root: true })
        }

      if (!rootState.app.analyticsLoaded && !rootState.app.analyticsPending) {
        dispatch('app/initializeAnalytics', {}, { root: true })
      }

      if (!state.acceptedCookies) {
        // there is some kind of race condition where a screen refresh or going to new tab will not show the gdpr
        setTimeout(()=> {
          notify({
            group: 'cookie',
            duration: -1 // Negative duration -> forever
          })
        }, 1000)
      }
    } catch (err) {
      console.error('User failed to load')
    }
    return state.authenticated
  },
  async updateUser ({ commit }, user) {
    try {
      await http.put('user', { params: user })
      const { data } = await http.get('auth/whoami')

      await commit(USER_RECEIVED, { user: data })
    } catch (err) {
      console.error('User failed to update')
    }
  },
  async updatePassword ({ state, commit }, { currentPassword, newPassword }) {
    try {
      const formData = new URLSearchParams()
      formData.append('old-password', currentPassword)
      formData.append('new-password', newPassword)

      await http.post(`users/${state.id}/password`, formData)
      await commit('UPDATE_PASSWORD_SUCCESS', true)
      notify({
        title: i18n.global.t('updatePassword'),
        text: i18n.global.t('updatePasswordMessage'),
        type: 'success'
      })
    } catch (err) {
      const response = err.response
      await commit('UPDATE_PASSWORD_SUCCESS', false)
      postError({
        title: i18n.global.t('error'),
        text: response ? response.data.message : err,
      })
    }
  },
  updateLocale({ commit }, payload) {
    commit(UPDATE_LOCALE, payload)
  }
}

const mutations = {
  [USER_CREDENTIALS_RECEIVED] (state, { credentials }) {
    state.credentialsReceived = true
    state.username = credentials && credentials.username ? credentials.username : ''
    state.remember = credentials && credentials.remember ? credentials.remember : false
    state.tenantKey = credentials && credentials.tk ? credentials.tk : ''
    state.organizationKey = credentials && credentials.ok ? credentials.ok : ''
  },
  [USER_RECEIVED] (state, { user }) {
    state.id = user.userId
    state.username = user.username
    state.email = user.email
    state.firstName = user.firstName
    state.lastName = user.lastName
    state.tenantKey = user.tenantKey
    state.organizationId = user.organizationId
    state.organizationKey = user.organizationKey
    state.accessPrivileges = user.accessPrivileges
    state.superAdmin = user.superAdmin
    state.contactOwnerEnabled = user.contactOwnerEnabled
    state.erpEnabled = user.erpEnabled
    state.enable3dFeatures = user.enable3dFeatures
    state.searchExport = user.searchExport
    state.searchHidden = user.searchHidden
    state.hideIdentifier = user.hideIdentifier || false
    state.shortLocale = user.locale.split('-')[0]
    state.generic = user.generic || false
    state.ssoLoginSystem = user.ssoLoginSystem
    state.betaAccessKeys = user.betaAccessKeys || []
    state.systemConfigs = user.systemConfigs || {}
    state.tenantDefaultSupplier = user.defaultSupplierKey
    state.tenantDefaultUom = user.defaultUom
    state.hotpointZoomBehavior = user.hotpointZoomBehavior
    state.passwordUpdatedDate = user.passwordUpdatedDate
    state.daysUntilPasswordExpires = user.daysUntilPasswordExpires
    state.enableProcessPlzToDraft = user.enableProcessPlzToDraft
    state.acceptedCookies = user.acceptedCookies
    state.tenantLocale = user.tenantLocale
    state.hideBooksInSearch = user.hideBooksInSearch
    state.hidePagesInSearch = user.hidePagesInSearch
    state.hidePartsInSearch = user.hidePartsInSearch
    state.showPartInformationBanner = user.showPartInformationBanner
    const userLocale = localStorage.getItem('userLocale')
    const isWidget = store.getters['widgets/isWidget']
    if (userLocale && !isWidget) {
      state.locale = userLocale
      state.shortLocale = userLocale.split('-')[0]
      //ensure the userLocale matches the cookie
      store.dispatch('auth/user/updatePreferences', { locale: userLocale})
    } else if (!isWidget) {
      localStorage.setItem('userLocale', 'en-US')
      store.dispatch('auth/user/updatePreferences', { locale: 'en-US'})
    }
    if (user.licensePackage) {
      state.licensePackage = LicensePackage[user.licensePackage]
    }
    if (state.generic) {
      localStorage.removeItem("searchHistory_" + state.id)
    }
  },
  [USER_CLASSIC_TOKEN_READY] (state) {
    state.classicTokenReady = true
  },
  [UPDATE_LOCALE] (state, { locale, shortLocale }) {
    state.locale = locale
    state.shortLocale = shortLocale
  },
  [UPDATE_PASSWORD_SUCCESS] (state, value) {
    state.passwordUpdateSuccess = value
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
  modules: {
    labels,
    preferences,
    pricing,
    tenant
  }
}
