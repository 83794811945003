<template>
  <button :disabled="disabled"
          :class="['button', stylingClass]"
          @click="emit('click')">
    <bulma-icon :iconClass="iconClass"/>
    <span v-if="text">
      {{ text }}
    </span>
  </button>
</template>

<script setup>
// This is a partial replacement of Buefy b-button.
// It's meant for buttons that have an icon.
// See WidgetContentHeader.vue for an implementation example.

import BulmaIcon from './BulmaIcon.vue'

defineProps({
  stylingClass: { type: String, default: '' },
  iconClass: { type: String, default: '' },
  text: { type: String, default: '' },
  disabled: { type: Boolean, default: false }
})
const emit = defineEmits(['click'])
</script>
