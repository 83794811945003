<template>
  <div class="search-suggestions-box">
    <div class="suggestion-column">
      <div class="suggestions-box">
        <div>
          <div class="title-five search-suggestions-header-title">
            <h3 class="header-text">{{ $t('suggestedKeywords') }}</h3>
          </div>
          <div v-if="!loadingSuggestions">
            <ul v-if="!displayNoSuggestionsMessage">
              <li v-for="item in suggestions">
                <a class="search-suggestions-item" @click="select(item)">
                  {{ suggestionItemText(item) }}
                </a>
              </li>
            </ul>
            <div class="search-suggestions-item" v-else>
                {{ $t('noSuggestions')}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="suggestion-results-column">
      <div class="exact-match-section">
        <div class="search-suggestions-header-title"
             @click="toggleExactMatch">
          <input type="checkbox"
                 :checked="exactMatch">
          <label class="title-six exact-match-label">{{ $t('showOnlyExactMatches') }}</label>
        </div>
      </div>
      <div>
        <div>
          <div class="title-six search-suggestions-header-title">
            <h3 class="header-result-text">{{ $t('suggestedResults') }} {{ $t('forSearchTerm', { term: formattedQuery }) }}</h3>
            <b-tooltip multilined type="is-light is-primary globe" position="is-bottom" :label="localeToolTipText">
              <i class="fas fa-globe" />
            </b-tooltip>
            <div class="view-more-link"><a @click="searchAll">{{ $t('viewAllResults') }}</a></div>
          </div>
          <div v-if="!loadingSuggestionResults">
            <search-suggestion-result
              v-if="!hideParts && hasPartsResults"
              :contentType="'Part'"
              :count="partsCount"
              :suggestedSearchResults="props.suggestedPartSearchResults"
              @selectContent="selectContent"
              @searchContent="searchContentType"
            />
            <search-suggestion-result
              v-if="!hidePages && hasPageResults"
              :contentType="'Page'"
              :count="pageCount"
              :suggestedSearchResults="props.suggestedPageSearchResults"
              @selectContent="selectContent"
              @searchContent="searchContentType"
            />
            <search-suggestion-result
              v-if="!hideBooks && hasBookResults"
              :contentType="'book'"
              :count="bookCount"
              :suggestedSearchResults="props.suggestedBookSearchResults"
              @selectContent="selectContent"
              @searchContent="searchContentType"
            />
            <search-suggestion-result
              v-if="hasDocumentResults"
              :contentType="'document'"
              :count="documentCount"
              :suggestedSearchResults="props.suggestedDocumentSearchResults"
              @selectContent="selectContent"
              @searchContent="searchContentType"
            />
            <search-suggestion-result
              v-if="hasExternalResults"
              :contentType="'external'"
              :count="externalCount"
              :suggestedSearchResults="props.suggestedExternalSearchResults"
              @selectContent="selectContent"
              @searchContent="searchContentType"
            />
            <search-suggestion-result
              v-if="hasImageResults"
              :contentType="'image'"
              :count="imageCount"
              :suggestedSearchResults="props.suggestedImageSearchResults"
              @selectContent="selectContent"
              @searchContent="searchContentType"
            />
            <search-suggestion-result
              v-if="hasVideoResults"
              :contentType="'video'"
              :count="videoCount"
              :suggestedSearchResults="props.suggestedVideoSearchResults"
              @selectContent="selectContent"
              @searchContent="searchContentType"
            />
            <search-suggestion-result
              v-if="hasMicrositeResults"
              :contentType="'microsite'"
              :count="micrositeCount"
              :suggestedSearchResults="props.suggestedMicrositeSearchResults"
              @selectContent="selectContent"
              @searchContent="searchContentType"
            />
          </div>
          <div class="spinner-space" v-else>
            <utility-block/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex"
import { computed, onMounted } from "vue";
import SearchSuggestionResult from './SearchSuggestionResult'
import UtilityBlock from '@/components/common/UtilityBlock'
import i18n from "@/locales";
import { useWidgetNavigator } from "@/components/widgets/composables/useWidgetNavigator";
import { useOpenToContent } from "@/components/widgets/composables/useOpenToContent";
import { logSearchBarSelection } from '@/controllers/library'
import { SearchEntityTypes } from "@/helpers/SearchEntityType";
import router from "@/router";

const emit = defineEmits(['selectSuggestion', 'close', 'searchContent', 'searchAll', 'toggleExactMatch'])
const store = useStore()
const props = defineProps({
  query: {
    type: String,
    required: true
  },
  exactMatch: {
    type: Boolean,
    required: true
  },
  suggestions: {
    type: Array,
    required: true
  },
  suggestedBookSearchResults: {
    type: Array,
    required: true
  },
  suggestedPageSearchResults: {
    type: Array,
    required: true
  },
  suggestedPartSearchResults: {
    type: Array,
    required: true
  },
  suggestedExternalSearchResults: {
    type: Array,
    required: true
  },
  suggestedDocumentSearchResults: {
    type: Array,
    required: true
  },
  suggestedImageSearchResults: {
    type: Array,
    required: true
  },
  suggestedVideoSearchResults: {
    type: Array,
    required: true
  },
  suggestedMicrositeSearchResults: {
    type: Array,
    required: true
  },
  suggestedSearchResultTypes: {
    type: Array,
    required: true
  },
  loadingSuggestions: {
    type: Boolean
  },
  loadingSuggestionResults: {
    type: Boolean
  }
})

const formattedQuery = computed(() => (props.query?.length > 0) ? (props.exactMatch && !props.query?.startsWith('"') && !props.query?.endsWith('"')) ? "\"" + props.query + "\"" : props.query : "")

const hasPartsResults = computed(() => props.suggestedPartSearchResults?.length > 0)
const partsCount = computed(() => props.suggestedSearchResultTypes?.find((t) => t.key.toLowerCase() === 'part')?.count)

const hasPageResults = computed(() => props.suggestedPageSearchResults?.length > 0)
const pageCount = computed(() => props.suggestedSearchResultTypes?.find((t) => t.key.toLowerCase() === 'page')?.count)

const hasBookResults = computed(() => props.suggestedBookSearchResults?.length > 0)
const bookCount = computed(() => props.suggestedSearchResultTypes?.find((t) => t.key.toLowerCase() === 'book')?.count)

const documentCount = computed(() => props.suggestedSearchResultTypes?.find((t) => t.key.toLowerCase() === 'document')?.count)
const hasDocumentResults = computed(() => props.suggestedDocumentSearchResults?.length > 0)

const externalCount = computed(() => props.suggestedSearchResultTypes?.find((t) => t.key.toLowerCase() === 'external')?.count)
const hasExternalResults = computed(() => props.suggestedExternalSearchResults?.length > 0)

const imageCount = computed(() => props.suggestedSearchResultTypes?.find((t) => t.key.toLowerCase() === 'image')?.count)
const hasImageResults = computed(() => props.suggestedImageSearchResults?.length > 0)

const videoCount = computed(() => props.suggestedSearchResultTypes?.find((t) => t.key.toLowerCase() === 'video')?.count)
const hasVideoResults = computed(() => props.suggestedVideoSearchResults?.length > 0)

const micrositeCount = computed(() => props.suggestedSearchResultTypes?.find((t) => t.key.toLowerCase() === 'microsite')?.count)
const hasMicrositeResults = computed(() => props.suggestedMicrositeSearchResults?.length > 0)

const totalCount = computed(() => (micrositeCount.value ?? 0)  + (videoCount.value ?? 0) + (imageCount.value ?? 0) + (externalCount.value ?? 0) + (documentCount.value ?? 0)
  + (bookCount.value ?? 0) + (pageCount.value ?? 0) + (partsCount.value ?? 0))

const hidePages = computed(() => store.state.user.hidePagesInSearch)
const hideBooks = computed(() => store.state.user.hideBooksInSearch)
const hideParts = computed(() => store.state.user.hidePartsInSearch)
const displayNoSuggestionsMessage = computed(() => (!props.loadingSuggestions) && (!props.loadingSuggestionResults) &&
  (props.suggestions?.length === 0)
  && (hasMicrositeResults.value || hasVideoResults.value || hasImageResults.value || hasExternalResults.value ||
    hasDocumentResults.value || hasBookResults.value || hasPageResults.value || hasPartsResults.value))

const localeToolTipText = computed(() => {
  const userLocale = i18n.global.locale ?? 'en-US'
  const tenantLocale = store.state.user.tenantLocale?.replace('_','-') ?? userLocale
  const userLang = store.state.locales.items.find((locale) => locale.code4 === userLocale)?.name ?? userLocale
  const properUserLang = userLang.charAt(0).toUpperCase() + userLang.slice(1)
  if (userLocale === tenantLocale) {
    return i18n.global.t('searchingInLanguage', { lang: properUserLang })
  } else {
    const tenantLang = store.state.locales.items.find((locale) => locale.code4 === tenantLocale)?.name ?? tenantLocale
    const properTenantLang = tenantLang.charAt(0).toUpperCase() + tenantLang.slice(1)
    return i18n.global.t('searchingInTwoLanguages', { lang1: properUserLang, lang2: properTenantLang })
  }
  }
)
const isWidget = computed(() => store.getters['widgets/isWidget'])
const { mapDtoAndNavToWidgetContent, entityIdToIdDtoMap } = useWidgetNavigator()
const { setTocQuery: setWidgetTocQuery } = useOpenToContent()

const toggleExactMatch = () => {
  emit('toggleExactMatch')
}
const select = (value) => {
  emit('selectSuggestion', value)
}

const selectContent = async (content, type, navigateToDetails) => {
  emit('close')
  await store.dispatch('search/clearTocQuery')
  await store.dispatch('browse/clearSearchTrackingId')
  await store.dispatch('search/clearSearchTrackingId')
  const qVal = props.query?.replace('*', '')
  //needs to be retained in state for breadcrumbs and pdfs
  await store.dispatch('search/setSearchQuery', qVal)
  try {
    logSearchBarSelection({
      contentId: content.entityId,
      contentType: SearchEntityTypes[type.toLowerCase()],
      totalResults: totalCount.value,
      searchTerm: formattedQuery.value
    })
  } catch (e) { }
  const typeStr = type?.toString().toLowerCase()
  const isDocument = ((typeStr === 'document') || ((typeStr === 'page') && (content?.identifier?.toLowerCase().endsWith('.pdf'))))
  if (isWidget.value) {
    //we should not need to clear the content state here
    //if the same content is requested it is already loaded
    //clearAllContentState()
    setWidgetTocQuery('')
    await store.dispatch('content/setWidgetScrollToWhereUsed', true)
    mapDtoAndNavToWidgetContent(
        {
          entityId: content.entityId,
          entityType: content.entityType?.toLowerCase(),
          mediaType: content.mediaType?.toLowerCase(),
          identifier: content.identifier,
          updated: content.updated,
          created: content.created,
          name: content.name
        },
        entityIdToIdDtoMap,
        true,
      navigateToDetails? true : false
    )
  } else {
    if (isDocument) {
      const qVal = props.query?.replace('*', '')
      await store.dispatch('content/navigateToContent', { content: content, query: { q: qVal } })
    } else {
      if (navigateToDetails) {
        let path = '/' + content.entityType?.toLowerCase() + '/' + content.entityId + '/details'
        router.push({ path: path, hash: '#whereused' }).catch(() => {})
      } else {
        await store.dispatch('content/navigateToContent', { content })
      }
    }
  }
}

const searchContentType = (type) => {
  emit('searchContent', type)
}
const searchAll = () => {
  emit('searchAll')
}
const suggestionItemText = (value) => {
  return value?.length > 30 ? value?.substring(0, 30) + '...' : value
}

onMounted(async () => {
  await store.dispatch('locales/getLocales')
})
</script>

<style scoped>
.search-suggestions-box {
  background-color: #fff;
  width: 100%;
  border: 2px solid lightgrey;
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  flex: 1;
}

.suggestions-box {
  background-color: #fafafa;
  padding-bottom: .5em;
}

.header-text {
  font-weight: bold;
}

.header-result-text {
  font-weight: bold;
  white-space: nowrap;
  max-width: 20em;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: .25em;
}

.search-suggestions-header-title {
  padding: .25em;
  display: flex;
}

.search-suggestions-item {
  margin-top: .20em;
  margin-left: .25em;
}

.view-more-link {
  float: right;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 1em;
}

.suggestion-column {
  min-width: 18em;
  border-right: lightgray;
  border-right-style: solid;
  border-right-width: 2px;
  position: -webkit-sticky;
  position: sticky;
  @media only screen and (max-width: 1400px) {
    min-width: 15em;
  }
  @media only screen and (max-width: 1200px) {
    min-width: 11em;
  }
  @media only screen and (max-width: 1023px) {
    display: none;
  }
}

.suggestion-results-column {
  width: 100%;
  overflow-y: scroll;
}

.spinner-space {
  position: absolute;
  top: 40%;
  left: 70%;
  @media only screen and (max-width: 1023px) {
    left: 50%;
  }
}
.exact-match-section {
  border-bottom: 2px solid lightgrey;
}
.exact-match-label {
  margin-left: .5em;
}
.globe {
  margin-right: 1em;
}
</style>
