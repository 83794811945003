<template>
  <div class="comments-form"
       v-d-click-outside="checkIsActive">
    <div class="field">
      <p class="control">
        <textarea v-model="text"
                  ref="textArea"
                  data-int="comment-text-area"
                  class="textarea"
                  :class="['textarea', {'active': active}]"
                  :placeholder="$t('addComment') + '...'"
                  rows="2"
                  @focus="focusText"
                  @focusout="checkIsActive"
                  @input="textUpdated"
                  @keyup.enter="enterSubmit"/>
      </p>
    </div>
  </div>
  <div class="is-flex justify-end nav-controls">
    <bulma-field :label="$t('visibility')"
                 class="mr-3"
                 custom-class="mr-2 mb-0 is-center"
                 :is-horizontal="breakPoint > 1125">
      <div class="select is-flex is-flex-direction-column">
        <select v-model="comment.acl"
                @change="textUpdated"
                data-unit="new-comment-access">
          <option v-for="(level, key) in accesslevels"
                  :key="key"
                  :value="key">
            {{ level.label }}
          </option>
        </select>
        <p>{{ $t('whoCanViewComment') }}</p>
      </div>
    </bulma-field>
    <bulma-field :grouped="true"
                 :expanded="true"
                 position="is-right"
                 :group-multiline="false"
                 class="pb-3 control-buttons">
      <template v-if="!comment.id">
        <button class="button mr-3 is-primary"
                :disabled="!comment.text.length"
                data-unit="new-comment-submit"
                @click="submit">
          {{ $t('submit') }}
        </button>
        <button id="lastElement"
                class="button is-primary"
                :disabled="!comment.text.length"
                data-unit="new-comment-clear"
                @click="$emit('cancel')">
          {{ $t('clear') }}
        </button>
      </template>
      <template v-else>
        <bulma-button-icon class="button mr-2"
                           data-unit="edit-comment-cancel"
                           :iconClass="'fas fa-ban'"
                           :text="$t('cancel')"
                           @click="$emit('cancel')">
        </bulma-button-icon>
        <bulma-button-icon class="button mr-3 is-primary"
                           :disabled="!fieldUpdated"
                           :iconClass="'fas fa-save'"
                           :text="$t('update')"
                           data-unit="edit-comment-update"
                           @click="submit">
        {{ $t('update') }}
      </bulma-button-icon>
      </template>
    </bulma-field>
  </div>
</template>

<script>
import {
  accessControlLevels
} from '@/components/admin/content/editor/accessControlBindings'
import BulmaField from "@/components/common/BulmaField.vue"
import BulmaIcon from "@/components/common/BulmaIcon.vue"
import BulmaButtonIcon from "@/components/common/BulmaButtonIcon.vue"
import { useBreakpoints } from"@/helpers/responsiveBreakpoints"
import { ref } from "vue"
import { clone } from "lodash";

export default {
  name: 'CommentsEdit',
  props: {
    comment: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      active: false,
      fieldUpdated: false,
      originalValue: ''
    }
  },
  components: {BulmaButtonIcon, BulmaIcon, BulmaField },
  setup() {
    const breakPoint = ref(useBreakpoints().width)
    return {
      breakPoint
    }
  },
  computed: {
    accesslevels: () => accessControlLevels,
    text: {
      get() {
        return this.comment.text || '';
      },
      set(value) {
        this.comment.text = value;
      }
    },
    isGlobal: {
      get() {
        return this.comment.global;
      },
      set(value) {
        this.comment.global = value;
      }
    },
    isPublicBelowOrg: {
      get() {
        return this.comment.publicBelowOrg;
      },
      set(value) {
        this.comment.publicBelowOrg = value;
      }
    }
  },
  methods: {
    checkIsActive () {
      this.active = !!this.text && !this.focused
      this.$emit('active', this.active)
    },
    enterSubmit(event) {
      if (event.shiftKey) {
        this.submit();
      }
    },
    focusText () {
      this.active = true
      this.$emit('active', this.active)
    },
    async submit() {
      await this.$emit('submit', this.text)
      if (!this.comment.id) this.$emit('cancel')
    },
    textUpdated () {
      this.fieldUpdated = (this.text !== this.originalValue.text) || (this.comment.acl !== this.originalValue.acl)
    }
  },
  mounted() {
    this.originalValue = clone(this.comment)
  }
}
</script>

<style scoped lang="scss">
.textarea {
  max-height: 3em;
  overflow: hidden;
  &.active {
    min-height: 8em;
    max-height: unset;
    overflow: scroll;
    border-color: #3E7ACC;
    box-shadow: 0 0 0 0.125em rgba(62, 122, 204, 0.25);
  }
}
.comments-form {
  padding: 1em 0;
}
.negative-margin {
  margin: 0 -1em;
}
.nav-controls {
  padding-bottom: 1em;

  .control-buttons {
    min-width: 11em;
  }
}
select {
  min-height: 2.5em;
}
.button {
  margin-right: .25rem;
}
.checkbox {
  padding: .5rem 0 .5rem 0;
}
.global {
  margin-left: 1rem;
}
</style>
