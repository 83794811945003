/* eslint-disable max-len */
module.exports =
{
  aOfB: '{a} van {b}',
  about: 'Over',
  aboutThisItem: 'Over dit item',
  accessApiKeyManager: 'Verkrijg toegang tot de API Key Manager voor deze tenant.',
  accessControls: 'Toegangscontroles',
  accessControlsCopiedText: 'Toegangscontroles worden onmiddellijk bijgewerkt. Media veiligheidsmachtigingen zullen gedurende de volgende vijf minuten worden bijgewerkt.',
  accessControlsCopiedTitle: 'Toegangscontroles met succes gekopieerd',
  accessControlsCopyConfirmation: 'Dit zal alle bestaande toegangscontroles verwijderen van {to} en deze worden vervangen met toegangscontroles van {from}. Deze actie kan niet ongedaan gemaakt worden.',
  accessControlsDescription: 'Toegangscontroles bepalen wie deze media kan zien, op basis van geselecteerde Organisaties en Mediacategorieën.',
  accessControlsIndeterminance: 'Opmerking: Ouderorganisaties hebben steeds toegang tot inhoud die werd toegewezen aan (een van) hun kinderen.',
  account: 'Account',
  accountErrorMessage: 'Foutmelding voor account',
  accountErrorMessageWarning: 'Het veld accountErrorMessage in de database wordt ingesteld op “Unauthorized” voor de tenant.',
  action: 'Actie | Acties',
  actionDelete: 'verwijderen',
  actionLoad: 'laden',
  actionPaste: 'Plakken',
  actionSave: 'Opslaan',
  actionRun: 'uitvoeren',
  actionUndoWarning: 'Deze actie kan niet ongedaan gemaakt worden.',
  actionUpload: 'uploaden',
  actualSize: 'Effectieve grootte',
  add: 'Toevoegen',
  addAPIKey: 'Een API-Sleutel Toevoegen',
  addItem: '{item} toevoegen',
  addABookText: 'Een boek toevoegen',
  addASharedCart: 'Gedeelde winkelwagen toevoegen',
  addATemplate: 'Sjabloon toevoegen',
  addATag: 'Label toevoegen',
  addABookToStart: '{addABookText} om te starten.',
  addAForm: 'Voeg een Vorm toe om te starten',
  addAFormOnly: 'Een formulier toevoegen',
  addAMedia: 'Media toevoegen',
  addAnOrderQuoteEmailFormat: 'Een indeling voor een bestelling/offerte e-mail toevoegen',
  addAPrintSettingText: 'Printinstelling toevoegen',
  addAPrintSettingToStart: '{addAPrintSettingText} om te starten.',
  addBooks: 'Boeken Toevoegen',
  addChildBrowseFlow: 'Browse Flow voor Kinderen Toevoegen',
  addChapter: 'Hoofdstuk toevoegen',
  addComment: 'Voeg een opmerking toe',
  addExistingPart: 'Bestaand Deel Toevoegen',
  addGroupsToUsers: '{groups} groepen toevoegen aan {users} gebruikers',
  additionalInformation: 'Bijkomende Informatie',
  additionalNotification: 'Aanvullende Melding',
  additionalPartNumberDescription: 'Gebruik als bijkomend veld voor onderdeelnummer',
  addBrowseFlow: 'Browse Flow Toevoegen',
  addMedia: 'Media Toevoegen',
  addPages: 'Pagina’s Toevoegen',
  addPart: 'Een onderdeel Toevoegen',
  addReports: 'Rapporten toevoegen',
  addRootBrowseFlow: 'Hoofd Browse Flow Toevoegen',
  addRow: 'Rij toevoegen',
  addTemplates: 'Sjablonen toevoegen',
  addToCartMessage: '{partNumber} succesvol toegevoegd',
  addToUserGroup: 'Toevoegen aan Groepen',
  address: 'Adres | Adressen',
  addressInUse: 'Een adres is momenteel ingesteld als standaardadres en kan niet verwijderd worden',
  addressInUseSubtitle: 'Om dit adres te verwijderen, moet u eerst dit adres als standaardadres verwijderen.',
  addressLine: 'Adreslijn {number}',
  addWidgetKey: 'Een widgetsleutel toevoegen',
  admin: 'Beheerder',
  adminCenter: 'Beheercentrum',
adminCenterBanner: {
    bodyLabel: 'Bannerbody',
    enableLabel: 'Adminbanner inschakelen',
    headerGreenOption: 'Groen',
    headerLabel: 'Bannerkop',
    headerRedOption: 'Rood',
    headerYellowOption: 'Geel',
    title: 'Banner van Admin Center',
    typeLabel: 'Bannertype'
  },
  adminCenterMessage: 'Beheercentrum voor inhoud, gebruikers, bibliotheekinstellingen, rapportage en exporteren',
  adminMessage: 'Functies worden toegevoegd naarmate ze beschikbaar zijn',
  administration: 'Beheer',
  affectedBookMedia: '{count} Boekmedia wordt bijgewerkt.',
  affectedTemplateMedia: '{count} Boeksjablonen worden bijgewerkt.',
  after: 'Na',
  aggregatedFromChildren: 'Samenvoeging van Kinderen',
  aiAlgorithm: 'AI-scanner',
  all: 'Alle',
  alphanumeric: 'Alfanumeriek',
  algorithm: 'Algoritme',
  alignHorizontal: 'Horizontaal uitlijnen',
  alignVertical: 'Verticaal uitlijnen',
  allUsersDisabled: 'Alle gebruikers die tot de tenant behoren worden uitgeschakeld.',
  analyticsJSON: 'Analytics JSON',
  andCountMore: 'en {count} meer...',
  any: 'Elk',
  apiKey: 'API-Sleutel | API-Sleutels',
  apiKeyAdmin: 'API Key Admin',
  apiAuthHeader: 'API Authorization Header',
  apiKeyCopied: 'Waarde van API Authorization Header gekopieerd naar klembord',
  apiKeyDeleted: 'API-Sleutel verwijderd',
  apiKeyDeletedError: 'Fout bij verwijderen API-Sleutel',
  apiKeyDeleteMsg: 'Verwijderen van deze API-Sleutel zal het gebruik ervan bij integraties uitschakelen',
  apiKeyDeleteTitle: 'Weet u zeker dat u deze API-Sleutel wilt verwijderen?',
  apiKeyInstructions: 'Sleutel wordt gegenereerd na het Opslaan',
  apiKeyManager: 'API-Sleutelmanager',
  apiKeyNameEmpty: 'Naam kan niet leeg zijn',
  apiKeyRegenError: 'Fout bij opnieuw genereren API-sleutel',
  apiKeyRegenMsg: 'Opnieuw genereren van deze API-Sleutel kan bestaande integraties uitschakelen',
  apiKeyRegenTitle: 'Weet u zeker dat u deze API-Sleutel opnieuw wilt genereren?',
  apiKeySaved: 'API-Sleutel Opgeslagen',
  apiKeyType: 'Type van API Key',
  appliesTo: 'Geldt voor',
  appliesToEmpty: 'Vereisen minstens één geselecteerd entiteitstype',
  appliesToPopulated: 'Instellen voor welke entiteiten de tags kunnen worden toegepast',
  applyItem: '{item} Toepassen',
  applyToChildItems: 'Toepassen op onderliggende{item}',
  applyToPartOnly: 'Alleen op dit onderdeel toepassen',
  april: 'April',
  area: 'Gebied',
  areYouSure: 'Weet u zeker dat u {item} wilt verwijderen?',
  areYouSureCopy: 'Weet u zeker dat u {item} wilt kopiëren?',
  areYouSureYouWantToDisableTheTenant: 'Weet u zeker dat u de tenant wil uitschakelen?',
  assembly: 'Montage | Montages',
  assemblyCreatorSuccessTitle: 'Montage-opdracht aanmaken ingediend',
  assemblyCreatorSuccessSubtitle: 'Hotpoints-links en wijzigingen aan de inhoudstafel van het boek zullen onmiddellijk gemaakt worden. Zoekresultaten zullen weergegeven worden van zodra het indexeren is voltooid.',
  assemblyConfirmationTitle: 'Wilt u het creëren van een montage voltooien?',
  assemblyCreatorHelpText1: 'Met deze functie kunt u een geselecteerde pagina in boeken invoegen op plaatsen waar u dit onderdeel {partName} tegenkomt, en het onderdeel en de geselecteerde pagina worden automatisch gelinkt via een hotpoint link. U kunt optioneel het invoegen beperken tot geselecteerde boeken.',
  assemblyCreatorHelpText2: 'De geselecteerde pagina wordt één keer per boek ingevoegd na de eerste verschijning van {partName}.',
  assemblyStepOne: 'Stap 1: Selecteer de in te voegen pagina',
  assemblyStepTwo: 'Stap 2: Kies de boeken in welke u de pagina wenst in te voegen',
  asset: 'Bedrijfsmiddel | Bedrijfsmiddelen',
  assetsNotFound: 'Geen {type} Gevonden',
  assignBrowseFlow: 'Browse Flow Toewijzen',
  assignedOrganizations: 'Toegewezen Organisaties',
  atLeastFiveError: 'Selecteer een waarde die niet minder is dan 5.',
  attachment: 'Bijlage | Bijlagen',
  attachmentEditorDescription: 'Voeg afbeeldingen, video’s en documenten rechtstreeks toe aan deze entiteit. Opmerking, deze kunt u terugvinden in het tabblad “Gerelateerd”.',
  attachmentView: 'Bijlagen Weergeven',
  attachRelatedPDFs: 'PDF’s van betrokken onderdelenpagina’s toevoegen',
  attributions: 'Bevoegdheden',
  august: 'Augustus',
  automaticZoom: 'Automatische Zoom',
  autoHotpoint: 'Auto hotpoint',
  autoPopulate: 'Automatisch Invullen',
  availability: 'Beschikbaarheid',
  back: 'Achter',
  backCover: 'Cover Achteraan',
  backStitch: 'Stiksel Achter',
  backToLibrary: 'Terug naar Bibliotheek',
  backToSearch: 'Terug naar Zoekopdracht',
  badCredentials: 'Ongeldige Referenties',
  bahasaDescription: 'Bahasa Indonesische beschrijving',
  bahasaName: 'Bahasa Indonesische naam Bahasa',
  banner: 'Banner',
  before: 'Voor',
  beginTypingToFindAnything: 'Begin met typen om iets te vinden',
  betaAccessKeys: 'Beta-toegangssleutels',
  betaResources: 'Betabronnen',
  betaReleaseVideo: 'Beta Release Video',
  blobFinder: 'BLOB Zoeker',
  body: 'Body',
  bomDefaultSupplier: 'Standaard leverancier van stuklijst',
  bomItem: 'Stuklijstitem',
  bom: 'Stuklijst',
  bomExportSuccess: 'Export van stuklijst succesvol',
  bomLayout: 'Lay-out van de Stuklijst',
  bomListOptions: 'BOM lijstopties',
  bomListSettings: 'BOM lijstinstellingen',
  both: 'Beide',
  book: 'Boek | Boeken',
  bookInserts: 'Boek Invoegingen',
  bookLabel: 'Boek | Boeken',
  bookPrintingDisable: 'Boek Afdrukken Uitschakelen',
  booksAndPagesDisplay: 'Weergave Boeken en Pagina’s',
  boost: 'Zoeken Boosten',
  boostDescription: 'Voeg een waarde toe die hoger is dan 1,5 om de zoekrelevantie te boosten voor deze tag wat betreft onderdeelnummers, media-identificatie en namen',
  boostRangeInvalid: 'Boost moet tussen 0,5 en 100,0 liggen',
  border: 'Rand',
  bottom: 'Onderzijde',
  branding: 'Branding',
  browse: 'Bladeren',
  browseComputer: 'Bladeren door Computer',
  browseFlow: 'Browse Flow | Browse Flows',
  browseFlowAssigned: 'Browse Flow Toegewezen',
  browseFlowCopyMessage: 'Voer een unieke naam in voor de nieuwe Browse Flow.',
  browseFlowDeleted: 'Door verwijderde flows bladeren',
  browseFlowDeleteMessage: 'Het verwijderen van een browse flow verwijdert niet alleen de browse flow maar ook al zijn browse flows voor kinderen.',
  browseFlowEmptyState: 'Geen Browse Flows gedefinieerd. {new} om te starten.',
  browseFlowHeirarchy: 'Browse Flow Hiërarchie',
  browseFlowNoExistingCriteria: 'Er zijn geen zoekcriteria ingesteld voor deze browse flow. Gebruik de bovenstaande functies om zoekcriteria te selecteren.',
  browseFlowMoveToRoot: 'Plaats hier een node om een kind op het eerste niveau te maken',
  browseFlowNoAssignedOrgs: 'Geen toegewezen organisaties',
  browseFlowSaveFailed: 'Browse Flow kon niet bijgewerkt worden',
  browseFlowSetDefaultSuccess: 'Standaard Browse Flow set',
  browseFlowThumbnailDeleteFailTitle: 'Miniatuur Verwijderen Mislukt',
  browseFlowThumbnailDeleteSuccessTitle: 'Miniatuur Verwijderen Gelukt',
  browseFlowThumbnailUploadFailTitle: 'Miniatuur Uploaden Mislukt',
  browseFlowThumbnailUploadSuccessTitle: 'Miniatuur Uploaden Gelukt',
  browseFlowTooManyOrgs: 'U heeft teveel organisaties om de huidige browse flow editor te gebruiken. Gelieve Documoto Support te contacteren via {email} voor hulp bij het bewerken van uw Documoto browse flows.',
  browserPartiallySupport: 'Mogelijk ziet u esthetische verschillen en andere wijzigingen in deze browser.',
  browseFlowSaved: 'Browse Flow opgeslagen',
  browserDeprecation: {
    title: 'Beëindigingsmelding Browser',
    text: 'In overeenstemming met de geleidelijke stopzetting van de ondersteuning van Internet Explorer (alle versies) door Microsoft en de daarmee verband houdende veiligheidsoverwegingen, zullen wij de toegang van gebruikers tot deze site via Internet Explorer (alle versies) na 30 september 2021 niet langer ondersteunen.'
  },
  buildHotpointLinks: 'Hotpointlinks Aanmaken',
  buildHotpointLinksInfo: 'Deze actie zal eerst alle bestaande hotpointlinks verwijderen van de boekenpagina’s, vervolgens nieuwe hotpointlinks creëren tussen onderdelen en corresponderende montagepagina’s',
  buildHotpointLinksTitle: 'Wilt u hotpointlinks aanmaken voor dit boek?',
  bulkIndexingLane: 'Strook voor bulkindexering',
  bulkInsert: 'Bulk Invoegen',
  bulkExportConfirm: 'Wilt u {n} {unit} exporteren?',
  bulkExportLimit: 'Limiet is {limit} boeken per exportopdracht',
  bulkPageInsertJob: 'Pagina invoegen in bulk',
  business: 'Bedrijf',
  cancel: 'Annuleren',
  canceled: 'Geannuleerd',
  cancelAssemblyCreatorTitle: 'Uw montage wegwerpen?',
  cancelAssemblyCreatorSubtitle: 'Uw montage creëren zal niet verwerkt worden.',
  cannotExceed100: 'Kan 100% niet overschrijden',
  cart: 'Winkelwagen | Winkelwagens',
  cartBehaviorEnabled: 'Prompt Hoeveelheid Toevoegen aan Winkelwagen Inschakelen',
  cartBehaviorDisabled: 'Prompt Hoeveelheid Toevoegen aan Winkelwagen Uitschakelen',
  cartBehaviorLabel: 'Gedrag Toevoegen aan Winkelwagen',
  cartColumn: 'Winkelwagen Kolom | Winkelwagen Kolommen',
  cartColumn1: '1e Winkelwagen Prijskolom',
  cartColumn2: '2e Winkelwagen Prijskolom',
  cartColumn3: '3e Winkelwagen Prijskolom',
  cartColumnPartLocationContent1: 'Het inschakelen van deze functie zal de volgende gevolgen hebben:',
  cartColumnPartLocationContent2: 'De uniciteit van onderdeelrijen in het winkelwagentje wordt ook gebaseerd op hun locatie binnen een contentonderdeel, indien van toepassing.',
  cartColumnPartLocationContent3: 'Deze instelling kan niet worden uitgeschakeld op organisatieniveau en zal van toepassing zijn op alle gebruikers.',
  cartColumnPartLocationContent4: 'Deze instelling mag niet worden ingeschakeld als u een Submit Cart-integratie hebt zonder eerst contact op te nemen met uw Documoto Klanten Succes Manager.',
  cartColumnPartLocationOption: 'Onderdeellocatiegegevens Inschakelen in Winkelwagen',
  cartColumnPartLocationTitle: 'Weet u zeker dat u Onderdeellocatiegegevens in Winkelwagen wilt inschakelen?',
  cartDisable: 'Winkelwagen Uitschakelen',
  cartEmailFailed: 'E-mail inhoud winkelwagen verzenden is mislukt',
  cartEmailSent: 'E-mail inhoud winkelwagen verzenden is gelukt',
  cartEnable: 'Winkelwagen Inschakelen',
  cartSaveEnable: 'Winkelwagen Opslaan Inschakelen',
  cartErpMessage: 'Succesvol verzonden naar Erp',
  cartImportError: 'Fout bij importeren van winkelwagen',
  cartLoadedWithItemsRemoved: '{count} item(s) verwijderd omdat u geen machtiging hebt om ze op dit moment te bestellen',
  cartSharedAddEdit: 'Gedeelde Winkelwagens Toevoegen/Bewerken',
  cartUpdated: 'Winkelwagentje Bijgewerkt',
  cartUpdatedAndRemovedCount: 'Winkelwagen bijgewerkt; {count} niet bestelbare onderdelen verwijderd',
  category: 'Categorie | Categorieën',
  categoryCreated: 'Categorie Aangemaakt',
  categoryUpdated: 'Categorie Bijgewerkt',
  centimeter: 'Centimeter | Centimeters',
  changeNotification: 'Melding wijzigen',
  changeScope: '{n} {type} zal worden beïnvloed',
  changeUserOrganization: 'Organisatie Wijzigen',
  chapter: 'Hoofdstuk | Hoofdstukken',
  chapterHeader: 'Hoofdstuk Koptekst',
  chaptersOnly: 'Alleen Hoofdstukken',
  chineseDescription: 'Chinese beschrijving',
  chineseName: 'Chinese naam',
  circle: 'Cirkel',
  city: 'Plaats',
  classicAdminMessage: 'Hiermee wordt u doorgestuurd naar Flex om de beheerdermodules te gebruiken.',
  classicAdmins: 'Klassieke Beheerders',
  classicExporter: 'Klassieke Exporteur',
  classicExporterMessage: 'Oude Flash-gebaseerde inhoud exportmodule',
  classicOrganizationAdmin: 'Klassiek Organisatiebeheer',
  classicOrganizationAdminMessage: 'Oude Flash-gebaseerde beheermodule voor het beheren van organisaties en gebruikers',
  classicPublisher: 'Klassieke Uitgever',
  classicPublisherMessage: 'Oude Flash-gebaseerde uitgevermodule voor contentcreatie en printinstellingen',
  classicReporting: 'Klassieke Rapportage',
  classicReportingMessage: 'Oude Flash-gebaseerde rapportagemodule',
  classicTenantAdmin: 'Klassiek Tenantbeheer',
  classicTenantAdminMessage: 'Oude Flash-gebaseerde beheermodule voor instellingen van alle tenants',
  className: 'Klassenaam',
  clear: 'Wissen',
  clearItemNumber: 'Itemnummer wissen',
  clearItemNumberTitle: 'Wilt u het itemnummer wissen?',
  clearItemNumberMessage: 'Het wissen van het itemnummer zal leiden tot het verwijderen van de volgende gegevens:',
  clearFilters: 'Filters Wissen',
  clearHotpoints: 'Hotpoints wissen',
  clearSearch: 'Zoekopdracht wissen',
  clickHereToUpload: 'Zet het bestand hier neer of klik om een overschrijving te uploaden.',
  clickMagnifyingGlass: 'Klik op het vergrootglas om onmiddellijk naar Advanced Search te gaan.',
  clickOkToContinue: 'Klik op “OK” om door te gaan.',
  clickToDownload: 'Klik om te Downloaden',
  clickToEnlarge: 'Klik om te vergroten',
  clickToOpen: 'Klik om te Openen',
  clipboard: 'Klembord',
  clipboardAccessError: 'Pas uw browserinstellingen aan om toegang tot het Klembord mogelijk te maken.',
  close: 'Sluiten',
  closed: 'Gesloten',
  collapseAll: 'Alles Samenvouwen',
  color: 'Kleur | Kleuren',
  column: 'Kolom | Kolommen',
  columnName: 'Kolomnaam',
  columnItemType: {
    customText: 'Aangepaste Tekst',
    documentTitle: 'Documenttitel',
    horizontalLine: 'Horizontale Lijn',
    pageName: 'Paginanaam',
    pageNumber: 'Paginanummer',
    printDate: 'Printdatum',
    tenantName: 'Naam Huurder',
    userName: 'Gebruikersnaam'
  },
  combinedPage: 'Gecombineerde Pagina',
  combinedPageMessage: 'Diagram bovenaan, onderdelenlijst onderaan',
  comingSoon: 'Komt Binnenkort',
  commaDelimited: 'Door komma‘s gescheiden',
  comment: 'Opmerking | Opmerkingen',
  commentAdded: 'Opmerking toegevoegd',
  commentDeleted: 'Opmerking verwijderd',
  commentEdit: 'Opmerkingen Toevoegen/Bewerken',
  commentEditAll: 'Alle Opmerkingen Bewerken',
  commentUpdated: 'Opmerking bijgewerkt',
  commentView: 'Opmerkingen Weergeven',
  commerceSystem: 'Handelsysteem',
  completed: 'Voltooid',
  confirm: 'Bevestigen',
  confirmBrowseFlowApplyToChildren: 'Wilt u deze browse flow toepassen voor kinderorganisaties?',
  confirmBrowseFlowOverrideToChildren: 'Dit zal alle browse flow-overschrijvingen verwijderen die ingesteld zijn voor de kinderorganisaties.',
  confirmPrintSettingsApplyToChildren: 'Wilt u deze printinstelling toepassen voor kinderorganisaties?',
  confirmPrintSettingsOverrideToChildren: 'Dit zal alle printinstelling overschrijvingen verwijderen die ingesteld zijn voor de kinderorganisaties.',
  contains: 'Bevat',
  containsHeaderRow: 'Bevat koprij',
  content: 'Content | Contents',
  contentAccessControls: '{content} Toegangscontrole',
  contentEntity: 'Contententiteit | Contententiteiten',
  contentNotFound: 'Content niet gevonden',
  contentUpdatedQueueJob: 'Inhoud bijgewerkte wachtrij',
  contourShape: 'Contourvorm',
  convertEntity: '{entity} Omvormen',
  convertToEntity: 'Omvormen naar {entity}',
  copyAccessControls: 'Toegangscontroles Kopiëren',
  copyAccessControlsWarning: 'Dit zal alle toegewezen toegangscontroles van een geselecteerde Organisatie kopiëren naar deze huidige Organisatie',
  copy: 'Kopiëren',
  copyAndRetain: 'Kopiëren & Behouden voor Gebruik Tijdens Integratie',
  copyAndRetainWidget: 'Kopiëren & Behouden, dit is de enige keer dat de waarden van de widgetautorisatie voor u zichtbaar zal zijn in de applicatie',
  copyAtoB: '{a} naar {b} Kopiëren',
  copyContent: '{type} Kopiëren? {name}',
  copyingItem: 'Kopiëren van {item}',
  copyItem: '{item} Kopiëren',
  copyItemQuestion: '{item} kopiëren?',
  copyTo: 'Kopiëren naar {target}',
  copyMeMessage: 'Stuur me een kopie van dit bericht',
  copyrightMessage: '© 2010-{year} Documoto Inc. Alle rechten voorbehouden.',
  count: 'Count',
  country: 'Land | Landen',
  coverPages: 'Voorpagina’s',
  coverAmpStitchPages: 'Voorpagina’s & Pagina’s Stikken',
  createAssembly: 'Montage Aanmaken',
  created: 'Aangemaakt',
  createdBy: 'Aangemaakt door {name}',
  createdDate: 'Aanmaakdatum',
  createdOn: 'Aangemaakt op',
  currentPassword: 'Huidig Wachtwoord',
  custom: 'Aangepast',
  czechDescription: 'Tsjechische beschrijving',
  czechName: 'Tsjechisch naam',
  dashboard: 'Dashboard | Dashboards',
  dashboardLibrary: 'Dashboard Library',
  dataInsights: 'Data Insights',
  dataInsightsInfoMessage: 'Reporting and Dashboard Center voor inzichten in content, gebruikers en bibliotheek.',
  dataMapping: 'Gegevensmapping/Voorbeeldgegevens (tot 10 rijen)',
  dataOptions: 'Gegevensopties',
  date: 'Datum',
  dateModified: 'Wijzigingsdatum',
  debugPageBuilderPLZ: 'Paginabouwer PLZ debuggen',
  december: 'December',
  decreaseHotpoint: 'Hotpoint verminderen',
  default: 'Standaard',
  defaultAddress: 'Standaard Adres',
  defaultBrowseFlow: 'Standaard Browse Flow',
  defaultCurrencyCode: 'Standaard Valutacode',
  defaultCurrencySymbolOn: 'Standaard valutasymbool aan',
  defaultEmailRecipient: 'Standaard E-mail Ontvanger | E-mail Ontvangers',
  defaultImage: 'Standaard Afbeelding | Standaard Afbeeldingen',
  defaultImagesManager: 'Standaard Beheerder Afbeeldingen',
  defaultItemImage: '{item} Standaard Afbeelding',
  defaultPartsListLayout: 'Standaard Lay-out Onderdelenlijst',
  defaultPartsListLayoutHelp: 'Standaard lay-out onderdelen links of onderaan de illustratie',
  defaultSearchMode: 'Standaard Zoekmodus',
  defaultSearchModeHelp: 'Standaard globale zoekmethode voor \'bevat\' of \'gelijk aan\'',
  defaultSortMode: 'Standaard Sorteermodus',
  defaultSupplier: 'Standaardleverancier',
  defaultTocStateOpen: 'Standaard INHOUDSTAFEL Status Open',
  defaultTocStateOpenHelp: 'Bepaalt of de INHOUDSTAFEL automatisch moet openen wanneer u een boek bekijkt',
  defaultUom: 'Standaard UOM',
  defaultValue: 'Standaard Waarde',
  defaults: 'Standaarden',
  defaultsAndCapabilities: 'Standaarden en Mogelijkheden',
  delete: 'Verwijderen',
  deleteAll: 'Overnemen Van Ouder',
  deleteAnyPartnerApiKeys: 'Verwijder alle Partner API Keys die zijn verstrekt aan SmartEquip.',
  deleteContent: 'Weet u zeker dat u {type} wilt verwijderen? {name}',
deletePart: {
    infoOne: 'Verwijderde onderdelen zijn onmiddellijk van kracht in Library en Content Admin. Het verwijderde onderdeel kan nog gezocht worden tot een paar minuten later het herindexeren heeft plaatsgevonden.',
    deletingPartNumber: '{partNumber} wordt verwijderd.',
    affectedCarts: 'Uit alle winkelwagens die dit onderdeel bevatten, zal dat onderdeel permanent worden verwijderd.',
    infoTwo: 'Deze actie kan niet ongedaan worden gemaakt zonder hulp van Documoto Support. Na 90 dagen worden het verwijderde record en alle koppelingen definitief verwijderd.'
  },
deletePartInUse: {
    title: 'Dit onderdeel behoort tot pagina(´s) en kan niet worden verwijderd.',
    body: 'Documoto ondersteunt momenteel alleen het verwijderen van onderdelen die niet tot pagina´s behoren. Verwijder dit onderdeel van gepubliceerde of conceptpagina´s om verder te gaan met verwijderen.',
    submitLabel: 'Ga naar pagina waar dit wordt gebruikt'
  },
  deleteRelated: 'Weet u zeker dat u {count} {type} wil verwijderen?',
  deletedItem: '{item} verwijderd',
  deleteFilesMessage: 'Weet u zeker dat u {count} bestand(en) wilt verwijderen?',
  deleteHotpointLinksTitle: 'Weet u zeker dat u hotpoint-link(s) wil verwijderen?',
  deleteMessage: 'Weet u zeker dat u dit wilt verwijderen?',
  deleteSelectedCriteria: 'Geselecteerde Criteria Verwijderen',
  deleteTagsTitle: 'Weet u zeker dat u tag(s) wil verwijderen?',
  deleteTaxonMessage: 'Dit zal dit taxon, samen met de kinderen, voorgoed verwijderen. Bent u zeker dat u verder wilt gaan?',
  deleteTaxonomyMessage: 'Dit zal alle taxons verwijderen voor deze Organisatie of Tenant. Bent u zeker dat u verder wilt gaan?',
  deleteTenantSupplier: 'Weet u zeker dat u deze leverancier voor deze tenant wil verwijderen?',
  demo: 'Demo',
  description: 'Beschrijving',
  deselectAll: 'Deselecteer Alle',
  deselectCriteria: 'Deselecteer Criteria',
  detail: 'Detail | Details',
  diagonal: 'Diagonaal',
  diameter: 'Diameter',
  disable: 'Uitschakelen',
  disableAll: 'Alles uitschakelen',
  disableDocumotoTenant: 'Documoto Tenant uitschakelen',
  disableFwdReindex: 'Doorsturen van herindexeren doelmedia voor DSW uitschakelen',
  disableLibraryBannerImage: 'Afbeelding van Library Banner uitschakelen',
  disableSmartEquipModalTitle: 'Weet u zeker dat u deze licentie wil uitschakelen?',
  disableTEK: 'Direct URL Login uitschakelen',
  disabled: 'Uitgeschakeld',
  discard: 'Verwerpen',
  discardChanges: 'Wijzigingen verwerpen',
  discardQ: 'Wijzigingen verwerpen?',
  discounted: 'Korting',
  discountedPrice: 'Prijs met Korting',
  discountedTotal: 'Totaal met Korting',
  discountPrice: 'Prijs met korting',
  displayInOrdersList: 'Weergeven in Lijst met Orders',
  displayOrder: 'Bestelling Weergeven',
  displayOrderDescription: 'Stelt de weergavevolgorde in van het dropdown menu van de Filters voor contenttypetags',
  document: 'Document | Documenten',
  documentLabel: 'Document | Documenten',
  documentSettings: 'Documentinstellingen',
  documotoAcademy: 'Documoto Academy',
  documotoLicensePackage: 'Documoto-licentiepakket',
  domain: 'Domein',
  domainEmptyMessage: 'Allowed Domain mag niet leeg zijn',
  domainInvalidMessage: 'Een geldige domeinnaam is vereist',
  download: 'Downloaden',
  downloadBrowser: 'Download een van deze geweldige browsers.',
  downloadFailed: 'Fout tijdens downloaden van {name}',
  downloadStarted: 'Bezig met downloaden van {name}',
  draft: 'Concept| Concepten',
  draftBomDeleteTitle: 'Weet u zeker dat u dit deel van de pagina wil verwijderen?',
  draftBomMultipleDeleteTitle: 'Weet u zeker dat u deze onderdelen van de pagina wil verwijderen?',
  draftBomDropError: 'Kan onderdelen niet meer dan 5 niveaus diep nesten',
  draftBomEmptyField: 'Ale velden in de onderdelenlijst moeten worden ingevuld',
  draftBomQuantityError: 'Hoeveelheid moet een getal zijn',
  draftBomError: 'Fout bij manipuleren van stuklijst',
  draftPage: 'Conceptpagina',
  draftPageDelete: 'Concept verwijderen',
  draftPageDeleteMessage: 'Conceptpagina succesvol verwijderd.',
  draftPageDeleteWarning: 'Verwijderen van paginaconcept zal updates die bezig zijn verwijderen.',
  draftPageLastUpdated: 'Concept - Laatst bijgewerkt op {date} door {username}',
  draftPageLoadError: 'Concept kon niet worden geladen.',
  draftPageProcessingPlz: 'Pagina wordt geladen vanaf het PLZ-bestand',
  draftPagePublishInProgress: 'Taak om pagina te publiceren is bezig',
  draftPageSaveSuccess: 'Conceptpagina succesvol opgeslagen.',
  draftPageSaveUnsuccessful: 'Conceptpagina niet succesvol opgeslagen.',
  draftSheetImageHotpointsFailure: 'Kan geen {algoritme} hotpoints genereren voor draftsheet afbeelding.',
  draftValidationMessage: {
    item: 'WAARSCHUWING: Onderdeelnummer {partNumber} van stuklijst heeft geen gespecificeerd itemnummer',
    empty: 'Geen validatiefouten/Waarschuwingen',
    hotpointOrHotpointLink: 'BOM item {item} heeft geen hotpoint(s)/hotpointkoppeling(en) gespecificeerd',
    quantity: 'BOM item {item} heeft geen hoeveelheid gespecificeerd',
    tag: 'BOM item {item} tag {tagName} ontbrekende waarde van het tag',
    translationDescription: 'BOM item {item} ontbrekende {lang} vertaling van beschrijving',
    uom: 'BOM item {item} heeft geen meeteenheid gespecificeerd',
    supersede: 'Onderdeel {partNumber} heeft {supersedPartNumber} vervangen als gevolg van de opvolging.'
  },
  drawAutohotpointRegion: 'Teken {shape} grootte',
  drawMinimumArea: 'Teken minimaal gebied',
  dropFileMessage: 'Zet het bestand hier neer of klik om te uploaden.',
  duplicate: 'Dupliceren',
  duplicateSaveError: 'Dubbele invoer. Kan niet opslaan.',
  dutchDescription: 'Nederlandse beschrijving',
  dutchName: 'Nederlandse naam',
  dynamic: 'Dynamisch',
  dynamicNaming: 'Dynamische Naam Geven',
  ecommerce: 'e-Commerce',
  ecommerceEmailFormatCreated: 'Ecommerce e-mailindeling aangemaakt',
  ecommerceEmailFormatSaved: 'Ecommerce e-mailindeling opgeslagen',
  ecommerceLogo: 'e-Commerce Logo',
  ecommerceUseExtOrder: 'Extern bestelnummer e-commerce gebruiken',
  edit: 'Bewerken',
  editItem: 'Bewerk {item}',
  email: 'E-mailadres',
  emailFormat: 'E-mailindeling',
  emailFormatTemplate: 'Sjabloon e-mailindeling | Sjablonen e-mailindeling',
  emailList: 'E-maillijst',
  emailMessage: 'Een e-mail is vereist',
  emailRecipient: 'E-mail Ontvanger | E-mail Ontvangers',
  emailRecipientMessage: 'Deze e-mailadressen zullen een e-mail ontvangen wanneer de bestelstatus verandert in',
  emptyMessage: {
    orderSuggestions: 'Het lijkt erop dat er nog geen bestelsuggesties zijn voor dit onderdeel.'
  },
  emptyBomState: 'Geen stuklijstitems gedefinieerd voor pagina. Klik op +{add} om te beginnen',
  enable: 'Inschakelen',
  enable3d: '3D inschakelen',
  enableAll: 'Alles inschakelen',
  enableAPIKeyAdmin: 'API-sleutel admin inschakelen',
  enabled: 'Ingeschakeld',
  enableCommerce: 'Handel Inschakelen',
  enableEcommerceOrderManagement: 'Order Management inschakelen',
  enableExporter: 'Exporter inschakelen',
  enableExportToLinkOne: 'Exporteren naar LinkOne inschakelen',
  enableExportToSmartEquip: 'Exporteren naar SmartEquip inschakelen',
  enablePDFDownloadWarning: 'Waarschuwing voor het downloaden van grote PDF inschakelen',
  largePDFDownloadWarning: 'Omwille van de grote omvang van het PDF-bestand kan het langer duren dan normaal om het document te laden en weer te geven in de applicatie. Wees geduldig terwijl het bestand wordt gedownload om te kunnen bekijken.',
  enableIframeSupport: 'iFrame Support inschakelen',
  enableLibraryBetaFeatures: 'Betafuncties van Library inschakelen',
  enablePerformanceLogging: 'Prestatielogging inschakelen',
  enablePLZToDraft: 'PLZ-proces naar concept inschakelen',
  enableQuickAddToCart: 'Quick Add to Cart inschakelen',
  enableQuickAddToCartHelpContext: 'Instellen op \’Enable\’ zal de functie Quick Add to Cart boven de content viewer voor deze content inschakelen.',
  enableRocketUi: 'Rocket User Experience Inschakelen',
  enableSmartEquipApiIntegration: 'SmartEquip API Integration inschakelen',
  enableWidgetLogo: 'Logo van Documoto-widget inschakelen',
  enableWidgets: 'Widgets inschakelen',
  encryptedKey: 'Geëncrypteerde Sleutel',
  endDate: 'Einddatum',
  endDateBad: 'De startdatum moet gelijk zijn aan of voor de einddatum liggen.',
  enforceNumericSize: 'Numerieke Maat Forceren',
  englishDescription: 'Engelse beschrijving',
  englishName: 'Engelse naam',
  enterprise: 'Onderneming',
  enterReportParameters: 'Rapportparameters Invoeren',
  enterUniqueNameForNewItem: 'Voer een unieke naam in voor het nieuwe {item}',
  enterValidNumber: 'Gelieve een geldig nummer in te voeren',
  enterValidEmail: 'Gelieve een geldig e-mailadres in te voeren',
  entityType: 'Entiteitstype | Entiteitstypes',
  erp: 'ERP',
  erpEndpointConfig: 'ERP-eindpuntconfiguratie',
  erpPartInfo: 'Cacheduur ERP-onderdeelinfo in minuten',
  erpSystem: 'ERP-systeem',
  erpSystems: 'ERP-systemen',
  erpSystemSaved: 'ERP-systeem opgeslagen',
  error: 'Fout',
  errorDeletePageMessage: 'Er is een fout opgetreden tijdens het verwijderen van de pagina.',
  errorInputParsing: 'Probleem met parseren van invoerbestand.',
  errorPageMessage: 'Er is een fout opgetreden.',
  errorPageTitle: 'Er is hier iets fout...',
  errorType: {
    error: 'Fout',
    warning: 'Waarschuwing'
  },
  essential: 'Essentiële onderdeel | Essentiële onderdelen',
  eta: 'ETA',
  etlJob: 'ETL',
  exact: 'Exact',
  excelFile: 'Excel-bestand',
  expandAll: 'Alles Uitklappen',
  export: 'Exporteren',
  exportSearch: 'Zoeken Exporteren',
  exportAsCsv: 'Exporteren als CSV (Vereist Formaat voor Importeren)',
  exportAsCSVDropdown: 'Exporteren als CSV',
  exportAsHtml: 'Exporteren als HTML',
  exportAsPdf: 'Exporteren als PDF',
  exportAsPlz: 'Exporteren als PLZ',
  exportAsXlsx: 'Exporteren als XLSX',
  exportBasicPartInfo: 'Basis Onderdeelinformatie Exporteren',
  exportCartToXlsxDisabled: 'Winkelwagen Rxporteren naar XLSX Uitschakelen',
  exportCartToXlsxEnabled: 'Winkelwagen Exporteren naar XLSX Inschakelen',
  exportDisplayedCartColumns: 'Weergegeven wWnkelwagenkolommen Exporteren',
  exportJob: 'Taak Exporteren',
  exportLane: 'Strook exporteren',
  exportMessage: 'Exporttaak succesvol verzonden.',
  exporter: 'Exporteur',
  exporterMessage: 'Hiermee kunt u inhoud exporteren',
  exportParts: 'Onderdelen exporteren',
  external: 'Extern | Externen',
  externalUrl: 'Externe URL',
  faceted: 'Gefacetteerd',
  facetedTag: 'Filterwaarden moeten gefacetteerd zijn',
  facetedTagTooMany: 'Tag overschrijdt 1.000 unieke tag waardelimiet',
  failedAction: '{content} mislukt om {action}.',
  failedProcessContentSql: 'Procesinhoud mislukt omwille van niet-gemachtigde tekst. Upload de ondersteunde content opnieuw.',
  failedToCopy: '{content} mislukt om te kopiëren.',
  failedToDelete: '{content} mislukt om te verwijderen.',
  failedToLoad: '{content} kon niet worden geladen.',
  failedToSave: '{content} mislukt om op te slaan.',
  failedToSubmitItem: 'Mislukt om {item} in te dienen',
  failedToUpdate: '{content} mislukt om bij te werken.',
  false: 'Onwaar',
  faq: 'FAQ',
  favorites: 'Favorieten',
  favoritesLists: 'Lijst met Favorieten',
  february: 'Februari',
  feedbackForm: 'Feedback Indienen',
  feedbackFormMessage: 'Gelieve input te geven over de functies die u zou willen zien',
  fieldDefinition: 'Velddefinitie',
  field: 'Veld | Velden',
  fieldName: 'Veldnaam',
  file: 'Bestand | Bestanden',
  filename: 'Bestandsnaam',
  filesSubmitted: 'Bestand(en) ingediend voor verwerking',
  fileUpload: 'Bestand uploaden',
  fill: 'Vullen',
  filter: 'Filter | Filters',
  filterOrgNameOnly: 'Filter: Alleen Organisatienaam',
  filterOrgParentNames: 'Filter: Organisatie & Bovenliggende Namen',
  filterable: 'Filterbaar',
  filterableTag: 'Filterbare Tag',
  filterBy: 'Filteren Op',
  filterBoundsError: 'Filter moet 3 of meer karakters bevatten',
  finnishDescription: 'Finse beschrijving',
  finnishName: 'Finse naam',
  firstName: 'Voornaam',
  firstNameEmpty: 'Voornaam mag niet leeg zijn',
  fixedHotpointSize: 'Vast Hotpointformaat',
  font: 'Lettertype | Lettertypes',
  footer: 'Voettekst',
  forgotPassword: 'Wachtwoord Vergeten',
  forgotPasswordMessage: 'Een e-mail met instructies om uw wachtwoord opnieuw in te stellen wordt verzonden naar de opgegeven gebruikersnaam.',
  forgotPasswordSuccess: 'Als de opgegeven gebruikersnaam geldig is, wordt een e-mail met instructies om uw wachtwoord opnieuw in te stellen verzonden naar het gekoppelde adres.',
  form: 'Vorm | Vormen',
  format: 'Formaat',
  formattingOptions: 'Indelingopties',
  formCount: 'Formuliertelling',
  formName: 'Formuliernaam',
  formType: 'Formuliertype',
  freezeImage: 'Afbeelding bevriezen',
  frenchDescription: 'Franse beschrijving',
  frenchName: 'Franse naam',
  fri: 'Vr.',
  from: 'Van',
  fromDate: 'Van Datum',
  frontCover: 'Cover Vooraan',
  frontStitch: 'Stiksel Voor',
  fullScreen: 'Volledig scherm',
  gdprFaqs: 'Veelgestelde Vragen over GDPR',
  gdprMessage: 'Door aan te melden in deze sectie van onze website begrijpt u dat wij essentiële cookies gebruiken. Deze cookies zijn essentieel om u door onze Services te laten navigeren. Zonder deze cookies kunnen we de door u gevraagde services niet aanbieden. Raadpleeg voor meer informatie ons {policy}.',
  general: 'Algemeen',
  generalSearch: 'Algemene Zoekopdracht',
  generic: 'Generiek',
  germanDescription: 'Duitse beschrijving',
  germanName: 'Duitse naam',
  gotIt: 'Hebbes',
  goTo: 'Ga naar',
  goToHome: 'Ga naar Startpagina',
  goToJobManager: 'Ga naar Taakmanager',
  goToSearch: 'Ga naar Zoekopdracht',
  hashKey: 'Hekjestoets',
  headerAmpFooter: 'Kop- en Voettekst',
  header: 'Koptekst',
  help: 'Help',
  helpCenter: 'Helpcentrum',
  highlightDescription: 'Onderdelen met deze tag in onderdelenlijst markeren',
  history: 'Geschiedenis',
  home: 'Startpagina',
  horizontal: 'Horizontaal',
  host: 'Host',
  hotpoint: 'Hotpoint | Hotpoints',
  hotpointCalloutBehavior: 'Hotpoint afroepgedrag',
  hotpointLinksWillBeCreated: 'Hotpoint links zullen aangemaakt worden in {count} {content}',
  hotpointLink: 'Hotpoint-link | Hotpoint-links',
  hotpointsGenerated: '{count} hotpoints gevonden op de afbeelding.',
  hotpointShift: 'Hotpoint (Shift H)',
  hourlyDWSRequestLimit: 'Uurlimiet voor DWS-aanvragen',
  hourlyRESTRequestLimit: 'Uurlimiet voor REST-aanvragen',
  htmlUiUserSwitchEnabled: 'HTML UI gebruikersschakelaar ingeschakeld',
  hungarianDescription: 'Hongaarse beschrijving',
  hungarianName: 'Hongaarse naam',
  icon: 'Pictogram',
  id: 'ID',
  identifier: 'Identificatie',
  ignore: 'NEGEREN',
  ignoreAccessControls: 'Toegangscontroles Negeren',
  ignoreMatchingTranslations: 'Negeer vertalingen met overeenkomende landinstellingen bij het Importeren',
  illustration: 'Illustratie | Illustraties',
  image: 'Afbeelding | Afbeeldingen',
  imageDeleteMessage: 'Weet u zeker dat u deze pagina-afbeelding wil verwijderen?',
  imageUploadFailure: 'Afbeelding opladen mislukt',
  imageSaveFail: 'Afbeelding opslaan mislukt',
  imageOptimizer: 'Afbeelding Optimizer',
  imageSizeExceededTitle: 'Maximale afbeeldingsgrootte overschreden',
  imageSizeExceededMessage: 'De maximale bestandsgrootte voor Documoto is {size} megabytes. De afbeelding die u probeert op te laden heeft de maximale grootte overschreden en kan niet worden geladen. Gelieve de afbeelding te verkleinen om deze toe te voegen aan deze pagina.',
  imageDimensionsExceededMessage: 'De maximale afbeeldingsgrootte in Documoto is 65,5 megapixels (8.191 X 8.191 pixels).  De afbeelding die u probeert op te laden heeft de maximale grootte overschreden en kan niet worden geladen. Klik op “Formaat wijzigen" om Documoto toe te staan om het formaat te wijzigen en de afbeelding in te voegen, of “Annuleren" om de afbeelding te bewerken en handmatig in te voegen',
  import: 'Importeren',
  importBOM: 'BOM importeren',
  importBOMError: 'Verwerking van voorbeeldgegevens mislukt, controleer de invoerbron opnieuw',
  importParts: 'Onderdelen importeren',
  inCart: 'In Winkelwagen',
  inCartDescription: 'Tags in winkelwagen weergeven',
  includePagePartDetails: 'Details op pagina onderdeelniveau toevoegen',
  includePartDetails: 'Details op onderdeelniveau toevoegen',
  indexQueueJob: 'Indexwachtrij',
  inPartsList: 'In Onderdelenlijst',
  inPartsListDescription: 'Tags in onderdelenlijst weergeven',
  inputSource: 'Invoerbron',
  inch: 'Inch | Inches',
  includeComments: 'Opmerkingen Opnemen',
  includeCommentsAuthorAndDate: 'Auteur en Datum Opmerking Opnemen',
  includeHeaderRow: 'Koprij toevoegen',
  includeImages: 'Afbeeldingen Opnemen',
  includePagesIndex: 'Pagina-index ‘Opnemen',
  includePartsIndex: 'Onderdelenindex Opnemen',
  includePartsList: 'Onderdelenlijst Opnemen',
  includeSubChapters: 'Subhoofdstukken Opnemen',
  indexLane: 'Indexstrook',
  useSequentialPageNumbering: 'Opeenvolgende Paginanummering Gebruiken',
  hideBookInSearchAndRecent: 'Boeken in Zoekresultaten & Recent Toegevoegd Verbergen',
  hideDuplicatePages: 'Duplicaatpagina‘s Verbergen',
  hidePageInSearchAndRecent: 'Pagina’s in Zoekresultaten & Recent Toegevoegd Verbergen',
  hidePartInSearchAndRecent: 'Onderdelen in Zoekresultaten & Recent Toegevoegd Verbergen',
  homePageSettings: 'Instellingen homepagina',
  hotpointZoomBehavior: 'Hotpoint zoomgedrag',
  includeNewParts: 'Inclusief alleen „nieuwe” onderdelen (niet in Documoto)',
  includeWatermark: 'Inclusief watermerk?',
  increaseHotpoint: 'Hotpoint verhogen',
  indexed: 'Geïndexeerd',
  indexingConfiguration: 'Indexeringsconfiguratie',
  indexIncludeTimestamp: 'Index omvat tijdstempel',
  info: 'Info',
  information: 'Informatie',
  inherit: 'Overnemen',
  inheritedParent: 'Overgenomen van Ouder.',
  inputSpecification: 'Invoerspecificatie',
  inputType: 'Inputtype',
  inputTypeListboxWarning: 'Sorteerinstellingen zullen weergegeven worden na het opslaan',
  integration: 'Integratie | Integraties',
  integrationsAddPartToErpCart: '{action} Onderdeel Toevoegen aan ERP-Wagentje',
  integrationsAddToShoppingCartPricingFromErp: '{action} Toevoegen aan Winkelwagen - Prijzen uit ERP',
  integrationsDisableErp: 'ERP Uitschakelen',
  integrationsDoErpForQuotes: 'ERP voor Offertes gebruiken',
  integrationsEnableErp: 'ERP Inschakelen',
  integrationsEnableFetchPartInfoFromErp: 'Onderdeleninfo Verzamelen van ERP Inschakelen',
  integrationsEnableScopeErp: 'ERP {scope} Inschakelen',
  integrationsEnableSendShoppingCartToErp: 'Winkelwagen naar ERP Sturen Inschakelen',
  integrationsEnableSyncPoAddressWithErp: 'Postadres Synchroniseren met ERP Inschakelen',
  integrationsErpAvailabilityMappedFrom: 'ERP Beschikbaarheid Toegewezen Van',
  integrationsErpCurrencyCodeMappedFrom: 'ERP Valutacode Toegewezen Van',
  integrationsErpDiscountedPriceMappedFrom: 'ERP Promotieprijs Toegewezen Van',
  integrationsErpEtaMappedFrom: 'ERP ETA Toegewezen Van',
  integrationsErpMappings: 'ERP-Kaarten',
  integrationsErpRetailPriceMappedFrom: 'ERP Retailprijs Toegewezen Van',
  integrationsErpWholesalePriceMappedFrom: 'ERP Groothandelprijs Toegewezen Van',
  integrationsInvokeGetPartInfoFromClient: 'GetPartInfo van Klant Inroepen',
  integrationsParseAddressForType: 'Adres Analyseren voor Type',
  integrationsShoppingCartAndPartsTitle: 'Winkelwagen en Onderdelen',
  integrationsShoppingCartAvailability: '{action} Winkelwagen Beschikbaarheid',
  integrationsShoppingCartEtaFromErp: '{action} Winkelwagen - ETA van ERP',
  internalApiKey: 'Interne API Key',
  internalDescription: 'Interne Beschrijving',
  internalName: 'Interne Naam',
  internalNameOptional: 'Uw identificatie voor de tag',
  internalNameRequired: 'Er is een naam vereist',
  invalidDecimals: 'Slechts twee decimale plaatsen zijn toegestaan',
  invalidField: '{field} is ongeldig',
  invalidFileType: 'Bestandstype niet ondersteund. Zie het Documoto Support Center voor een volledige lijst van ondersteunde bestandsextensies.',
  isSearchable: 'Is Zoekbaar',
  insertDataField: 'Gegevensveld invoegen',
  isSystem: 'Is Systeem',
  italianDescription: 'Italiaanse beschrijving',
  italianName: 'Italiaanse naam',
  item: '#',
  itemAlt: 'Item',
  itemCopied: '{item} gekopieerd',
  itemDeleted: '{item} verwijderd',
  itemInProgress: '{item} in behandeling',
  itemInformation: '{item} informatie',
  itemMustBeLteItem: '{itemA} moet gelijk zijn aan of voor {itemB} komen.',
  itemMustBeUnique: '{item} moet uniek zijn',
  itemMustBeUniqueAndNotEmpty: '{item} moet uniek zijn en is vereist',
  itemName: '{item} naam',
  itemNotShown: '',
  itemSaved: '{item} opgeslagen',
  itemSpecification: 'Itemspecificatie',
  showChapterTOCForHiddenPages: 'Toon Hoofdstuk TOC voor Verborgen Pagina‘s',
  january: 'Januari',
  japaneseDescription: 'Japanse beschrijving',
  japaneseName: 'Japanse naam',
  job: 'Taak | Taken',
  jobManager: 'Taakbeheerder',
  jobManagerMessage: 'Taken exporteren, publiceren en afdrukken/downloaden',
  july: 'Juli',
  june: 'Juni',
  koreanDescription: 'Koreaanse beschrijving',
  koreanName: 'Koreaanse naam',
  label: 'Label | Labels',
  labelKey: 'Labelsleutel',
  landscape: 'Liggend',
  laneConfiguration: 'Strookconfiguratie',
  language: 'Taal',
  lastModifiedDate: 'Datum laatste wijziging',
  lastGenerated: 'Laatst Gegenereerd',
  lastName: 'Achternaam',
  lastNameEmpty: 'Familienaam mag niet leeg zijn',
  lastUpdatedOn: 'Laatst bijgewerkt op',
  layout: 'Lay-out',
  layoutOptions: 'Lay-outopties',
  ledger: 'Grootboek',
  left: 'Links',
  legal: 'Juridisch',
  letter: 'Letter',
  library: 'Bibliotheek',
  libraryAdmin: 'Bibliotheek Beheer',
  libraryBannerLogo: 'Bannerlogo van bibliotheek',
  librarySettings: 'Bibliotheekinstellingen',
  librarySettingsDescription: 'Deze instellingen beheren verschillende bibliotheekelementen en opties. Wees voorzichtig bij het aanbrengen van wijzigingen. Contacteer Documoto Services voor meer info.',
  licenseConfiguration: 'Licentieconfiguratie',
  line: 'Lijn',
  lineTool: 'Lijngereedschap',
  link: 'Link | Links',
  listBox: 'Keuzelijst',
  listBoxValue: 'Lijstvak Waarde | Lijstvakken Waarden',
  listValue: 'Lijstwaarde | Lijstwaarden',
  load: 'Laden',
  loadMore: 'Laad meer',
  loading: 'Laden',
  locale: 'Lokale',
  login: 'Aanmelden',
  loginBackground: 'Aanmelden Achtergrond',
  loginGDPRMessageBeginning: 'Deze website gebruikt cookies. Door deze website verder te gebruiken gaat u akkoord dat cookies worden gebruikt. Raadpleeg voor meer informatie onze ',
  loginGDPRMessageEnd: 'en onze',
  loginSSO: 'Aanmelden SSO',
  logiReportName: 'Logi-rapportnaam',
  logiReportToggle: 'Logi-rapport?',
  logo: 'Logo | Logo‘s',
  logout: 'Afmelden',
  lookupLoadParts: 'Opzoeken/laden onderdelen uit Documoto bij import',
  lowerBound: 'Ondergrens',
  makePagesDivisibleBy: 'Maak Pagina’s Deelbaar Door:',
  mainInfo: 'Hoofdinfo',
  mainLogo: 'Hoofdlogo',
  manageItem: '{item} Beheren',
  management: 'Management',
  march: 'Maart',
  margin: 'Marge | Marges',
  markdown: 'Verminderen',
  may: 'Mei',
  media: 'Media | Media',
  mediaCategory: 'Mediacategorie | Mediacategorieën',
  mediaCategoryEditor: 'Mediacategorie-Editor',
  mediaCategoryName: 'Naam Mediacategorie',
  mediaCategoryNameMissing: 'Alle mediacategorieën moeten een naam hebben',
  mediaBookDeleteMessage: 'Door deze media te verwijderen, zult u geen toegang meer hebben tot de inhoud, noch via de Bibliotheek, noch via Content Admin en het zal niet langer beschikbaar zijn voor gebruikers of Contentbeheerders. Alle delen of pagina’s in dit boek die niet in andere boeken worden gevonden, zullen ook niet meer beschikbaar zijn in de Bibliotheek.',
  mediaDeleteMessage: 'Als u dit sjabloon verwijdert, wordt het verwijderd van Content Admin en het zal niet langer beschikbaar zijn om te kopiëren naar nieuw Media.',
  mediaDeleteSuccessText: 'Het zal verwijderd worden van lijsten van zodra de indexering voltooid is.',
  mediaDescription: 'Mediabeschrijving',
  mediaIdentifier: 'Media-identificator',
  mediaName: 'Medianaam',
  mediaPrintSettingsSaveSuccess: 'Instellingen Media Afdrukken opgeslagen',
  mediaWhereUsed: 'Media Waar Gebruikt',
  menu: 'Menu',
  merge: 'Samenvoegen',
  mergeOrOverrideMessage: 'Wilt u deze items overschrijven of samenvoegen?',
  mergeWarning: 'Importeren van gegevens zal bestaande onderdelen bijwerken en de nieuwe items toevoegen',
  message: 'Bericht',
  messageLevel: 'Berichtniveau',
  microsite: 'Microsite | Microsites',
  micrositeAdmin: {
    cardSubtitle: 'Het gecomprimeerde inhoudsbestand en HTML-invoerinformatie',
    cardTitle: 'Inhoud van de site',
    errorRequiredFile: 'Een ZIP-bestand met de inhoud van de site is vereist',
    fieldFile: 'ZIP-bestand met de inhoud van de site',
    fieldPoint: 'HTML-invoerpunt',
  },
  minArea: 'Minimaal gebied',
  minAreaMessage: 'Minimale waarde is vereist De waarde mag een bereik van 1 tot 1500 niet overschrijden.  Moet een gehele waarde zijn.',
  moreInfo: 'Meer info',
  multipleAlternatingPages: 'Meerdere Afwisselende Pagina’s',
  multipleAlternatingPagesMessage: 'Diagram op pagina’s 1 en 3, onderdelenlijst op pagina’s 2 en 4.',
  mToM: 'M2M',
  multiplePages: "Meerdere Pagina's",
  multiplePagesMessage: 'Diagram op afzonderlijke pagina gevolgd door onderdelenlijstpagina’s',
  requiredField: 'Veld Vereist',
  messageMessage: 'Er is een bericht vereist',
  metadata: 'Metadata',
  mine: 'Mine',
  miscellaneous: 'Overige',
  mon: 'Ma.',
  myCart: 'Mijn winkelwagen | Mijn Winkelwagens',
  navigation: 'Navigatie',
  nItemsCreated: '{n} {items} Aangemaakt',
  nItemsDeleted: '{n} {items} Verwijderd',
  nOfMItems: '{n} van {m} {items}',
  name: 'Naam',
  nameDescriptionHelpText: 'De naam en optionele beschrijving',
  new: 'Nieuw',
  newAPIKey: 'Nieuwe API-Sleutel',
  newERPSystem: 'Nieuw ERP-systeem',
  newItem: 'Nieuw {item}',
  newKeyGenerated: 'Nieuwe Integratiesleutel Gegenereerd, Kopieer & Bewaar voor toekomstig gebruik',
  newMediaCategory: 'Nieuwe Mediacategorie',
  newPassword: 'Nieuw Wachtwoord',
  newsItem: 'Nieuwsitem | Nieuwsitems',
  newTenant: 'Nieuwe tenant',
  newWidgetKey: 'Nieuwe widgetsleutel',
  newWidgetKeyGenerated: 'Nieuwe widgetsleutel gegenereerd, kopieer en bewaar deze voor toekomstig gebruik',
  noTenantSuppliers: 'Geen leveranciers toegewezen aan de tenant.',
  noAPIKeys: 'Geen API-Sleutels gedefinieerd.',
  node: 'Node',
  noData: 'Geen Gegevens',
  noFilesSelected: 'Geen Bestanden Geselecteerd',
  noIllustrationFound: 'Geen illustratie gevonden',
  noImageFound: 'Geen afbeelding gevonden',
  noItemAvailable: 'Geen {item} beschikbaar',
  noItemFound: 'Geen {item} gevonden.',
  noItemSelected: 'Geen {item} geselecteerd',
  noItemAddedToTarget: 'Geen {item} toegevoegd aan het {target}. ',
  noItemsFound: 'Geen Items Gevonden',
  noMoreResults: 'Geen resultaten meer',
  noFormsMessage: 'Geen vormen gedefinieerd',
  noMediaDefined: 'Geen media gedefinieerd',
  nonSearchableFieldsSuccessfullyUpdated: 'Niet-doorzoekbare velden succesvol bijgewerkt.',
  noPartsSelected: 'Geen onderdelen geselecteerd.',
  noPrice: 'Vraag voor een Prijs',
  noResults: 'Geen resultaten',
  norwegianDescription: 'Noorse beschrijving',
  norwegianName: 'Noorse naam',
  noSearchableTags: 'Geen zoekbare tags',
  noSuggestions: 'Geen suggesties voor de zoekterm',
  notOrderable: 'Niet Bestelbaar',
  notSelected: 'Niet geselecteerd',
  noTagsFound: 'Geen tags gevonden',
  noUnsavedChanges: 'Geen Onopgeslagen Veranderingen',
  none: 'Geen',
  notImplemented: 'Niet Geïmplementeerd',
  notification: 'Melding | Meldingen',
  notificationsMessage: 'Belangrijke aankondigingen en nieuws',
  notMapped: 'Niet toegewezen',
  november: 'November',
  noWidgetKeys: 'Geen widgetsleutels gedefinieerd.',
  numeric: 'Numeriek',
  numberOf: '# van {item}',
  october: 'Oktober',
  of: 'van',
  off: 'Uit',
  offlineContent: 'Offline Inhoud',
  ok: 'Ok',
  oldPassword: 'Oud wachtwoord',
  on: 'Aan',
  opacity: 'Ondoorzichtigheid',
  open: 'Openen',
  openItem: '{item} Openen',
  optimizeIndexJob: 'Index optimaliseren',
  options: 'Opties',
  openTab: 'Open link in een nieuw tabblad',
  order: 'Bestelling | Bestellingen',
  orderable: 'Bestelbaar',
  orderableDescription: 'Onderdeel kan worden toegevoegd aan winkelwagen en kan besteld worden',
  orderableLocal: 'Bestelbaar op deze pagina',
  orderableLocalBlocked: 'Onderdeel niet Wereldwijd Bestelbaar',
  orderDisable: 'Bestelgeschiedenis Uitschakelen',
  orderEmailRecipient: 'Bestellingse-mail Ontvanger | Bestellingse-mail Ontvangers',
  orderEntity: 'Orderentiteit | Orderentiteiten',
  orderFieldUneditableRequired: 'Als u een Order-/Offerteveld instelt op Vereist en niet Aanpasbaar Door Gebruiker, kan uw Order-/Offerteformulier onmogelijk worden voltooid. Gelieve te verifiëren dat uw Order-/Offerteformulieren kunnen worden voltooid.',
  orderFieldsEmptyMessage: 'Geen Order-/Offertevelden gedefinieerd.',
  orderFieldsEmptyPrompt: 'Een Order-/Offerteveld toevoegen',
  orderField: {
    nameMessage: 'Als u de naam van het Order-/Offerteveld wijzigt, zorg er dan voor dat u alle Order-/Offerteformulieren of E-mailindelingen die deze gebruiken, ook bijwerkt.',
  },
  orderFieldCopyMessage: 'Voer een unieke naam in voor het nieuwe Order-/Offerteveld',
  orderForm: {
    uniqueNameInputHint: 'Voer een unieke naam in voor het nieuwe Formulier.'
  },
  orderFormField: 'Order-/Offerteformulier | Velden Order-/Offerteformulier',
  orderFormLayout: 'Lay-out Order-/Offerteformulier | Lay-outs Order-/Offerteformulier',
  orderFormSetDefault: 'Standaard bestel-/offerteformulier ingesteld',
  orderFormType: 'Bestelformulier',
  orderLayout: 'Lay-out Bestelling | Lay-outs Bestelling',
  orderManagementApprover: 'Bestelbeheer Goedkeurder',
  orderManagementDisable: 'Bestelbeheer Admin Uitschakelen',
  orderManagementViewer: 'Bestelbeheer Bekijker',
  orderNumber: 'Bestelnummer',
  orderQuoteEmail: 'Bestelling/Offerte e-mail | Bestelling/Offerte e-mails',
  orderQuoteEmailFormat: 'Bestelling/Offerte e-mailindeling | Bestelling/Offerte e-mailindelingen',
  orderQuoteEmailFormatCopyMessage: 'Selecteer een taal voor de nieuwe Bestelling/Offerte e-mailindeling.',
  orderQuoteFetchError: 'Fout bij het verkrijgen van gegevens van Bestel/Offerteformulier',
  orderQuoteForm: 'Bestelling/Offerteformulier | Bestelling/Offerteformulieren',
  orderQuoteSaveConfirmation: 'Sa uw wijzigingen aan Bestel/Offerteformulier voor een organisatie op?',
  orderQuoteSaveError: 'Fout bij het opslaan van van gegevens van Bestel/Offerteformulier',
  orderQuoteFormSaved: 'Order-/Offerteformulier opgeslagen',
  orderStatus: 'Status Order/Offerte | Statussen Order/Offerte',
  orderStatusDeleteMessage: 'Deze status werd gebruikt voor bestellingen en kan niet worden verwijderd.',
  orderSubmittedMessage: 'Succesvol Ingediend',
  orderSuggestion: 'Bestelsuggestie | Bestelsuggesties',
  orderSuggestionEnable: 'Bestelsuggesties Inschakelen',
  orderSuggestionRemovalWarning: 'Het onderdeel zal niet langer worden weergegeven als bestelsuggestie. | De onderdelen zullen niet langer worden weergegeven als bestelsuggesties.',
  orderSuggestionThreshold: 'Bestelsuggestie Drempel',
  ordersLogo: 'Orders Logo',
  ordersMessage: 'Bestelgeschiedenis',
  orientation: 'Oriëntatie',
  org: 'Org',
  orgEmailRecipients: 'Org e-mail Ontvangers',
  organization: 'Organisatie | Organisaties',
  organizationAdmin: 'Organisatiebeheer',
  organizationAdminBrowseFlow: 'Een Organisatie kan de standaard browse flow gebruiken, of u kunt deze overschrijven en elke andere browse flow gebruiken.',
  organizationAdminMessage: 'Regelt het gebruikersbeheer en organisatorische instellingen',
  organizationEmpty: 'Organisatie moet geselecteerd worden',
  organizationEmailRecipients: 'Organisatie E-mail Ontvangers',
  organizationEnforceAccessControlsForQuickAdd: 'Toegangscontrole afdwingen voor snel toevoegen',
  organizationKey: 'Organisatiesleutel',
  organizationLibrarySettingsDescription: 'Deze instellingen beheren verschillende Bibliotheekelementen en opties op Organisatieniveau. Wees voorzichtig bij het aanbrengen van wijzigingen. Contacteer Documoto Services voor meer info.',
  organizationMoveToRoot: 'Sleep naar hier om naar hoofdmap te verplaatsen',
  organizationName: 'Naam van de organisatie',
  organizationRearrange: 'Organisaties Herschikken',
  organizationRearrangeDescription: 'De ouder-kind Organisatorische structuur bepaalt verschillende overneembare eigenschappen van een Organisatie zoals Printinstellingen, Stijlen en anderen. Sleep Organisaties om de gewenste hiërarchie weer te geven.',
  organizationReportSettingsSuccessMessage: 'Rapportinstellingen opgeslagen',
  organizationSelect: 'Organisatie Selecteren',
  organizationTags: 'Organisatietags',
  organizationsEmpty: 'Zo te zien heeft u nog geen organisaties aangemaakt',
  orgNameOnly: 'Alleen Organisatienaam',
  other: 'Andere',
  otherSettings: 'Andere Instellingen',
  outboundCustomerIdParameter: 'Uitgaand Klant-ID Parameternaam',
  outboundSessionIdParameter: 'Uitgaand Sessie-ID Parameternaam',
  outlineMode: 'Uitlijnmodus (Shift O)',
  output: 'Uitvoer',
  outputCatalog: 'Uitvoercatalogus',
  outputDefinition: 'Uitvoerdefinitie | Uitvoerdefinities',
  outputDefinitionDeleted: 'Uitvoerdefinitie verwijderd',
  outputDefinitionSaved: 'Uitvoerdefinitie opgeslagen',
  outputFileName: 'Naam uitvoerbestand',
  outputName: 'Uitvoernaam',
  outputDestination: 'Uitvoerbestemming',
  outputSpecification: 'Uitvoerspecificatie',
  outputSubType: 'Uitvoersubtype',
  outputType: 'Uitvoertype',
  overridden: 'Overschreven',
  overriddenByOrg: 'Overschreven door Org',
  override: 'Overschrijven',
  overrideBrowseFlow: 'Browse Flow Overschrijven',
  overrideFacetLimitValue: 'Overschrijven waarde van facetlimiet',
  overrideHotpointScale: 'Hotpointschaal Overschrijven',
  overridePdfNamingStandard: 'Norm voor PDF-benaming negeren',
  overridePrintSettings: 'Printinstellingen Orschrijven',
  overrideFontSettings: 'Lettertypinstellingen Overschrijven',
  overrideOrderForm: 'Bestelformulier overschrijven',
  overrideQuoteForm: 'Offerteformulier overschrijven',
  overrideSvgOpacity: 'SVG-Lichtdichtheid Overschrijven',
  overrideWarning: 'Het importeren van gegevens vervangt uw huidige BOM-lijst',
  owner: 'Eigenaar | Eigenaars',
  page: "Pagina | Pagina's",
  pageBulkInsertConfirmTitle: 'Wilt u bulk invoegen voltooien?',
  pageBulkInsertConfirmInsertAfter: 'Dit zal {replacement} na {original} in {whereUsedBookCount} {bookUnits} en {whereUsedTemplateCount} {templateUnits} invoegen.',
  pageBulkInsertConfirmInsertBefore: 'Dit zal {replacement} voor {original} in {whereUsedBookCount} {bookUnits} en {whereUsedTemplateCount} {templateUnits} invoegen.',
  pageBulkInsertConfirmInsertReplace: 'Dit zal {replacement} invoegen en {original} verwijderen in {whereUsedBookCount} {bookUnits} en {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertBooksConfirmInsertAfter: 'Dit zal {replacement} na {original} in {whereUsedBookCount} {bookUnits} invoegen.',
  pageBulkInsertBooksConfirmInsertBefore: 'Dit zal {replacement} voor {original} in {whereUsedBookCount} {bookUnits} invoegen.',
  pageBulkInsertBooksConfirmInsertReplace: 'Dit zal {replacement} invoegen en {original} verwijderen in {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertTemplatesConfirmInsertAfter: 'Dit zal {replacement} na {original} in {whereUsedTemplateCount} {templateUnits} invoegen.',
  pageBulkInsertTemplatesConfirmInsertBefore: 'Dit zal {replacement} voor {original} in {whereUsedTemplateCount} {templateUnits} invoegen.',
  pageBulkInsertTemplatesConfirmInsertReplace: 'Dit zal {replacement} invoegen en {original} verwijderen in {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertDiscard: 'Uw bulk invoegen zal niet verwerkt worden.',
  pageBulkInsertHelpText: 'Met deze functie kunt u deze bronpagina {thisDisplayName} invoegen in geselecteerde delen van boeken waar er reeds een andere pagina {originalDisplayName} bestaat. U kunt ook optioneel {originalDisplayName} verwijderen van deze boeken.',
  pageBulkInsertInsertAfter: '{replacement} Invoegen NA {original}',
  pageBulkInsertInsertBefore: '{replacement} Invoegen VOOR {original}',
  pageBulkInsertInsertReplace: '{replacement} Invoegen en {original} VERWIJDEREN',
  pageBulkInsertScopeAll: 'Alle {type} die de doelpagina {originalDisplayName} hebben ({whereUsedCount} {units})',
  pageBulkInsertScopeNone: 'Geen van de {type} die de doelpagina {originalDisplayName} hebben',
  pageBulkInsertScopeSome: 'Enkele van de {type} die de doelpagina {originalDisplayName} hebben',
  pageBulkInsertStep1: 'Stap 1: Zoek een gewenste doelpagina',
  pageBulkInsertStep2: 'Stap 2: Bepaal hoe de pagina’s in te voegen of te vervangen',
  pageBulkInsertStep3: 'Stap 3: kies de boeken in welke u de bulk invoegen wenst te verwerken',
  pageBulkInsertStep4: 'Stap 4: Kies de sjablonen waarmee u het invoegen in bulk wil verwerken.',
  pageBulkInsertSuccessTitle: 'Opdracht bulk invoegen ingediend',
  pageBulkInsertSuccessBody: 'Wijzigingen inhoudspagina boek zullen onmiddellijk gemaakt worden. Zoekresultaten zullen weergegeven worden van zodra indexering voltooid is.',
  pageBulkErrorTooManyBooks: 'Beperk het geselecteerde aantal boeken tot niet meer dan 1000',
  pageBuilder: 'Paginabouwer',
  pageComposer: 'Page Composer',
  pageCreated: 'Pagina Aangemaakt',
  pageDeleteMessage: 'Verwijderde pagina´s zijn onmiddellijk van kracht in Library en Content Admin. De verwijderde pagina kan nog gezocht worden tot een paar minuten later het herindexeren heeft plaatsgevonden.',
  pageDeleteMessage2: 'Deze actie kan niet ongedaan worden gemaakt zonder hulp van Documoto Support. Na 90 dagen worden het verwijderde record en alle koppelingen definitief verwijderd.',
  pageDescription: 'Paginabeschrijving',
  pageFile: 'Paginabestand',
  pageFilename: 'Bestandsnaam van pagina',
  pageFilenameDeleted: '{filename} wordt verwijderd.',
  pageFileRequired: 'Een paging file is vereist.',
  pageBomPartsDeleted: '{count} onderdelen op pagina BOM zullen niet langer toegankelijk zijn via {filename}.',
  pageDraftsDeleted: 'Conceptpagina´s die aan deze pagina zijn gekoppeld, worden permanent verwijderd.',
  pageFit: 'Pagina Passend',
  pageHeader: 'Koptekst Pagina',
  pageLabel: 'Pagina | Pagina`s',
  pageLayout: 'Paginalay-out',
  pageName: 'Paginanaam',
  pagePart: 'Pagina-onderdeel | Pagina-onderdelen',
  pagePartTag: 'Pagina Onderdeellabel | Pagina Onderdeellabels',
  pagePartData: 'Gegevens pagina-onderdeel',
  pageUsageDataCouldNotBeLoaded: 'Gegevens over paginagebruik konden niet worden geladen',
  pagePartLevelDetails: 'Pagina onderdeel bestelbaar/zichtbaar, pagina onderdeellabels',
  pagePartNumberVisible: 'Pagina onderdeelnummer zichtbaar',
  pagePartOrderable: 'Pagina onderdeel bestelbaar',
  pagePartOrderableDescription: 'Onderdeel bestelbaar vanaf deze pagina',
  pagePartVisibleDescription: 'Onderdeelnummer zichtbaar op deze pagina',
  pageUpdated: 'Pagina Bijgewerkt',
  pageViewer: 'Paginaweergave',
  pageWidth: 'Paginabreedte',
  pagesWhereUsed: 'Pagina’s Waar Gebruikt',
  parentName: 'Oudernaam | Oudernamen',
  part: 'Onderdeel | Onderdelen',
  partCode: 'Onderdeelcode | Onderdeelcodes',
  partCodeAddError: 'Kan Onderdeelcode(`s) Niet Toevoegen',
  partCodeErrorEmpty: 'Kon niet opslaan: Onderdeelcode mag niet leeg zijn',
  partCodeErrorInUse: 'Deze onderdeelcode wordt gebruikt door onderdelen in Documoto en mag niet verwijderd worden. Contacteer Documoto Support via support{\'@\'}documoto.com voor hulp.',
  partCodeErrorNotFound: 'Deze onderdeelcodes konden niet verwijderd worden omdat ze niet gevonden konden worden, zorg ervoor dat alle wijzigingen worden opgeslagen.',
  partCodesManager: 'Onderdeelcodes Beheerder',
  partCodesMessage: 'Onderdeelcodes kunnen worden gebruikt in plaats van onderdeelnummers en worden vaak gebruikt om aan te geven wanneer Stuklijstitems verouderd zijn, niet beschikbaar zijn of alleen referentieartikelen zijn (bijv. \'PNNA\', \'-\', \'REF\').',
  partCreated: 'Onderdeel Aangemaakt',
  partData: 'Onderdeelgegevens',
  partUpdated: 'Onderdeel bijgewerkt',
  partDebouncerEmailQueueJob: 'E-mailwachtrij',
  partDescription: 'Onderdeelbeschrijving',
  partLabel: 'Onderdeel | Onderdelen',
  partLevelDetails: 'Onderdeelprijzen, bestelbaar/zichtbaar, extra vertalingen, onderdeellabels',
  partName: 'Onderdeelnaam',
  partnerApiKey: 'Partner API Key',
  partnerRESTRequestLimit: 'Partner REST aanvraaglimiet',
  partNumber: 'Onderdeelnummer',
  partNumberDescription: 'Het interne onderdeelnummer voor eindgebruikers om te zoeken',
  partNumberVisible: 'Onderdeelnummer Zichtbaar',
  partNumberVisibleDescription: 'Onderdeelnummer is zichtbaar voor eindgebruikers',
  partNumberVisibleLocal: 'Zichtbaar op deze pagina',
  partNumberVisibleLocalBlocked: 'Onderdeelnummer is niet Wereldwijd Zichtbaar',
  partOrderable: 'Onderdeel bestelbaar',
  partRequired: 'Tenminste één onderdeel is vereist',
  partTagProperty: 'Onderdeeltag Eigenschap| Onderdeeltag Eigenschappen',
  partTag: 'Pagina Label | Pagina Labels',
  partThumbnailDescription: '',
  partialSupport: 'Gedeeltelijke Ondersteuning',
  partsListColumn: 'Onderdelenlijst Kolom | Onderdelenlijst Kolommen',
  partsBook: 'Onderdelenboeken | Onderdelenboeken',
  partsColumns: 'Onderdelenkolommen',
  partsNotSelected: 'Standaard afbeelding gebruikt voor onderdeel-miniaturen. Indien een standaard miniatuur niet wordt toegepast, zal Documoto een standaard automatische miniatuur weergeven.',
  partsPage: 'Onderdelenpagina | Onderdelenpagina’s',
  partsPages: 'Onderdelenpagina’s',
  partsPageGroup: {
    excludePartCodesFromPartsLists: 'Onderdeelcodes uit Onderdelenlijsten Verwijderen',
    highlightAlternateRows: 'Afwisselende Rijen Markeren',
    rotateImagesAutomatically: 'Afbeeldingen Automatisch Roteren',
    printBorderAroundImages: 'Printrand Rond Afbeeldingen',
    keepImagesOnLeft: 'Afbeeldingen Links Houden',
    keepBOMOnRight: 'Stuklijst Rechts Houden',
    columnsUpdateErrorMsg: 'Er is een fout opgetreden bij het bijwerken van de Onderdelenkolommen',
    columnsUpdateSuccessMsg: 'Onderdelenkolommen bijgewerkt',
    columnsLoadErrorMsg: 'Kon onderdelenkolommen niet laden',
    printHotpointsOnImages: 'Hotpoints afdrukken op afbeeldingen',
  },
  partsList: 'Onderdelenlijst',
  partsListData: 'Gegevens Onderdelenlijst',
  partsListGridHeader: 'Rasterkoptekst Onderdelenlijst',
  password: 'Wachtwoord',
  passwordExpiredHeader: 'Wachtwoord is verlopen',
  passwordExpiredInfo: 'Uw wachtwoord is verlopen. Uw nieuw wachtwoord moet voldoen aan de volgende criteria:',
  passwordCriteriaInfo: 'Uw nieuw wachtwoord moet voldoen aan de volgende criteria:',
  passwordBlankMessage: 'Wachtwoord mag niet blanco zijn',
  passwordCannotBeSame: 'Mag niet hetzelfde zijn als uw huidige wachtwoord',
  passwordCasingMassage: 'Moet tenminste één kleine letter en één hoofdletter bevatten',
  passwordLowerCaseMessage: 'Wachtwoord moet tenminste één kleine letter bevatten',
  passwordMinLengthMessage: 'Wachtwoorden moeten minstens 8 karakters lang zijn',
  passwordNotMatchingMessage: 'Wachtwoorden zijn niet hetzelfde',
  passwordSpecialCharactersMessage: 'Wachtwoord moet tenminste één speciaal teken bevatten',
  passwordUpperCaseMessage: 'Wachtwoord moet tenminste één hoofdletter bevatten',
  passwordWillExpire1Day: 'Het wachtwoord om aan te melden verloopt in minder dan 1 dag',
  passwordWillExpire5Days: 'Het wachtwoord om aan te melden verloopt in minder dan 5 dagen',
  passwordsMatch: 'Wachtwoorden komen overeen',
  permission: 'Toestemming| Toestemmingen',
  phoneNumber: 'Telefoonnummer',
  phoneNumberExt: 'Ext',
  phonePrimary: 'Primair Telefoonnummer',
  phoneSecondary: 'Secundair Telefoonnummer',
  pinFilter: 'Filter vastmaken aan zoekresultatenpagina',
  pinned: 'Vastgezet',
  plusMoreOrgs: '+ {totalOrgCount} bijkomende Organisaties',
  polishDescription: 'Poolse beschrijving',
  polishName: 'Poolse naam',
  populatePdfNameWith: 'PDF-bestandsnaam opvullen met',
  populatePdfNameSelection: 'Selectie van PDF-bestandsnaam opvullen',
  port: 'Poort',
  portrait: 'Staand',
  portugueseDescription: 'Portugese beschrijving',
  portugueseName: 'Portugese naam',
  postComment: 'Opmerking Posten',
  postalCode: 'Postcode',
  poweredBy: 'Aangedreven door',
  preferences: 'Voorkeuren',
  preferencesDisable: 'Gebruikersvoorkeuren Uitschakelen',
  prefix: 'Prefix | Prefixes',
  preview: 'Voorbeeld',
  previewOfName: 'Voorvertoning van {name}',
  pricing: 'Prijs',
  pricingDiscountEnabled: 'Prijs met Korting Weergeven',
  pricingDisplayInCart: 'Prijzen in Winkelwagen Weergeven',
  pricingDisplayInPartsList: 'Prijzen in Onderdelenlijst Weergeven',
  pricingGlobal: 'Wereldwijde Prijzen',
  pricingGlobalDescription: 'De normale prijs wanner er geen overschrijving van een Organisatie is',
  pricingHidePricingButton: 'Knop Prijzen Verbergen Inschakelen',
  pricingHidePricingButtonDisable: 'Knop Prijzen Verbergen Uitschakelen',
  pricingOrganization: 'Organisatieprijzen',
  pricingOrganizationDeleteConfirm: 'Weet u zeker dat u deze Organisatieprijzen wilt verwijderen?',
  pricingOrganizationDescription: 'Organisatieprijzen overschrijven de wereldwijde prijzen voor alle gebruikers binnen een Organisatie en de Kinderorganisaties.',
  pricingOrganizationDuplicate: 'Deze organisatie werd reeds overschreven',
  pricingOrganizationEmptyState: 'Geen organisatie-overschrijvingen bepaald. Klik op {add} om te starten.',
  pricingRetailEnabled: 'Retailprijs Weergeven',
  pricingWholesaleEnabled: 'Groothandelprijs Weergeven',
  primary: 'Primair',
  print: 'Afdrukken',
  printerLane: 'Printerstrook',
  printJob: 'Taak Afdrukken',
  printLogo: 'Logo afdrukken',
  printSetting: 'Printinstelling',
  printSettingDeleteFail: 'Kon Printinstelling Niet Annuleren',
  printSettingSaveFail: 'Kon Printinstelling Niet Opslaan',
  printSettingEmptyMessage: 'Geen Printinstelling bepaald. Voeg een Printinstelling toe om te starten',
  printSettingSetDefaultSuccess: 'Standaard Printinstelling Ingesteld',
  printSettingUpdateFail: 'Kon Printinstelling Niet Bijwerken',
  printSettingCopyMessage: 'Voer een unieke naam in voor de nieuwe Printinstelling.',
  printSettingCoverLoadErrorMsg: '',
  printSettingCreated: 'Printinstellingen aangemaakt',
  printSettings: 'Printinstellingen',
  printSettingsLogo: 'Printinstellingen Logo',
  printing: 'Bezig Met Afdrukken',
  printingDisabled: 'Alles Afdrukken Uitschakelen',
  privacyPolicy: 'Privacybeleid',
  proceed: 'Doorgaan',
  process: 'Verwerken',
  processContent: 'Verwerk Content',
  processing: 'Bezig met verwerken',
  processToDraft: 'Proces naar concept',
  processedPlzToDraft: 'Verwerkte PLZ(s) naar Concept(en)',
  production: 'Productie',
  professional: 'Professioneel',
  profile: 'Profiel',
  prohibitExportDownloadOfContent: 'Exporteren/Downloaden van content verbieden',
  prohibitExportDownloadOfContentHelpText: 'De instelling op \'Enable\' zal voorkomen dat een gebruiker (met machtigingen) deze inhoud kan exporteren of downloaden.',
  property: 'Eigenschap | Eigenschappen',
  province: 'Provincie',
  public: 'Publiek',
  publish: 'Publiceren',
  publisherLane: 'Publicatiestrook',
  publisherManualLane: 'Handmatige publicatiestrook',
  publishThumbnailJob: 'Miniatuur publiceren',
  publishDocuments: 'Documenten publiceren',
  publishDraft: 'Concept publiceren',
  publisher: 'Uitgever| Uitgevers',
  publishingErrors: 'Publicatiefouten',
  publishingErrorsWithName: 'Fouten {name} publiceren',
  publishingInProgress: 'Bezig met publiceren, kan niet indienen',
  publisherJob: 'Taak van Uitgever',
  publisherMessage: 'Contentcreatie, beheer en printinstellingen',
  purchaseOrder: 'Inkooporder',
  purchaseOrderTemplateEnabled: 'Bestelsjablonen Inschakelen',
  purchaseOrderTemplateDisabled: 'Bestelsjablonen Uitschakelen',
  qty: 'Aantal',
  quantity: 'Aantal',
  query: 'Zoekopdracht',
  quickAdd: 'Snel Toevoegen',
  quickAddHelpText: 'Begin te typen om onderdelen te vinden om aan het winkelmandje toe te voegen.',
  quickAddInCartEnabled: 'Snel in Winkelwagen Toevoegen Inschakelen',
  quickLinks: 'Snelle Links',
  quote: 'Quote | Quotes',
  quoteFormType: 'Offerteformulier',
  quoteTemplateDisabled: 'Offertesjablonen Uitschakelen',
  quoteTemplateEnabled: 'Offertesjablonen Inschakelen',
  range: 'Bereik',
  rearrange: 'Herschikken',
  rebuildIndexJob: 'Index opnieuw bouwen',
  recentSearches: 'Recent zoekopdrachten',
  recentlyAdded: 'Onlangs Toegevoegd',
  recentlyEdited: 'Recent Bijgewerkt',
  recentlyViewed: 'Onlangs Bekeken',
  rectangle: 'Rechthoek',
  redirect: 'Omleiden',
  refresh: 'Vernieuwen',
  related: 'Gerelateerd',
  relatedDescription: 'Gerelateerde media die bestaan in de bibliotheek voor dit {type}. Opmerking: gerelateerde media moet toegangscontroles ingeschakeld hebben voor gebruikers om te zien in het tabblad \'Gerelateerd\'',
  relatedInfo: 'Gerelateerde Info',
  relatedView: 'Gerelateerde Media Weergeven',
  relationship: 'Relatie | Relaties',
  releaseNotes: 'Uitgaveopmerkingen',
  rememberMe: 'Onthoud Mij',
  remainingWidth: 'Resterende Breedte',
  remove: 'Verwijderen',
  removeAll: 'Alles Verwijderen',
  removeItemConfirmation: 'Weet u zeker dat u {item} wilt verwijderen?',
  removeItemCount: 'Weet u zeker dat u {count} {type} wil verwijderen?',
  replace: 'Vervangen',
  replaceExisting: 'Bestaande Vervangen',
  report: 'Rapport | Rapporten',
  reportAnIssue: 'Een probleem Melden',
  reportAnIssueMessage: 'E-mail succesvol verzonden.',
  reportCatalogName: 'Naam van rapportcatalogus',
  reportCatalogNotSet: 'Rapportcatalogus is niet gedefinieerd voor het rapport.',
  reportDefinition: 'Rapportdefinitie | Rapportdefinities ',
  reportDefinitionDeleteError: 'Rapportdefinitie kon niet verwijderd worden',
  reportsEmptyState: 'Geen rapporten',
  reportExportTimeWarning: 'Afhankelijk van het aantal records en de geselecteerde indeling kan het exporteren enkele minuten duren om te voltooien.',
  reporting: 'Verslaglegging',
  reportingMessage: 'Toegang tot verschillende rapporten en maatstaven',
  reportSettings: 'Rapportinstellingen',
  reportsLibrary: 'Rapportbibliotheek',
  requestForQuote: 'Verzoek om Offerte',
  requestFormType: 'Offerteformulier',
  required: 'Vereist',
  requiredItem: '{item} is vereist',
  requiredMessage: 'Er is een {item} vereist',
  requiredMessageAndUnique: 'Een {item} is vereist en moet uniek zijn.',
  requireAuthentication: 'Authenticatie Vereist',
  requiredReading: 'Verplicht Te Lezen Tekst',
  requiredReadingMessage: 'Controleer het volgende:  ',
  requiredUrlMessage: 'Er is een correcte URL vereist.',
  reset: 'Opnieuw Instellen',
  resetPassword: 'Wachtwoord Opnieuw Instellen',
  resetPasswordError: 'Er was een probleem met het opnieuw instellen van uw wachtwoord, probeer het opnieuw.',
  resetPasswordTokenError: 'Token om wachtwoord te herstellen is ongeldig.',
  resetZoom: 'Zoom opnieuw instellen',
  resource: 'Bron | Bronnen',
  resize: 'Formaat wijzigen',
  resizeThumbnails: 'Automatisch verkleinen/vergroten van miniaturen',
  result: 'Resultaat | Resultaten',
  resultFor: 'Resultaat voor {item} | Resultaten voor {item}',
  retail: 'Retail',
  retailPrice: 'Retailprijs',
  retailTotal: 'Totaal van Retail',
  return: 'Retourneren',
  returnToItem: 'Terugkeren naar {item}',
  returnToPartList: 'Terugkeren naar Onderdelenlijst',
  returnToMedia: 'Terugkeren naar Media',
  returnToTemplate: 'Terugkeren naar Template',
  returnToViewer: 'Terugkeren naar Viewer',
  revertChanges: 'Wijzigingen Ongedaan Maken',
  right: 'Rechts',
  romanianDescription: 'Roemeense beschrijving',
  romanianName: 'Roemeense naam',
  rootLevelBrowseFlow: 'Hoofdniveau',
  row: 'Rij',
  russianDescription: 'Russische beschrijving',
  russianName: 'Russische naam',
  sampleData: 'Voorbeeldgegevens',
  samplePreview: 'Voorbeeld',
  sandbox: 'Zandbak',
  sat: 'Zat',
  save: 'Opslaan',
  saveChanges: 'Wijzigingen opslaan voor {item}? {name}',
  saveChangesToItem: 'Wijzigingen opslaan voor dit {item}?',
  saved: 'Opgeslagen',
  saveNewAPIKey: 'Nieuwe Integratiesleutel niet Opgeslagen, Keer terug om de instelling te voltooien',
  saveNewWidgetKey: 'Nieuwe widgetsleutel niet opgeslagen. Keer terug op de instelling te voltooien.',
  search: 'Zoeken',
  searchableFieldsSuccessfullyUpdated: 'Doorzoekbare velden succesvol bijgewerkt.',
  searchAllContent: 'Alle content doorzoeken',
  searchConfiguration: 'Zoekconfiguratie',
  searchCriteria: 'Zoekcriteria',
  searchDocumoto: 'Zoeken door Documoto',
  searchFeatures: 'Zoekfuncties',
  searchFieldConfigDescription: 'De volgende systeemveldzoekinstellingen zijn alleen van toepassing op algemene zoekopdrachten in de Library, de zoekresultatenpagina en Snel toevoegen aan winkelwagen. Andere zoekfuncties (zoals zoeken in het Admin Center en Library waar gebruikt) worden niet beïnvloed.',
  searchInContent: 'Zoeken in {contentName}...',
  searchingInLanguage: 'Zoeken in {lang} alleen',
  searchingInTwoLanguages: 'Zoeken in {lang1} en {lang2} alleen',
  searchPlaceholder: 'Alle content doorzoeken...',
  searchPrompt: 'Alle content doorzoeken...',
  searchProperty: 'Zoek Eigenschap | Zoek Eigenschappen',
  searchResultsConfiguration: 'Configuratie van zoekresultaten',
  searchSettings: 'Zoekinstellingen',
  searchSettingsDescription: 'Deze instellingen regelen verschillende gedragingen, functies en resultaten bij het zoeken in de Library.',
  searchTerm: 'Zoekterm',
  searchTips: 'Tips om te zoeken:',
  searchable: 'Zoekbaar',
  searchableTag: 'Zoekbare Tag',
  section: 'Sectie',
  select: 'Selecteren',
  selectAField: 'Selecteer een {field}',
  selectAll: 'Selecteer Alle',
  selectAndClose: 'Selecteren en Sluiten',
  selected: 'Geselecteerd',
  selectedTargetPage: 'Geselecteerde Doelpagina',
  selectChapters: 'Hoofdstuk(ken) selecteren',
  selectChildren: 'Kinderen Selecteren',
  selectFields: '{field} Selecteren',
  selectItem: 'Selecteer {item}',
  selectMedia: 'Media selecteren',
  selectPage: 'Geselecteerde Pagina',
  selectPages: 'Pagina(‘s) selecteren',
  selectTargetPage: 'Doelpagina Selecteren',
  send: 'Verzenden',
  september: 'September',
  serbianDescription: 'Servische beschrijving',
  serbianName: 'Servische naam',
  serviceUri: 'Service-URL',
  sessionLog: 'Sessielogboek',
  set: 'Instellen',
  setAsDefault: 'Instellen als Standaard',
  settings: 'Instellingen',
  settingsMessage: 'Werk uw gebruikersvoorkeuren en wachtwoord bij',
  sequence: 'Reeks',
  shape: 'Vorm',
  shapeTool: 'Vormgereedschap',
  shared: 'Gedeeld',
  sharedCart: 'Gedeelde Winkelwagen | Gedeelde Winkelwagens',
  sharedCartDeleteContent: 'Dit zal deze Gedeelde Winkelwagen verwijderen voor alle Organisaties waarin hij gedeeld is.',
  sharedCartOrganizationContent: 'Selecteer de Organisaties die deze Gedeelde Winkelwagen kunnen gebruiken',
  sharedShoppingCartsEnabled: 'Gedeelde Winkelwagens Inschakelen',
  shoppingCartExportJob: 'Exporteren winkelwagentje',
  shoppingCartLoadingLongTime: 'Het winkelmandje wordt geladen. De pictogrammen van het winkelmandje wordt beschikbaar van zodra het proces is voltooid. Als het probleem blijft bestaan, neem contact op met uw Documoto Administrator voor ondersteuning.',
  showExportFromSearch: 'Export van Zoekresultaten Toestaan',
  showExportFromSearchHelp: 'Exporteren van een boekenlijst uit een zoekresultaat toestaan',
  showHelp: 'Help Weergeven',
  showIdentifierInSearch: 'Toon Media Identifier in Zoeken',
  showItem: '{item} Weergeven',
  showLess: 'Minder Bekijken',
  showMediaDescriptionFilter: 'Media Beschrijvingsfilter Weergeven',
  showMediaIdentifierFilter: 'Media Identificatiefilter Weergeven',
  showMediaNameFilter: 'Media Naamfilter Weergeven',
  showMore: 'Meer Bekijken',
  showOnlyExactMatches: 'Alleen exacte overeenkomsten weergeven',
  showOrganizationFilter: 'Organisatiefilter Weergeven',
  showPartNameFilter: 'Onderdeel Naamfilter Weergeven',
  showPartNumberFilter: 'Onderdeel Nummerfilter Weergeven',
  showRecentlyAdded: 'Onlangs Toegevoegd Weergeven',
  showRecentlyViewed: 'Onlangs Bekeken Weergeven',
  showSearchButton: 'Zoektoets Homepagina Weergeven',
  showSearchButtonHelp: 'Toets \'Ga naar Zoekopdracht\' weergeven in de Header van Bibliotheek',
  signature: 'Handtekening',
  size: 'Formaat',
  snapshot: 'Snapshot | Snapshots',
  solrSlaveSvrOverride: 'Basis URL‘s van SOLR slave-servers overschrijven',
  sortAsc: '{value} A-Z',
  sortDesc: '{value} Z-A',
  sortRelevant: 'Meest Relevant',
  sortType: 'Sorteertype',
  spanishDescription: 'Spaanse beschrijving',
  spanishName: 'Spaanse naam',
  square: 'Vierkant',
  ssoConfiguration: 'SSO-configuratie',
  ssoIdpMetadataUrl: 'SSO IDP metadata URL',
  ssoLoginMaxAuthenticationAgeSeconds: 'Max authenticatieleeftijd van SSO-aanmelding in seconden',
  ssoSamlDnAttributeName: 'SSO SAML DN attributnaam',
  ssoSamlEmailAttributeName: 'SSO SAML e-mail attribuutnaam',
  ssoSamlMemberofAttributeName: 'SSO SAML lid van attribuutnaam',
  ssoSniForSsoMetadataRetrieval: 'SNI gebruiken voor ophalen van SSO-metadata',
  standard: 'Standaard',
  standardUserGroup: 'Standaard gebruikersgroep',
  startDate: 'Begindatum',
  startDateRequired: 'Een startdatum is vereist.',
  state: 'Staat',
  static: 'Statisch',
  status: 'Status',
  statusCode: 'Statuscode | Statuscodes',
  stitchPages: 'Pagina’s Stikken',
  storeFrontAdmin: 'Winkelpui Beheer',
  storefront: 'Winkelpui',
  storefrontIntegration: 'Winkelpui Integratie | Winkelpui Integraties',
  storefrontIntegrationSubtitle: 'Deze instellingen beheren verschillende Winkelpui Integratie-elementen en opties. Wees voorzichtig bij het aanbrengen van wijzigingen. Contacteer Documoto Support voor meer info.',
  storefrontSettings: 'Winkelpui Instellingen',
  storefrontSettingsDescription: 'Deze instellingen beheren verschillende Winkelpui-elementen en opties. Wees voorzichtig bij het aanbrengen van wijzigingen. Contacteer Documoto Services voor meer info.',
  storefrontSettingsOrgDescription: 'Deze instellingen beheren verschillende Winkelpui-elementen en opties op Organisatieniveau. Wees voorzichtig bij het aanbrengen van wijzigingen. Contacteer Documoto Services voor meer info.',
  storeFilterValues: 'Filterwaarden opslaan',
  storeFilterValuesDescription: 'Door deze instelling in te schakelen, worden waarden opgeslagen die door gebruikers zijn ingesteld in hun zoekpaginafilters voor deze tag.',
  stroke: 'Slag',
  style: 'Stijl | Stijlen',
  styles: {
    colorHotpoint: 'Hotpointstandaard',
    invalidHexcode: 'Ongeldige hexadecimale code',
    inheritedFromOrg: 'Geërfd van organisatie {org}',
    inheritedFromSystem: 'Geërfd van systeemstandaard',
    inheritedFromTenant: 'Geërfd van tenant',
    organization: 'Organizatiestijl | Organizatiestijlen',
    organizationSaved: 'Organizatiestijlen opgeslagen',
    sampleNavigation: 'Navigatietabbladen',
    samplePrimary: 'Navigatie/Knoppen',
    sampleSelectedHotpoint: 'Geselecteerde hotpoints',
    tenant: 'Tenantstijl | Tenantstijlen',
    tenantSaved: 'Organizatiestijlen opgeslagen'
  },
  subChapter: 'Sub-hoofdstuk | Sub-hoofdstukken',
  subject: 'Onderwerp',
  subjectMessage: 'Er is een Onderwerp vereist',
  submit: 'Verzenden',
  submitPurchaseOrder: 'Inkooporder',
  submitRequestForQuote: 'Verzoek om Offerte',
  submitted: 'Ingediend',
  submitter: 'Indiener',
  submittedBy: 'Ingediend door',
  submitterEmail: 'E-mail van de indiener',
  submitterFirstName: 'Voornaam van de indiener',
  submittedFilesMessage: '{fileCount} bestand(en) ingediend.',
  submitterLastName: 'Achternaam van de indiener',
  submitterOrgName: 'Bedrijfsnaam van de indiener',
  submitterUserName: 'Gebruikersnaam van de indiener',
  success: 'Gelukt',
  imageSaveSuccess: 'Afbeeldingen succesvol bijgewerkt',
  successNotificationDuration: 'Toevoegen aan Winkelwagen - Duur van de Succesvolle Toastmeldingen',
  suffix: 'Suffix | Suffixes',
  suggestedKeywords: 'Voorgestelde trefwoorden',
  suggestedResults: 'Gesuggereerde resultaten',
  suggestion: 'Suggestie | Suggesties',
  sun: 'Zon',
  superadmin: 'Superadmin',
  superAdminSettings: "Instellingen superadmin",
  supersede: 'Opvolgen',
  supersedeComplete: 'Wilt u de opvolging voltooien?',
  supersedeDiscardChanges: 'Opvolging verwerpen?',
  supersedeMainText: 'Opvolging vervangt een bestaand onderdeel door één of meerdere onderdelen, op elke Onderdelenpagina waar het oude onderdeel gevonden wordt. Het oude onderdeelnummer wordt verwijderd maar blijft vindbaar via tags zodat inhoud gevonden kan worden a.d.h.v. het oude nummer of nieuw(e) nummer(s).',
  supersedeMessage: 'Onderdeelupdates treden onmiddellijk in werking. Zoekfuncties zullen de updates in enkele minuten weergeven.',
  supersedeNotProcessed: 'Uw opvolging zal niet verwerkt worden.',
  supersedeReplacePart: 'Zoek één of meerdere onderdelen die onderdeel zullen vervangen',
  supersedeSuccessful: 'Opvolging Gelukt',
  supersedeWith: 'Onderdelen met ... Laten Opvolgen',
  supplier: 'Leverancier | Leveranciers',
  supplierKey: 'Leveranciersleutel',
  supplierDescription: 'Het bedrijf dat dit onderdeel aanlevert',
  supportedImageFormats: 'Ondersteunde afbeeldingsformaten: GIF, JPG, JPEG, PNG',
  supportedDraftArchives: 'Niet ondersteund bedrag; conceptbestanden ondersteund: MDZ, PLZ',
  surroundWithQuotes: 'Zet aanhalingstekens rond meerdere zoektermen voor een exacte overeenkomst.',
  svgFinder: 'SVG Zoeker',
  swedishDescription: 'Zweedse beschrijving',
  swedishName: 'Zweedse naam',
  system: 'Systeem',
  systemAdmin: 'Systeembeheerder',
  systemFields: 'Systeemvelden',
  systemFieldSearchAndFiltering: 'Zoeken in en filteren van systeemvelden',
  systemStatus: 'Systeemstatus',
  systemName: 'Systeemnaam',
  systemVersion: 'Systeemversie',
  tabDelimited: 'Door tabs gescheiden',
  tableOfContents: 'Inhoudstafel',
  tabloid: 'Tabloid',
  tag: 'Tag | Tags',
  tagName: 'Tagnaam',
  tagEditor: {
    rangeBoundLengthInvalid: 'Grens kan niet meer dan {limit} cijfers bevatten.',
    tagEditorModal: 'Tag Editor Modaal',
    filterPrompt: 'De eerste 100 waarden worden weergegeven. Gebruik filter om meer te zien.'
  },
  tagSearchAndFiltering: 'Zoeken en filteren in tags',
  tagSearchAndFilteringHelpText: 'Tip: Sommige zoek- en filterinstellingen kunnen ook worden geconfigureerd in Tag Manager, dat u hier kunt vinden: ',
  tagSubmitAffected: 'Elk {type} inhoud zal automatisch de tag {tag} weglaten als aanvulling op de wijzigingen.',
  tagDeleteAffectedCount: 'Dit zal de tag en taginformatie verwijderen van {count} items.',
  tagDeleteAffectedNone: 'Geen enkel item maakt momenteel gebruik van deze tag.',
  tagManagerDeleteWarning: 'Alle bestaande media met {deleteCategoryName} zal niet meer over deze categorie beschikken na het verwijderen.',
  tagsDisplayMore: 'De eerste 100 waarden worden weergegeven. Gebruik filter om meer te zien.',
  tagsManager: 'Tagbeheer',
  tagValue: 'Tagwaarde | Tagwaardes',
  tagValueMissing: 'U moet een tagwaarde invoeren',
  tagValueMultiple: 'Scheidingstekens met puntkomma voor meerdere ingaven',
  tagValueTooLong: 'Tagwaarde overschrijdt tekenlimiet van 25 | Tagwaarden overschrijden tekenlimiet van 25',
  taxon: 'Taxon',
  taxonomy: 'Taxonomie',
  template: 'Sjabloon | Sjablonen',
  templateEmailRecipients: 'Template E-mail Ontvangers',
  templateLayout: {
    addRow: 'Rij toevoegen',
    addItem: 'Item toevoegen',
    deleteRow: 'Rij verwijderen',
    deleteColumn: 'Kolom verwijderen',
    fullWidth: 'Volledige breedte',
    oneColumn: 'Eén kolom',
    threeColumns: 'Twee kolommen',
    twoColumns: 'Drie kolommen'
  },
  tenant: 'Tenant | Tenants',
  tenantAdmin: 'Tenant Beheer',
  tenantAdminMessage: 'Regelt de instellingen van de tenant',
  tenantCleanerJob: 'Onderhoud tenant',
  tenantConfiguration: 'Tenantconfiguratie',
  tenantEmailRecipients: 'Huurder E-mail Ontvangers',
  tenantEmailRecipientsOverrideMessage: 'Deze zullen worden overgeschreven wanneer de Organisatie E-mail Ontvangers worden gespecificeerd',
  tenantIndexQueueJob: 'Indexwachtrij tenant',
  tenantKey: 'Tenantsleutel',
  tenantKeyError: 'Tenantsleutel is niet uniek',
  tenantKeyMessage: 'Tenantsleutel moet uniek zijn',
  tenantLabelDeleteMessage: 'Door dit label te verwijderen, verschijnt de standaard Documotonaam opnieuw.',
  tenantReindexModulus: 'Modulus van herindexatie van tenant',
  tenantSettings: {
    collapseTOCLabel: 'Inhoudstafel Samenvouwen op Desbetreffende Pagina',
    defaultLocaleLabel: 'Standaardlocatie',
    defaultLocaleMessage: 'Standaardlocatie van tenant.',
    deleteMissingTranslationsOnPublishLabel: 'Ontbrekende vertalingen verwijderen bij het publiceren?',
    deleteMissingTranslationsOnPublishMessage: 'Inschakelen om gedeeltelijke vertalingen bij het publiceren te verwijderen',
    defaultSupplierLabel: 'Standaardleverancier',
    defaultSupplierMessage: 'Standaardleverancier van tenant',
    enableChapterIndexingMessage: 'Hoofdstukken indexeren',
    enableChapterIndexingLabel: 'Indexatie inschakelen voor Mediahoofdstukken',
    enableIndexingLabel: 'Indexeren bij publiceren',
    enableIndexingMessage: 'Schakelt indexering voor publiceren in.',
    helpUrlLabel: 'Standaard Help-URL',
    helpUrlMessage: 'De URL van een alternatief hulpsysteem.',
    hotPointLinkPageInBookOnlyLabel: 'Pagina Hotpoint-links in Book Only',
    hotPointLinkPageInBookOnlyMessage: 'Hotpoint-links van pagina’s in Book Only',
    mediaTimestampLabel: 'Media Tijdstempel bij Toegangscontrole Wijziging Bijwerken',
    mediaTimestampMessage: 'Inschakelen indien een wijziging in de toegangscontroles de tijdstempel van de media moet bijwerken',
    publisherDefaultUom: 'Uitgever Standaard UOM',
    publishingContactLabel: 'Publicatiecontact E-mail',
    publishingContactMessage: 'Het standaard e-mailadres dat e-mails zal ontvangen wanneer er een inhoudsprobleem gerapporteerd wordt.',
    replaceHotpointLabel: 'Hotpointlinks Altijd Vervangen bij Publicatie',
    replaceHotpointMessage: 'Enkel voor publicatie van Docustudio.',
    ssoLoginSystemLabel: 'SSO-aanmeldsysteem',
    ssoLoginSystemMessage: 'Identiteitsprovidersysteem voor SSO.',
    userPasswordExpirationLabel: 'Aantal dagen waarna het gebruikerswachtwoord verloopt',
    userPasswordExpirationMessage: 'Aantal dagen waarna wachtwoorden opnieuw moeten worden ingesteld; blanco zorgt ervoor dat ze nooit verlopen',
    lockPartTrOnPublishLabel: 'Gedeeltelijke vertalingen bij het publiceren vergrendelen',
    lockPartTrOnPublishMessage: 'Voorkomt dat gedeeltelijke vertalingen gewijzigd worden tijdens het publiceren',
    exportPgAsHashLabel: 'Exporteren van paginabestandsnaam als hashsleutel',
    exportPgAsHashMessage: 'Gebruik paginahashsleutels als paginabestandsnamen in geëxporteerde inhoud',
  },
  tenantSettingsTitle: 'Huurderinstellingen',
  tenantSettingsSaved: 'Huurderinstellingen Opgeslagen',
  tenantSettingsSubtitle: 'Deze instellingen beheren eigenschappen en gedragingen van de huurder. Wees voorzicht bij het wijzigen ervan. Neem contact op met Documoto Services voor meer informatie',
  tenantSuppliers: 'Tenantleveranciers',
  tenantLabelsPageText: 'Labels worden gebruikt om de namen van verschillende objecten die in de Bibliotheek en op de Admin-schermen van Documoto staan, te personaliseren.',
  tenantPrintSettingsDescription: 'Kies de standaard printinstelling die geldt voor alle tenants. Deze zal gebruikt worden voor het printen van alle boeken tenzij overschreven door de Organisatie of Media.',
  tenantType: 'Tenanttype',
  tenantWebserviceUser: 'Tenant webservicegebruiker',
  text: 'Tekst',
  textArea: 'Tekstgebied',
  textField: 'Tekstveld',
  textFile: 'Tekstbestand (CSV, TXT)',
  textTool: 'Tekstgereedschap',
  threeD: {
    addFile: 'Voeg een 3D Part File toe',
    beforeSaveModal: {
      title: 'Er bestaat al een CAD-bestand in Documoto met deze bestandsnaam.',
      message: 'Wilt u dit bestand overal vervangen waar het voorkomt, of wilt u het alleen aan dit onderdeel koppelen? Als u kiest om het bestand om te vervangen, zal het volgende gebeuren:',
      messageBulletOne: '{count} onderdelen worden bijgewerkt met dit nieuwe CAD-bestand',
      messageBulletTwo: 'De eerdere versie van dit bestand wordt verwijderd uit het Documoto-bestandssysteem',
      tip: 'Tip: Om te voorkomen dat bestanden worden overschreven tijdens het toevoegen van bijlagen, kunt u de bestandsnaam van het bestand dat u wilt toevoegen, wijzigen voordat u het importeert.'
    },
    deleteTitle: 'Weet u zeker dat u deze 3D-weergave wilt verwijderen?',
    drawMode: {
      label: 'Tekenmodus',
      hiddenLines: 'Verborgen lijnen',
      shaded: 'Met schaduw',
      wireframe: 'Draadraster',
      wireframeShaded: 'Draadraster met schaduw',
    },
    explode: 'Uitvouwen',
    fileSizeModal: {
      message: 'De maximaal toegestane bestandsgrootte in Documoto is 25 megabytes. Het bestand dat u probeert te uploaden heeft de maximale grootte overschreden en kan niet worden geladen. Klik op “OK” om door te gaan.',
      title: 'Maximale bestandsgrootte overschreden',
    },
    orientationAndProjection: {
      back: 'Achterkant',
      bottom: 'Onderkant',
      front: 'Voorkant',
      isometric: 'Isometrisch',
      left: 'Links',
      orthographic: 'Orthografisch',
      projection: 'Projectie',
      perspective: 'Perspectief',
      right: 'Rechts',
      top: 'Bovenkant'
    },
    resetCamera: 'Camera opnieuw instellen',
    snede: {
      label: 'Snede',
      planeX: 'Snijvlak X',
      planeY: 'Snijvlak Y',
      planeZ: 'Snijvlak Z',
      reset: 'Snijvlak opnieuw instellen',
      section: 'Snijvlaksectie wisselen',
      visibility: 'Snijvlakzichtbaarheid wisselen',
    },
    supportedFilesMessage: 'Documoto ondersteunt vele CAD-bestandformaten. Controleer het Support Center als u niet zeker weet of uw CAD-bestandsformaat wordt ondersteund.',
    toolsLabel: '3D Tools',
    topAssemblyFilename: 'Bestandsnaam Top Assembly',
    topAssemblyFilenameRequired: 'Bestandsnaam Top Assembly is vereist',
    viewerTitle: '3D Viewer',
  },
  thu: 'Don.',
  thumbnail: 'Miniatuur',
  thumbnailDeleted: 'Miniatuur Verwijderen Gelukt',
  thumbnailDeletedError: 'Miniatuur Verwijderen Mislukt',
  thumbnailUpdated: 'Miniatuur Bijwerken Gelukt',
  thumbnailUpdatedError: 'Miniatuur Bijwerken Mislukt',
  thumbnailReapplyWarning: 'Het systeem kan een miniatuur opnieuw toepassen op basis van inhoud van de media. Dit kan enkele minuten in beslag nemen.',
  thumbnailResizeTooltip: 'Verkleint/vergroot miniaturen tot maximaal 500x500 pixels, indien aangevinkt',
  title: 'Titel',
  to: 'Aan',
  toastNotifications: 'Toastmeldingen',
  toDate: 'Tot datum',
  toc: 'Inhoudsopgave',
  tocClipBoardSuccessTitle: 'Gekopieerd naar Klembord',
  tocClipBoardWarningText: 'WAARSCHUWING: Wijzigingen die niet zijn opgeslagen, kunnen niet naar uw klembord worden gekopieerd',
  tocClipBoardWarningTitle: 'Gekopieerd naar Klembord',
  tocDisplayingFirstValues: 'De eerste 100 waarden worden weergegeven. Gebruik filter om meer te zien.',
  tocDuplicateWarningText: 'Er werd een dubbele pagina gedetecteerd in een hoofdstuk. Het duplicaat werd verwijderd.',
  tocEmptyMessage: 'Zo te zien heeft u nog geen Inhoudstafel aangemaakt',
  toGetStarted: 'om te beginnen.',
  tocMissingChapterName: 'Hoofdstuknaam Ontbreekt',
  tocModalTableCaption: 'Tag Editor Modaal',
  tocNothingSelected: 'Niets Geselecteerd',
  tocOpenEditor: 'Editor Openen',
  tocSaveRequired: 'Opslaan vereist alvorens hoofdstukdetails bij te werken',
  tocState: 'Beginstatus van de Inhoudstafel',
  tocThumbnailDeleteFailTitle: 'Miniatuur Verwijderen Mislukt',
  tocThumbnailDeleteSuccessTitle: 'Miniatuur Verwijderen Gelukt',
  tocThumbnailUploadFailTitle: 'Miniatuur Uploaden Mislukt',
  tocThumbnailUploadSuccessTitle: 'Miniatuur Uploaden Gelukt',
  tocTranslationMessage: 'De naam en optionele beschrijving van dit hoofdstuk',
  tocUntitledPage: 'Pagina Zonder Titel',
  tooManySelections: 'Lijst overschrijdt selectielimiet van {limit}',
  togglePage: 'Pagina wisselen',
  top: 'Boven',
  total: 'Totaal',
  totalItems: 'Totaal Aantal Items',
  translation: 'Vertaling | Vertalingen',
  translationHelpTextItem: 'De naam en optionele beschrijving van dit {item}',
  true: 'Waar',
  tryAgain: 'Gelieve opnieuw te proberen.',
  tue: 'Di.',
  turkishDescription: 'Turkse beschrijving',
  turkishName: 'Turkse naam',
  twoSided: 'Tweezijdig',
  type: 'Type | Types',
  typeAtLeastTwoChars: 'Typ tenminste 2 tekens om een lijst met overeenkomsten te bekijken.',
  typeToConfirm: 'Typ {value} om te bevestigen',
  typography: 'Typografie',
  ukraineDescription: 'Oekraïense beschrijving',
  ukraineName: 'Oekraïense naam',
  unableToRetrieveResults: 'Kon resultaten voor uw vraag/zoekopdracht niet verzamelen',
  unauthorized: 'Niet-gemachtigd',
  unknownError: 'Onbekende fout, gelieve contact op te nemen met Documoto Support via support{\'@\'}documoto.com voor hulp',
  unknownPart: 'Onbekend Onderdeel',
  unknownType: 'Onbekend type',
  unsavedAPIKeyWarning: 'Uw API-Sleutel gaat verloren als u niet Terugkeert',
  unsavedChanges: 'U heeft wijzigingen die niet zijn opgeslagen. Verdergaan?',
  unsavedChangesWarning: 'Uw wijzigingen zullen verloren gaan als u ze niet opslaat.',
  unsavedWidgetKeyWarning: 'Uw widgetsleutel zal verloren gaan als u niet terugkeert.',
  unsupportedBrowserMessage: 'We hebben deze toepassing gebouwd met de nieuwste technologie. Dit zorgt dat de toepassing sneller en eenvoudiger is in gebruik. Helaas ondersteunt uw browser deze technologieën niet.',
  uom: 'Maateenheid',
  update: 'Update',
  updateFailed: 'Bijwerken mislukt',
  updateIndex: 'Index Bijwerken',
  updatePassword: 'Wachtwoord Bijwerken',
  updatePasswordMessage: 'Wachtwoord succesvol bijgewerkt',
  updatePasswordPending: 'Wachtwoord wijzigen opslaan in behandeling.',
  updated: 'Bijgewerkt',
  upgradeBrowser: 'Gelieve uw browser te upgraden.',
  upload: 'Uploaden',
  uploadComplete: 'Uploaden Voltooien',
  uploadInProgress: 'Bezig met Uploaden',
  uponSavingDisabledTenant: 'Na het opslaan van deze pagina met deze instelling ingeschakeld, zal het volgende gebeuren:',
  uponSavingThisLicense: 'Na het opslaan van deze pagina met de deze instelling, MOET U HET VOLGENDE DOEN:',
  upperBound: 'Bovengrens',
  untitledOrg: 'Naamloze Organisatie',
  usCurrency: 'USD',
  useDefaultCartColumns: 'Gebruik Standaard Kolommen Winkelwagen',
  useDefaultPartsListColumns: 'Standaard Kolommen Onderdelenlijst',
  user: 'Gebruiker',
  userAddress: 'Gebruikersadres | Gebruikersadressen',
  userCount: 'Gebruikerstelling',
  userEditable: 'Aanpasbaar Door Gebruiker',
  userExpirationDate: 'Vervaldatum van de gebruiker',
  userExpirationDateInvalid: 'Vervaldatum van de gebruiker moet worden ingevoerd',
  userGroup: 'Gebruikersgroep | Gebruikersgroepen',
  userGroupEmpty: 'Gebruikersgroepen kunnen niet leeg zijn',
  userGroupType: 'Type gebruikersgroep',
  userGroupUniqueMessage: 'Deze naam moet anders zijn dan alle andere Gebruikersgroepnamen voor deze tenant',
  userGroupUsers: 'Gebruikersgroep Gebruikers',
  username: 'Gebruikersnaam',
  usernameEmpty: 'Gebruikersnaam mag niet leeg zijn',
  users: 'Gebruikers',
  usersWillReceiveAnUnauthorizedError: 'Gebruikers zullen een fout “Unauthorized” krijgen wanneer ze proberen om aan te melden in de tenant.',
  useSniForSsoMetadataRetrieval: 'SNI gebruiken voor ophalen van SSO-metadata',
  value: 'Waarde',
  validate: 'Valideren',
  validateTenantDirectories: {
    buttonLabel: 'Inhoudmappen valideren',
    error: 'Mislukt om mappen te valideren',
    success: 'Tenantmappen valideren',
  },
  variantSKU: 'SKU-variant',
  validationMessage: 'Validatiebericht | Validatieberichten',
  valueGteError: 'Waarde moet kleiner zijn dan of gelijk zijn aan {max}',
  valueLteError: 'Waarde moet groter zijn dan of gelijk zijn aan {min}',
  vendorName: 'Naam van de leverancier',
  verifyPassword: 'Wachtwoord Verifiëren',
  vertical: 'Verticaal',
  version: 'Versie',
  video: 'Video | Videos',
  view: 'Weergave',
  viewAll: 'Alles bekijken',
  viewAllPagesAndBooks: 'Weergave: Alle pagina´s en boeken',
  viewAllResults: 'Alle resultaten bekijken',
  viewBooks: 'Weergave: Boeken',
  viewMore: 'Meer bekijken',
  viewPages: 'Weergave: Pagina´s',
  viewModeList: 'Lijstweergave',
  viewModeTable: 'Tabelweergave',
  visibility: 'Zichtbaarheid',
  visibilityDescription: 'Wie kan deze {entity} weergeven',
  visible: 'Zichtbaar',
  waitForUpload: 'Wacht tot de upload voltooid is.',
  watermark: 'Watermerk',
  watermarkFont: 'Lettertype watermerk',
  watermarkOpacityPercentage: '% doorzichtigheid van watermerk',
  watermarkOrientation: 'Oriëntatie van watermerk',
  watermarkSaved: 'Watermerk opgeslagen',
  watermarkSaveFailed: 'Watermerk opslaan mislukt.',
  watermarkSelectionRequired: 'Selectie van gebruikersnaam of tekst vereist.',
  watermarkText: 'Tekst watermerk',
  wed: 'Woe.',
  whereUsed: 'Waar gebruikt',
  whoCanViewComment: 'Wie kan deze opmerking bekijken?',
  wholesale: 'Groothandel',
  wholesalePrice: 'Groothandelprijs',
  wholesaleTotal: 'Totaal van groothandel',
  widget: 'Widget',
  widgetAuthHeader: 'Autorisatiewaarde van widget',
  widgetKey: 'Widgetsleutel | Widgetsleutels',
  widgetKeyAdmin: 'Admin widgetsleutel',
  widgetKeyCopied: 'Waarde van widgetautorisatie gekopieerd naar het Klembord',
  widgetKeyDeleted: 'Widgetsleutel verwijderd',
  widgetKeyDeletedError: 'Fout bij verwijderen van widgetsleutel',
  widgetKeyDeleteMsg: 'Het verwijderen van de widgetsleutel schakelt het gebruik ervan uit',
  widgetKeyManager: 'Widgetsleutelmanager',
  widgetKeyNameEmpty: 'Naam moet uniek zijn en is verplicht. Deze waarde wordt ook gebruikt voor rapportage- en logdoeleinden als gebruikersnaam voor elke widgetactiviteit',
  widgetKeyRegenError: 'Fout bij genereren van widgetsleutel',
  widgetKeyRegenMsg: 'Regenereren van deze widgetsleutel kan bestaande integraties uitschakelen',
  widgetKeyRegenTitle: 'Weet u zeker dat u deze widgetsleutel wil verwijderen?',
  widgetKeySaved: 'Widgetsleutel opgeslagen',
  widgetPermissions: 'Widgetgebruikers zijn alleen-lezen en de toegang tot functies wordt beheerd via de configuratie op widgetniveau. Standaardrechten voor gebruikersgroepen zijn niet van toepassing op widgetgebruikers. Toegangsrechten voor mediacategorieën wel. Ga verder met het toewijzen van toegangscontroles voor mediacategorieën.',
  widgetRefreshErrorMsg: "Vernieuw jouw pagina om de gevraagde content te bekijken",
  widgetUserGroup: 'Widgetgebruikersgroep',
  widgetUserGroupDeleteMsg: 'Kan widgetgroep met gebruikers niet verwijderen',
  willBeAffected: 'zal beïnvloed worden.',
  willBeDeleted: '{content} zal verwijderd worden.',
  willBeInsertedIntoItem: '{name} zal ingevoegd worden voor {item}',
  willBeUpdated: 'zal bijgewerkt worden.',
  zipCode: 'Postcode',
  zoomHotpointToCanvas: 'Hotpoint zoomen tot Canvasformaat',
  zoomIn: 'Inzoomen',
  zoomOut: 'Uitzoomen'
}
/* eslint-enable max-len */
