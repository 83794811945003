<template>
  <component  :is="searchComponent"
              class="widget-search"
              @widgetNavToContent="mapDtoAndNavToWidgetContent($event, dtoMap)"
              @clearSearchInput="clearSearchInput"
              @toggleExactMatch="toggleExactMatch"
  >
    <template #printExport>
      <div class="is-inline-flex"></div>
    </template>
    <template v-slot:content="slotProps">
        <widget-loading-icon v-if="!slotProps.itemsLoaded
                            && isFreshSearch"/>
        <component  v-else
                    :is="slotProps.viewModeComponent"
                    :items="slotProps.items"
                    :offset="offset"
                    :total="total"
                    :isGettingMore="isGettingMore"
                    class="widget-search-results"
                    @info="slotProps.info"
                    @open="slotProps.open"
                    @widgetNavToContent="mapDtoAndNavToWidgetContent($event, dtoMap)"
                    @getMoreSearchResults="getMoreSearchResults"/>
    </template>
    <template #searchPagination>
      <div></div>
    </template>
  </component>
</template>

<script setup>
import { computed, ref } from "vue"
import { useStore } from "vuex"
import Search from "@/components/library/search/Search"
import BrowseSearch from "@/components/library/browse/BrowseSearch"
import WidgetLoadingIcon from "@/components/widgets/components/WidgetLoadingIcon"
import { useWidgetNavigator } from '@/components/widgets/composables/useWidgetNavigator'
const emit = defineEmits(['clearSearchInput'])

const store = useStore()
const { isWithinBrowseContext,
  mapDtoAndNavToWidgetContent } = useWidgetNavigator()

const isGettingMore = ref(false)

const offset = computed(() => isWithinBrowseContext.value
  ? store.state.browse.search.offset
  : store.state.search.offset)
const total = computed(() => isWithinBrowseContext.value
  ? store.state.browse.search.total
  : store.state.search.total)
const isFreshSearch = computed(() => offset.value === 0)
const searchComponent = computed(() => isWithinBrowseContext.value
  ? BrowseSearch
  : Search)

const dtoMap = new Map([
  ["contentType", "mediaType"],
  ["created", "created"],
  ["description", "description"],
  ["id", "entityId"],
  ["identifier", "identifier"],
  ["name", "name"],
  ["type", "entityType"],
  ["updated", "updated"]
])

async function getMoreSearchResults() {
  if (isGettingMore.value) return
  try {
    isGettingMore.value = true
    const action = isWithinBrowseContext.value
      ? 'browse/setSearchPage'
      : 'search/setSearchPage'
    await store.dispatch(action, offset.value + 1)
  } catch(e) {
    console.error(e)
  } finally {
    isGettingMore.value = false
  }
}
const clearSearchInput = () =>{
  emit('clearSearchInput')
}
const toggleExactMatch = () =>{
  emit('toggleExactMatch')
}
</script>
<style>
.widget-search > :not(.widget-search-results):not(:last-child) {
  min-height: fit-content;
  padding-bottom: 0.25rem;
}
.widget-search > aside {
  z-index: 39;
}
.widget-search-results {
  border-top: 1px solid lightgrey;
}
.widget-search > .pagination {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
</style>
