/* eslint-disable max-len */
module.exports =
{
  aOfB: '{a} sur {b}',
  about: 'À propos',
  aboutThisItem: 'À propos de cet article',
  accessApiKeyManager: 'Accéder au Gestionnaire de Clés d’API pour ce locataire.',
  accessControls: 'Contrôles d’Accès',
  accessControlsCopiedText: 'Les contrôles d’accès sont mis à jour instantanément. Les privilèges de sécurité des médias seront mis à jour d’ici quelques minutes.',
  accessControlsCopiedTitle: 'Les contrôles d’accès ont été copiés avec succès',
  accessControlsCopyConfirmation: 'Cette opération supprimera tous les contrôles d’accès de {to} et les remplacera par les contrôles d’accès de {from}. Cette action est irréversible.',
  accessControlsDescription: 'Les contrôles d’accès définissent qui peut voir ce média, en fonction des Organisations et des Catégories de Médias sélectionnées.',
  accessControlsIndeterminance: 'Remarque : Les organisations parent peuvent accéder en permanence au contenu attribué à leurs entités enfant.',
  account: 'Compte',
  accountErrorMessage: 'Message d‘erreur de compte',
  accountErrorMessageWarning: 'Le champ accountErrorMessage de la base de données sera paramétré sur "Non autorisé" pour le locataire.',
  action: 'Action | Actions',
  actionDelete: 'supprimer',
  actionLoad: 'charger',
  actionPaste: 'Coller',
  actionSave: 'Enregistrer',
  actionRun: 'exécuter',
  actionUndoWarning: 'Cette action est irréversible.',
  actionUpload: 'télécharger',
  actualSize: 'Taille Réelle',
  add: 'Ajouter',
  addAPIKey: 'Ajouter une Clé d’API',
  addItem: 'Ajouter {item}',
  addABookText: 'Ajouter un livre',
  addASharedCart: 'Ajouter un panier partagé',
  addATemplate: 'Ajouter un modèle',
  addATag: 'Ajouter une Balise',
  addABookToStart: '{addABookText} pour commencer.',
  addAForm: 'Ajouter un Formulaire pour commencer.',
  addAFormOnly: 'Ajouter un Formulaire',
  addAMedia: 'Ajouter un média',
  addAnOrderQuoteEmailFormat: 'Ajouter un Format d‘E-mail de Commande/Devis',
  addAPrintSettingText: 'Ajouter un Paramètre d’Impression',
  addAPrintSettingToStart: '{addAPrintSettingText} pour commencer.',
  addBooks: 'Ajouter des Livres',
  addChildBrowseFlow: 'Ajouter un Parcours de Flux Enfant',
  addChapter: 'Ajouter Chapitre',
  addComment: 'Ajouter un commentaire',
  addExistingPart: 'Ajouter une Pièce Existante',
  addGroupsToUsers: 'Ajouter les groupes {groups} aux utilisateurs {users}',
  additionalInformation: 'Informations Supplémentaires',
  additionalNotification: 'Notification Supplémentaire',
  additionalPartNumberDescription: 'Utiliser comme champ de numéro de pièce supplémentaire',
  addBrowseFlow: 'Ajouter un Parcours de Flux',
  addMedia: 'Ajouter un Média',
  addPages: 'Ajouter des Pages',
  addPart: 'Ajouter une Pièce',
  addReports: 'Ajouter Rapports',
  addRootBrowseFlow: 'Ajouter un Parcours de Flux Racine',
  addRow: 'Ajouter une Ligne',
  addTemplates: 'Ajouter des modèles',
  addToCartMessage: '{partNumber} ajouté au panier avec succès',
  addToUserGroup: 'Ajouter aux Groupes',
  address: 'Adresse | Adresses',
  addressInUse: 'Une adresse est actuellement définie comme adresse par défaut et ne peut pas être supprimée',
  addressInUseSubtitle: 'Pour supprimer cette adresse, vous devez d’abord désigner une autre adresse comme adresse par défaut.',
  addressLine: 'Ligne d‘adresse {number}',
  addWidgetKey: 'Ajouter une clé de widget',
  admin: 'Gestion',
  adminCenter: 'Centre de Gestion',
adminCenterBanner: {
    bodyLabel: 'Corps de la bannière',
    enableLabel: 'Activer Bannière Admin',
    headerGreenOption: 'Vert',
    headerLabel: 'En-tête Bannière',
    headerRedOption: 'Rouge',
    headerYellowOption: 'Jaune',
    title: 'Bannière du Centre d’Admin',
    typeLabel: 'Type de Bannière'
  },
  adminCenterMessage: 'Centre de Gestion pour le contenu, les utilisateurs, les paramètres de bibliothèque, l’établissement de rapports et l’exportation',
  adminMessage: 'De nouvelles fonctionnalités seront ajoutées au fur et à mesure de leur disponibilité',
  administration: 'Gestion',
  affectedBookMedia: '{count} Le média Livre sera mis à jour.',
  affectedTemplateMedia: '{count} Les modèles Livres seront mis à jour.',
  after: 'Après',
  aggregatedFromChildren: 'Regroupé à partir des Enfants',
  aiAlgorithm: 'Scanner IA',
  all: 'Tout',
  alphanumeric: 'Alphanumérique',
  algorithm: 'Algorithme',
  alignHorizontal: 'Alignement Horizontal',
  alignVertical: 'Alignement Vertical',
  allUsersDisabled: 'Tous les utilisateurs qui appartiennent au locataire seront supprimés.',
  analyticsJSON: 'Analyse JSON',
  andCountMore: 'et {count} de plus...',
  any: 'Tous',
  apiKey: 'Clé d’API | Clés d’API',
  apiKeyAdmin: 'Admin Clé d’API',
  apiAuthHeader: 'En-tête d’Autorisation API',
  apiKeyCopied: 'Valeur d’En-tête d’Autorisation API copiée dans le Presse-papiers',
  apiKeyDeleted: 'Clé d’API supprimée',
  apiKeyDeletedError: 'Erreur lors de la suppression de la Clé d’API',
  apiKeyDeleteMsg: 'Supprimer cette Clé d’API désactivera son utilisation par les intégrations',
  apiKeyDeleteTitle: 'Êtes-vous sûr(e) de vouloir supprimer cette Clé d’API ?',
  apiKeyInstructions: 'La clé sera générée lors de l’Enregistrement',
  apiKeyManager: 'Gestionnaire de Clés d’API',
  apiKeyNameEmpty: 'Le champ Nom ne peut pas être vide',
  apiKeyRegenError: 'Erreur lors de la régénération de la Clé d’API',
  apiKeyRegenMsg: 'Régénérer cette Clé d’API pourrait désactiver les intégrations existantes',
  apiKeyRegenTitle: 'Êtes-vous sûr(e) de vouloir régénère cette Clé d’API ?',
  apiKeySaved: 'Clé d’API Enregistrée',
  apiKeyType: 'Type de Clé d’API',
  appliesTo: 'Applicable À',
  appliesToEmpty: 'Au moins un type d’entité doit être sélectionné',
  appliesToPopulated: 'Définir les entités pouvant recevoir la balise',
  applyItem: 'Appliquer {item}',
  applyToChildItems: 'Appliquer à l’Enfant {item}',
  applyToPartOnly: 'Appliquer uniquement à cette pièce',
  april: 'Avril',
  area: 'Zone',
  areYouSure: 'Êtes-vous sûr(e) de vouloir supprimer {item} ?',
  areYouSureCopy: 'Êtes-vous sûr(e) de vouloir copier {item} ?',
  areYouSureYouWantToDisableTheTenant: 'Voulez-vous vraiment désactiver le locataire ?',
  assembly: 'Assemblage | Assemblages',
  assemblyCreatorSuccessTitle: 'Tâche d‘ensemble créé soumise',
  assemblyCreatorSuccessSubtitle: 'Les modifications apportées aux liens des points de référencement et à la table des matières du livre seront effectuées immédiatement. Les résultats de recherche afficheront les modifications une fois l‘indexation terminée.',
  assemblyConfirmationTitle: 'Souhaitez-vous terminer la création de l’assemblage ?',
  assemblyCreatorHelpText1: 'Cette fonctionnalité vous permet d’insérer une page sélectionnée aux livres contenant cette pièce {partName}, reliant automatiquement la pièce et la page sélectionnée grâce à un lien de référencement. Vous avez également l’option de limiter l’insertion aux seuls livres sélectionnés.',
  assemblyCreatorHelpText2: 'La page sélectionnée est insérée une fois par livre, après la première occurrence de {partName}.',
  assemblyStepOne: '1ère étape : Sélectionner la page à insérer',
  assemblyStepTwo: '2ème étape : Choisir les livres dans lesquels vous souhaitez insérer la page',
  asset: 'Actif | Actifs',
  assetsNotFound: 'Aucun {type} trouvé',
  assignBrowseFlow: 'Attribuer un Parcours de Flux',
  assignedOrganizations: 'Organisations Attribuées',
  atLeastFiveError: 'Veuillez sélectionner une valeur qui n‘est pas inférieure à 5.',
  attachment: 'Pièce Jointe | Pièces Jointes',
  attachmentEditorDescription: 'Joindre des images, vidéos et documents directement à cette entité. Remarque : vous les trouverez dans l’onglet « Associé ».',
  attachmentView: 'Voir les Pièces Jointes',
  attachRelatedPDFs: 'Joindre les fichiers PDF des pages des pièces associées',
  attributions: 'Attributions',
  august: 'Août',
  automaticZoom: 'Zoom Automatique',
  autoHotpoint: 'Référencement Auto',
  autoPopulate: 'Saisie Automatique',
  availability: 'Disponibilité',
  back: 'Retour',
  backCover: 'Couverture Arrière',
  backStitch: 'Couture Arrière',
  backToLibrary: 'Retour à la Bibliothèque',
  backToSearch: 'Retour à la Recherche',
  badCredentials: 'Mauvais Identifiants',
  bahasaDescription: 'Description Indonésienne en Bahasa',
  bahasaName: 'Nom Indonésien en Bahasa',
  banner: 'Bannière',
  before: 'Avant',
  beginTypingToFindAnything: 'Commencer à saisir du texte pour trouver quelque chose',
  betaAccessKeys: 'Clés d‘accès bêta',
  betaResources: 'Ressources Bêta',
  betaReleaseVideo: 'Vidéo de la Version Bêta',
  blobFinder: 'Détecteur de BLOB',
  body: 'Corps',
  bomDefaultSupplier: 'Fournisseur par Défaut de la Nomenclature',
  bomItem: 'Article de Nomenclature',
  bom: 'Nomenclature',
  bomExportSuccess: 'Exportation de la Nomenclature Réussie',
  bomLayout: 'Disposition des Nomenclatures',
  bomListOptions: 'Options de Liste de Nomenclature',
  bomListSettings: 'Paramètres de Liste de Nomenclature',
  both: 'Les deux',
  book: 'Livre | Livres',
  bookInserts: 'Encarts de Livre',
  bookLabel: 'Livre | Livres',
  bookPrintingDisable: 'Désactiver l’Impression du Livre',
  booksAndPagesDisplay: 'Affichage des Livres et des Pages',
  boost: 'Recherche Rapide',
  boostDescription: 'Ajouter une valeur supérieure à 1,5 pour améliorer la pertinence de la recherche de cette balise par rapport aux numéros de pièce, aux identifiants de médias et aux noms',
  boostRangeInvalid: 'Boost doit être compris entre 0,5 et 100,0',
  border: 'Bordure',
  bottom: 'Bas',
  branding: 'Marquage',
  browse: 'Parcourir',
  browseComputer: 'Parcourir l’Ordinateur',
  browseFlow: 'Parcourir le Flux | Parcourir les Flux',
  browseFlowAssigned: 'Parcours de Flux Attribué',
  browseFlowCopyMessage: 'Veuillez saisir un nom unique pour le nouveau Parcours de Flux.',
  browseFlowDeleted: 'Flux de navigation supprimé',
  browseFlowDeleteMessage: 'La suppression d’un parcours de flux ne supprime pas seulement le parcours de flux mais aussi tous ses noeuds enfant.',
  browseFlowEmptyState: 'Aucun Parcours de Flux défini. {new} pour commencer.',
  browseFlowHierarchy: 'Hiérarchie des Parcours de Flux',
  browseFlowNoExistingCriteria: 'Aucun critère de recherche n`a été défini pour ce parcours de flux. Utilisez les commandes ci-dessus pour sélectionner des critères de recherche.',
  browseFlowMoveToRoot: 'Épingler le nœud ici pour créer un enfant de premier rang',
  browseFlowNoAssignedOrgs: 'Aucune organisation attribuée',
  browseFlowSaveFailed: 'Impossible de mettre à jour le Parcours de Flux',
  browseFlowSetDefaultSuccess: 'Le Parcours de Flux par défaut a été défini',
  browseFlowThumbnailDeleteFailTitle: 'La Suppression de la Vignette a Échoué',
  browseFlowThumbnailDeleteSuccessTitle: 'Vignette Supprimée avec Succès',
  browseFlowThumbnailUploadFailTitle: 'Le Téléchargement de la Vignette a Échoué',
  browseFlowThumbnailUploadSuccessTitle: 'Vignette Téléchargée avec Succès',
  browseFlowTooManyOrgs: 'Vous avez trop d’organisations pour utiliser l’éditeur actuel de parcours de flux. Pour obtenir de l’aide sur la façon de modifier vos parcours de flux Documoto, veuillez contacter l’Assistance Documoto à l’adresse e-mail suivante : {email}',
  browserPartiallySupport: 'Il se peut que vous voyiez des différences d’ordre esthétique et d’autres changements avec ce navigateur.',
  browseFlowSaved: 'Parcours de Flux enregistré',
  browserDeprecation: {
    title: 'Préavis de dépréciation du navigateur',
    text: 'En raison de l`abandon progressif par Microsoft de la prise en charge d`Internet Explorer (toutes les versions) et des problèmes de sécurité connexes, nous ne prendrons plus en charge l`accès des utilisateurs à ce site via Internet Explorer (toutes les versions) après le 30 Septembre 2021.'
  },
  buildHotpointLinks: 'Créer des Liens de Référencement',
  buildHotpointLinksInfo: 'Cette action est une action d’ajout. Elle ne supprimera aucun lien de référencement existant. Cette action ajoutera de nouveaux liens de points de référencement entre les pièces et toutes les pages d’assemblage correspondantes si un lien de point de référencement n’existe pas déjà.',
  buildHotpointLinksTitle: 'Souhaitez-vous créer des liens de référencement pour ce livre ?',
  bulkIndexingLane: 'Voie d‘indexation groupée',
  bulkInsert: 'Insertion Groupée',
  bulkExportConfirm: 'Souhaitez-vous exporter {n} {unit} ?',
  bulkExportLimit: 'La limite est fixée à {limit} livres par tâche d’exportation',
  bulkPageInsertJob: 'Insertion de Page Groupée',
  business: 'Commerce',
  cancel: 'Annuler',
  canceled: 'Annulé',
  cancelAssemblyCreatorTitle: 'Abandonner l‘ensemble créé ?',
  cancelAssemblyCreatorSubtitle: 'Votre ensemble créé ne sera pas traité.',
  cannotExceed100: 'Ne doit pas dépasser 100 %',
  cart: 'Panier | Paniers',
  cartBehaviorEnabled: 'Activer l’Invite Quantité à Ajouter au Panier',
  cartBehaviorDisabled: 'Désactiver l’Invite Quantité à Ajouter au Panier',
  cartBehaviorLabel: 'Comportement de l’Invite Quantité à Ajouter au Panier',
  cartColumn: 'Colonne Panier | Colonnes Panier',
  cartColumn1: '1ère Colonne de Prix du Panier',
  cartColumn2: '2ème Colonne de Prix du Panier',
  cartColumn3: '3ème Colonne de Prix du Panier',
  cartColumnPartLocationContent1: 'Activer cette fonction aura les conséquences suivantes :  ',
  cartColumnPartLocationContent2: "L'unicité de la ligne de pièces dans le panier sera également basée sur son emplacement dans un élément de contenu, le cas échéant.",
  cartColumnPartLocationContent3: 'Ce paramètre ne peut pas être désactivé au niveau de l`organisation et s`appliquera à tous les utilisateurs.',
  cartColumnPartLocationContent4: 'Ce paramètre ne doit pas être activé si vous disposez d`une intégration Soumettre le Panier sans avoir préalablement contacté le Responsable de la Réussite Client de Documoto.',
  cartColumnPartLocationOption: 'Activer les Données de Localisation de Pièces dans le Panier',
  cartColumnPartLocationTitle: 'Êtes-vous sûr(e) de vouloir activer les Données de Localisation de Pièces dans le Panier ?',
  cartDisable: 'Désactiver le Panier',
  cartEmailFailed: 'Échec de l‘E-mail de Panier',
  cartEmailSent: 'Succès de l‘E-mail de Panier',
  cartEnable: 'Activer le Panier',
  cartSaveEnable: 'Activer l’Enregistrement du Panier',
  cartErpMessage: 'Envoyé au système ERP avec succès',
  cartImportError: 'Erreur lors de l’importation du panier',
  cartLoadedWithItemsRemoved: '{count} élément(s) retirés car vous n’avez pas la permission de les commander à l’heure actuelle',
  cartSharedAddEdit: 'Ajouter/Modifier des Paniers Partagés',
  cartUpdated: 'Panier Mis à Jour',
  cartUpdatedAndRemovedCount: 'Panier Mis à jour ; {count} pièces indisponibles à la commande supprimées',
  category: 'Catégorie | Catégories',
  categoryCreated: 'Catégorie Créée',
  categoryUpdated: 'Catégorie Mise à Jour',
  centimeter: 'Centimètre | Centimètres',
  changeNotification: 'Notification de modification',
  changeScope: 'Cette opération affectera {n} {type}',
  changeUserOrganization: 'Modifier l’Organisation',
  chapter: 'Chapitre | Chapitres',
  chapterHeader: 'En-tête de Chapitre',
  chaptersOnly: 'Chapitres Uniquement',
  chineseDescription: 'Description en Chinois',
  chineseName: 'Nom en Chinois',
  circle: 'Cercle',
  city: 'Ville',
  classicAdminMessage: 'Cette option vous redirigera vers Flex pour utiliser les modules de gestion.',
  classicAdmins: 'Classic Admins',
  classicExporter: 'Classic Exporter',
  classicExporterMessage: 'Module Flash hérité d’exportation de contenu',
  classicOrganizationAdmin: 'Classic Organization Admin',
  classicOrganizationAdminMessage: 'Module Flash hérité d’administration des utilisateurs pour la gestion des organisations et des utilisateurs',
  classicPublisher: 'Classic Publisher',
  classicPublisherMessage: 'Module Flash hérité d’édition pour la création de contenu et les paramètres d’impression',
  classicReporting: 'Classic Reporting',
  classicReportingMessage: 'Module Flash hérité d’établissement de rapports',
  classicTenantAdmin: 'Classic Tenant Admin',
  classicTenantAdminMessage: 'Module Flash hérité de gestion des paramètres à l’échelle des locataires',
  className: 'Nom de classe',
  clear: 'Effacer',
  clearItemNumber: 'Effacer le Numéro d‘Article',
  clearItemNumberTitle: 'Voulez-vous effacer le numéro d‘article ?',
  clearItemNumberMessage: 'L‘effacement du numéro d‘article entraînera la suppression des données suivantes:',
  clearFilters: 'Effacer les Filtres',
  clearHotpoints: 'Effacer les Points de Référencement',
  clearSearch: 'Effacer Recherche',
  clickHereToUpload: 'Déposer un fichier ou cliquer pour télécharger une version de remplacement.',
  clickMagnifyingGlass: 'Cliquez sur la loupe pour accéder directement à Recherche Avancée.',
  clickOkToContinue: 'Cliquez sur "OK" pour continuer.',
  clickToDownload: 'Cliquer pour Télécharger',
  clickToEnlarge: 'Cliquez pour Agrandir',
  clickToOpen: 'Cliquer pour Ouvrir',
  clipboard: 'Presse-papiers',
  clipboardAccessError: 'Veuillez modifier les paramètres de votre navigateur pour autoriser l‘accès au Presse-papier.',
  close: 'Fermer',
  closed: 'Fermé',
  collapseAll: 'Tout Réduire',
  color: 'Couleur | Couleurs',
  column: 'Colonne | Colonnes',
  columnName: 'Nom de colonne',
  columnItemType: {
    customText: 'Texte Personnalisé',
    documentTitle: 'Titre du Document',
    horizontalLine: 'Ligne Lorizontale',
    pageName: 'Nom de Page',
    pageNumber: 'Numéro de Page',
    printDate: 'Date d’Impression',
    tenantName: 'Nom du Locataire',
    userName: 'Nom d’Utilisateur'
  },
  combinedPage: 'Page Combinée',
  combinedPageMessage: 'Diagramme en haut, liste des pièces en bas',
  comingSoon: 'Prochainement',
  commaDelimited: 'Délimité par des virgules',
  comment: 'Commentaire | Commentaires',
  commentAdded: 'Commentaire ajouté',
  commentDeleted: 'Commentaire supprimé',
  commentEdit: 'Ajouter/Modifier un commentaire',
  commentEditAll: 'Modifier Tous les Commentaires',
  commentUpdated: 'Commentaire mis à jour',
  commentView: 'Voir les Commentaires',
  commerceSystem: 'Système de Commerce',
  completed: 'Terminé',
  confirm: 'Confirmer',
  confirmBrowseFlowApplyToChildren: 'Souhaitez-vous appliquer ce parcours de flux aux organisations enfant ?',
  confirmBrowseFlowOverrideToChildren: 'Cette opération supprimera tout remplacement de parcours de flux défini au niveau des organisations enfant.',
  confirmPrintSettingsApplyToChildren: 'Souhaitez-vous appliquer ce paramètre d’impression aux organisations enfant ?',
  confirmPrintSettingsOverrideToChildren: 'Cette opération supprimera tout remplacement de paramètre d’impression défini au niveau des organisations enfant.',
  contains: 'Contient',
  containsHeaderRow: 'Contient Rangée d‘En-tête',
  content: 'Contenu | Contenus',
  contentAccessControls: 'Contrôle d’Accès {content}',
  contentEntity: 'Entité de Contenu | Entités de Contenu',
  contentNotFound: 'Contenu introuvable',
  contentUpdatedQueueJob: 'File d‘attente de Contenus Mis à jour',
  contourShape: 'Forme de contour',
  convertEntity: 'Convertir {entity}',
  convertToEntity: 'Convertir en {entity}',
  copyAccessControls: 'Copier les Contrôles d’Accès',
  copyAccessControlsWarning: 'Tous les contrôles d’accès attribués à l’Organisation sélectionnée seront copiés vers cette Organisation',
  copy: 'Copier',
  copyAndRetain: 'Copier et Conserver pour Utilisation dans l’Intégration',
  copyAndRetainWidget: 'Copier et Conserver, c’est la seule fois où la valeur d’Autorisation de Widget sera visible pour vous dans l’application',
  copyAtoB: 'Copier {a} vers {b}',
  copyContent: 'Copier {type} ? {name}',
  copyingItem: 'Copie de {item}',
  copyItem: 'Copier {item}',
  copyItemQuestion: 'Copier {item} ?',
  copyTo: 'Copier vers {target}',
  copyMeMessage: 'Me mettre en copie de ce message',
  copyrightMessage: '© 2010-{year} Documoto Inc. Tous droits réservés.',
  count: 'Nombre',
  country: 'Pays | Pays',
  coverPages: 'Pages de Couverture',
  coverAmpStitchPages: 'Pages de Couverture et de Couture',
  createAssembly: 'Créer l’Assemblage',
  created: 'Créé(e)',
  createdBy: 'Créé(e) par {name}',
  createdDate: 'Date de création',
  createdOn: 'Créé(e) le',
  currentPassword: 'Mot de Passe Actuel',
  custom: 'Personnalisé',
  czechDescription: 'Description en Tchèque',
  czechName: 'Nom en Tchèque',
  dashboard: 'Tableau de Bord | Tableaux de Bord',
  dashboardLibrary: 'Bibliothèque des Tableaux de Bord',
  dataInsights: 'Informations sur Données',
  dataInsightsInfoMessage: 'Centre de Rapports et de Tableaux de Bord pour des informations sur le contenu, les utilisateurs et les données de bibliothèque.',
  dataMapping: 'Mappage des Données/Échantillon de Données (jusqu‘à 10 lignes)',
  dataOptions: 'Options de données',
  date: 'Date',
  dateModified: 'Date de Modification',
  debugPageBuilderPLZ: 'Deboguer le Créateur de Page PLZ',
  december: 'Décembre',
  decreaseHotpoint: 'Diminuer Référencement',
  default: 'Par Défaut',
  defaultAddress: 'Adresse par Défaut',
  defaultBrowseFlow: 'Parcours de Flux par Défaut',
  defaultCurrencyCode: 'Code de Devise par Défaut',
  defaultCurrencySymbolOn: 'Symbole de devise par défaut Activé',
  defaultEmailRecipient: 'Destinataire de l’E-mail par Défaut | Destinataires de l’E-mail par Défaut',
  defaultImage: 'Image par Défaut | Images par Défaut',
  defaultImagesManager: 'Gestionnaire d’Images par Défaut',
  defaultItemImage: 'Image par Défaut pour {item}',
  defaultPartsListLayout: 'Disposition par Défaut de la Liste des Pièces',
  defaultPartsListLayoutHelp: 'Disposition par défaut des pièces à gauche ou en dessous de l’illustration',
  defaultSearchMode: 'Mode de Recherche par Défaut',
  defaultSearchModeHelp: "Définir la méthode de recherche globale par défaut sur 'contains' ou 'exact'",
  defaultSortMode: 'Mode de Tri par Défaut',
  defaultSupplier: 'Fournisseur par Défaut',
  defaultTocStateOpen: 'Statut Par Défaut Ouvert de la Table des Matières',
  defaultTocStateOpenHelp: 'Détermine si la table des matières reste ouverte pendant la consultation d’un livre',
  defaultUom: 'Unité de Mesure par Défaut',
  defaultValue: 'Valeur par défaut',
  defaults: 'Paramètres par défaut',
  defaultsAndCapabilities: 'Paramètres par défaut et Capacités',
  delete: 'Supprimer',
  deleteAll: 'Hériter du Parent',
  deleteAnyPartnerApiKeys: 'Supprimer toutes les Clés d’API Partenaires qui ont été fournies à SmartEquip.',
  deleteContent: 'Êtes-vous sûr(e) de vouloir supprimer {type} ? {name}',
deletePart: {
    infoOne: 'Les suppressions de pièces prennent effet immédiatement dans Bibliothèque et Admin de Contenu. La pièce supprimée restera interrogeable jusqu’à la réindexation dans les prochaines minutes.',
    deletingPartNumber: '{partNumber} sera supprimé.',
    affectedCarts: 'Tous les paniers contenant cette pièce verront la pièce définitivement supprimée.',
    infoTwo: 'Cette action ne peut pas être annulée sans l’aide de l’Assistance Documoto. Après 90 jours, l’enregistrement supprimé et toutes les associations seront définitivement purgés.'
  },
deletePartInUse: {
    title: 'Cette pièce appartient à une ou plusieurs page(s) et ne peut pas être supprimée.',
    body: 'Actuellement, Documoto prend uniquement en charge la suppression des pièces qui n’appartiennent pas à des pages. Veuillez supprimer cette pièce de toutes les pages publiées ou pages brouillon afin de procéder à la suppression demandée.',
    submitLabel: 'Aller à la page d’utilisation'
  },
  deleteRelated: 'Êtes-vous sûr(e) de vouloir supprimer{count} {type} ?',
  deletedItem: '{item} supprimé',
  deleteFilesMessage: 'Êtes-vous sûr(e) de vouloir supprimer {count} fichier(s) ?',
  deleteHotpointLinksTitle: 'Êtes-vous certain(e) de vouloir supprimer le(s) lien(s) de référencement ?',
  deleteMessage: 'Êtes-vous sûr(e) de vouloir supprimer ?',
  deleteSelectedCriteria: 'Supprimer le(s) Critère(s) Sélectionné(s)',
  deleteTagsTitle: 'Êtes-vous certain(e) de vouloir supprimer le(s) balise(s) ?',
  deleteTaxonMessage: 'Cette action supprimera définitivement cette taxonomie ainsi que tous ses enfants. Êtes-vous sûr(e) de vouloir continuer ?',
  deleteTaxonomyMessage: 'Cette action supprimera définitivement toutes les taxonomies pour cette Organisation ou ce Locataire. Êtes-vous sûr(e) de vouloir continuer ?',
  deleteTenantSupplier: 'Êtes-vous sûr(e) de vouloir supprimer ce fournisseur pour le locataire ?',
  demo: 'Démo',
  description: 'Description',
  deselectAll: 'Tout Désélectionner',
  deselectCriteria: 'Désélectionner le(s) Critère(s)',
  detail: 'Détail | Détails',
  diagonal: 'Diagonal',
  diameter: 'Diamètre',
  disable: 'Désactiver',
  disableAll: 'Désactiver tout',
  disableDocumotoTenant: 'Désactiver Locataire Documoto',
  disableFwdReindex: 'Désactiver le transfert du média cible de réindexation pour DSW',
  disableLibraryBannerImage: 'Désactiver Image de la Bannière de la Bibliothèque',
  disableSmartEquipModalTitle: 'Voulez-vous vraiment désactiver cette licence ?',
  disableTEK: 'Désactiver la Connexion par URL directe',
  disabled: 'Désactivé',
  discard: 'Annuler',
  discardChanges: 'Annuler les modifications',
  discardQ: 'Annuler les modifications ?',
  discounted: 'Réduit',
  discountedPrice: 'Prix Réduit',
  discountedTotal: 'Total Réduit',
  discountPrice: 'Prix Réduit',
  displayInOrdersList: 'Afficher dans la Liste des Commandes',
  displayOrder: 'Ordre d’Affichage',
  displayOrderDescription: 'Définit l’ordre d’affichage du menu déroulant des Filtres pour les balises de types de contenu',
  document: 'Document | Documents',
  documentLabel: 'Document | Documents',
  documentSettings: 'Paramètres du Document',
  documotoAcademy: 'Documoto Academy',
  documotoLicensePackage: 'Forfait de licence Documoto',
  domain: 'Domaine',
  domainEmptyMessage: 'Domaine Autorisé ne peut pas être vide',
  domainInvalidMessage: 'Un nom de domaine valide est requis',
  download: 'Télécharger',
  downloadBrowser: 'Télécharger l’un de ces excellents navigateurs.',
  downloadFailed: 'Erreur de téléchargement de {name}',
  downloadStarted: 'Téléchargement en cours de {name}',
  draft: 'Brouillon | Brouillons',
  draftBomDeleteTitle: 'Êtes-vous certain(e) de vouloir supprimer cette pièce de la page ?',
  draftBomMultipleDeleteTitle: 'Êtes-vous certain(e) de vouloir supprimer ces pièces de la page ?',
  draftBomDropError: 'Impossible d’imbriquer les pièces sur plus de 5 niveaux de profondeur',
  draftBomEmptyField: 'Tous les champs dans Liste des Pièces doivent être remplis',
  draftBomQuantityError: 'Quantité doit être un chiffre',
  draftBomError: 'Erreur lors de la manipulation de la Nomenclature brouillon',
  draftPage: 'Page Brouillon',
  draftPageDelete: 'Supprimer Brouillon',
  draftPageDeleteMessage: 'Page Brouillon supprimée avec succès.',
  draftPageDeleteWarning: 'La suppression de la Page Brouillon supprimera les modifications en cours.',
  draftPageLastUpdated: 'Brouillon - Dernière Mise à jour {date} par {username}',
  draftPageLoadError: 'Échec de chargement de la page brouillon.',
  draftPageProcessingPlz: 'Page en cours de chargement à partir du fichier PLZ',
  draftPagePublishInProgress: 'Tâche de Publication de Page en cours',
  draftPageSaveSuccess: 'Page Brouillon enregistrée avec succès.',
  draftPageSaveUnsuccessful: 'Échec d’enregistrement de la page Brouillon.',
  draftSheetImageHotpointsFailure: 'Impossible de générer des points de référencement {algorithm} pour l‘image de la feuille de brouillon.',
  draftValidationMessage: {
    item: 'AVERTISSEMENT : Le Code Produit {partNumber} de la Nomenclature n‘a pas de Numéro d‘Article spécifié',
    empty: 'Pas d‘Erreurs/Avertissements de Validation',
    hotpointOrHotpointLink: 'L‘article de Nomenclature {item} n‘a pas de référencement(s)/lien(s) de référencement spécifiés',
    quantity: 'Article de Nomenclature {item} n‘a pas de quantité spécifié',
    tag: 'Valeur de balise absente de la balise {tagName} de l‘article de Nomenclature {item}',
    translationDescription: 'Description de traduction {lang} absente de l‘article de Nomenclature {item}',
    uom: 'L‘article de Nomenclature {item} n‘a pas d‘unité de mesure spécifiée',
    supersede: 'La pièce {partNumber} a remplacé {supersededPartNumber} en raison d‘une substitution.'
  },
  drawAutohotpointRegion: 'Dessiner la taille {shape}',
  drawMinimumArea: 'Dessiner la Zone Minimum',
  dropFileMessage: 'Déposer le fichier ici ou cliquer pour télécharger.',
  duplicate: 'Doublon',
  duplicateSaveError: 'Doublon. Enregistrement impossible.',
  dutchDescription: 'Description en Néerlandais',
  dutchName: 'Nom en Néerlandais',
  dynamic: 'Dynamique',
  dynamicNaming: 'Dénommination Dynamique',
  ecommerce: 'Commerce électronique',
  ecommerceEmailFormatCreated: 'Format d‘E-mail de E-Commerce créé',
  ecommerceEmailFormatSaved: 'Format d‘E-mail de E-Commerce enregistré',
  ecommerceLogo: 'Logo eCommerce',
  ecommerceUseExtOrder: 'Numéro de commande externe à l‘usage du Commerce Électronique',
  edit: 'Modifier',
  editItem: 'Modifier {item}',
  email: 'E-mail',
  emailFormat: 'Format d‘E-mail',
  emailFormatTemplate: 'Modèle de Format d‘E-mail | Modèles de Format d‘E-mail',
  emailList: 'Liste des E-mails',
  emailMessage: 'Un e-mail est requis',
  emailRecipient: 'Destinataire de l’E-mail | Destinataires de l’E-mail',
  emailRecipientMessage: 'Un e-mail sera envoyé à ces adresses lorsque le statut de la commande passe à',
  emptyMessage: {
    orderSuggestions: 'Il semble qu’aucune Suggestion de Commande n’a été définie pour cette pièce.'
  },
  emptyBomState: 'Aucun Article de Nomenclature défini pour la page. Cliquez sur +{add} pour commencer',
  enable: 'Activer',
  enable3d: 'Activer la 3D',
  enableAll: 'Activer tout',
  enableAPIKeyAdmin: 'Activer Admin Clé d‘API',
  enabled: 'Activé',
  enableCommerce: 'Activer commerce',
  enableEcommerceOrderManagement: 'Activer la Gestion des Commandes',
  enableExporter: 'Activer l‘Exportateur',
  enableExportToLinkOne: 'Activer l‘Exportation vers LinkOne',
  enableExportToSmartEquip: 'Activer l‘Exportation vers SmartEquip',
  enablePDFDownloadWarning: 'Activer l’Avertissement de Téléchargement de Fichier PDF Volumineux',
  largePDFDownloadWarning: 'En raison de la taille volumineuse du fichier PDF, le chargement et l’affichage du document dans l’application peuvent prendre plus de temps que d’habitude - veuillez patienter pendant le téléchargement du fichier aux fins d’affichage.',
  enableIframeSupport: 'Activer la Prise en charge iFrame',
  enableLibraryBetaFeatures: 'Activer les Fonctionnalités Bêta de la Bibliothèque',
  enablePerformanceLogging: 'Activer l‘Enregistrement des Performances',
  enablePLZToDraft: 'Activer le traitement PLZ vers Brouillon',
  enableQuickAddToCart: 'Activer l’Ajout Rapide au Panier',
  enableQuickAddToCartHelpContext: 'La valeur \’Enable\’ permet d’activer la fonction Ajout Rapide au Panier au-dessus de la fenêtre de visualisation de ce contenu.',
  enableRocketUi: 'Activer l’Expérience Utilisateur de Rocket',
  enableSmartEquipApiIntegration: 'Activer l’Intégration de l’API SmartEquip',
  enableWidgetLogo: 'Activer le Logo du Widget Documoto',
  enableWidgets: 'Activer les Widgets',
  encryptedKey: 'Clé Chiffrée',
  endDate: 'Date de Fin',
  endDateBad: 'La date de début doit être égale ou antérieure à la date de fin.',
  enforceNumericSize: 'Appliquer la Taille Numérique',
  englishDescription: 'Description en Anglais',
  englishName: 'Nom en Anglais',
  enterprise: 'Entreprise',
  enterReportParameters: 'Saisir les Paramètres du Rapport',
  enterUniqueNameForNewItem: 'Veuillez saisir un nom unique pour le nouveau {item}',
  enterValidNumber: 'Veuillez saisir un numéro valide',
  enterValidEmail: 'Veuillez saisir une adresse e-mail valide',
  entityType: 'Type d’Entité | Types d’Entité',
  erp: 'ERP',
  erpEndpointConfig: 'Configuration du point de terminaison ERP',
  erpPartInfo: 'Durée en minutes du cache des Informations de Pièce ERP',
  erpSystem: 'Système ERP',
  erpSystems: 'Systèmes ERP',
  erpSystemSaved: 'Système ERP enregistré',
  error: 'Erreur',
  errorDeletePageMessage: 'Une erreur est survenue pendant la suppression de la page.',
  errorInputParsing: 'Problème lors de l’analyse du fichier source.',
  errorPageMessage: 'Une erreur s’est produite.',
  errorPageTitle: 'Il y a un problème...',
  errorType: {
    error: 'Erreur',
    warning: 'Avertissement'
  },
  essential: 'Essentiel | Essentiels',
  eta: 'Heure d’arrivée estimée (ETA)',
  etlJob: 'ETL',
  exact: 'Exact',
  excelFile: 'Fichier Excel',
  expandAll: 'Tout Développer',
  export: 'Exporter',
  exportSearch: 'Exporter la Recherche',
  exportAsCsv: 'Exporter au format CSV (Format Requis pour l’Importation)',
  exportAsCSVDropdown: 'Exporter au format CSV',
  exportAsHtml: 'Exporter au format HTML',
  exportAsPdf: 'Exporter au format PDF',
  exportAsPlz: 'Exporter au format PLZ',
  exportAsXlsx: 'Exporter au format XLSX',
  exportBasicPartInfo: 'Exporter les Informations de Base de la Pièce',
  exportCartToXlsxDisabled: 'Désactiver l’Exportation du Panier au format XLSX',
  exportCartToXlsxEnabled: 'Activer l’Exportation du Panier au format XLSX',
  exportDisplayedCartColumns: 'Exporter les Colonnes du Panier Affiché',
  exportJob: 'Exporter la Tâche',
  exportLane: 'Voie d‘exportation',
  exportMessage: 'Tâche d’exportation soumise avec succès.',
  exporter: 'Programme d’exportation',
  exporterMessage: 'Vous permet d’exporter du contenu',
  exportParts: 'Exporter Pièces',
  external: 'Externe | Externes',
  externalUrl: 'URL externe',
  faceted: 'À facettes',
  facetedTag: 'Les valeurs de filtre devraient être à facettes',
  facetedTagTooMany: 'La balise dépasse la valeur limite de 1 000 balises uniques',
  failedAction: 'échec de {action} de {content}',
  failedProcessContentSql: 'Échec de Traitement du Contenu en raison de la présence de texte non autorisé. Veuillez télécharger à nouveau du contenu pris en charge.',
  failedToCopy: 'La copie de {content} a échoué.',
  failedToDelete: 'La suppression de {content} a échoué.',
  failedToLoad: 'Le chargement de {content} a échoué.',
  failedToSave: 'L’enregistrement de {content} a échoué.',
  failedToSubmitItem: 'Échec de l‘envoi de {item}',
  failedToUpdate: 'La mise à jour de {content} a échoué.',
  false: 'Faux',
  faq: 'Foire aux questions',
  favorites: 'Favoris',
  favoritesLists: 'Listes de Favoris',
  february: 'Février',
  feedbackForm: 'Soumettre un Commentaire',
  feedbackFormMessage: 'Parlez-nous des fonctionnalités que vous souhaiteriez voir',
  fieldDefinition: 'Définition du Champ',
  field: 'Champ | Champs',
  fieldName: 'Nom du champ',
  file: 'Fichier | Fichiers',
  filename: 'Nom du Fichier',
  filesSubmitted: 'Fichier(s) soumis pour traitement',
  fileUpload: 'Téléchargement de Fichiers',
  fill: 'Remplir',
  filter: 'Filtre | Filtres',
  filterOrgNameOnly: 'Filtrer : Nom Org Uniquement',
  filterOrgParentNames: 'Filtrer : Noms Org et Parent',
  filterable: 'Filtrable',
  filterableTag: 'Balise Filtrable',
  filterBy: 'Filtrer Par',
  filterBoundsError: 'Le filtre doit contenir au moins 3 caractères',
  finnishDescription: 'Description en Finnois',
  finnishName: 'Nom en Finnois',
  firstName: 'Prénom',
  firstNameEmpty: 'Le champ Prénom ne peut pas être vide',
  fixedHotpointSize: 'Taille du Point de Référencement Fixe',
  font: 'Police | Polices',
  footer: 'Pied de Page',
  forgotPassword: 'Mot de Passe Oublié',
  forgotPasswordMessage: 'Un e-mail contenant des instructions pour réinitialiser votre mot de passe sera envoyé au nom d‘utilisateur indiqué.',
  forgotPasswordSuccess: 'Si le nom d‘utilisateur indiqué est valable, un e-mail contenant des instructions pour réinitialiser votre mot de passe sera envoyé à l‘adresse indiquée dans le dossier.',
  form: 'Formulaire | Formulaires',
  format: 'Format',
  formattingOptions: 'Options de Formatage',
  formCount: 'Nombre de Formulaires',
  formName: 'Nom du Formulaire',
  formType: 'Type de Formulaire',
  freezeImage: 'Figer l‘Image',
  frenchDescription: 'Description en Français',
  frenchName: 'Nom en Français',
  fri: 'Ven',
  from: 'De',
  fromDate: 'À Partir De',
  frontCover: 'Couverture Avant',
  frontStitch: 'Couture Avant',
  fullScreen: 'Plein écran',
  gdprFaqs: 'Foires aux questions sur le RGPD',
  gdprMessage: 'En vous connectant à cette section de notre site Web, vous comprenez que nous utilisons des cookies indispensables. Ces cookies sont indispensables pour vous permettre d‘utiliser nos Services. Sans ces cookies, nous ne pouvons pas vous fournir les services que vous avez demandés. Pour en savoir plus, consultez notre {policy}.',
  general: 'Général',
  generalSearch: 'Recherche Générale',
  generic: 'Générique',
  germanDescription: 'Description en Allemand',
  germanName: 'Nom en Allemand',
  gotIt: 'J’ai Compris',
  goTo: 'Aller À',
  goToHome: 'Aller à l’Accueil',
  goToJobManager: 'Aller au Gestionnaire de Tâches',
  goToSearch: 'Aller à la Recherche',
  hashKey: 'Touche dièze',
  headerAmpFooter: 'En-tête et Pied de page',
  header: 'En-tête',
  help: 'Aide',
  helpCenter: 'Centre d’Aide',
  highlightDescription: 'Cette balise permet de mettre en surbrillance les pièces dans la liste des pièces',
  history: 'Historique',
  home: 'Accueil',
  horizontal: 'Horizontal',
  host: 'Hôte',
  hotpoint: 'Point de Référencement | Points de Référencement',
  hotpointCalloutBehavior: 'Comportement d‘appel du point de référencement',
  hotpointLinksWillBeCreated: 'Des liens de points de référencement seront créés dans {count} {content}',
  hotpointLink: 'Lien de Référencement | Liens de Référencement',
  hotpointsGenerated: '{count} points de référencement trouvés sur l‘image.',
  hotpointShift: 'Référencement (Shift H)',
  hourlyDWSRequestLimit: 'Limite horaire des requêtes DWS',
  hourlyRESTRequestLimit: 'Limite horaire des requêtes REST',
  htmlUiUserSwitchEnabled: 'Bascule Utilisateur interface utilisateur HTML activée',
  hungarianDescription: 'Description en Hongrois',
  hungarianName: 'Nom en Hongrois',
  icon: 'Icône',
  id: 'Identifiant',
  identifier: 'Identifiant',
  ignore: 'IGNORER',
  ignoreAccessControls: 'Ignorer les Contrôles d’Accès',
  ignoreMatchingTranslations: 'Ignorer les Traductions avec des régions concordantes lors de l’Importation',
  illustration: 'Illustration | Illustrations',
  image: 'Image | Images',
  imageDeleteMessage: 'Êtes-vous certain(e) de vouloir supprimer cette Image de Page ?',
  imageUploadFailure: 'Échec de Chargement de l‘Image',
  imageSaveFail: 'Échec d‘Enregistrement de l‘Image',
  imageOptimizer: 'Optimiseur d’Images',
  imageSizeExceededTitle: 'Taille d’Image Maximum dépassée',
  imageSizeExceededMessage: 'La taille maximum autorisée du fichier image dans Documoto est de {size} mégaoctets. L‘image que vous tentez de télécharger a dépassé la taille maximum autorisée et ne peut pas être chargée. Veuillez redimensionner l‘image pour l‘ajouter à cette page.',
  imageDimensionsExceededMessage: 'La taille d’image maximum dans Documoto est de 65,5 mégapixels (8,191 X 8,191 pixels).  L’image que vous tentez de télécharger a dépassé la taille maximum autorisée et ne peut pas être chargée. Cliquez sur "Redimensionner" pour permettre à Documoto de redimensionner et d’insérer l’image, ou sur "Annuler" pour modifier l’image et l’insérer manuellement',
  import: 'Importer',
  importBOM: 'Importer Nomenclature',
  importBOMError: 'Échec du traitement de l‘échantillon de données, revérifier la source d‘entrée.',
  importParts: 'Importer Pièces',
  inCart: 'Dans le Panier',
  inCartDescription: 'Afficher les balises dans le panier',
  includePagePartDetails: 'Inclure les détails de niveau Pièce de la Page',
  includePartDetails: 'Inclure les détails de niveau Pièce',
  indexQueueJob: 'File d‘attente d‘Index',
  inPartsList: 'Dans la Liste des Pièces',
  inPartsListDescription: 'Afficher les balises dans la liste des pièces',
  inputSource: 'Source d‘entrée',
  inch: 'Pouce | Pouces',
  includeComments: 'Inclure les Commentaires',
  includeCommentsAuthorAndDate: 'Inclure l`Auteur et la Date du Commentaire',
  includeHeaderRow: 'Inclure la Ligne d‘En-tête',
  includeImages: 'Inclure les Images',
  includePagesIndex: 'Inclure l’Index des Pages',
  includePartsIndex: 'Inclure l’Index des Pièces',
  includePartsList: 'Inclure la Liste des Pièces',
  includeSubChapters: 'Inclure les Sous-Chapitres',
  indexLane: 'Voie d‘Index',
  useSequentialPageNumbering: 'Appliquer une Numérotation Séquentielle des Pages',
  hideBookInSearchAndRecent: 'Masquer les Livres dans les Résultats de Recherche et les Derniers Articles Ajoutés',
  hideDuplicatePages: 'Masquer les Pages Dupliquées',
  hidePageInSearchAndRecent: 'Masquer les Pages dans les Résultats de Recherche et les Derniers Articles Ajoutés',
  hidePartInSearchAndRecent: 'Masquer les Pièces dans les Résultats de Recherche et les Derniers Articles Ajoutés',
  homePageSettings: 'Paramètres de la Page d’Accueil',
  hotpointZoomBehavior: 'Comportement de zoom du point de référencement',
  includeNewParts: 'Inclure uniquement les "nouvelles" pièces (pas dans Documoto)',
  includeWatermark: 'Inclure le Filigrane ?',
  increaseHotpoint: 'Augmenter Référencement',
  indexed: 'Indexé',
  indexingConfiguration: 'Configuration de l‘indexation',
  indexIncludeTimestamp: 'Inclure Horodatage dans l‘Index',
  info: 'Info',
  information: 'Information',
  inherit: 'Hériter',
  inheritedParent: 'Hérité du Parent.',
  inputSpecification: 'Spécification d‘entrée',
  inputType: 'Type d’Entrée',
  inputTypeListboxWarning: 'Les paramètres de tri s’afficheront lors de l’enregistrement',
  integration: 'Intégration | Intégrations',
  integrationsAddPartToErpCart: '{action} Ajouter la Pièce au panier du système ERP',
  integrationsAddToShoppingCartPricingFromErp: '{action} Ajouter au Panier les Prix extraits du système ERP',
  integrationsDisableErp: 'Désactiver le système ERP',
  integrationsDoErpForQuotes: 'Exécuter le système ERP pour les Devis',
  integrationsEnableErp: 'Activer le système ERP',
  integrationsEnableFetchPartInfoFromErp: 'Activer la fonction Récupération des Informations de Pièce auprès du système ERP',
  integrationsEnableScopeErp: 'Activer le {scope} du système ERP',
  integrationsEnableSendShoppingCartToErp: 'Activer la fonction Envoyer le Panier au système ERP',
  integrationsEnableSyncPoAddressWithErp: 'Activer la fonction Synchroniser l’Adresse du Bon de Commande avec le système ERP',
  integrationsErpAvailabilityMappedFrom: 'Disponibilité du Système ERP Mappée À Partir De',
  integrationsErpCurrencyCodeMappedFrom: 'Code de devise du Système ERP Mappé À Partir De',
  integrationsErpDiscountedPriceMappedFrom: 'Prix Réduit du Système ERP Mappé À Partir De',
  integrationsErpEtaMappedFrom: 'ETA du système ERP Mappée À Partir De',
  integrationsErpMappings: 'Mappages du système ERP',
  integrationsErpRetailPriceMappedFrom: 'Prix au Détail du Système ERP Mappé À Partir De',
  integrationsErpWholesalePriceMappedFrom: 'Prix de Gros du Système ERP Mappé À Partir De',
  integrationsInvokeGetPartInfoFromClient: 'Invoquer GetPartInfo auprès du Client',
  integrationsParseAddressForType: 'Redistribuer l’Adresse pour le Type',
  integrationsShoppingCartAndPartsTitle: 'Panier et Pièces',
  integrationsShoppingCartAvailability: '{action} Disponibilité du Panier',
  integrationsShoppingCartEtaFromErp: '{action} ETA du Panier À Partir du Système ERP',
  internalApiKey: 'Clé d’API Interne',
  internalDescription: 'Description Interne',
  internalName: 'Nom Interne',
  internalNameOptional: 'Votre identifiant pour la balise',
  internalNameRequired: 'Un nom est requis',
  invalidDecimals: 'Deux décimales seulement sont autorisées',
  invalidField: '{field} n’est pas valide',
  invalidFileType: 'Type de fichier non pris en charge. Veuillez consulter le Centre d’Assistance Documoto pour une liste complète des extensions de fichiers prises en charge.',
  isSearchable: 'Est Recherchable',
  insertDataField: 'Insérer un Champ de Données',
  isSystem: 'Est un Système',
  italianDescription: 'Description en Italien',
  italianName: 'Nom en Italien',
  item: '#',
  itemAlt: 'Élément',
  itemCopied: '{item} copié',
  itemDeleted: '{item} supprimé',
  itemInProgress: '{item} en cours',
  itemInformation: 'Information sur {item}',
  itemMustBeLteItem: '{itemA} doit être égal ou antérieur à {itemB}.',
  itemMustBeUnique: '{item} doit être unique',
  itemMustBeUniqueAndNotEmpty: '{item} doit être unique et est requis',
  itemName: 'Nom de {item}',
  itemNotShown: '',
  itemSaved: '{item} enregistré',
  itemSpecification: 'Spécification de l‘Article',
  showChapterTOCForHiddenPages: 'Afficher la Table des Matières du Chapitre pour les Pages Masquées',
  january: 'Janvier',
  japaneseDescription: 'Description en japonais',
  japaneseName: 'Nom en Japonais',
  job: 'Tâche | Tâches',
  jobManager: 'Gestionnaire de Tâches',
  jobManagerMessage: 'Exporter, éditer et imprimer/télécharger les tâches',
  july: 'Juillet',
  june: 'Juin',
  koreanDescription: 'Description en Coréen',
  koreanName: 'Nom en Coréen',
  label: 'Désignation | Désignations',
  labelKey: 'Clé de Désignation',
  landscape: 'Paysage',
  laneConfiguration: 'Configuration de voie',
  language: 'Langue',
  lastModifiedDate: 'Date de la dernière modification',
  lastGenerated: 'Dernière génération',
  lastName: 'Nom de Famille',
  lastNameEmpty: 'Le champ Nom de famille ne peut pas être vide',
  lastUpdatedOn: 'Dernière mise à jour',
  layout: 'Disposition',
  layoutOptions: 'Options de disposition',
  ledger: 'Registre',
  left: 'Gauche',
  legal: 'Juridique',
  letter: 'Lettre',
  library: 'Bibliothèque',
  libraryAdmin: 'Gestion de la Bibliothèque',
  libraryBannerLogo: 'Logo Bannière Bibliothèque',
  librarySettings: 'Paramètres de la Bibliothèque',
  librarySettingsDescription: 'Ces paramètres contrôlent divers éléments et options de la Bibliothèque. Toute modification doit être effectuée avec prudence. Contacter Documoto Services pour plus d’informations.',
  licenseConfiguration: 'Configuration de licence',
  line: 'Ligne',
  lineTool: 'Outil de Ligne',
  link: 'Lien | Liens',
  listBox: 'Menu Déroulant',
  listBoxValue: 'Valeur du Menu Déroulant | Valeurs du Menu Déroulant',
  listValue: 'Valeur de Liste | Valeurs de Liste',
  load: 'Charger',
  loadMore: 'Charger plus',
  loading: 'Chargement',
  locale: 'Région',
  login: 'Connexion',
  loginBackground: 'Arrière-plan de la Page de Connexion',
  loginGDPRMessageBeginning: 'Ce site Web utilise des cookies. En poursuivant votre visite sur ce site Web, vous consentez à l’utilisation des cookies. Pour en savoir plus, consultez notre ',
  loginGDPRMessageEnd: 'et nos',
  loginSSO: 'Connexion SSO',
  logiReportName: 'Nom du Rapport Logi',
  logiReportToggle: 'Rapport Logi ?',
  logo: 'Logo | Logos',
  logout: 'Déconnexion',
  lookupLoadParts: 'Chercher/Charger des Pièces à partir de Documoto lors de l‘Importation',
  lowerBound: 'Limite Inférieure',
  makePagesDivisibleBy: 'Rendre les Pages Divisibles Par :  ',
  mainInfo: 'Information Principale',
  mainLogo: 'Logo Principal',
  manageItem: 'Gérer {item}',
  management: 'Gestion',
  march: 'Mars',
  margin: 'Marge | Marges',
  markdown: 'Démarque',
  may: 'Mai',
  media: 'Média | Médias',
  mediaCategory: 'Catégorie de Médias | Catégories de Médias',
  mediaCategoryEditor: 'Éditeur des Catégories de Médias',
  mediaCategoryName: 'Nom de la Catégorie de Médias',
  mediaCategoryNameMissing: 'Toutes les catégories de médias doivent avoir un nom',
  mediaBookDeleteMessage: 'La suppression de ce média retirera l’accès à ce contenu dans la Bibliothèque et la Gestion de Contenu et il ne sera plus disponible pour les utilisateurs ou les Gestionnaires de Contenu. Toute partie ou page figurant dans ce livre et qui ne figure pas dans d’autres livres ne sera plus disponible dans la Bibliothèque.',
  mediaDeleteMessage: 'La suppression de ce modèle le retirera du Gestionnaire de Contenu et il ne sera plus disponible pour être copié vers un nouveau Média.',
  mediaDeleteSuccessText: 'Il sera retiré des listes une fois l’indexation terminée.',
  mediaDescription: 'Description du Média',
  mediaIdentifier: 'Identifiant du Média',
  mediaName: 'Nom du Média',
  mediaPrintSettingsSaveSuccess: 'Paramètres d’Impression des Médias Enregistrés',
  mediaWhereUsed: 'Médias Où l’Élément Apparaît',
  menu: 'Menu',
  merge: 'Fusionner',
  mergeOrOverrideMessage: 'Voulez-vous écraser ou fusionner ces éléments ?',
  mergeWarning: 'L‘importation des données mettra à jour les pièces existantes et ajoutera les nouvelles entrées.',
  message: 'Message',
  messageLevel: 'Niveau du Message',
  microsite: 'Microsite | Microsites',
  micrositeAdmin: {
    cardSubtitle: 'Le fichier de contenu compressé et les informations d‘entrée HTML',
    cardTitle: 'Contenu du site',
    errorRequiredFile: 'Un fichier ZIP de contenu du site est nécessaire',
    fieldFile: 'Fichier ZIP de contenu du site',
    fieldPoint: 'Point d‘entrée HTML',
  },
  minArea: 'Zone Minimum',
  minAreaMessage: 'Valeur minimum obligatoire. La valeur ne doit pas dépasser une plage de 1 à 1500.  Doit être une valeur entière.',
  moreInfo: 'Plus d’Infos',
  multipleAlternatingPages: 'Plusieurs Pages Alternées',
  multipleAlternatingPagesMessage: 'Diagramme en pages 1 et 3, liste de pièces en pages 2 et 4.',
  mToM: 'M2M',
  multiplePages: 'Pages Multiples',
  multiplePagesMessage: 'Diagramme sur sa propre page suivi de pages contenant la liste des pièces',
  requiredField: 'Champ Obligatoire',
  messageMessage: 'Un message est requis',
  metadata: 'Métadonnée',
  mine: 'M’appartient',
  miscellaneous: 'Divers',
  mon: 'Lun',
  myCart: 'Mon Panier | Mes Paniers',
  navigation: 'Navigation',
  nItemsCreated: '{n} {items} Créés',
  nItemsDeleted: '{n} {items} Supprimés',
  nOfMItems: '{n} sur {m} {items}',
  name: 'Nom',
  nameDescriptionHelpText: 'Le nom et la description facultative',
  new: 'Nouveau',
  newAPIKey: 'Nouvelle Clé d’API',
  newERPSystem: 'Nouveau Système ERP',
  newItem: 'Nouveau {item}',
  newKeyGenerated: 'Nouvelle Clé d’Intégration Générée, Copier et Conserver pour utilisation ultérieure',
  newMediaCategory: 'Nouvelle Catégorie de Médias',
  newPassword: 'Nouveau Mot de Passe',
  newsItem: 'Article d’Actualité | Articles d’Actualités',
  newTenant: 'Nouveau locataire',
  newWidgetKey: 'Nouvelle Clé de Widget',
  newWidgetKeyGenerated: 'Nouvelle Clé de Widget Générée, Copier et Conserver pour utilisation ultérieure',
  noTenantSuppliers: 'Aucun fournisseur attribué au locataire.',
  noAPIKeys: 'Aucune Clé d’API définie.',
  node: 'Nœud',
  noData: 'Pas de Données',
  noFilesSelected: 'Aucun Fichier Sélectionné',
  noIllustrationFound: 'Aucune Illustration Trouvée',
  noImageFound: 'Aucune Image Trouvée',
  noItemAvailable: 'Aucun {item} disponible',
  noItemFound: 'Aucun {item} trouvé.',
  noItemSelected: 'Aucun {item} sélectionné',
  noItemAddedToTarget: 'Pas de {item} ajouté au {target}. ',
  noItemsFound: 'Aucun Élément Trouvé',
  noMoreResults: 'Aucun autre résultat',
  noFormsMessage: 'Aucun Formulaire défini.',
  noMediaDefined: 'Aucun média défini',
  nonSearchableFieldsSuccessfullyUpdated: 'Champs non interrogeables mis à jour avec succès.',
  noPartsSelected: 'Aucune pièce sélectionnée.',
  noPrice: 'Prix sur Demande',
  noResults: 'Aucun résultat',
  norwegianDescription: 'Description en Norvégien',
  norwegianName: 'Nom en Norvégien',
  noSearchableTags: 'Aucune balise recherchable',
  noSuggestions: 'Aucune suggestion pour le terme recherché',
  notOrderable: 'Non Disponible(s) à la Commande',
  notSelected: 'Non sélectionné',
  noTagsFound: 'Aucune balise trouvée',
  noUnsavedChanges: 'Aucune Modification Non Enregistrée',
  none: 'Aucun(e)',
  notImplemented: 'Non Mis en Oeuvre',
  notification: 'Notification | Notifications',
  notificationsMessage: 'Annonces et actualités importantes',
  notMapped: 'Non Mappé',
  november: 'Novembre',
  noWidgetKeys: 'Aucune Clé de Widget définie.',
  numeric: 'Numérique',
  numberOf: 'Nombre de {item}',
  october: 'Octobre',
  of: 'sur/de',
  off: 'Désactivé',
  offlineContent: 'Contenu Hors Ligne',
  ok: 'Ok',
  oldPassword: 'Ancien mot de passe',
  on: 'Activé',
  opacity: 'Opacité',
  open: 'Ouvrir',
  openItem: 'Ouvrir {item}',
  optimizeIndexJob: 'Optimiser Index',
  options: 'Options',
  openTab: 'Ouvrir le lien dans un nouvel onglet',
  order: 'Commande | Commandes',
  orderable: 'Disponible(s) à la Commande',
  orderableDescription: 'Cette pièce peut être ajoutée au panier et achetée',
  orderableLocal: 'Disponible(s) à la commande sur cette page',
  orderableLocalBlocked: 'Pièce non Disponible à la Commande au Niveau Mondial',
  orderDisable: 'Désactiver l’Historique de Commandes',
  orderEmailRecipient: 'Destinataire de l’E-mail de Commande | Destinataires de l’E-mail de Commande',
  orderEntity: 'Entité de Commande | Entités de Commande',
  orderFieldUneditableRequired: '‘Si un Champ de Commande/Devis est défini comme Requis et Non Modifiable par l‘Utilisateur, votre Formulaire de Commande/Devis risque d‘être impossible à modifier. Veuillez vérifier que vos Formulaires de Commande/Devis peuvent être complétés.’,',
  orderFieldsEmptyMessage: 'Aucun Champ de Commande/Devis défini.',
  orderFieldsEmptyPrompt: 'Ajouter un Champ de Commande/Devis',
  orderField: {
    nameMessage: 'Si vous modifiez le nom du Champ de Commande/Devis, veillez à modifier tout Formulaire de Commande/Devis ou Format d‘E-mail qui utilise ce champ.',
  },
  orderFieldCopyMessage: 'Veuillez saisir un nom unique pour le nouveau Champ de Commande/Devis',
  orderForm: {
    uniqueNameInputHint: 'Veuillez saisir un nom unique pour le nouveau Formulaire.'
  },
  orderFormField: 'Champ de Commande/Devis | Champs de Commande/Devis',
  orderFormLayout: 'Disposition du formulaire de Commande/Devis | Dispositions du formulaires de Commande/Devis',
  orderFormSetDefault: 'Formulaire de Commande/Devis par Défaut',
  orderFormType: 'Formulaire de Commande',
  orderLayout: 'Disposition de la Commande | Dispositions des Commandes',
  orderManagementApprover: 'Approbateur de Gestion des Commandes',
  orderManagementDisable: 'Désactiver le Module de Gestion des commandes',
  orderManagementViewer: 'Visionneuse de Gestion des commandes',
  orderNumber: 'Numéro de Commande',
  orderQuoteEmail: 'E-mail de Commande/Devis | Emails de Commande/Devis',
  orderQuoteEmailFormat: 'Format de l‘E-mail de Commande/Devis | Formats de l‘E-mail de Commande/Devis',
  orderQuoteEmailFormatCopyMessage: 'Sélectionnez une langue pour le nouveau Format d‘E-mail de Commande/Devis.',
  orderQuoteFetchError: 'Erreur lors de l‘obtention des données du Formulaire de Commande/Devis',
  orderQuoteForm: 'Formulaire de Commande/Devis | Formulaires de Commande/Devis',
  orderQuoteSaveConfirmation: 'Enregistrer les modifications apportées aux formulaires de Commande/Devis de l‘organisation ?',
  orderQuoteSaveError: 'Erreur lors de l‘enregistrement des données du Formulaire de Commande/Devis',
  orderQuoteFormSaved: 'Formulaire de Commande/Devis enregistré',
  orderStatus: 'Statut de Commande/Devis | Statuts de Commande/Devis',
  orderStatusDeleteMessage: 'Ce statut a été appliqué à des commandes et ne peut pas être supprimé.',
  orderSubmittedMessage: 'Soumis(e) avec Succès',
  orderSuggestion: 'Suggestion de Commande | Suggestions de Commandes',
  orderSuggestionEnable: 'Activer les Suggestions de Commande',
  orderSuggestionRemovalWarning: 'Cette pièce n’apparaîtra plus dans les suggestions de commande. | Ces pièces n’apparaîtront plus dans les suggestions de commande.',
  orderSuggestionThreshold: 'Seuil des Suggestions de Commande',
  ordersLogo: 'Logo des Commandes',
  ordersMessage: 'Historique des commandes',
  orientation: 'Orientation',
  org: 'Org',
  orgEmailRecipients: 'Destinataires de l’E-mail de l’Organisation',
  organization: 'Organisation | Organisations',
  organizationAdmin: 'Gestion de l’Organisation',
  organizationAdminBrowseFlow: 'Une organisation peut utiliser le parcours de flux par défaut, ou vous pouvez l’écraser et utiliser tout autre parcours de flux.',
  organizationAdminMessage: 'Contrôle la gestion des utilisateurs et les paramètres de l’organisation',
  organizationEmpty: 'Une organisation doit être sélectionnée',
  organizationEmailRecipients: 'Destinataires de l’E-mail de l’Organisation',
  organizationEnforceAccessControlsForQuickAdd: 'Appliquer les Contrôles d’Accès pour l’Ajout Rapide',
  organizationKey: 'Clé de l’Organisation',
  organizationLibrarySettingsDescription: 'Ces paramètres contrôlent divers éléments et options relatifs de la Bibliothèque au niveau Organisationnel. Toute modification doit être effectuée avec prudence. Contacter Documoto Services pour plus d’informations.',
  organizationMoveToRoot: 'Déposer ici pour déplacer vers la racine',
  organizationName: 'Nom de l’Organisation',
  organizationRearrange: 'Réorganiser les Organisations',
  organizationRearrangeDescription: 'La structure Organisationnelle parent-enfant définit de nombreux attributs héritables d’une Organisation tels que les Paramètres d’impression, les Styles, etc. Faites glisser et déplacez les Organisations pour afficher la hiérarchie souhaitée.',
  organizationReportSettingsSuccessMessage: 'Paramètres de rapport enregistrés',
  organizationSelect: 'Sélectionner l’Organisation',
  organizationTags: 'Balises de l’Organisation',
  organizationsEmpty: 'Il semble que vous n’avez pas encore créé d’organisation',
  orgNameOnly: 'Nom Org Uniquement',
  other: 'Autre',
  otherSettings: 'Autres Paramètres',
  outboundCustomerIdParameter: 'Nom du Paramètre d’Identification du Client Sortant',
  outboundSessionIdParameter: 'Nom du Paramètre d’Identification de la Session Sortante',
  outlineMode: 'Mode Contour (Shift O)',
  output: 'Sortie',
  outputCatalog: 'Catalogue de Sortie',
  outputDefinition: 'Définition de Sortie | Définitions de Sortie',
  outputDefinitionDeleted: 'Définition de Sortie supprimée',
  outputDefinitionSaved: 'Définition de Sortie Enregistrée',
  outputFileName: 'Nom du Fichier de Sortie',
  outputName: 'Nom de Sortie',
  outputDestination: 'Destination de Sortie',
  outputSpecification: 'Spécification de Sortie',
  outputSubType: 'Sous-type de Sortie',
  outputType: 'Type de Sortie',
  overridden: 'Fichier Écrasé',
  overriddenByOrg: 'Écrasé par l’Organisation',
  override: 'Écraser',
  overrideBrowseFlow: 'Écraser le Parcours de Flux',
  overrideFacetLimitValue: 'Écraser la valeur limite de facette',
  overrideHotpointScale: 'Écraser l’Échelle du Point de Référencement',
  overridePdfNamingStandard: 'Remplacer la Norme de Dénomination de Fichiers PDF',
  overridePrintSettings: 'Écraser les Paramètres d’Impression',
  overrideFontSettings: 'Écraser les Paramètres de Police',
  overrideOrderForm: 'Replacer le Formulaire de Commande',
  overrideQuoteForm: 'Remplacer le Formulaire de Devis',
  overrideSvgOpacity: 'Écraser l’Opacité SVG',
  overrideWarning: 'L‘importation des données remplacera votre liste de nomenclature actuelle.',
  owner: 'Propriétaire | Propriétaires',
  page: 'Page | Pages',
  pageBulkInsertConfirmTitle: 'Souhaitez-vous terminer l’insertion groupée ?',
  pageBulkInsertConfirmInsertAfter: 'Cette opération insérera {replacement} après {original} dans {whereUsedBookCount} {bookUnits} et {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertConfirmInsertBefore: 'Cette opération insérera {replacement} avant {original} dans {whereUsedBookCount} {bookUnits} et {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertConfirmInsertReplace: 'Cette opération insérera {replacement} et retirera {original} dans {whereUsedBookCount} {bookUnits} et {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertBooksConfirmInsertAfter: 'Cette opération insérera {replacement} après {original} dans {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertBooksConfirmInsertBefore: 'Cette opération insérera {replacement} avant {original} dans {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertBooksConfirmInsertReplace: 'Cette opération insérera {replacement} et retirera {original} dans {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertTemplatesConfirmInsertAfter: 'Cette opération insérera {replacement} après {original} dans {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertTemplatesConfirmInsertBefore: 'Cette opération insérera {replacement} avant {original} dans {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertTemplatesConfirmInsertReplace: 'Cette opération insérera {replacement} et retirera {original} dans {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertDiscard: 'Votre insertion groupée ne sera pas traitée',
  pageBulkInsertHelpText: 'Cette fonctionnalité vous permet d’insérer cette page source {thisDisplayName} dans des livres de pièces sélectionnés dans lesquels une autre page {originalDisplayName} existe déjà. En option, vous pouvez également supprimer {originalDisplayName} de ces livres.',
  pageBulkInsertInsertAfter: 'Insérer {replacement} APRÈS {original}',
  pageBulkInsertInsertBefore: 'Insérer {replacement} AVANT {original}',
  pageBulkInsertInsertReplace: 'Insérer {replacement} et SUPPRIMER {original}',
  pageBulkInsertScopeAll: 'Tous les éléments {type} contenant la page cible {originalDisplayName} ({whereUsedCount} {units})',
  pageBulkInsertScopeNone: 'Aucun des {type} contenant la page cible {originalDisplayName}',
  pageBulkInsertScopeSome: 'Certains des {type} contenant la page cible {originalDisplayName}',
  pageBulkInsertStep1: '1ère étape : Trouver la page cible souhaitée',
  pageBulkInsertStep2: '2ème étape : Décider comment insérer ou remplacer les pages',
  pageBulkInsertStep3: '3ème étape : Choisir les livres qui feront l’objet de l’insertion groupée.',
  pageBulkInsertStep4: 'Étape 4 : Choisir les modèles qui feront l‘objet de l‘insertion groupée.',
  pageBulkInsertSuccessTitle: 'Tâche d’insertion groupée envoyée',
  pageBulkInsertSuccessBody: 'Les modifications apportées à la table des matières du livre seront effectuées immédiatement. Les résultats de recherche afficheront les modifications une fois l’indexation terminée.',
  pageBulkErrorTooManyBooks: 'Limitez le nombre de livres sélectionnés à 1000 maximum',
  pageBuilder: 'Créateur de Page',
  pageComposer: 'Page Composer',
  pageCreated: 'Page Créée',
  pageDeleteMessage: 'Les suppressions de pages prennent effet immédiatement dans la Bibliothèque et l’Administration de Contenu. La page supprimée restera interrogeable jusqu’à la réindexation dans les prochaines minutes.',
  pageDeleteMessage2: 'Cette action ne peut pas être annulée sans l’aide de l’Assistance Documoto. Après 90 jours, l’enregistrement supprimé et toutes les associations seront définitivement purgés.',
  pageDescription: 'Description de la Page',
  pageFile: 'Fichier de Page',
  pageFilename: 'Nom de fichier de la Page',
  pageFilenameDeleted: '{filename} sera supprimé.',
  pageFileRequired: 'Un fichier de pages est requis.',
  pageBomPartsDeleted: '{count} pièces sur la Nomenclature de page ne seront plus accessibles via {filename}.',
  pageDraftsDeleted: 'Les pages Brouillon associées à cette page seront définitivement supprimées.',
  pageFit: 'Ajuster à la Page',
  pageHeader: 'En-tête de Page',
  pageLabel: 'Page | Pages',
  pageLayout: 'Disposition de Page',
  pageName: 'Nom de la Page',
  pagePart: 'Partie de Page | Parties de Page',
  pagePartTag: 'Balise de Pièce de Page | Balises de Pièces de Pages',
  pagePartData: 'Données de Pièce de la Page',
  pageUsageDataCouldNotBeLoaded: 'Impossible de Charger les Données d’Utilisation de la Page',
  pagePartLevelDetails: 'Page Pièces Commandables/Visibles, Balises Page Pièces',
  pagePartNumberVisible: 'Numéro de Pièce de la Page Visible',
  pagePartOrderable: 'Pièce de la Page Commandable',
  pagePartOrderableDescription: 'Pièce Commandable depuis cette page',
  pagePartVisibleDescription: 'Numéro de Pièce visible sur cette page',
  pageUpdated: 'Page Actualisée',
  pageViewer: 'Visionneuse de Page',
  pageWidth: 'Largeur de Page',
  pagesWhereUsed: 'Pages Où l’Élément Apparaît',
  parentName: 'Nom du Parent | Noms des Parents',
  part: 'Pièce | Pièces',
  partCode: 'Code de la Pièce | Codes des Pièces',
  partCodeAddError: 'Impossible d`ajouter les Codes des Pièces',
  partCodeErrorEmpty: 'Enregistrement impossible : le code de pièce ne peut pas être vide',
  partCodeErrorInUse: 'Ce code de pièce est utilisé par des pièces dans Documoto et ne peut pas être supprimé. Contacter l’Assistance Documoto à l’adresse support{\'@\'}documoto.com pour obtenir de l’aide.',
  partCodeErrorNotFound: 'La suppression de ce code de pièce a échoué, car il est introuvable. Veillez à enregistrer toutes les modifications.',
  partCodesManager: 'Gestionnaire des Codes de Pièces',
  partCodesMessage: 'Les codes de pièces peuvent être utilisés à la place des numéros de pièces et sont souvent utilisés pour indiquer si les articles de Nomenclature sont obsolètes, non disponibles ou sont des articles de référence uniquement (par exemple, \'PNNA\', \'-\', \'REF\').',
  partCreated: 'Pièce Créée',
  partData: 'Données de Pièce',
  partUpdated: 'Pièce Mise à jour',
  partDebouncerEmailQueueJob: 'File d‘Attente d‘E-mails',
  partDescription: 'Description de la Pièce',
  partLabel: 'Pièce | Pièces',
  partLevelDetails: 'Prix des Pièces, Commandable/Visible, Traductions Supplémentaires, Balises des Pièces',
  partName: 'Nom de Pièce',
  partnerApiKey: 'Clé d’API Partenaire',
  partnerRESTRequestLimit: 'Limite des Demandes REST Partenaires',
  partNumber: 'Numéro de Pièce',
  partNumberDescription: 'Le numéro de pièce interne que les utilisateurs finaux peuvent rechercher',
  partNumberVisible: 'Numéro de Pièce Visible',
  partNumberVisibleDescription: 'Le numéro de pièce est visible par les utilisateurs finaux',
  partNumberVisibleLocal: 'Visible sur cette page',
  partNumberVisibleLocalBlocked: 'Le Numéro de Pièce n’est pas Visible au Niveau Mondial',
  partOrderable: 'Pièce Commandable',
  partRequired: 'Au moins une pièce est requise',
  partTagProperty: 'Propriété de la Balise de la Pièce | Propriétés de la Balise de la Pièce',
  partTag: 'Balise de Pièce | Balises de Pièces',
  partThumbnailDescription: '',
  partialSupport: 'Prise en Charge Partielle',
  partsListColumn: 'Colonne de la Liste des Pièces | Colonnes de la Liste des Pièces',
  partsBook: 'Livre des Pièces | Livres des Pièces',
  partsColumns: 'Colonnes des Pièces',
  partsNotSelected: 'Image par défaut utilisée pour les vignettes de pièces. Si une vignette personnalisée n’est pas appliquée, Documoto affichera une vignette standard par défaut',
  partsPage: 'Page de Pièces | Pages de Pièces',
  partsPages: 'Pages des Pièces',
  partsPageGroup: {
    excludePartCodesFromPartsLists: 'Exclure les Codes de Pièces des Listes de Pièces',
    highlightAlternateRows: 'Mettre en surbrillance les Lignes Alternées',
    rotateImagesAutomatically: 'Faire Pivoter les Images Automatiquement',
    printBorderAroundImages: 'Imprimer les Bordures des Images',
    keepImagesOnLeft: 'Garder les Images à Gauche',
    keepBOMOnRight: 'Garder la Nomenclature à Droite',
    columnsUpdateErrorMsg: 'Une erreur est survenue en tentant de mettre à jour les Colonnes de Pièces',
    columnsUpdateSuccessMsg: 'Colonnes de Pièces mises à jour',
    columnsLoadErrorMsg: 'Le chargement des colonnes de pièces a échoué',
    printHotpointsOnImages: 'Imprimer Référencements sur Images',
  },
  partsList: 'Liste des Pièces',
  partsListData: 'Données relatives à la Liste des Pièces',
  partsListGridHeader: 'En-tête du Tableau de la Liste des Pièces',
  password: 'Mot de passe',
  passwordExpiredHeader: 'Mot de passe expiré',
  passwordExpiredInfo: 'Votre mot de passe a expiré. Votre nouveau mot de passe doit respecter les critères suivants :',
  passwordCriteriaInfo: 'Votre nouveau mot de passe doit respecter les critères suivants :',
  passwordBlankMessage: 'Le mot de passe ne peut pas être vide',
  passwordCannotBeSame: 'Doit être différent de votre mot de passe actuel',
  passwordCasingMassage: 'Doit comporter au moins une minuscule et une majuscule',
  passwordLowerCaseMessage: 'Le mot de passe doit contenir au moins une minuscule',
  passwordMinLengthMessage: 'Les mots de passe doivent comporter au moins 8 caractères',
  passwordNotMatchingMessage: 'Les mots de passe ne correspondent pas',
  passwordSpecialCharactersMessage: 'Le mot de passe doit contenir au moins un caractère spécial',
  passwordUpperCaseMessage: 'Le mot de passe doit contenir au moins une majuscule',
  passwordWillExpire1Day: 'Le mot de passe de connexion expirera dans moins de 1 jour',
  passwordWillExpire5Days: 'Le mot de passe de connexion expirera dans moins de 5 jours',
  passwordsMatch: 'Les mots de passe correspondent',
  permission: 'Permission | Permissions',
  phoneNumber: 'Numéro de Téléphone',
  phoneNumberExt: 'Poste',
  phonePrimary: 'Numéro de Téléphone Principal',
  phoneSecondary: 'Numéro de Téléphone Secondaire',
  pinFilter: 'Épingler le filtre à la page de résultats de la recherche',
  pinned: 'Épinglé',
  plusMoreOrgs: '+ {totalOrgCount} Organisations supplémentaires',
  polishDescription: 'Description en Polonais',
  polishName: 'Nom en Polonais',
  populatePdfNameWith: 'Renseigner le Nom de fichier PDF avec',
  populatePdfNameSelection: 'Sélection Remplir le Nom de Fichier PDF',
  port: 'Port',
  portrait: 'Portrait',
  portugueseDescription: 'Description en Portugais',
  portugueseName: 'Nom en Portugais',
  postComment: 'Publier le Commentaire',
  postalCode: 'Code Postal',
  poweredBy: 'Optimisé Par',
  preferences: 'Préférences',
  preferencesDisable: 'Désactiver les Préférences Utilisateur',
  prefix: 'Préfixe | Préfixes',
  preview: 'Aperçu',
  previewOfName: 'Aperçu de {name}',
  pricing: 'Tarification',
  pricingDiscountEnabled: 'Afficher le Prix Réduit',
  pricingDisplayInCart: 'Afficher les Prix dans le Panier',
  pricingDisplayInPartsList: 'Afficher les Prix dans la Liste des Pièces',
  pricingGlobal: 'Tarification Globale',
  pricingGlobalDescription: 'Le tarif normal en l’absence de dérogation d’Organisation',
  pricingHidePricingButton: 'Activer le Bouton Masquer les Prix',
  pricingHidePricingButtonDisable: 'Désactiver le Bouton Masquer les Prix',
  pricingOrganization: 'Tarification de l’Organisation',
  pricingOrganizationDeleteConfirm: 'Êtes-vous sûr(e) de vouloir supprimer la tarification de l’Organisation ?',
  pricingOrganizationDescription: 'La tarification de l’Organisation remplacera la tarification globale pour tous les utilisateurs au sein d’une Organisation et de ses Organisations enfant.',
  pricingOrganizationDuplicate: 'Cette organisation est déjà écrasée',
  pricingOrganizationEmptyState: 'Aucun remplacement d’organisation n’a été défini. Cliquer sur {add} pour commencer.',
  pricingRetailEnabled: 'Afficher le Prix au Détail',
  pricingWholesaleEnabled: 'Afficher le Prix de Gros',
  primary: 'Principal',
  print: 'Imprimer',
  printerLane: 'Voie de l‘imprimante',
  printJob: 'Tâche d’Impression',
  printLogo: 'Logo Imprimé',
  printSetting: 'Paramètre d’Impression',
  printSettingDeleteFail: 'La Suppression du paramètre d’Impression a Échoué',
  printSettingSaveFail: 'L’Enregistrement du Paramètre d’Impression a Échoué',
  printSettingEmptyMessage: 'Aucun Paramètre d’Impression n’a été défini. Ajouter un Paramètre d’Impression pour commencer.',
  printSettingSetDefaultSuccess: 'Le Paramètre d’Impression par Défaut a été défini',
  printSettingUpdateFail: 'L’Actualisation du Paramètre d’Impression a Échoué',
  printSettingCopyMessage: 'Veuillez saisir un nom unique pour le nouveau Paramètre d’Impression.',
  printSettingCoverLoadErrorMsg: '',
  printSettingCreated: 'Le Paramètre d’Impression a été créé',
  printSettings: 'Paramètres d’Impression',
  printSettingsLogo: 'Paramètres d’Impression Logo',
  printing: 'Impression',
  printingDisabled: 'Désactiver Toutes les Tâches d’Impression',
  privacyPolicy: 'Politique de Confidentialité',
  proceed: 'Continuer',
  process: 'Traiter',
  processContent: 'Traiter le Contenu',
  processing: 'Traitement',
  processToDraft: 'Traiter vers Brouillon',
  processedPlzToDraft: 'PLZ(s) traité(s) vers Brouillon(s)',
  production: 'Production',
  professional: 'Professionnel',
  profile: 'Profil',
  prohibitExportDownloadOfContent: 'Interdire l‘exportation/le téléchargement de contenu',
  prohibitExportDownloadOfContentHelpText: 'Régler sur \'Activer\' pour empêcher tout utilisateur (disposant des privilèges) d‘exporter ou de télécharger ce contenu.',
  property: 'Propriété | Propriétés',
  province: 'Province',
  public: 'Public',
  publish: 'Éditer',
  publisherLane: 'Voie de l‘éditeur',
  publisherManualLane: 'Voie manuelle de l‘éditeur',
  publishThumbnailJob: 'Publier Vignette',
  publishDocuments: 'Publier Documents',
  publishDraft: 'Publier Brouillon',
  publisher: 'Éditeur | Éditeurs',
  publishingErrors: 'Erreurs de Publication',
  publishingErrorsWithName: 'Erreurs de Publication {name}',
  publishingInProgress: 'Publication en cours, impossible d‘envoyer',
  publisherJob: 'Tâche d’Édition',
  publisherMessage: 'Création de contenu, gestion et paramètres d’impression',
  purchaseOrder: 'Bon de Commande',
  purchaseOrderTemplateEnabled: 'Activer les Modèles de Commande',
  purchaseOrderTemplateDisabled: 'Désactiver les Modèles de Commande',
  qty: 'Qté',
  quantity: 'Quantité',
  query: 'Requête',
  quickAdd: 'Ajout Rapide',
  quickAddHelpText: 'Commencez la saisie de texte pour trouver des pièces à ajouter au panier.',
  quickAddInCartEnabled: 'Activer l’Ajout Rapide dans le Panier',
  quickLinks: 'Liens Rapides',
  quote: 'Devis | Devis',
  quoteFormType: 'Formulaire de Devis',
  quoteTemplateDisabled: 'Désactiver les Modèles de Devis',
  quoteTemplateEnabled: 'Activer les Modèles de Devis',
  range: 'Plage',
  rearrange: 'Réorganiser',
  rebuildIndexJob: 'Reconstruire Index',
  recentSearches: 'Recherches Récentes',
  recentlyAdded: 'Ajoutés Récemment',
  recentlyEdited: 'Modifiés Récemment',
  recentlyViewed: 'Consultés récemment',
  rectangle: 'Rectangle',
  redirect: 'Rediriger',
  refresh: 'Actualiser',
  related: 'Associé',
  relatedDescription: 'Associer les médias existants dans la bibliothèque à ce {type}. Remarque : les médias associés doivent avoir les contrôles d‘accès appliqués pour que les utilisateurs puissent les voir dans l‘onglet \'Associé\'.',
  relatedInfo: 'Information Associée',
  relatedView: 'Voir les Médias Associés',
  relationship: 'Relations | Relations',
  releaseNotes: 'Notes de Version',
  rememberMe: 'Se souvenir de moi',
  remainingWidth: 'Largeur Restante',
  remove: 'Retirer',
  removeAll: 'Retirer Tout',
  removeItemConfirmation: 'Êtes-vous sûr(e) de vouloir retirer {item} ?',
  removeItemCount: 'Êtes-vous sûr(e) de vouloir supprimer {count} {type} ?',
  replace: 'Remplacer',
  replaceExisting: 'Remplacer Existant',
  report: 'Rapport | Rapports',
  reportAnIssue: 'Signaler un Problème',
  reportAnIssueMessage: 'L’e-mail a été envoyé avec succès.',
  reportCatalogName: 'Nom de Catalogue du Rapport',
  reportCatalogNotSet: 'Le catalogue de rapport n’a pas été défini pour le rapport.',
  reportDefinition: 'Définition de Rapport | Définitions de Rapport',
  reportDefinitionDeleteError: 'Échec de suppression de la définition du rapport',
  reportsEmptyState: 'Aucun Rapport',
  reportExportTimeWarning: 'En fonction du nombre d’enregistrements et du format sélectionné, l’exportation peut prendre plusieurs minutes.',
  reporting: 'Rapport',
  reportingMessage: 'Accéder à divers rapports et indicateurs',
  reportSettings: 'Paramètres de Rapports',
  reportsLibrary: 'Bibliothèque de Rapports',
  requestForQuote: 'Demande de Devis',
  requestFormType: 'Formulaire de Devis',
  required: 'Requis(e)',
  requiredItem: '{item} est requis.',
  requiredMessage: 'Un(e) {item} est requis(e)',
  requiredMessageAndUnique: 'Un {item} est requis et doit être unique.',
  requireAuthentication: 'Authentification Requise',
  requiredReading: 'Lecture Requise',
  requiredReadingMessage: 'Veuillez vérifier les éléments suivants :  ',
  requiredUrlMessage: 'Une URL au format approprié est requise.',
  reset: 'Réinitialiser',
  resetPassword: 'Réinitialiser le Mot de Passe',
  resetPasswordError: 'Un problème est survenu lors de la réinitialisation de votre mot de passe, veuillez réessayer.',
  resetPasswordTokenError: 'Le jeton de réinitialisation de mot de passe n’est pas valide.',
  resetZoom: 'Réinitialiser Zoom',
  resource: 'Ressource | Ressources',
  resize: 'Redimensionner',
  resizeThumbnails: 'Redimensionner automatiquement les vignettes',
  result: 'Résultat | Résultats',
  resultFor: 'Résultat pour {item} | Résultats pour {item}',
  retail: 'Prix au détail',
  retailPrice: 'Prix au Détail',
  retailTotal: 'Total du Prix au Détail',
  return: 'Revenir en arrière',
  returnToItem: 'Revenir à {item}',
  returnToPartList: 'Revenir à la Liste des Pièces',
  returnToMedia: 'Revenir au Média',
  returnToTemplate: 'Revenir au Modèle',
  returnToViewer: 'Retour à la Visionneuse',
  revertChanges: 'Annuler les Modifications',
  right: 'Droite',
  romanianDescription: 'Description en Roumain',
  romanianName: 'Nom en Roumain',
  rootLevelBrowseFlow: 'Niveau Racine',
  row: 'Ligne',
  russianDescription: 'Description en Russe',
  russianName: 'Nom en Russe',
  sampleData: 'Échantillon de Données',
  samplePreview: 'Modèle d’aperçu',
  sandbox: 'Bac à sable',
  sat: 'Sam',
  save: 'Enregistrer',
  saveChanges: 'Enregistrer les modifications apportées à {item} ? {name}',
  saveChangesToItem: 'Enregistrer les modifications apportées à ce/cet/cette {item} ?',
  saved: 'Enregistré(e)',
  saveNewAPIKey: 'La Nouvelle Clé d’Intégration n’a pas été Enregistrée, Revenez en arrière pour terminer la configuration.',
  saveNewWidgetKey: 'La nouvelle Clé de Widget n’a pas été enregistrée. Revenir pour terminer la configuration.',
  search: 'Rechercher',
  searchableFieldsSuccessfullyUpdated: 'Champs interrogeables mis à jour avec succès.',
  searchAllContent: 'Rechercher dans Tout le Contenu',
  searchConfiguration: 'Configuration de Recherche',
  searchCriteria: 'Critères de Recherche',
  searchDocumoto: 'Rechercher dans Documoto',
  searchFeatures: 'Caractéristiques de Recherche',
  searchFieldConfigDescription: 'Les paramètres de recherche de champ système suivants s’appliquent uniquement à la recherche générale dans la Bibliothèque, à la page des résultats de recherche et à l’Ajout Rapide dans le Panier. Les autres fonctions de recherche (comme la recherche dans le Centre d’Administration et la Bibliothèque d’Utilisation) ne seront pas affectées.',
  searchInContent: 'Rechercher dans {contentName}...',
  searchingInLanguage: 'Recherche dans {lang} uniquement ',
  searchingInTwoLanguages: 'Recherche dans {lang1} et {lang2} seulement',
  searchPlaceholder: 'Rechercher dans tout le contenu...',
  searchPrompt: 'Rechercher dans tout le contenu...',
  searchProperty: 'Rechercher une Propriété | Rechercher des Propriétés',
  searchResultsConfiguration: 'Configuration des Résultats de Recherche',
  searchSettings: 'Paramètres de Recherche',
  searchSettingsDescription: 'Ces paramètres contrôlent divers comportements, fonctionnalités et résultats de la recherche dans la Bibliothèque.',
  searchTerm: 'Rechercher un Terme',
  searchTips: 'Conseils de Recherche :',
  searchable: 'Recherchable',
  searchableTag: 'Balise Recherchable',
  section: 'Section',
  select: 'Sélectionner',
  selectAField: 'Sélectionner un {field}',
  selectAll: 'Tout Sélectionner',
  selectAndClose: 'Sélectionner et Fermer',
  selected: 'Sélectionné(e)',
  selectedTargetPage: 'Page Cible Sélectionnée',
  selectChapters: 'Sélectionner Chapitre(s)',
  selectChildren: 'Sélectionner les Enfants',
  selectFields: 'Sélectionner {fields}',
  selectItem: 'Sélectionner {item}',
  selectMedia: 'Sélectionner Média(s)',
  selectPage: 'Sélectionner la Page',
  selectPages: 'Sélectionner Page(s)',
  selectTargetPage: 'Sélectionner la Page Cible',
  send: 'Envoyer',
  september: 'Septembre',
  serbianDescription: 'Description en Serbe',
  serbianName: 'Nom en Serbe',
  serviceUri: 'Service URI',
  sessionLog: 'Journal de Session',
  set: 'Définir',
  setAsDefault: 'Définir comme Valeur par Défaut',
  settings: 'Paramètres',
  settingsMessage: 'Mettre à jour vos préférences utilisateur et votre mot de passe',
  sequence: 'Séquence',
  shape: 'Forme',
  shapeTool: 'Outil de Forme',
  shared: 'Partagé',
  sharedCart: 'Panier Partagé | Paniers Partagés',
  sharedCartDeleteContent: 'Cette opération supprimera ce Panier Partagé pour toutes les Organisations avec lesquelles il a été partagé.',
  sharedCartOrganizationContent: 'Sélectionner les Organisations pouvant utiliser ce Panier Partagé.',
  sharedShoppingCartsEnabled: 'Activer les Paniers Partagés',
  shoppingCartExportJob: 'Exportation Panier',
  shoppingCartLoadingLongTime: 'Le panier d’achat est en cours de chargement. Les icônes de panier deviendront disponibles une fois le processus terminé. Si le problème persiste, veuillez contacter votre Administrateur Documoto pour obtenir de l’aide.',
  showExportFromSearch: 'Autoriser l’Exportation à partir des Résultats de Recherche',
  showExportFromSearchHelp: 'Autoriser l’exportation de la liste des livres résultant d’une recherche',
  showHelp: 'Afficher l’Aide',
  showIdentifierInSearch: 'Afficher l’Identifiant de Média dans Recherche',
  showItem: 'Afficher {item}',
  showLess: 'Afficher Moins',
  showMediaDescriptionFilter: 'Afficher le Filtre Description du Média',
  showMediaIdentifierFilter: 'Afficher le Filtre Identifiant du Média',
  showMediaNameFilter: 'Afficher le Filtre Nom du Média',
  showMore: 'Afficher Plus',
  showOnlyExactMatches: 'Afficher uniquement les correspondances exactes',
  showOrganizationFilter: 'Afficher le Filtre Organisation',
  showPartNameFilter: 'Afficher le Filtre Nom de Pièce',
  showPartNumberFilter: 'Afficher le Filtre Numéro de Pièce',
  showRecentlyAdded: 'Afficher Ajoutés Récemment',
  showRecentlyViewed: 'Voir les Derniers Articles Consultés',
  showSearchButton: 'Afficher le Bouton de Recherche de la Page d’Accueil',
  showSearchButtonHelp: 'Afficher le bouton \'Aller à la Recherche\' dans l’en-tête de la Bibliothèque',
  signature: 'Signature',
  size: 'Taille',
  snapshot: 'Instantané | Instantanés',
  solrSlaveSvrOverride: 'Écrasement des URL de base des serveurs esclaves SOLR',
  sortAsc: '{value} A-Z',
  sortDesc: '{value} Z-A',
  sortRelevant: 'Le Plus Pertinent',
  sortType: 'Type de Tri',
  spanishDescription: 'Description en Espagnol',
  spanishName: 'Nom en Espagnol',
  square: 'Carré',
  ssoConfiguration: 'Configuration SSO',
  ssoIdpMetadataUrl: 'URL des métadonnées IDP SSO',
  ssoLoginMaxAuthenticationAgeSeconds: 'Ancienneté maximale en secondes de l‘authentification de connexion SSO',
  ssoSamlDnAttributeName: 'Nom de l‘attribut DN SAML SSO',
  ssoSamlEmailAttributeName: 'Nom de l‘attribut e-mail SAML SSO',
  ssoSamlMemberofAttributeName: 'Nom de l‘attribut MembreDe SAML SSO',
  ssoSniForSsoMetadataRetrieval: 'Utiliser le SNI pour la récupération des métadonnées SSO',
  standard: 'Standard',
  standardUserGroup: 'Groupe d’Utilisateurs Standard',
  startDate: 'Date de Début',
  startDateRequired: 'Une date de début est requise.',
  state: 'État',
  static: 'Statique',
  status: 'Statut',
  statusCode: 'Code de Statut | Codes de Statuts',
  stitchPages: 'Pages de Couture',
  storeFrontAdmin: 'Gestion Storefront',
  storefront: 'Storefront',
  storefrontIntegration: 'Intégration Storefront | Intégrations Storefront',
  storefrontIntegrationSubtitle: 'Ces paramètres contrôlent divers éléments et options relatifs à l’Intégration Storefront. Toute modification doit être effectuée avec prudence. Contacter l’Assistance Documoto pour plus d’informations.',
  storefrontSettings: 'Paramètres de Storefront',
  storefrontSettingsDescription: 'Ces paramètres contrôlent divers éléments et options de Storefront. Toute modification doit être effectuée avec prudence. Contacter Documoto Services pour plus d’informations.',
  storefrontSettingsOrgDescription: 'Ces paramètres contrôlent divers éléments et options de Storefront au niveau Organisationnel. Toute modification doit être effectuée avec prudence. Contacter Documoto Services pour plus d’informations.',
  storeFilterValues: 'Stocker les Valeurs des Filtres',
  storeFilterValuesDescription: 'L’activation de ce paramètre permet de stocker les valeurs définies par les utilisateurs dans les filtres de leur page de recherche pour cette balise.',
  stroke: 'Trait',
  style: 'Style | Styles',
  styles: {
    colorHotpoint: 'Point de Référencement Par Défaut',
    invalidHexcode: 'Code hexadécimal non valide',
    inheritedFromOrg: 'Hérité de l’Organisation {org}',
    inheritedFromSystem: 'Hérité de la Valeur Système par Défaut',
    inheritedFromTenant: 'Hérité du Locataire',
    organization: 'Style d’Organisation | Styles d’Organisations',
    organizationSaved: 'Styles d’Organisations Enregistrés',
    sampleNavigation: 'Onglets de navigation',
    samplePrimary: 'Navigation/Boutons',
    sampleSelectedHotpoint: 'Points de Référencement Sélectionnés',
    tenant: 'Style de Locataire | Styles de Locataires',
    tenantSaved: 'Styles de Locataires Enregistrés'
  },
  subChapter: 'Sous-chapitre | Sous-chapitres',
  subject: 'Objet',
  subjectMessage: 'Un Objet est requis',
  submit: 'Soumettre',
  submitPurchaseOrder: 'Bon de Commande',
  submitRequestForQuote: 'Demande de Devis',
  submitted: 'Soumis',
  submitter: 'Expéditeur',
  submittedBy: 'Soumis par',
  submitterEmail: 'Adresse E-mail du Demandeur',
  submitterFirstName: 'Prénom du Demandeur',
  submittedFilesMessage: '{fileCount} fichier(s) a/ont été soumis.',
  submitterLastName: 'Nom de Famille du Demandeur',
  submitterOrgName: 'Nom de l‘Organisation du Demandeur',
  submitterUserName: 'Nom d‘Utilisateur du Demandeur',
  success: 'Succès',
  imageSaveSuccess: 'Images Mises à Jour avec Succès',
  successNotificationDuration: 'Ajouter au Panier - Durée de la Bulle de Notification de Succès',
  suffix: 'Suffixe | Suffixes',
  suggestedKeywords: 'Mots-clés Suggérés',
  suggestedResults: 'Résultats Suggérés',
  suggestion: 'Suggestion | Suggestions',
  sun: 'Dim',
  superadmin: 'Superadmin',
  superAdminSettings: "Paramètres superadmin",
  supersede: 'Remplacer',
  supersedeComplete: 'Souhaitez-vous terminer le remplacement ?',
  supersedeDiscardChanges: 'Annuler le remplacement ?',
  supersedeMainText: 'Cette opération remplace une pièce existante par une ou plusieurs autres pièces, sur toutes les Pages des Pièces contenant l’ancienne pièce. L’ancienne pièce est supprimée, mais peut toujours être recherchée à l’aide des balises. Les résultats peuvent ainsi être trouvés avec l’ancien numéro ou le nouveau numéro.',
  supersedeMessage: 'Les mises à jour des pièces prendront effet immédiatement. La recherche affichera les mises à jour au bout de quelques minutes.',
  supersedeNotProcessed: 'Votre remplacement ne sera pas traité.',
  supersedeReplacePart: 'Trouver une ou plusieurs pièces pour remplacer la pièce',
  supersedeSuccessful: 'Remplacé(e) avec Succès',
  supersedeWith: 'Remplacer la Pièce Par...',
  supplier: 'Fournisseur | Fournisseurs',
  supplierKey: 'Clé de fournisseur',
  supplierDescription: 'L’entreprise qui fournit cette pièce',
  supportedImageFormats: 'Formats d’images pris en charge : GIF, JPG, JPEG, PNG',
  supportedDraftArchives: 'Fichier non pris en charge ; fichiers brouillons pris en charge : MDZ, PLZ',
  surroundWithQuotes: 'Encadrez plusieurs termes de recherche par une paire de guillemets pour obtenir une correspondance exacte.',
  svgFinder: 'Détecteur de SVG',
  swedishDescription: 'Description en Suédois',
  swedishName: 'Nom en Suédois',
  system: 'Système',
  systemAdmin: 'Gestion Système',
  systemFields: 'Champs du Système',
  systemFieldSearchAndFiltering: 'Recherche et Filtrage des Champs du Système',
  systemStatus: 'Statut du Système',
  systemName: 'Nom du Système',
  systemVersion: 'Version du Système',
  tabDelimited: 'Délimité par des tabulations',
  tableOfContents: 'Table des Matières',
  tabloid: 'Tabloïde',
  tag: 'Balise | Balises',
  tagName: 'Nom de la balise',
  tagEditor: {
    rangeBoundLengthInvalid: 'La limite ne peut pas contenir plus de {limit} chiffres.',
    tagEditorModal: 'Module d’Édition de Balises',
    filterPrompt: 'Affichage des 100 premières valeurs. Utilisez le filtre pour en voir plus.'
  },
  tagSearchAndFiltering: 'Recherche et filtrage de balises',
  tagSearchAndFilteringHelpText: 'Astuce : Certains paramètres de recherche et de filtrage sont également configurables dans les éditeurs de balises du Gestionnaire de balises, ici: ',
  tagSubmitAffected: 'Tout contenu {type} se verra automatiquement attribuer la balise {tag} en plus des modifications apportées.',
  tagDeleteAffectedCount: 'Cette opération supprimera la balise et retirera les informations de balise de {count} éléments.',
  tagDeleteAffectedNone: 'Aucun élément n’utilise actuellement cette balise.',
  tagManagerDeleteWarning: 'Tout média existant avec {deleteCategoryName} sera retiré de cette catégorie après la suppression.',
  tagsDisplayMore: 'Affichage des 100 premières valeurs. Utilisez le filtre pour en voir plus.',
  tagsManager: 'Gestionnaire de Balises',
  tagValue: 'Valeur de Balise | Valeurs de Balises',
  tagValueMissing: 'Vous devez saisir une valeur de balise',
  tagValueMultiple: 'Délimité par un point-virgule dans le cas d’entrées multiples',
  tagValueTooLong: 'La valeur de la balise dépasse la limite de 25 caractères maximum | Les valeurs de la balise dépassent la limite des 25 caractères maximum',
  taxon: 'Taxon',
  taxonomy: 'Taxonomie',
  template: 'Modèle | Modèles',
  templateEmailRecipients: 'Destinataires du Modèle d’E-mail',
  templateLayout: {
    addRow: 'Ajouter une Ligne',
    addItem: 'Ajouter un Article',
    deleteRow: 'Supprimer une Ligne',
    deleteColumn: 'Supprimer une Colonne',
    fullWidth: 'Pleine Largeur',
    oneColumn: 'Une Colonne',
    threeColumns: 'Trois Colonnes',
    twoColumns: 'Deux Colonnes'
  },
  tenant: 'Locataire | Locataires',
  tenantAdmin: 'Gestion des Locataires',
  tenantAdminMessage: 'Contrôle les paramètres à l’échelle des locataires',
  tenantCleanerJob: 'Entretien Locataire',
  tenantConfiguration: 'Configuration de locataire',
  tenantEmailRecipients: 'Destinataires de l’E-mail Locataire',
  tenantEmailRecipientsOverrideMessage: 'Ces éléments seront écrasés si des Destinataires de l’E-mail de l’Organisation sont spécifiés',
  tenantIndexQueueJob: 'File d‘attente de l‘Index des Locataires',
  tenantKey: 'Clé de locataire',
  tenantKeyError: 'La clé de locataire n‘est pas unique',
  tenantKeyMessage: 'La clé de locataire doit être unique',
  tenantLabelDeleteMessage: 'En supprimant cette désignation, le nom par défaut de Documoto sera rétabli.',
  tenantReindexModulus: 'Module de réindexation des locataires',
  tenantSettings: {
    collapseTOCLabel: 'Réduire la Table des Matières sur la Page de Correspondance',
    defaultLocaleLabel: 'Région par Défaut',
    defaultLocaleMessage: 'La région par défaut du locataire',
    deleteMissingTranslationsOnPublishLabel: 'Supprimer les traductions manquantes lors de l‘édition ?',
    deleteMissingTranslationsOnPublishMessage: 'Activer pour Supprimer les Traductions de Pièces lors de l‘édition',
    defaultSupplierLabel: 'Fournisseur par Défaut',
    defaultSupplierMessage: 'Le fournisseur par défaut du locataire',
    enableChapterIndexingMessage: 'Index des Chapitres',
    enableChapterIndexingLabel: 'Active l‘indexation pour les Chapitres de Médias',
    enableIndexingLabel: 'Indexation pour Publication',
    enableIndexingMessage: 'Active l’indexation pour la publication.',
    helpUrlLabel: 'URL d’Aide par Défaut',
    helpUrlMessage: 'L’URL d’un système d’aide alternatif.',
    hotPointLinkPageInBookOnlyLabel: 'Liens de Référencement de Page dans le Livre Uniquement',
    hotPointLinkPageInBookOnlyMessage: 'Liens de Référencement des Pages dans le Livre Uniquement',
    mediaTimestampLabel: 'Mettre à Jour l’Horodatage du Média lors de Modifications des Contrôles d’Accès',
    mediaTimestampMessage: 'Activer si une modification dans les contrôles d’accès doit mettre à jour l’horodatage du média.',
    publisherDefaultUom: 'Unité de Mesure (UDM) par défaut de l’Éditeur',
    publishingContactLabel: 'Édition de l’E-mail de Contact',
    publishingContactMessage: 'L’adresse e-mail par défaut à laquelle seront envoyés les e-mails lorsqu’un problème de contenu est signalé.',
    replaceHotpointLabel: 'Toujours Remplacer les Liens de Référencement lors de l’Édition',
    replaceHotpointMessage: 'Réservé à l’édition sur Docustudio',
    ssoLoginSystemLabel: 'Système de connexion SSO',
    ssoLoginSystemMessage: 'Système fournisseur d‘identité pour SSO.',
    userPasswordExpirationLabel: 'Jours d‘expiration du mot de passe utilisateur',
    userPasswordExpirationMessage: 'Nombre de jours après lesquels les mots de passe doivent être réinitialisés ; laisser vide pour qu‘ils n‘expirent jamais',
    lockPartTrOnPublishLabel: 'Verrouiller les Traductions de Pièces lors de l‘édition',
    lockPartTrOnPublishMessage: 'Empêche la modification des traductions de pièces lors de l‘édition',
    exportPgAsHashLabel: 'Exporter le nom de fichier de la page en tant que clé de hachage',
    exportPgAsHashMessage: 'Utiliser les clés de hachage des pages en tant que noms de fichiers de pages dans le contenu exporté',
  },
  tenantSettingsTitle: 'Paramètres de Locataire',
  tenantSettingsSaved: 'Paramètres de Locataire Enregistrés',
  tenantSettingsSubtitle: 'Ces paramètres contrôlent les propriétés et comportements à l’échelle des locataires. Toute modification doit être effectuée avec prudence. Contacter Documoto Services pour plus d’informations.',
  tenantSuppliers: 'Fournisseurs de locataire',
  tenantLabelsPageText: 'Les désignations sont utilisées pour personnaliser les noms de nombreux objets que l’on trouve dans la Bibliothèque de Documoto et les écrans de Gestion.',
  tenantPrintSettingsDescription: 'Choisir le paramètre d’impression par défaut à l’échelle du locataire. Celui-ci sera utilisé pour toutes les impressions de livres à moins d’être remplacé au niveau de l’Organisation ou du Média.',
  tenantType: 'Type de locataire',
  tenantWebserviceUser: 'Utilisateur du service Web Locataire',
  text: 'Texte',
  textArea: 'Zone de texte',
  textField: 'Champ de texte',
  textFile: 'Fichier Texte (CSV, TXT)',
  textTool: 'Outil de Texte',
  threeD : {
    addFile: 'Ajouter un Fichier de Pièce 3D',
    beforeSaveModal: {
      title: 'Un fichier CAO portant ce nom de fichier existe déjà dans Documoto.',
      message: 'Voulez-vous vraiment remplacer ce fichier partout où il existe, ou l’attacher uniquement à cette pièce ? Si vous choisissez de remplacer le fichier, voici ce qui arrivera :',
      messageBulletOne: 'Les pièces {count} seront mises à jour avec ce nouveau fichier CAO',
      messageBulletTwo: 'La version précédente de ce fichier sera supprimée du système de fichiers Documoto',
      tip: 'Conseil : Pour éviter l’écrasement de fichiers pendant le processus d’attachement, vous pouvez modifier le nom de fichier du fichier que vous essayez d’attacher avant de l’importer.'
    },
    deleteTitle: 'Voulez-vous vraiment supprimer cet affichage 3D ?',
    drawMode : {
      label: 'Mode Dessin',
      hiddenLines: 'Lignes Masquées',
      shaded: 'Grisé',
      wireframe: 'Armature',
      wireframeShaded: 'Armature grisée',
    },
    explode: 'Vue Éclatée',
    fileSizeModal: {
      message: 'La taille maximum autorisée du fichier dans Documoto est de 25 mégaoctets. Le fichier que vous tentez de télécharger a dépassé la taille maximum autorisée et ne peut pas être chargé. Cliquez sur "OK" pour continuer.',
      title: 'Taille de Fichier Maximum Dépassée',
    },
    orientationAndProjection: {
      back: 'Arrière',
      bottom: 'Bas',
      front: 'Avant',
      isometric: 'Isométrique',
      left: 'Gauche',
      orthographic: 'Orthographique',
      projection: 'Projection',
      perspective: 'Perspective',
      right: 'Droite',
      top: 'Haut'
    },
    resetCamera: 'Réinitialiser Caméra',
    coupe : {
      label: 'Coupe',
      planeX: 'Plan de Coupe X',
      planeY: 'Plan de Coupe Y',
      planeZ: 'Plan de Coupe Z',
      reset: 'Réinitialisation du Plan de Coupe',
      section: 'Bascule de Section du Plan de Coupe',
      visibility: 'Bascule de Visibilité du Plan de Coupe',
    },
    supportedFilesMessage: 'Documoto prend en charge de nombreux formats de fichiers CAO. Consultez le Centre d’Assistance en cas de doute sur la prise en charge de votre format de fichier CAO.',
    toolsLabel: 'Outils 3D',
    topAssemblyFilename: 'Nom de fichier de l’Assemblage Supérieur',
    topAssemblyFilenameRequired: 'Le nom de fichier de l’Assemblage Supérieur est requis',
    viewerTitle: 'Visionneuse 3D',
  },
  thu: 'Jeu',
  thumbnail: 'Vignette',
  thumbnailDeleted: 'Vignette Supprimée avec Succès',
  thumbnailDeletedError: 'La Suppression de la Vignette a Échoué',
  thumbnailUpdated: 'Vignette Mise à Jour avec Succès',
  thumbnailUpdatedError: 'La Mise à Jour de la Vignette a Échoué',
  thumbnailReapplyWarning: 'Le système peut réappliquer une vignette selon le contenu du média. Cette opération peut prendre quelques minutes.',
  thumbnailResizeTooltip: 'Redimensionne les vignettes pour qu‘elles ne dépassent pas 500x500 pixels, si cette option est cochée',
  title: 'Titre',
  to: 'À',
  toastNotifications: 'Bulles de Notifications',
  toDate: 'Jusqu’au',
  toc: 'Table des Matières',
  tocClipBoardSuccessTitle: 'Copié dans le Presse-Papiers',
  tocClipBoardWarningText: 'AVERTISSEMENT : Les modifications non enregistrées ne peuvent pas être copiées dans le presse-papiers',
  tocClipBoardWarningTitle: 'Copié dans le Presse-Papiers',
  tocDisplayingFirstValues: 'Affichage des 100 premières valeurs. Utilisez le filtre pour en voir plus.',
  tocDuplicateWarningText: 'Une page doublon a été détectée dans un chapitre. La page doublon a été supprimée.',
  tocEmptyMessage: 'Il semble que vous n’avez pas encore créé de Table des Matières',
  toGetStarted: 'pour commencer.',
  tocMissingChapterName: 'Nom de Chapitre Manquant',
  tocModalTableCaption: 'Module d’Édition de Balises',
  tocNothingSelected: 'Aucun Élément Sélectionné',
  tocOpenEditor: 'Ouvrir l’Éditeur',
  tocSaveRequired: 'Enregistrement requis avant de modifier les informations de chapitre',
  tocState: 'État Initial de la Table des Matières',
  tocThumbnailDeleteFailTitle: 'La Suppression de la Vignette a Échoué',
  tocThumbnailDeleteSuccessTitle: 'Vignette Supprimée avec Succès',
  tocThumbnailUploadFailTitle: 'Le Téléchargement de la Vignette a Échoué',
  tocThumbnailUploadSuccessTitle: 'Vignette Téléchargée avec Succès',
  tocTranslationMessage: 'Le nom et la description facultative de ce chapitre',
  tocUntitledPage: 'Page Sans Titre',
  tooManySelections: 'La liste dépasse la limite de sélection {limit}',
  togglePage: 'Commuter la Page',
  top: 'Haut',
  total: 'Total',
  totalItems: 'Total des Éléments',
  translation: 'Traduction | Traductions',
  translationHelpTextItem: 'Le nom et la description facultative de ce/cet/cette {item}',
  true: 'Vrai',
  tryAgain: 'Veuillez réessayer.',
  tue: 'Mar',
  turkishDescription: 'Description en Turque',
  turkishName: 'Nom en Turque',
  twoSided: 'Recto-verso',
  type: 'Type | Types',
  typeAtLeastTwoChars: 'Saisissez au moins 2 caractères pour afficher une liste de correspondances.',
  typeToConfirm: 'Saisir {value} pour confirmer',
  typography: 'Typographie',
  ukraineDescription: 'Description en Ukrainien',
  ukraineName: 'Nom en Ukrainien',
  unableToRetrieveResults: 'Impossible de récupérer les résultats de votre requête/recherche',
  unauthorized: 'Non Autorisé',
  unknownError: 'Une erreur inconnue est survenue. Veuillez contacter l’Assistance Documoto à l’adresse support{\'@\'}documoto.com pour obtenir de l’aide',
  unknownPart: 'Pièce Inconnue',
  unknownType: 'Type inconnu',
  unsavedAPIKeyWarning: 'Votre Clé d’API sera perdue si vous ne Revenez pas en arrière',
  unsavedChanges: 'Certaines modifications n’ont pas été enregistrées. Souhaitez-vous continuer ?',
  unsavedChangesWarning: 'Vos modifications seront perdues si vous ne les enregistrez pas.',
  unsavedWidgetKeyWarning: 'Votre Clé de Widget sera perdue si vous ne revenez pas.',
  unsupportedBrowserMessage: 'Nous avons conçu cette application à l’aide des toutes dernières technologies. Elle est ainsi plus rapide et plus facile à utiliser. Malheureusement, votre navigateur ne prend pas en charge ces technologies.',
  uom: 'Unité de mesure (UDM)',
  update: 'Mettre à jour',
  updateFailed: 'La mise à jour a échoué',
  updateIndex: 'Mettre à Jour l’Index',
  updatePassword: 'Mettre à Jour le Mot de Passe',
  updatePasswordMessage: 'Mot de passe mis à jour avec succès',
  updatePasswordPending: 'Le nouveau mot de passe doit être enregistré.',
  updated: 'Mis(e) à jour',
  upgradeBrowser: 'Veuillez mettre à niveau votre navigateur.',
  upload: 'Télécharger',
  uploadComplete: 'Téléchargement Terminé',
  uploadInProgress: 'Téléchargement en Cours',
  uponSavingDisabledTenant: 'Lors de l’enregistrement de cette page avec ce paramètre activé, voici ce qui se passera :',
  uponSavingThisLicense: 'Lorsque vous enregistrez cette page avec ce paramètre, vous DEVEZ FAIRE CE QUI SUIT :',
  upperBound: 'Limite Supérieure',
  untitledOrg: 'Organisation Sans Nom',
  usCurrency: 'USD',
  useDefaultCartColumns: 'Utiliser les Colonnes de Panier par Défaut',
  useDefaultPartsListColumns: 'Utiliser les Colonnes de Liste de Pièces par Défaut',
  user: 'Utilisateur',
  userAddress: 'Adresse de l’Utilisateur | Adresses de l’Utilisateur',
  userCount: 'Nombre d’Utilisateurs',
  userEditable: 'Modifiable par l’Utilisateur',
  userExpirationDate: 'Date d’Expiration de l’Utilisateur',
  userExpirationDateInvalid: 'La Date d’Expiration de l’Utilisateur doit être saisie',
  userGroup: 'Groupe d’Utilisateurs | Groupes d’Utilisateurs',
  userGroupEmpty: 'Le champ Groupes d’Utilisateurs ne peut pas être vide',
  userGroupType: 'Type de Groupe d’Utilisateurs',
  userGroupUniqueMessage: 'Ce nom doit être différent des autres noms de Groupes d’Utilisateurs pour ce locataire',
  userGroupUsers: 'Utilisateurs de Groupes d’Utilisateurs',
  username: 'Nom d’utilisateur',
  usernameEmpty: 'Le champ Nom d’utilisateur ne peut pas être vide',
  users: 'Utilisateurs',
  usersWillReceiveAnUnauthorizedError: 'Les utilisateurs recevront un message d’erreur "Non autorisé" lors d’une tentative de connexion au locataire.',
  useSniForSsoMetadataRetrieval: 'Utiliser le SNI pour la récupération des métadonnées SSO',
  value: 'Valeur',
  validate: 'Valider',
  validateTenantDirectories: {
    buttonLabel: 'Valider les répertoires de contenu',
    error: 'Échec de validation des répertoires',
    success: 'Répertoires locataires validés',
  },
  variantSKU: 'Variable de SKU',
  validationMessage: 'Message de Validation | Messages de Validation',
  valueGteError: 'La valeur doit être inférieure ou égale à {max}',
  valueLteError: 'La valeur doit être supérieure ou égale à {max}',
  vendorName: 'Nom du Fournisseur',
  verifyPassword: 'Vérifier le Mot de Passe',
  vertical: 'Vertical',
  version: 'Version',
  video: 'Vidéo | Vidéos',
  view: 'Voir',
  viewAll: 'Afficher tous',
  viewAllPagesAndBooks: 'Afficher : Toutes les Pages et Tous les Livres',
  viewAllResults: 'Afficher tous les résultats',
  viewBooks: 'Afficher : Livres',
  viewMore: 'Afficher plus',
  viewPages: 'Afficher : Pages',
  viewModeList: 'Affichage par Liste',
  viewModeTable: 'Affichage par Tableau',
  visibility: 'Visibilité',
  visibilityDescription: 'Qui peut voir ce/cet/cette {entity} ?',
  visible: 'Visible',
  waitForUpload: 'Veuillez attendre que le téléchargement soit terminé.',
  watermark: 'Filigrane',
  watermarkFont: 'Police du Filigrane',
  watermarkOpacityPercentage: '% d’opacité du Filigrane',
  watermarkOrientation: 'Orientation du Filigrane',
  watermarkSaved: 'Filigrane Enregistré',
  watermarkSaveFailed: 'Échec d’enregistrement du filigrane.',
  watermarkSelectionRequired: 'Sélection de Nom d’utilisateur ou de Texte requise.',
  watermarkText: 'Texte du Filigrane',
  wed: 'Mer',
  whereUsed: 'Apparaît Dans',
  whoCanViewComment: 'Qui peut afficher ce commentaire ?',
  wholesale: 'Prix de Gros',
  wholesalePrice: 'Prix de Gros',
  wholesaleTotal: 'Total du Prix de Gros',
  widget: 'Widget',
  widgetAuthHeader: 'Valeur d’Autorisation du Widget',
  widgetKey: 'Clé du Widget | Clés de Widgets',
  widgetKeyAdmin: 'Admin des Clés de Widgets',
  widgetKeyCopied: 'Valeur d’Autorisation du Widget copiée dans le Presse-papiers',
  widgetKeyDeleted: 'Clé de Widget Supprimée',
  widgetKeyDeletedError: 'Erreur lors de la suppression de la Clé de Widget',
  widgetKeyDeleteMsg: 'La suppression de la Clé de Widget désactivera son utilisation',
  widgetKeyManager: 'Gestionnaire de Clés de Widgets',
  widgetKeyNameEmpty: 'Le nom doit être unique et est obligatoire. Cette valeur sera également utilisée à des fins de rapports et de journalisation en tant que nom d’utilisateur pour toute activité liée aux Widgets',
  widgetKeyRegenError: 'Erreur lors de la régénération de la Clé de Widget',
  widgetKeyRegenMsg: 'La régénération de cette Clé de Widget peut désactiver les intégrations existantes',
  widgetKeyRegenTitle: 'Voulez-vous vraiment régénérer cette Clé de Widget ?',
  widgetKeySaved: 'Clé de Widget Enregistrée',
  widgetPermissions: 'Les utilisateurs de widgets sont en lecture seule, et l’accès aux fonctionnalités est géré par la configuration au niveau du Widget. Les autorisations de groupes d’utilisateurs standard ne s’appliquent pas aux utilisateurs de Widget. Des privilèges d’accès aux Catégories de Médias s’appliquent. Veuillez procéder à l’attribution des contrôles d’accès aux catégories de médias.',
  widgetRefreshErrorMsg: "Veuillez actualiser votre page pour afficher le contenu demandé",
  widgetUserGroup: 'Groupe d’utilisateurs de Widget',
  widgetUserGroupDeleteMsg: 'Impossible de supprimer le groupe Widget avec des utilisateurs',
  willBeAffected: 'sera/seront affecté(s)',
  willBeDeleted: '{content} sera supprimé.',
  willBeInsertedIntoItem: '{name} sera inséré dans {item}',
  willBeUpdated: 'sera mis à jour',
  zipCode: 'Code Postal',
  zoomHotpointToCanvas: 'Adapter le Point de Référencement à la taille de la Toile',
  zoomIn: 'Zoom Avant',
  zoomOut: 'Zoom Arrière'
}
/* eslint-enable max-len */
