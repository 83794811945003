import http from '@/http'
import {
  TagUpdatesMap,
  TenantAdminBanner,
  TenantInfo,
  TenantParams,
  TenantReportUpdatesMap,
  TenantsResponse,
  TenantTagParams,
} from '@/interfaces/admin/superAdminTenant'
import { loadProperties, saveProperties } from '@/components/admin/properties.js'
import { fromServer } from '@/components/admin/accessPrivileges.js'
import { TenantSupplierRequest, ErpSystemDto, SuperAdminTenantSsoSettings } from "@/interfaces/admin"

const SA_ERP = '/super-admin/erp-system'
const SA_SUPPLIER = '/super-admin/supplier'
const SA_TENANTS = '/super-admin/tenants'
const TENANT = 'tenant'

export async function createTenantDirectories(tenantKey: String): Promise<void> {
  await http.post(`/super-admin/tenants/${tenantKey}/directories`)
}

export async function getTenantKeys(params: TenantParams): Promise<TenantsResponse> {
  let tenantKeys: TenantInfo[] = []
  let pageCount, pageNum, total

  do {
    const { data, headers } = await http.get(SA_TENANTS, { params })
    total = parseInt(headers['x-count'])
    pageCount = parseInt(headers['x-page-count'])
    pageNum = parseInt(headers['x-page-num'])
    params.offset = parseInt(headers['x-offset']) + parseInt(headers['x-limit'])
    tenantKeys = tenantKeys.concat(data)
  } while (pageNum < pageCount)

  return { data: tenantKeys, total }
}

export async function getTenantInfoById(id: number): Promise<any> {
  const { data } = await http.get(`${SA_TENANTS}/${id}`)
  return data
}

export async function updateTenantInfoById(id: number, tenant: TenantInfo) {
  const { data } = await http.put(`${SA_TENANTS}/${id}`, tenant)
  return data
}

export async function getTenantTagValues({ tagId, tenantId, filter }: TenantTagParams) {
  const { data } = await http.get(`${SA_TENANTS}/${tenantId}/tags/${tagId}?filterTerm=${filter}`)
  return data
}

export async function savePendingTags(id: number, updatesMap: TagUpdatesMap) {
  const { data } = await http.put(`/tenant-tags/super-admin/by-tenant/${id}`, updatesMap)
  return data
}

export async function getSuppliersByTenantId(id: number) {
  const { data } = await http.get(`${SA_SUPPLIER}/tenant/${id}?offset=0&limit=1000`)
  return data
}

// On invocation, Vue component's "this" must be bound to this function
export async function loadSATenantProperties(this: any, id: number, opts = {}) {
  return await loadProperties.call(this, `${SA_TENANTS}/${id}/properties`, TENANT, opts)
}

// On invocation, Vue component's "this" must be bound to this function
export async function saveSATenantProperties(this: any, id: number, props: Record<string, any>, opts = {}) {
  // Trim all string values before submitting
  for (let key in props) {
    const value = props[key]
    if (typeof value === "string") {
      // Set to null to delete prop in DB if empty string
      props[key] = value.trim() || null
    }
  }
  return await saveProperties.call(this, `${SA_TENANTS}/${id}/properties`, TENANT, props, opts)
}

export async function loadSATenantAccessPrivileges(tenantId: number, includedPrivs: string[]) {
  const { data } = await http.get(`${SA_TENANTS}/${tenantId}/privileges`)
  const allTenantPrivs = fromServer(data)
  return includedPrivs.reduce((acc, priv) => ({
    ...acc,
    [priv]: allTenantPrivs.includes(priv)
  }), {})
}

export async function saveSATenantAccessPrivileges(tenantId: number, privs: any) {
  return await http.put(`${SA_TENANTS}/${tenantId}/privileges`, privs)
}

export async function getReports(tenantId: number) {
  let reports: any[] = []
  const params = { offset: 0 }
  let pageCount, pageNum, total

  do {
    const { data, headers } = await http.get(`${SA_TENANTS}/${tenantId}/reports/logi`, { params })
    total = parseInt(headers['x-count'])
    pageCount = parseInt(headers['x-page-count'])
    pageNum = parseInt(headers['x-page-num'])
    params.offset = parseInt(headers['x-offset']) + parseInt(headers['x-limit'])
    reports = reports.concat(data)
  } while (pageNum < pageCount)

  return { data: reports, total }
}

export async function setTenantReports(tenantId: number, updates: TenantReportUpdatesMap) {
  await http.put(`${SA_TENANTS}/${tenantId}/reports/logi`, updates)
}
export async function getAllSuppliers () {
  const { data } = await http.get(`${SA_SUPPLIER}?offset=0&limit=10000`)
  return data
}

export async function createSupplier (payload: TenantSupplierRequest) {
  const { data } = await http.post(`${SA_SUPPLIER}`, payload)
  return data
}

export async function deleteSupplierAssociation (payload: TenantSupplierRequest) {
  const { data } = await http.delete(`${SA_SUPPLIER}/${payload.supplierId}?tenantId=${payload.tenantId}`)
  return data
}

export async function putSupplierAssociation (payload: TenantSupplierRequest) {
  const { data } = await http.put(`${SA_SUPPLIER}`, payload)
  return data
}

export async function createTenant(tenant: TenantInfo) {
  const { data } = await http.post(`${SA_TENANTS}`, tenant)
  return data
}

export async function getAllErpSystems(params: any) {
  let erpSystems: ErpSystemDto[] = []
  let pageCount, pageNum, total

  do {
    const { data, headers } = await http.get(SA_ERP, { params })
    total = parseInt(headers['x-count'])
    pageCount = parseInt(headers['x-page-count'])
    pageNum = parseInt(headers['x-page-num'])
    params.offset = parseInt(headers['x-offset']) + parseInt(headers['x-limit'])
    erpSystems = erpSystems.concat(data)
  } while (pageNum < pageCount)

  return { data: erpSystems, total }
}

export async function getErpSystemById(id: number) {
  const { data } = await http.get(`${SA_ERP}/${id}`)
  return data
}

export async function updateErpSystemById(id: number, erp: ErpSystemDto) {
  const { data } = await http.put(`${SA_ERP}/${id}`, erp)
  return data
}

export async function createErpSystem(erp: ErpSystemDto) {
  const { data } = await http.post(SA_ERP, erp)
  return data
}

export async function loadTenantAdminBanner(tenantId: Number): Promise<TenantAdminBanner> {
  const { data } = await http.get(`${SA_TENANTS}/${tenantId}/banner`)
  return data[0]
}

export async function saveTenantSSOSettings(tenantId: number, ssoSettings: SuperAdminTenantSsoSettings) {
  await http.put(`${SA_TENANTS}/${tenantId}/sso-settings`, ssoSettings)
}

export async function updateTenantAdminBanner(
  tenantId: Number,
  banner: TenantAdminBanner
): Promise<void> {
  await http.put(`${SA_TENANTS}/${tenantId}/banner`, [banner])
}
