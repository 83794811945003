<template>
  <slot :title="title">
    <library-content-header class="library-content-header"
                            :title="title"
                            :style="contentHeaderStyle"
                            :content="content">
      <template #navigation>
        <div class="is-flex justify-space-between full-width">
          <div class="is-flex">
            <h3 v-if="showTitle"
                class="title-five ml-1">
              {{ title }}
            </h3>
            <bulma-button-icon v-if="!hasInfoPageOpen"
                               iconClass="fas fa-info-circle links-color"
                               class="pointer-on-hover mr-1 info-btn"
                               :disabled="hasEmptyDetails() || !hasInfoLandingPage"
                               :text="$t('contentViewerMoreInfo')"
                               @click="openInfoPage"/>
            <bulma-button-icon v-if="canShowBackButton"
                               class="pointer-on-hover mr-1 return-btn"
                               iconClass="fas fa-arrow-left links-color"
                               :text="$t('returnToViewer')"
                               @click="backToContentFromInfoPage"/>
          </div>
          <div class="buttons">
            <widget-quick-add-button v-if="showQuickAdd" />
            <button v-if="hasDownloadableContent"
                    class="button is-hidden-mobile ml-2"
                    @click="customDownload(content.contentUrl)">
              <span class="icon fas fa-download" />
              <span>{{ $t('download') }}</span>
            </button>
            <div v-if="hasDownloadableContent"
                 class="dropdown is-hidden-tablet is-right"
                 data-unit="actions-dropdown"
                 :class="{'is-active': toggleDropdown}">
              <div class="new-dropdown-trigger">
                <button aria-controls="dropdown-menu"
                        class="button ml-2"
                        @click="toggleDropdown=!toggleDropdown">
                  <span class="icon is-small">
                    <i v-if="toggleDropdown" class="fas fa-times"></i>
                    <i v-else class="fas fa-angle-down"></i>
                  </span>
                </button>
              </div>
              <div class="dropdown-menu"
                   id="dropdown-menu"
                   role="menu"
                   :style="{ 'margin-top': '-0.5rem' }">
                <div class="dropdown-content">
                  <a class="dropdown-item">
                    <div class="custom-download"
                         data-unit="download-button"
                         @click="customDownload(content.contentUrl)">
                      <span class="icon fas fa-download" />
                      <span>{{ $t('download') }}</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #contentActions>
        <!-- Override default content with nothing -->
        <div style="display: none"></div>
      </template>
    </library-content-header>
  </slot>
</template>

<script setup>
import BulmaButtonIcon from '@/components/common/BulmaButtonIcon.vue'
import i18n from '@/locales'
import WidgetQuickAddButton from "@/components/widgets/components/WidgetQuickAddButton.vue"
import LibraryContentHeader from '@/components/library/content/header/LibraryContentHeader'
import { computed, ref, toRaw } from 'vue'
import { customDownload } from '@/helpers/customDownload'
import { useBreakpoints } from '@/helpers/responsiveBreakpoints'
import { useInfoLandingPage } from '@/components/widgets/composables/useInfoLandingPage'
import { useStore } from 'vuex'
import { useWidgetNavigator } from '@/components/widgets/composables/useWidgetNavigator.ts'
import { useWidgetAddToCart } from "@/components/widgets/composables/useWidgetAddToCart"
import { WidgetTypes } from '@/interfaces/global/widgets'

const store = useStore()
const {
  backToContentFromInfoPage,
  hasInfoLandingPage,
  hasInfoPageOpen,
  openInfoPage
} = useInfoLandingPage()
const { hasWidgetCart } = useWidgetAddToCart()
const { isBook, page }  = useWidgetNavigator()
const { outerWidth } = useBreakpoints()

const canShowBackButton = computed(() => {
  return hasInfoPageOpen.value
    ? type.value === 'part'
      ? (isBook.value) || (page.value !== null)
      : true
    : false
})

const content = computed(() => store.state.content)

const hasDownloadableContent = computed(() => {
  const isBookPrintingDisabled = (store.state.content?.disableExport ?? false) ||
    (store.state.content?.toc?.book?.disableExport ?? false)

  if (['document', 'image', 'video'].includes(store.state.content.contentType)) {
    return !isBookPrintingDisabled
  }

  return false
})

const toggleDropdown = ref(false)
const type = computed(() => (content.value.type === 'media')
  ? content.value.contentType
  : content.value.type)

const title = computed(() => content.value?.isLoaded
  ? content.value.partNumber || content.value.name
  : i18n.global.tc(type.value, 1))

const contentHeaderStyle = computed(() => {
  const justify = hasInfoPageOpen.value ? 'space-between' : 'flex-start'
  return `justify-content: ${justify}; ` +
    'padding: 0.5rem; ' +
    'max-height: 13%;'
})
// computed property isn't calculating proper value. Converting this to function does the trick
const hasEmptyDetails = () => {
  const contentRaw = toRaw(content.value)
  return contentRaw.type !== 'page'
    ? !contentRaw.tags?.total && !contentRaw.tagCount && !contentRaw.comments?.total && !contentRaw.related?.total
    : false
}
// there is a difference in breakpoint returned for widgets vs normal ui for window.innerWidth
// in the browser emulator, the iframe outerWidth is the normal ui equivalent of window.innerWidth
// widgets should be using this outerWidth as the media breakpoint
const isMobile = computed(() => {
  return outerWidth.value <= 768
})
const widgetType = computed(() => {
  return store.getters['widgets/widgetType']
})
const showTitle = computed(() => {
  return (widgetType.value === WidgetTypes.page)
    || !isMobile.value
})

const showQuickAdd = computed(() => {
  return hasWidgetCart.value && content.value.enableQuickAddForContent && (widgetType.value === WidgetTypes.library)
})
</script>

<style lang="scss" scoped>
.dropdown-item:hover {
  background-color: hsl(0, 0%, 98%);
  color: #3E7ACC;
}
</style>
