<template>
  <section  v-if="!isMounting"
            class="section"
            data-int="browse-search">
    <h1 class="title is-size-4 is-marginless">
      {{ total || '' }} {{ subtitle }}
    </h1>
    <browse-filter-bar/>
    <slot name="content"
          :viewModeComponent="viewModeComponent"
          :items="items"
          :itemsLoaded="itemsLoaded"
          :info="info"
          :open="open"
          :isMounting="isMounting">
      <section  v-if="itemsLoaded"
                class="browse-results">
        <component  :is="viewModeComponent"
                    :items="items"
                    @info="info"
                    @open="open"
                    @widgetNavToContent="$emit('widgetNavToContent', $event)"/>
      </section>
      <section v-else class="browse-results-loading">
        <loading-icon/>
      </section>
    </slot>
    <slot name="searchPagination">
      <browse-pagination-wrapper v-if="items.length"/>
    </slot>
    <content-info-modal v-if="infoModalOpen"
                        :search-result="infoModalContent"
                        @close="close"/>
  </section>
  <loading-icon v-else style="margin-left: 50%; margin-top: 10%"/>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LoadingIcon from '@/components/common/LoadingIcon';
import ContentInfoModal from '@/components/common/ContentInfoModal';
import BrowseFilterBar from './BrowseFilterBar';
import SearchResultsList from '../search/SearchResultsList';
import SearchResultsTable from '../search/SearchResultsTable';
import BrowsePaginationWrapper from './BrowsePaginationWrapper';
import { useRouter } from "vue-router";
import { getRouteParamId } from "@/helpers/router";
import { title } from "./browseMetaTitle";

export default {
  name: 'BrowseSearch',
  provide() {
    return {
      sourceStore: 'browse/search',
      targetStore: 'browse'
    };
  },
  components: {
    ContentInfoModal,
    BrowseFilterBar,
    BrowsePaginationWrapper,
    LoadingIcon
  },
  data() {
    return {
      infoModalOpen: false,
      infoModalContent: undefined,
      windowWidth: -1,
      isMounting: true
    };
  },
  computed: {
    ...mapState({
      taxonName: ({ browse }) => browse.name,
      taxonLoaded: ({ browse }) => browse.isLoaded,
      items: ({ browse }) => browse.search.items,
      itemsLoaded: ({ browse }) => browse.search.isLoaded,
      total: ({ browse }) => browse.search.total,
      offset: ({ browse }) => browse.search.offset,
      filters: ({ browse }) => browse.search.filters.items,
      filtersLoaded: ({ browse }) => browse.search.filters.isLoaded,
      viewModeKey: ({ search }) => search.viewModeKey
      }),
    ...title,
    subtitle() {
      return this.$tc('resultFor', this.total, {item: this.title});
    },
    id() {
      const { id } = this.$route.params
      return (typeof id === "string")
        ? id.split(",")
        : id
    },
    name() {
      return this.taxonName ?
        this.taxonName : this.$i18n.t('browse');
    },
    viewModeComponent() {
      if (this.windowWidth <= 768) {
        return SearchResultsList;
      }
      if (this.isWidget) {
        return SearchResultsList
      } else {
        switch (this.viewModeKey) {
          case 'table':
            return SearchResultsTable;
          default:
            return SearchResultsList;
        }
      }
    }
  },
  watch: {
    async $route() {
      if (this.filtersLoaded) {
        await this.updateSelectedFilters();
      }
      this.scrollToTop();
    },
    async filters(newFilter, oldFilter) {
      if (newFilter !== oldFilter) {
        await this.updateSelectedFilters();
      }
    },
    offset() {
      this.scrollToTop();
    }
  },
  methods: {
    ...mapActions({
      setSelectedFiltersAndOptions: 'browse/setSelectedFiltersAndOptions',
      search: 'browse/search',
      getBrowse: 'browse/getBrowse',
      navigateToHome: 'browse/navigateToHome',
      navigateToContent: 'content/navigateToContent',
      getFilters: 'browse/getFilters'
    }),
    scrollToTop() {
      const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
      if (!supportsNativeSmoothScroll) return;

      const results = document.getElementsByClassName('browse-results');
      if (results.length) {
        results[0].scrollTo(0, 0);
      }
    },
    open(content) {
      this.navigateToContent({ content });
    },
    info(content) {
      this.infoModalContent = content;
      this.infoModalOpen = true;
    },
    close() {
      this.infoModalOpen = false;
    },
    async updateSelectedFilters() {
      // Ensure getBrowse call in created finishes first
      await this.$wait(500)
      await this.setSelectedFiltersAndOptions(this.$route.query);
      await this.search();
      // Ensure spinners show until browse & search results are retrieved
      // On mounting, search will start firing before getBrowse finishes
      if (!!this.isMounting) this.isMounting = false
    }
  },
  async created() {
    await this.getFilters();
    if (!this.id) {
      await this.navigateToHome({ replace: true, router: this.router });
    } else {
      let setId = await getRouteParamId(this.id)
      await this.getBrowse({ id: setId, router: this.router });
    }
  },
  mounted() {
    this.router = useRouter()
    this.windowWidth = window.innerWidth;
    this.$root.$on('windowResize', () => this.windowWidth = window.innerWidth);
  },
  beforeRouteLeave(to) {
    // When navigating to Asset,
    // Content.vue will dispatch callSearchTracker & clear the id
    if (to.name !== "Asset") {
      this.$store.dispatch("browse/clearSearchTrackingId")
    }
  }
};
</script>

<style scoped>
.section {
  flex: 1;
  display: flex;
  flex-flow: column;
  padding: .5rem 0 0 0;
}
.title {
  padding-left: .5rem;
}
.browse-results {
  flex: 1;
  overflow: auto;
  border-top: lightgray 1px solid;
  border-bottom: lightgray 1px solid;
}
.browse-results-loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-top: lightgray 1px solid;
  border-bottom: lightgray 1px solid;
}
</style>
