/* eslint-disable max-len */
module.exports =
{
  aOfB: '{a} / {b}',
  about: 'Hakkımızda',
  aboutThisItem: 'Bu Öge hakkında',
  accessApiKeyManager: 'Bu kiracı için Uygulama Programı Arabirimi Anahtarı Yöneticisi’ne erişin.',
  accessControls: 'Erişim Kontrolleri',
  accessControlsCopiedText: 'Erişim kontrolleri anında güncellenir. Medya güvenlik ayrıcalıkları birkaç dakika içinde güncellenecek.',
  accessControlsCopiedTitle: 'Erişim kontrolleri başarıyla kopyalandı',
  accessControlsCopyConfirmation: 'Bu, mevcut tüm erişim kontrollerini {to} üzerinden kaldıracak ve bunları {from} içindeki erişim kontrolleriyle değiştirecektir. Bu işlem geri alınamaz.',
  accessControlsDescription: 'Erişim kontrolleri, seçilen Kuruluşlara ve Medya Kategorilerine göre bu medyaları kimlerin görebileceğini tanımlar.',
  accessControlsIndeterminance: 'Not: Ana kuruluşların, alt kuruluşlarından herhangi birine atanan içeriğe erişimi her zaman vardır.',
  account: 'Hesap',
  accountErrorMessage: 'Hesap Hata Mesajı',
  accountErrorMessageWarning: 'Veri tabanındaki accountErrorMessage alanı, kiracı için “Yetkisiz” olarak ayarlanacaktır.',
  action: 'Aksiyon | Aksiyonlar',
  actionDelete: 'sil',
  actionLoad: 'yükle',
  actionPaste: 'Yapıştır',
  actionSave: 'Kaydet',
  actionRun: 'çalıştır',
  actionUndoWarning: 'Bu işlem geri alınamaz.',
  actionUpload: 'yükle',
  actualSize: 'Gerçek Boyut',
  add: 'Ekle',
  addAPIKey: 'Uygulama Programı Arabirimi Anahtarı Ekleyin',
  addItem: '{item} ekle',
  addABookText: 'Kitap ekle',
  addASharedCart: 'Paylaşılan sepet ekle',
  addATemplate: 'Şablon ekle',
  addATag: 'Etiket Ekle',
  addABookToStart: 'Başlamak için {addABookText}.',
  addAForm: 'Başlamak için bir Form ekleyin',
  addAFormOnly: 'Form Ekle',
  addAMedia: 'Medya ekle',
  addAnOrderQuoteEmailFormat: 'Başlamak için Sipariş/Teklif',
  addAPrintSettingText: 'Yazdırma Ayarı Ekle',
  addAPrintSettingToStart: 'Başlamak için {addAPrintSettingText}.',
  addBooks: 'Kitapları Ekle',
  addChildBrowseFlow: 'Alt Kuruluş Göz Atma Akışı Ekle',
  addChapter: 'Bölüm Ekle',
  addComment: 'Yorum ekle',
  addExistingPart: 'Mevcut Parça Ekle',
  addGroupsToUsers: '{users} kullanıcılarına {groups} grupları ekle',
  additionalInformation: 'Ek Bilgi',
  additionalNotification: 'Ek Bildirim',
  additionalPartNumberDescription: 'Ek parça numarası alanı olarak kullan',
  addBrowseFlow: 'Göz Atma Akışı Ekle',
  addMedia: 'Medya Ekle',
  addPages: 'Sayfa Ekle',
  addPart: 'Parça Ekle',
  addReports: 'Rapor Ekle',
  addRootBrowseFlow: 'Kök Göz Atma Akışı Ekle',
  addRow: 'Satır Ekle',
  addTemplates: 'Şablonları ekle',
  addToCartMessage: '{partNumber} bir başarılı şekilde eklendi',
  addToUserGroup: 'Gruplara Ekle',
  address: 'Adres | Adresler',
  addressInUse: 'Bir adres şu anda varsayılan adres olarak ayarlı ve silinemiyor',
  addressInUseSubtitle: 'Bu adresi silmek için önce bu adresi varsayılan adres olarak silmelisiniz.',
  addressLine: 'Adres Hattı {number}',
  addWidgetKey: 'Bir Widget Anahtarı ekleyin',
  admin: 'Yönetici',
  adminCenter: 'Yönetici Merkezi',
adminCenterBanner: {
    bodyLabel: 'Kapak Gövde Metni',
    enableLabel: 'Yönetici Kapağını Etkinleştir',
    headerGreenOption: 'Yeşil',
    headerLabel: 'Kapak Başlığı',
    headerRedOption: 'Kırmızı',
    headerYellowOption: 'Sarı',
    title: 'Yönetici Merkezi Kapağı',
    typeLabel: 'Kapak Türü'
  },
  adminCenterMessage: 'İçerik, kullanıcılar, kitaplık ayarları, raporlama ve dışa aktarma için yönetim merkezi',
  adminMessage: 'Kullanılabilen özellikler eklenir',
  administration: 'Yönetim',
  affectedBookMedia: '{count} Kitap Medyası güncellenecek.',
  affectedTemplateMedia: '{count} Kitap Şablonu güncellenecek.',
  after: 'Sonra',
  aggregatedFromChildren: 'Alt Kuruluşlardan Toplanmış',
  aiAlgorithm: 'AI Tarayıcısı',
  all: 'Tümü',
  alphanumeric: 'Alfasayısal',
  algorithm: 'Algoritma',
  alignHorizontal: 'Yatay Hizala',
  alignVertical: 'Dikey Hizala',
  allUsersDisabled: 'Kiracıya ait tüm kullanıcılar devre dışı bırakılacaktır.',
  analyticsJSON: 'Analiz JSON‘u',
  andCountMore: 've {count} adet daha...',
  any: 'Herhangi Biri',
  apiKey: 'Uygulama Programı Arabirimi Anahtarı | Uygulama Programı Arabirimi Anahtarları',
  apiKeyAdmin: 'API Anahtarı Yöneticisi',
  apiAuthHeader: 'API Yetkilendirme Başlığı',
  apiKeyCopied: 'API Yetkilendirme Başlığı değeri Panoya kopyalandı',
  apiKeyDeleted: 'Uygulama Programı Arabirimi Anahtarı Silindi',
  apiKeyDeletedError: 'Uygulama Programı Arabirimi Anahtarı silinirken hata oluştu',
  apiKeyDeleteMsg: 'Bu Uygulama Programı Arabirimi Anahtarının silinmesi entegrasyonlar tarafından kullanılmasını devre dışı bırakır',
  apiKeyDeleteTitle: 'Bu Uygulama Programı Arabirimi Anahtarını silmek istediğinize emin misiniz?',
  apiKeyInstructions: 'Anahtar Kaydedildiğinde oluşturulacak',
  apiKeyManager: 'Uygulama Programı Arabirimi Anahtarı Yöneticisi',
  apiKeyNameEmpty: 'Ad boş olamaz',
  apiKeyRegenError: 'Uygulama Programı Arabirimi Anahtarı yeniden oluşturulurken hata oluştu',
  apiKeyRegenMsg: 'Bu Uygulama Programı Arabirimi Anahtarının yeniden oluşturulması mevcut entegrasyonları devre dışı bırakabilir.',
  apiKeyRegenTitle: 'Bu Uygulama Programı Arabirimi Anahtarını yeniden oluşturmak istediğinize emin misiniz?',
  apiKeySaved: 'Uygulama Programı Arabirimi Anahtarı Kaydedildi',
  apiKeyType: 'Uygulama Programı Arabirimi Anahtarı Türü',
  appliesTo: 'Geçerli',
  appliesToEmpty: 'En az bir varlık türünün seçilmesi gerekiyor',
  appliesToPopulated: 'Etiketin hangi varlıklara uygulanabileceğini ayarlayın',
  applyItem: '{item} uygula',
  applyToChildItems: 'Alt Kuruluş için Uygula {item}',
  applyToPartOnly: 'Yalnızca bu parçaya uygula',
  april: 'Nisan',
  area: 'Alan',
  areYouSure: '{item} ögesini silmek istediğinizden emin misiniz?',
  areYouSureCopy: '{item} ögesini kopyalamak istediğinizden emin misiniz?',
  areYouSureYouWantToDisableTheTenant: 'Kiracıyı devre dışı bırakmak istediğinizden emin misiniz?',
  assembly: 'Kurulum | Kurulumlar',
  assemblyCreatorSuccessTitle: 'Gönderilen derleme işini oluştur',
  assemblyCreatorSuccessSubtitle: 'Hotpoint bağlantıları ve kitabın içerik tablosu değişiklikleri hemen yapılacaktır. Dizinleme tamamlandığında arama sonuçları yansıtılacaktır.',
  assemblyConfirmationTitle: 'Kurulum oluşturmayı tamamlamak istiyor musunuz?',
  assemblyCreatorHelpText1: 'Bu özellik, seçilen bir sayfayı, bu parçanın {partName} bulunduğu her yerde kitaplara eklemenizi sağlar ve parçayı ve seçilen sayfayı bir hotpoint bağlantısı üzerinden otomatik olarak birbirine bağlar. İsteğe bağlı olarak, eklemeyi yalnızca seçili kitaplarla sınırlayabilirsiniz.',
  assemblyCreatorHelpText2: 'Seçilen sayfa, ilk {partName} oluşumundan sonra kitap başına bir kez eklenir.',
  assemblyStepOne: '1. Adım: Eklenecek sayfayı seçin',
  assemblyStepTwo: '2. Adım: Sayfanın eklenmesini istediğiniz kitapları seçin',
  asset: 'Varlık | Varlıklar',
  assetsNotFound: '{type} Bulunamadı',
  assignBrowseFlow: 'Göz Atma Akışı Ata',
  assignedOrganizations: 'Atanmış Kuruluşlar',
  atLeastFiveError: 'Lütfen 5‘ten az olmayan bir değer seçin.',
  attachment: 'Ek | Ekler',
  attachmentEditorDescription: 'Resimleri, videoları ve belgeleri doğrudan bu varlığa ekleyin. Bunları "İlgili" sekmesinde bulabileceğinizi unutmayın.',
  attachmentView: 'Ekleri Görüntüle',
  attachRelatedPDFs: 'İlgili parça sayfalarının PDF’lerini ekle',
  attributions: 'Özellikler',
  august: 'Ağustos',
  automaticZoom: 'Otomatik Yakınlaştırma',
  autoHotpoint: 'Otomatik Etkin Nokta',
  autoPopulate: 'Otomatik Doldurma',
  availability: 'Kullanılabilirlik',
  back: 'Geri',
  backCover: 'Arka Kapak',
  backStitch: 'Arka Dikiş',
  backToLibrary: 'Kütüphaneye Geri Dön',
  backToSearch: 'Aramaya Geri Dön',
  badCredentials: 'Hatalı Kimlik Bilgileri',
  bahasaDescription: 'Bahasa Endonezce Açıklama',
  bahasaName: 'Bahasa Endonezce Ad',
  banner: 'Banner',
  before: 'Önce',
  beginTypingToFindAnything: 'Arama yapmak için yazmaya başlayın',
  betaAccessKeys: 'Beta Erişim Anahtarları',
  betaResources: 'Beta Kaynakları',
  betaReleaseVideo: 'Beta Sürüm Videosu',
  blobFinder: 'BLOB Sorgulayıcı',
  body: 'Gövde',
  bomDefaultSupplier: 'BOM Varsayılan Tedarikçi',
  bomItem: 'BOM Öğesi',
  bom: 'BOM',
  bomExportSuccess: 'BOM Dışa Aktarma Başarılı',
  bomLayout: 'Malzeme Listesi Planı',
  bomListOptions: 'Malzeme Listesi Seçenekleri',
  bomListSettings: 'Malzeme Listesi Ayarları',
  both: 'Her İkisi',
  book: 'Kitap | Kitaplar',
  bookInserts: 'Kitap Eklemeleri',
  bookLabel: 'Kitap | Kitaplar',
  bookPrintingDisable: 'Kitap Yazdırmayı Devre Dışı Bırak',
  booksAndPagesDisplay: 'Kitaplar ve Sayfa Görünümü',
  boost: 'Arama Artırma',
  boostDescription: 'Bu etiketin arama uygunluğunu parça numaraları, medya tanımlayıcıları ve isimler üzerinden artırmak için 1,5`in üzerinde bir değer ekleyin',
  boostRangeInvalid: 'Takviye 0,5 ila 100,0 arasında olmalıdır',
  border: 'Kenarlık',
  bottom: 'Alt',
  branding: 'Markalaşma',
  browse: 'Göz At',
  browseComputer: 'Bilgisayara Göz At',
  browseFlow: 'Akışa Göz At | Akışlara Göz At',
  browseFlowAssigned: 'Göz Atma Akışı Atandı',
  browseFlowCopyMessage: 'Yeni Göz Atma Akışı için benzersiz bir ad girin.',
  browseFlowDeleted: 'Göz Atma Akışı Silindi',
  browseFlowDeleteMessage: 'Bir göz atma akışı silindiğinde, yalnızca göz atma akışı değil, tüm alt göz atma akışları da silinir.',
  browseFlowEmptyState: 'Tanımlanmış Göz Atma Akışı yok. Başlamak için {new}.',
  browseFlowHeirarchy: 'Göz Atma Akışı Hiyerarşisi',
  browseFlowNoExistingCriteria: 'Bu göz atma akışı için herhangi bir arama kriteri belirlenmedi. Arama kriterlerini seçmek için yukarıdaki kontrolleri kullanın.',
  browseFlowMoveToRoot: 'Birinci seviye alt kuruluş yapmak için düğümü buraya bırakın',
  browseFlowNoAssignedOrgs: 'Atanan kuruluş yok',
  browseFlowSaveFailed: 'Göz Atma Akışı güncellenemedi',
  browseFlowSetDefaultSuccess: 'Varsayılan Göz Atma Akışı seti',
  browseFlowThumbnailDeleteFailTitle: 'Küçük Resim Silinemedi',
  browseFlowThumbnailDeleteSuccessTitle: 'Küçük Resim Silme Başarılı',
  browseFlowThumbnailUploadFailTitle: 'Küçük Resim Yüklenemedi',
  browseFlowThumbnailUploadSuccessTitle: 'Küçük Resim Yükleme Başarılı',
  browseFlowTooManyOrgs: 'Mevcut göz atma akışı düzenleyicisini kullanamayacak kadar çok kuruluşunuz var. Lütfen Documoto göz atma akışlarınızı düzenleme konusunda yardım için {email} adresinden Documoto Destek Merkezi ile iletişime geçin.',
  browserPartiallySupport: 'Bu tarayıcıda estetik farklılıklar ve başka değişiklikler görebilirsiniz.',
  browseFlowSaved: 'Göz Atma Akışı kaydedildi',
  browserDeprecation: {
    title: 'Tarayıcının Kullanımdan Kaldırılma Bildirimi',
    text: 'Microsoft`un Internet Explorer (tüm sürümler) desteğini aşamalı olarak kaldırması ve ilgili güvenlik endişeleri doğrultusunda, 30 Eylül 2021`den sonra kullanıcıların Internet Explorer (tüm sürümler) aracılığıyla bu siteye erişimini artık destekleyemeyeceğiz.'
  },
  buildHotpointLinks: 'Hotpoint Bağlantıları Oluşturun',
  buildHotpointLinksInfo: 'Bu eylem önce tüm mevcut hotpoint bağlantılarını kitabın sayfalarından kaldıracak, ardından parçalar ve eşleşen tüm kurulum sayfaları arasında yeni hotpoint bağlantıları oluşturacaktır.',
  buildHotpointLinksTitle: 'Bu kitap üzerinde hotpoint bağlantıları oluşturmak istiyor musunuz?',
  bulkIndexingLane: 'Toplu Dizinleme Yolu',
  bulkInsert: 'Toplu Ekleme',
  bulkExportConfirm: '{n} {unit} ögesini dışa aktarmak istiyor musunuz?',
  bulkExportLimit: 'Sınır, dışa aktarılan iş başına {limit} kitaptır',
  bulkPageInsertJob: 'Toplu Sayfa Ekleme',
  business: 'İşletme',
  cancel: 'İptal',
  canceled: 'İptal Edildi',
  cancelAssemblyCreatorTitle: 'Derlemeyi iptal et?',
  cancelAssemblyCreatorSubtitle: 'Derleme oluşturma işleme alınmayacaktır.',
  cannotExceed100: '%100‘ü geçemez',
  cart: 'Sepet | Sepetler',
  cartBehaviorEnabled: 'Sepete Ekle Miktar İstemini Etkinleştir',
  cartBehaviorDisabled: 'Sepete Ekle Miktar İstemini Devre Dışı Bırak',
  cartBehaviorLabel: 'Sepete Ekle Miktarı Davranışı',
  cartColumn: 'Sepet Sütunu | Sepet Sütunları',
  cartColumn1: '1. Alışveriş Sepeti Fiyat Sütunu',
  cartColumn2: '2. Alışveriş Sepeti Fiyat Sütunu',
  cartColumn3: '3. Alışveriş Sepeti Fiyat Sütunu',
  cartColumnPartLocationContent1: 'Bu özelliği etkinleştirmenin aşağıdaki sonuçları olacaktır:',
  cartColumnPartLocationContent2: 'Alışveriş sepetindeki parça satırı benzersizliği, varsa bir içerik içerisindeki konumuna da bağlı olacaktır.',
  cartColumnPartLocationContent3: 'Bu ayar kuruluş düzeyinde devre dışı bırakılamaz ve tüm kullanıcılar için geçerli olacaktır.',
  cartColumnPartLocationContent4: 'Sepet Gönderme entegrasyonunuz varsa bu ayar öncelikle Documoto Müşteri Başarı Yöneticinizle iletişim kurmadan etkinleştirilmemelidir.',
  cartColumnPartLocationOption: 'Sepetteki Parça-Konum Verilerini Etkinleştir',
  cartColumnPartLocationTitle: 'Sepetteki Parça-Konum Verilerini etkinleştirmek istediğinizden emin misiniz?',
  cartDisable: 'Alışveriş Sepetini Devre Dışı Bırak',
  cartEmailFailed: 'Sepet E-postası gönderilemedi',
  cartEmailSent: 'Sepet E-postası gönderildi',
  cartEnable: 'Alışveriş Sepetini Etkinleştir',
  cartSaveEnable: 'Alışveriş Sepetini Kaydetmeyi Etkinleştir',
  cartErpMessage: 'Erp’ye başarılı şekilde gönderildi',
  cartImportError: 'Sepet içe aktarılırken hata oluştu',
  cartLoadedWithItemsRemoved: '{count} öğe, şu anda bu öğeleri sipariş etme izniniz olmadığından kaldırıldı',
  cartSharedAddEdit: 'Paylaşılan Sepetleri Ekle / Düzenle',
  cartUpdated: 'Sepet Güncellendi',
  cartUpdatedAndRemovedCount: 'Sepet Güncellendi; sipariş verilemeyen {count} parça silindi',
  category: 'Kategori | Kategoriler',
  categoryCreated: 'Kategori Oluşturuldu',
  categoryUpdated: 'Kategori Güncellendi',
  centimeter: 'Santimetre | Santimetre',
  changeNotification: 'Değişiklik Bildirimi',
  changeScope: '{n} {type} etkilenecek',
  changeUserOrganization: 'Kuruluşu Değiştir',
  chapter: 'Bölüm | Bölümler',
  chapterHeader: 'Bölüm Başlığı',
  chaptersOnly: 'Sadece Bölümler',
  chineseDescription: 'Çince Açıklama',
  chineseName: 'Çince Ad',
  circle: 'Daire',
  city: 'Şehir',
  classicAdminMessage: 'Bu, sizi yönetici modüllerini kullanmak üzere Flex’e geri yönlendirecek.',
  classicAdmins: 'Klasik Yöneticiler',
  classicExporter: 'Klasik Dışa Aktarıcı',
  classicExporterMessage: 'Eski Flash tabanlı içerik dışa aktarma modülü',
  classicOrganizationAdmin: 'Klasik Kuruluş Yöneticisi',
  classicOrganizationAdminMessage: 'Kuruluşları ve kullanıcıları yönetmek için eski Flash Tabanlı kullanıcı yönetimi modülü',
  classicPublisher: 'Klasik Yayıncı',
  classicPublisherMessage: 'İçerik oluşturma ve yazdırma ayarları için eski Flash Tabanlı yayıncı modülü',
  classicReporting: 'Klasik Raporlama',
  classicReportingMessage: 'Eski Flash Tabanlı raporlama modülü',
  classicTenantAdmin: 'Klasik Kiracı Yöneticisi',
  classicTenantAdminMessage: 'Kiracı çapında ayarlar için eski Flash Tabanlı yönetim modülü',
  className: 'Sınıf Adı',
  clear: 'Temizle',
  clearItemNumber: 'Öge Numarasını Temizle',
  clearItemNumberTitle: 'Öge numarasını temizlemek istiyor musunuz?',
  clearItemNumberMessage: '"Öge numarasının silinmesi aşağıdaki verilerin silinmesine neden olacaktır:',
  clearFilters: 'Filtreleri Temizle',
  clearHotpoints: 'Hotpointleri temizle',
  clearSearch: 'Aramayı Temizle',
  clickHereToUpload: 'Dosyayı sürükleyin veya yenisini yüklemek için tıklayın.',
  clickMagnifyingGlass: 'Doğrudan Gelişmiş Arama seçeneğine gitmek için büyütece tıklayın.',
  clickOkToContinue: 'Devam etmek için "Tamam"´a tıklayın.',
  clickToDownload: 'İndirmek İçin Tıklayın',
  clickToEnlarge: 'Büyütmek İçin Tıklayın',
  clickToOpen: 'Açmak İçin Tıklayın',
  clipboard: 'Pano',
  clipboardAccessError: 'Lütfen tarayıcı ayarlarınızı Pano erişimine izin verecek şekilde ayarlayın',
  close: 'Kapat',
  closed: 'Kapatıldı',
  collapseAll: 'Tümünü Daralt',
  color: 'Renk | Renkler',
  column: 'Sütun | Sütunlar',
  columnName: 'Sütun Adı',
  columnItemType: {
    customText: 'Özel Metin',
    documentTitle: 'Doküman Başlığı',
    horizontalLine: 'Yatay Satır',
    pageName: 'Sayfa Adı',
    pageNumber: 'Sayfa Sayısı',
    printDate: 'Yazdırma Tarihi',
    tenantName: 'Kiracı Adı',
    userName: 'Kullanıcı Adı'
  },
  combinedPage: 'Birleşik Sayfa',
  combinedPageMessage: 'Diyagram üstte, parçalar listesi altta',
  comingSoon: 'Yakında Geliyor',
  commaDelimited: 'Virgülle sınırlandırılmış',
  comment: 'Yorum | Yorumlar',
  commentAdded: 'Yorum eklendi',
  commentDeleted: 'Yorum silindi',
  commentEdit: 'Yorum Ekle / Düzenle',
  commentEditAll: 'Tüm Yorumları Düzenle',
  commentUpdated: 'Yorum güncellendi',
  commentView: 'Yorumları Görüntüle',
  commerceSystem: 'Ticaret Sistemi',
  completed: 'Tamamlandı',
  confirm: 'Onayla',
  confirmBrowseFlowApplyToChildren: 'Bu göz atma akışını alt kuruluşlara uygulamak istiyor musunuz?',
  confirmBrowseFlowOverrideToChildren: 'Bu, alt kuruluşlarda ayarlanan, geçersiz kılınmış tüm göz atma akışlarını kaldıracaktır.',
  confirmPrintSettingsApplyToChildren: 'Bu yazdırma ayarını alt kuruluşlara uygulamak istiyor musunuz?',
  confirmPrintSettingsOverrideToChildren: 'Bu, alt kuruluşlarda ayarlanan, geçersiz kılınmış tüm yazdırma ayarlarını kaldıracaktır.',
  contains: 'İçinde geçen',
  containsHeaderRow: 'Üstbilgi Satırını İçerir',
  content: 'İçerik | İçerikler',
  contentAccessControls: '{content} Erişim Kontrolleri',
  contentEntity: 'İçerik Varlığı | İçerik Varlıkları',
  contentNotFound: 'İçerik bulunamadı',
  contentUpdatedQueueJob: 'İçerik Güncelleme Kuyruğu',
  contourShape: 'Şeklin Hatlarını Belirle',
  convertEntity: '{entity} varlığını dönüştür',
  convertToEntity: '{entity} varlığına dönüştür',
  copyAccessControls: 'Erişim Kontrollerini Kopyala',
  copyAccessControlsWarning: 'Bu, atanan tüm erişim kontrollerini, seçilen bir Kuruluştan bu mevcut Kuruluşa kopyalayacaktır',
  copy: 'Kopyala',
  copyAndRetain: 'Entegrasyon Kullanımı İçin Kopyalayın ve Saklayın',
  copyAndRetainWidget: 'Kopyala ve Sakla, Widget Yetkilendirme değerinin uygulamada size görüneceği tek andır',
  copyAtoB: '{a} ögesini {b} ögesine kopyala',
  copyContent: '{type} kopyala? {name}',
  copyingItem: '{item} kopyalanıyor',
  copyItem: '{item} ögesini kopyala',
  copyItemQuestion: '{item} ögesini kopyala?',
  copyTo: '{target} hedefine kopyala',
  copyMeMessage: 'Bu ileti için beni bilgiye ekle',
  copyrightMessage: '© 2010-{year} Documoto Inc. Tüm hakları saklıdır.',
  count: 'Miktar',
  country: 'Ülke | Ülkeler',
  coverPages: 'Kapak Sayfaları',
  coverAmpStitchPages: 'Kapak ve Dikiş Sayfaları',
  createAssembly: 'Kurulum Oluştur',
  created: 'Oluşturuldu',
  createdBy: '{name} tarafından oluşturuldu',
  createdDate: 'Oluşturulma Tarihi',
  createdOn: 'Oluşturulma tarihi',
  currentPassword: 'Mevcut Şifre',
  custom: 'Özel',
  czechDescription: 'Çekçe Açıklama',
  czechName: 'Çekçe Ad',
  dashboard: 'Gösterge Paneli | Gösterge Panelleri',
  dashboardLibrary: 'Pano Kitaplığı',
  dataInsights: 'Veri İçgörüleri',
  dataInsightsInfoMessage: 'İçerik, kullanıcı ve kitaplık veri içgörüleri için Raporlama ve Pano Merkezi.',
  dataMapping: 'Veri Eşleme/Örnek Veriler (en fazla 10 satır)',
  dataOptions: 'Veri Seçenekleri',
  date: 'Tarih',
  dateModified: 'Değiştirilme Tarihi',
  debugPageBuilderPLZ: 'Sayfa Oluşturucu PLZ‘de Hata Ayıkla',
  december: 'Aralık',
  decreaseHotpoint: 'Azaltma Etkin Noktası',
  default: 'Varsayılan',
  defaultAddress: 'Varsayılan Adres',
  defaultBrowseFlow: 'Varsayılan Göz Atma Akışı',
  defaultCurrencyCode: 'Varsayılan Para Birimi Kodu',
  defaultCurrencySymbolOn: 'Varsayılan Para Birimi Sembolü Açık',
  defaultEmailRecipient: 'Varsayılan E-posta Alıcısı | Varsayılan E-posta Alıcıları',
  defaultImage: 'Varsayılan Resim | Varsayılan Resimler',
  defaultImagesManager: 'Varsayılan Resim Yöneticisi',
  defaultItemImage: '{item} Varsayılan Resim',
  defaultPartsListLayout: 'Varsayılan Parça Listesi Düzeni',
  defaultPartsListLayoutHelp: 'Resmin solunda veya altında varsayılan parça düzeni',
  defaultSearchMode: 'Varsayılan Arama Modu',
  defaultSearchModeHelp: 'Varsayılan genel arama yöntemi \'içerir\' veya \'tam \'',
  defaultSortMode: 'Varsayılan Sıralama Modu',
  defaultSupplier: 'Varsayılan Tedarikçi',
  defaultTocStateOpen: 'Varsayılan İçindekiler Durumu Açık',
  defaultTocStateOpenHelp: 'Bir kitabı görüntülerken İçindekiler`in otomatik olarak açılıp açılmayacağını belirler',
  defaultUom: 'Varsayılan UOM',
  defaultValue: 'Öndeğer',
  defaults: 'Varsayılanlar',
  defaultsAndCapabilities: 'Varsayılanlar ve Yetenekler',
  delete: 'Sil',
  deleteAll: 'Ana Kuruluştan Devral',
  deleteAnyPartnerApiKeys: 'SmartEquip için sağlanmış olan herhangi bir Partner Uygulama Programı Arabirimi Anahtarını silin.',
  deleteContent: '{type} ögesini silmek istediğinizden emin misiniz? {name}',
deletePart: {
    infoOne: 'Parça silmeleri, Kütüphane ve İçerik Yöneticisi içinde anında gerçekleşir. Silinen parçalar, birkaç dakika içinde tekrar dizinlenene kadar aranabilir kalır.',
    deletingPartNumber: '{partNumber} silinecek.',
    affectedCarts: 'Bu parçayı içeren tüm sepetlerden parça kalıcı olarak kaldırılacak.',
    infoTwo: 'Bu eylem, Documoto Destek yardımı olmadan geri alınamaz. 90 gün sonra silinen kayıt ve tüm bileşenleri, kalıcı olarak silinir.'
  },
deletePartInUse: {
    title: 'Bu parça sayfaya ait ve silinemez.',
    body: 'Documoto şu an yalnızca sayfalara ait olmayan parçaların silinmesini destekliyor. Silme işlemine devam etmek için lütfen bu parçayı yayınlanmış veya taslak sayfalardan kaldırın.',
    submitLabel: 'Kullanıldığı Sayfaya Git'
  },
  deleteRelated: ' {count} {type} ögesini silmek istediğinizden emin misiniz?',
  deletedItem: '{item} silindi',
  deleteFilesMessage: '{count} dosyayı silmek istediğinizden emin misiniz?',
  deleteHotpointLinksTitle: 'Etkin nokta bağlantılarını silmek istediğinizden emin misiniz?',
  deleteMessage: 'Silmek istediğinizden emin misiniz?',
  deleteSelectedCriteria: 'Seçilen Kriterleri Sil',
  deleteTagsTitle: 'Etiketleri silmek istediğinizden emin misiniz?',
  deleteTaxonMessage: 'Bu, alt kuruluşları ile birlikte bu taksonu kalıcı olarak silecek. `Devam etmek istediğinizden emin misiniz?',
  deleteTaxonomyMessage: 'Bu, bu Kuruluş veya Kiracı için tüm taksonları kalıcı olarak silecek. Devam etmek istediğinizden emin misiniz?',
  deleteTenantSupplier: 'Kiracı için bu sağlayıcıyı silmek istediğinizden emin misiniz?',
  demo: 'Demo',
  description: 'Açıklama',
  deselectAll: 'Tüm Seçimleri Kaldır',
  deselectCriteria: 'Kriter Seçimini Kaldır',
  detail: 'Ayrıntı | Ayrıntılar',
  diagonal: 'Diyagonal',
  diameter: 'Çap',
  disable: 'Devre Dışı Bırak',
  disableAll: 'Tümünü Devre Dışı Bırak',
  disableDocumotoTenant: 'Documoto Kiracısını Devre Dışı Bırak',
  disableFwdReindex: 'DSW için Yönlendirme Yeniden Dizinleme Hedef Medyasını Devre Dışı Bırak',
  disableLibraryBannerImage: 'Kitaplık Başlığı Resmini Devre Dışı Bırak',
  disableSmartEquipModalTitle: 'Bu lisansı devre dışı bırakmak istediğinizden emin misiniz?',
  disableTEK: 'Doğrudan URL ile Oturum Açmayı Devre Dışı Bırak',
  disabled: 'Devre Dışı Bırakıldı',
  discard: 'Göz Ardı Et',
  discardChanges: 'Değişiklikleri göz ardı et',
  discardQ: 'Değişiklikler göz ardı edilsin mi?',
  discounted: 'İndirimli',
  discountedPrice: 'İndirimli Fiyat',
  discountedTotal: 'Toplam İndirim',
  discountPrice: 'İndirim Fiyatı',
  displayInOrdersList: 'Sipariş Listesinde Görüntüle',
  displayOrder: 'Görüntüleme Sırası',
  displayOrderDescription: 'İçerik türü etiketleri için Filtreler açılır menüsünün görüntülenme sırasını ayarlar',
  document: 'Doküman| Dokümanlar',
  documentLabel: 'Doküman| Dokümanlar',
  documentSettings: 'Doküman Ayarları',
  documotoAcademy: 'Documoto Academy',
  documotoLicensePackage: 'Documoto Lisans Paketi',
  domain: 'Etki Alanı',
  domainEmptyMessage: 'İzin Verilen Etki Alanı boş olamaz',
  domainInvalidMessage: 'Geçerli bir etki alanı adı gereklidir',
  download: 'İndir',
  downloadBrowser: 'Bu büyük tarayıcılardan birini indirin.',
  downloadFailed: 'İndirme hatası {name}',
  downloadStarted: 'Şunun için indirme devam ediyor: {name}',
  draft: 'Taslak | Taslaklar',
  draftBomDeleteTitle: 'Bu parçayı sayfadan silmek istediğinizden emin misiniz?',
  draftBomMultipleDeleteTitle: 'Bu kısımları sayfadan silmek istediğinizden emin misiniz?',
  draftBomDropError: 'Parçalar 5 düzeyden daha derin iç içe yerleştirilemez',
  draftBomEmptyField: 'Parçalar Listesi’ndeki tüm alanlar tamamlanmalıdır',
  draftBomQuantityError: 'Miktar bir sayı olmalıdır',
  draftBomError: 'Malzeme Listesi değiştirilirken hata oluştu',
  draftPage: 'Taslak Sayfa',
  draftPageDelete: 'Taslağı Sil',
  draftPageDeleteMessage: 'Taslak sayfa başarıyla silindi.',
  draftPageDeleteWarning: 'Sayfa taslağını silmek devam eden güncellemeleri kaldırır.',
  draftPageLastUpdated: 'Taslak - Son Güncelleme: {date} Güncelleyen: {username}',
  draftPageLoadError: 'Taslak sayfa yüklenemedi.',
  draftPageProcessingPlz: 'PLZ dosyasından yüklenmekte olan sayfa',
  draftPagePublishInProgress: 'Sayfa Yayımlama İşi devam ediyor',
  draftPageSaveSuccess: 'Taslak sayfa başarıyla kaydedildi.',
  draftPageSaveUnsuccessful: 'Taslak sayfa kaydedilemedi.',
  draftSheetImageHotpointsFailure: 'Taslak sayfa görüntüsü için {algorithm} hotpointleri oluşturulamıyor.',
  draftValidationMessage: {
    item: 'UYARI: BOM Parça Numarası {partNumber} için Öge Numarası belirtilmemiş',
    empty: 'Doğrulama Hatası/Uyarısı Yok',
    hotpointOrHotpointLink: 'Malzeme Listesinin {item} öğesinin etkin noktaları/etkin nokta bağlantıları belirtilmemiş',
    quantity: 'Malzeme Listesinin {item} öğesinin miktarı belirtilmemiş',
    tag: 'Malzeme Listesinin {item} öğesinin {tagName} etiketinin değeri eksik',
    translationDescription: 'Malzeme Listesinin {item} öğesinin {lang} dilinde çevirisi eksik',
    uom: 'Malzeme Listesinin {item} öğesinin ölçü birimi belirtilmemiş',
    supersede: 'Bölüm {partNumber} değiştirilme nedeniyle {supersededPartNumber}‘nın yerini aldı.'
  },
  drawAutohotpointRegion: 'Boyut {shape} çizin',
  drawMinimumArea: 'Minimum alan çizin',
  dropFileMessage: 'Dosyayı buraya sürükleyin veya yüklemek için tıklayın.',
  duplicate: 'Çoğalt',
  duplicateSaveError: 'Tekrarlanan girdi. Kaydedilemedi.',
  dutchDescription: 'Hollandaca Açıklama',
  dutchName: 'Hollandaca Ad',
  dynamic: 'Dinamik',
  dynamicNaming: 'Dinamik Adlandırma',
  ecommerce: 'E-ticaret',
  ecommerceEmailFormatCreated: 'E-Ticaret E-posta Formatı oluşturuldu',
  ecommerceEmailFormatSaved: 'E-Ticaret E-posta Formatı kaydedildi',
  ecommerceLogo: 'E-ticaret Logosu',
  ecommerceUseExtOrder: 'E-ticaret Kullanımı Harici Sipariş Numarası',
  edit: 'Düzenle',
  editItem: '{item} düzenle',
  email: 'E-posta',
  emailFormat: 'E-posta Formatı',
  emailFormatTemplate: 'E-posta Biçim Şablonu | E-posta Biçim Şablonları',
  emailList: 'E-Posta Listesi',
  emailMessage: 'E-posta gerekli',
  emailRecipient: 'E-posta Alıcısı | E-posta Alıcıları',
  emailRecipientMessage: 'Sipariş durumu değiştiğinde bu e-posta adresleri bir e-posta alacak',
  emptyMessage: {
    orderSuggestions: 'Görünüşe göre bu bölüm için henüz Sipariş Önerisi yok.'
  },
  emptyBomState: 'Sayfa için hiç BOM Öğesi tanımlanmadı. Başlamak için +{add} öğesine tıklayın',
  enable: 'Etkinleştir',
  enable3d: '3D’yi Etkinleştir',
  enableAll: 'Tümünü Etkinleştir',
  enableAPIKeyAdmin: 'API Anahtar Yöneticisini Etkinleştir',
  enabled: 'Etkinleştirildi',
  enableCommerce: 'Ticareti Etkinleştir',
  enableEcommerceOrderManagement: 'Sipariş Yönetimini Etkinleştir',
  enableExporter: 'Dışa Aktarıcıyı Etkinleştir',
  enableExportToLinkOne: 'LinkOne‘a Dışa Aktarmayı Etkinleştir',
  enableExportToSmartEquip: 'SmartEquip‘e Dışa Aktarmayı Etkinleştir',
  enablePDFDownloadWarning: 'Büyük PDF Dosyası İndirme Uyarısını Etkinleştir',
  largePDFDownloadWarning: 'PDF dosyasının büyük boyutlu olması nedeniyle uygulama içerisinde yükleme ve görüntüleme işlemleri normalden daha uzun sürebilir. Dosya, görüntüleme amacıyla indirilirken lütfen bekleyin.',
  enableIframeSupport: 'iFrame Desteği’ni Etkinleştir',
  enableLibraryBetaFeatures: 'Kütüphane Beta Özellikleri ögesini etkinleştir',
  enablePerformanceLogging: 'Performans Günlüğünü Etkinleştir',
  enablePLZToDraft: 'Taslağa PLZ İşlemini Etkinleştir',
  enableQuickAddToCart: 'Sepete Hızlı Ekleme’yi Etkinleştir',
  enableQuickAddToCartHelpContext: 'Bu seçeneği \“Etkin\” olarak ayarladığınızda bu içerik için yukarıdaki içerik görüntüleyicileri üzerinde yer alan Sepete Hızlı Ekleme özelliği etkinleştirilecektir.',
  enableRocketUi: 'Rocket Kullanıcı Deneyimini Etkinleştir',
  enableSmartEquipApiIntegration: 'SmartEquip Uygulama Programı Arabirimi Entegrasyonunu Etkinleştir',
  enableWidgetLogo: 'Documoto Widget Logosu ögesini etkinleştir',
  enableWidgets: 'Widgetlar ögesini etkinleştir',
  encryptedKey: 'Şifreli Anahtar',
  endDate: 'Bitiş Tarihi',
  endDateBad: 'Başlama tarihi, bitiş tarihiyle aynı veya daha önce olmalı.',
  enforceNumericSize: 'Sayısal Boyutu Zorla',
  englishDescription: 'İngilizce Açıklama',
  englishName: 'İngilizce Ad',
  enterprise: 'Kurumsal',
  enterReportParameters: 'Rapor Parametrelerini Gir',
  enterUniqueNameForNewItem: 'Yeni {item} için benzersiz bir ad girin',
  enterValidNumber: 'Lütfen geçerli bir numara girin',
  enterValidEmail: 'Lütfen geçerli bir e-posta girin',
  entityType: 'Varlık Türü | Varlık Türleri',
  erp: 'ERP',
  erpEndpointConfig: 'ERP Uç Nokta Yapılandırması',
  erpPartInfo: 'ERP Parça Bilgisi Önbellek Süresi Dakika',
  erpSystem: 'ERP Sistemi',
  erpSystems: 'ERP Sistemleri',
  erpSystemSaved: 'ERP Sistemi Kaydedildi',
  error: 'Hata',
  errorDeletePageMessage: 'Sayfa silinirken bir hata oluştu.',
  errorInputParsing: 'Giriş dosyası ayrıştırılırken sorun oluştu.',
  errorPageMessage: 'Bir hata meydana geldi.',
  errorPageTitle: 'Bir sorun oluştu...',
  errorType: {
    error: 'Hata',
    warning: 'Uyarı'
  },
  essential: 'Temel Unsur | Temel Unsurlar',
  eta: 'ETA',
  etlJob: 'ETL',
  exact: 'Tam adı',
  excelFile: 'Excel Dosyası',
  expandAll: 'Tümünü Genişlet',
  export: 'Dışa Aktar',
  exportSearch: 'Aramayı Dışa Aktar',
  exportAsCsv: 'CSV Olarak Dışa Aktar (İçe Aktarma için Gerekli Format)',
  exportAsCSVDropdown: 'CSV Olarak Dışa Aktar',
  exportAsHtml: 'HTML olarak dışarı aktar',
  exportAsPdf: 'PDF olarak dışa aktar',
  exportAsPlz: 'PLZ olarak dışa aktar',
  exportAsXlsx: 'XLSX olarak dışa aktar',
  exportBasicPartInfo: 'Temel Parça Bilgilerini Dışa Aktar',
  exportCartToXlsxDisabled: 'Sepeti XLSX`e Aktarmayı Devre Dışı Bırak',
  exportCartToXlsxEnabled: 'Sepeti XLSX`e Aktarmayı Etkinleştir',
  exportDisplayedCartColumns: 'Gösterilen Sepet Sütunlarını Dışa Aktar',
  exportJob: 'Dışa Aktarma İşi',
  exportLane: 'Yolu Dışa Aktar',
  exportMessage: 'Dışa aktarma işi başarılı şekilde gönderildi.',
  exporter: 'Dışa Aktarıcı',
  exporterMessage: 'İçeriği dışa aktarmanızı sağlar',
  exportParts: 'Parçaları Dışarı Aktar',
  external: 'Harici | Hariciler',
  externalUrl: 'Harici URL',
  faceted: 'Yönlü',
  facetedTag: 'Filtre değerleri yönlü olmalıdır',
  facetedTagTooMany: 'Etiket 1.000 benzersiz etiket değeri sınırını aşıyor',
  failedAction: '{content} için {action} gerçekleştirilemedi.',
  failedProcessContentSql: 'Yetkilendirilmemiş metin nedeniyle İçeriği İşleme başarısız oldu. Lütfen desteklenen içeriği tekrar yükleyin.',
  failedToCopy: '{content} kopyalanamadı.',
  failedToDelete: '{content} silinemedi.',
  failedToLoad: '{content} yüklenemedi.',
  failedToSave: '{content} silinemedi.',
  failedToSubmitItem: '{item} gönderilemedi',
  failedToUpdate: '{content} güncellenemedi.',
  false: 'Yanlış',
  faq: 'SSS',
  favorites: 'Favoriler',
  favoritesLists: 'Favoriler Listesi',
  february: 'Şubat',
  feedbackForm: 'Geri Bildirim Gönder',
  feedbackFormMessage: 'Görmek istediğiniz özellikler için giriş yapın',
  fieldDefinition: 'Alan Tanımı',
  field: 'Alan | Alanlar',
  fieldName: 'Alan Adı',
  file: 'Dosya | Dosyalar',
  filename: 'Dosya Adı',
  filesSubmitted: 'Dosya(lar), işlenmek üzere kaydedildi',
  fileUpload: 'Dosya Yükleme',
  fill: 'Dolgu',
  filter: 'Filtre | Filtreler',
  filterOrgNameOnly: 'Filtreleyin, Org Name Only',
  filterOrgParentNames: 'Filter: Organizasyon ve Üst Adlar',
  filterable: 'Filtrelenebilir',
  filterableTag: 'Filtrelenebilir Etiket',
  filterBy: 'Filtreleme Kriteri',
  filterBoundsError: 'Filtre en az 3 veya daha fazla karakter içermelidir',
  finnishDescription: 'Fince Açıklama',
  finnishName: 'Fince Ad',
  firstName: 'Ad',
  firstNameEmpty: 'Ad boş bırakılamaz',
  fixedHotpointSize: 'Sabit Hotpoint Boyutu',
  font: 'Font | Fontlar',
  footer: 'Altbilgi',
  forgotPassword: 'Parolamı Unuttum',
  forgotPasswordMessage: 'Parolanızı sıfırlamak için gerekli talimatları içeren bir e-posta, sağladığınız kullanıcı adına gönderilecektir.',
  forgotPasswordSuccess: 'Parolanızı sıfırlamak için gerekli talimatları içeren bir e-posta, sağlanan kullanıcı adı geçerliyse dosyadaki adrese gönderilecektir.',
  form: 'Form | Formlar',
  format: 'Format',
  formattingOptions: 'Biçimlendirme Seçenekleri',
  formCount: 'Form Sayısı',
  formName: 'Form Adı',
  formType: 'Form Türü',
  freezeImage: 'Görüntüyü Dondur',
  frenchDescription: 'Fransızca Açıklama',
  frenchName: 'Fransızca Ad',
  fri: 'Cum',
  from: 'Kimden',
  fromDate: 'Başlangıç Tarihi',
  frontCover: 'Ön Kapak',
  frontStitch: 'Ön Dikiş',
  fullScreen: 'Tam Ekran',
  gdprFaqs: 'GDPR ile ilgili SSS',
  gdprMessage: 'Web sitemizin bu bölümünde oturum açarak gerekli çerezler kullandığımızı kabul etmiş oluyorsunuz. Bu çerezler, Hizmetlerimize gitmenizi sağlamak için gereklidir. Bu çerezler olmadan talep ettiğiniz hizmetleri sağlayamayız. Daha fazla bilgi için bkz. {policy}.',
  general: 'Genel',
  generalSearch: 'Genel Arama',
  generic: 'Genel',
  germanDescription: 'Almanca Açıklama',
  germanName: 'Almanca Ad',
  gotIt: 'Anladım',
  goTo: 'Şuraya Git:',
  goToHome: 'Ana Sayfaya Git',
  goToJobManager: 'İş Yöneticisine Git',
  goToSearch: 'Aramaya Git',
  hashKey: 'Kare Tuşu',
  headerAmpFooter: 'Üstbilgi ve Altbilgi',
  header: 'Başlık',
  help: 'Yardım',
  helpCenter: 'Yardım Merkezi',
  highlightDescription: 'Bu etiketle parça listesindeki parçaları vurgulayın',
  history: 'Geçmiş',
  home: 'Ana Sayfa',
  horizontal: 'Yatay',
  host: 'Etki Alanı',
  hotpoint: 'Hotpoint | Hotpoint’ler',
  hotpointCalloutBehavior: 'Hotpoint Belirtme Çizgisi Davranışı',
  hotpointLinksWillBeCreated: 'Hotpoint bağlantıları {count} {content} içinde oluşturulacak',
  hotpointLink: 'Etkin Nokta Bağlantısı | Etkin Nokta Bağlantıları',
  hotpointsGenerated: 'Görüntüde {count} önemli nokta bulundu.',
  hotpointShift: 'Etkin nokta (Shift H)',
  hourlyDWSRequestLimit: 'Saatlik DWS Talep Limiti',
  hourlyRESTRequestLimit: 'Saatlik REST Talep Limiti',
  htmlUiUserSwitchEnabled: 'HTML Kullanıcı Arayüzü Kullanıcı Anahtarı Etkin',
  hungarianDescription: 'Macarca Açıklama',
  hungarianName: 'Macarca Ad',
  icon: 'İkon',
  id: 'Kimlik',
  identifier: 'Tanımlayıcı',
  ignore: 'YOK SAY',
  ignoreAccessControls: 'Erişim Kontrollerini Yoksay',
  ignoreMatchingTranslations: 'İçeri aktarırken eşleşen yerel ayarlara sahip Çevirileri yoksay',
  illustration: 'Çizim | Çizimler',
  image: 'Resim| Resimler',
  imageDeleteMessage: 'Bu Sayfa Resmini silmek istediğinizden emin misiniz?',
  imageUploadFailure: 'Resim Karşıya Yüklenemedi',
  imageSaveFail: 'Resim Kaydedilemedi',
  imageOptimizer: 'Görüntü İyileştirici',
  imageSizeExceededTitle: 'En Fazla Resim Boyutu Aşıldı',
  imageSizeExceededMessage: 'Documoto‘da izin verilen maksimum resim boyutu {size} megabayttır. Karşıya yüklemeye çalıştığınız resim maksimum boyutu aştığı için yüklenemiyor. Lütfen bu sayfaya eklenecek resmi yeniden boyutlandırın.',
  imageDimensionsExceededMessage: 'Documoto’da en fazla resim boyutu 65,5 megapikseldir (8.191 X 8.191 piksel).  Karşıya yüklemeye çalıştığınız resim en büyük boyutu aştı ve yüklenemiyor. Documoto’nun resmi yeniden boyutlandırıp eklemesine izin vermek için "Yeniden Boyutlandır" seçeneğine veya resmi el ile düzenleyip eklemek için "İptal" seçeneğine tıklayın',
  import: 'İçe Aktar',
  importBOM: 'Malzeme Listesini İçeri Aktar',
  importBOMError: 'Örnek veriler işlenemedi, giriş kaynağını yeniden denetleyin',
  importParts: 'Parçaları İçeri Aktar',
  inCart: 'Sepette',
  inCartDescription: 'Sepette etiketleri görüntüle',
  includePagePartDetails: 'Sayfa Bölümü düzeyinde ayrıntıları dâhil et',
  includePartDetails: 'Bölüm düzeyinde ayrıntıları dâhil et',
  indexQueueJob: 'Dizin Kuyruğu',
  inPartsList: 'Parça Listesinde',
  inPartsListDescription: 'Etiketleri parça listesinde görüntüle',
  inputSource: 'Giriş Kaynağı',
  inch: 'İnç | İnç',
  includeComments: 'Yorumları Dahil Et',
  includeCommentsAuthorAndDate: 'Yorum Yazarını ve Tarihi Dahil Et',
  includeHeaderRow: 'Üst bilgi Satırını Dâhil Et',
  includeImages: 'Resimleri Dahil Et',
  includePagesIndex: 'Sayfa Endeksini Dahil Et',
  includePartsIndex: 'Parça Endeksini Dahil Et',
  includePartsList: 'Parça Listesini Dahil Et',
  includeSubChapters: 'Alt Bölümleri Dahil Et',
  indexLane: 'Dizin Yolu',
  useSequentialPageNumbering: 'Sıralı Sayfa Numaralandırma Kullan',
  hideBookInSearchAndRecent: 'Arama Sonuçlarında ve Son Eklenenlerdeki Kitapları Gizle',
  hideDuplicatePages: 'Yinelenen Sayfaları Gizle',
  hidePageInSearchAndRecent: 'Arama Sonuçlarında ve Son Eklenenlerdeki Sayfaları Gizle',
  hidePartInSearchAndRecent: 'Arama Sonuçlarında ve Son Eklenenlerdeki Parçaları Gizle',
  homePageSettings: 'Ana Sayfa Ayarları',
  hotpointZoomBehavior: 'Hotpoint Yakınlaştırma Hareketi',
  includeNewParts: 'Yalnızca "yeni" bölümleri dâhil et (Documoto‘da değil)',
  includeWatermark: 'Filigran dahil edilsin mi?',
  increaseHotpoint: 'Etkin Noktayı Artır',
  indexed: 'Endeksli',
  indexingConfiguration: 'Dizinleme Yapılandırması',
  indexIncludeTimestamp: 'Dizin Zaman Damgası İçerir',
  info: 'Bilgi',
  information: 'Bilgi',
  inherit: 'Devral',
  inheritedParent: 'Ana Kuruluştan Devralındı.',
  inputSpecification: 'Giriş Bildirimi',
  inputType: 'Girdi Türü',
  inputTypeListboxWarning: 'Yapılandırmaları sıralama, kaydetme işleminin ardından görüntülenecektir',
  integration: 'Entegrasyon | Entegrasyonlar ',
  integrationsAddPartToErpCart: '{action} Parçayı ERP Sepetine Ekle',
  integrationsAddToShoppingCartPricingFromErp: '{action} ERP`den Alışveriş Sepeti Fiyatlandırmasına Ekle',
  integrationsDisableErp: 'ERP`yi Devre Dışı Bırak',
  integrationsDoErpForQuotes: 'Teklifler İçin ERP Yap',
  integrationsEnableErp: 'ERP`yi Etkinleştir',
  integrationsEnableFetchPartInfoFromErp: 'ERP`den Parça Bilgilerini Getirmeyi Etkinleştir',
  integrationsEnableScopeErp: '{scope} ERP`yi etkinleştir',
  integrationsEnableSendShoppingCartToErp: 'Alışveriş Sepetini ERP`ye Göndermeyi Etkinleştir',
  integrationsEnableSyncPoAddressWithErp: 'ERP ile PO Adresini Senkronize Etmeyi Etkinleştir',
  integrationsErpAvailabilityMappedFrom: 'ERP Kullanılabilirliğinin Eşleştirildiği Yer',
  integrationsErpCurrencyCodeMappedFrom: 'ERP Para Birimi Kodunun Eşleştirildiği Yer',
  integrationsErpDiscountedPriceMappedFrom: 'ERP İndirimli Fiyatın Eşleştirildiği Yer',
  integrationsErpEtaMappedFrom: 'ERP ETA’nın Eşleştirildiği Yer',
  integrationsErpMappings: 'ERP Eşlemeleri',
  integrationsErpRetailPriceMappedFrom: 'ERP Perakende Fiyatının Eşleştirildiği Yer',
  integrationsErpWholesalePriceMappedFrom: 'ERP Toptan Satış Fiyatının Eşleştirildiği Yer',
  integrationsInvokeGetPartInfoFromClient: 'Müşteriden GetPartInfo Çağır',
  integrationsParseAddressForType: 'Tür İçin Adresi Ayrıştır',
  integrationsShoppingCartAndPartsTitle: 'Alışveriş Sepeti ve Parçaları',
  integrationsShoppingCartAvailability: '{action} Alışveriş Sepeti Kullanılabilirliği',
  integrationsShoppingCartEtaFromErp: '{action} ERP`den Alışveriş Sepeti ETA`sı',
  internalApiKey: 'Dahili Uygulama Programı Arabirimi Anahtarı',
  internalDescription: 'Dahili Açıklama',
  internalName: 'Dahili Adı',
  internalNameOptional: 'Etiket için tanımlayıcınız',
  internalNameRequired: 'Bir ad gerekli',
  invalidDecimals: 'Yalnızca iki ondalık basamağa izin verilir',
  invalidField: '{field} geçersiz',
  invalidFileType: 'Dosya türü desteklenmiyor. Desteklenen dosya uzantılarının tam listesi için Documoto Destek Merkezine bakın.',
  isSearchable: 'Aranabilir',
  insertDataField: 'Veri Alanı Ekle',
  isSystem: 'Sistemde',
  italianDescription: 'İtalyanca Açıklama',
  italianName: 'İtalyanca Ad',
  item: 'No.',
  itemAlt: 'Öge',
  itemCopied: '{item} kopyalandı',
  itemDeleted: '{item} silindi',
  itemInProgress: '{item} devam ediyor',
  itemInformation: '{item} bilgileri',
  itemMustBeLteItem: '{itemA}, {itemB} ögesine eşit veya ondan önce olmalıdır.',
  itemMustBeUnique: '{item} benzersiz olmalıdır',
  itemMustBeUniqueAndNotEmpty: '{item} benzersiz olmalıdır ve zorunludur',
  itemName: '{item} Adı',
  itemNotShown: '',
  itemSaved: '{item} kaydedildi',
  itemSpecification: 'Öğe Belirtimi',
  showChapterTOCForHiddenPages: 'Gizli Sayfalar İçin Bölüme Ait İçindekileri Göster',
  january: 'Ocak',
  japaneseDescription: 'Japonca Açıklama',
  japaneseName: 'Japonca Ad',
  job: 'İş | İşler',
  jobManager: 'İş Yöneticisi',
  jobManagerMessage: 'İşleri dışa aktarın, yayınlayın ve yazdırın/indirin',
  july: 'Temmuz',
  june: 'Haziran',
  koreanDescription: 'Korece Açıklama',
  koreanName: 'Korece Ad',
  label: 'Etiket | Etiketler',
  labelKey: 'Etiket Anahtarı',
  landscape: 'Yatay',
  laneConfiguration: 'Yol Yapılandırması',
  language: 'Dil',
  lastModifiedDate: 'Son Değiştirilme Tarihi',
  lastGenerated: 'En Son Oluşturulan',
  lastName: 'Soyadı',
  lastNameEmpty: 'Soyadı boş bırakılamaz',
  lastUpdatedOn: 'Son güncelleme tarihi',
  layout: 'Düzen',
  layoutOptions: 'Düzen Seçenekleri',
  ledger: 'Kayıt Defteri',
  left: 'Sol',
  legal: 'Yasal',
  letter: 'Harf',
  library: 'Kitaplık',
  libraryAdmin: 'Kitaplık Yöneticisi',
  libraryBannerLogo: 'Kitaplık Başlığı Logosu',
  librarySettings: 'Kitaplık Ayarları',
  librarySettingsDescription: 'Bu ayarlar çeşitli Kitaplık ögelerini ve seçeneklerini kontrol eder. Değişiklik yaparken dikkatli olun. Daha fazla bilgi için Documoto Hizmetleri ile iletişime geçin.',
  licenseConfiguration: 'Lisans Yapılandırması',
  line: 'Satır',
  lineTool: 'Çizgi Aracı',
  link: 'Bağlantı | Bağlantılar',
  listBox: 'Liste Kutusu',
  listBoxValue: 'Liste Kutusu Değeri | Liste Kutusu Değerleri',
  listValue: 'Liste Değeri | Liste Değerleri',
  load: 'Yükle',
  loadMore: 'Daha fazla yükle',
  loading: 'Yükleniyor',
  locale: 'Yerel',
  login: 'Oturum Aç',
  loginBackground: 'Oturum Açma Arka Planı',
  loginGDPRMessageBeginning: 'Bu web sitesinde çerezler kullanılmaktadır. Bu web sitesini kullanmaya devam ederek çerezlerin kullanılmasına onay vermiş olursunuz. Daha fazla bilgi için bkz. ',
  loginGDPRMessageEnd: 've bkz.',
  loginSSO: 'SSO Oturumu Aç',
  logiReportName: 'Logi Raporu Adı',
  logiReportToggle: 'Logi Raporu mu?',
  logo: 'Logo | Logolar',
  logout: 'Oturumu Kapat',
  lookupLoadParts: 'Documoto İçe Aktarma´dan Parça Ara/Yükle',
  lowerBound: 'Alt Sınır',
  makePagesDivisibleBy: 'Sayfaları Bölünebilir Yap:',
  mainInfo: 'Ana Bilgi',
  mainLogo: 'Ana Logo',
  manageItem: '{item} ögesini yönet',
  management: 'Yönetim',
  march: 'Mart',
  margin: 'Marj | Marjlar',
  markdown: 'Alt İşaret',
  may: 'Mayıs',
  media: 'Medya | Medya',
  mediaCategory: 'Medya Kategorisi | Medya Kategorileri',
  mediaCategoryEditor: 'Medya Kategorisi Düzenleyici',
  mediaCategoryName: 'Medya Kategorisi Adı',
  mediaCategoryNameMissing: 'Tüm medya kategorileri için bir ad gereklidir',
  mediaBookDeleteMessage: 'Bu medyanın silinmesi, hem Kitaplık hem de İçerik Yöneticisi’ndeki içeriğe erişimi kaldıracaktır ve kullanıcılar ya da İçerik Yöneticileri için bundan böyle erişilebilir olmayacak. Bu kitapta bulunan ancak diğer kitaplarda bulunmayan herhangi bir parça ya da sayfa Kitaplık’da da bulunmayacak.',
  mediaDeleteMessage: 'Bu şablonun silinmesi, bunu İçerik Yöneticisinden kaldıracak ve Yeni Medya’ya kopyalanmak için de erişilebilir olmayacak.',
  mediaDeleteSuccessText: 'Endeksleme tamamlandığında listelerden kaldırılacaktır.',
  mediaDescription: 'Medya Tanımı',
  mediaIdentifier: 'Medya Tanımlayıcısı',
  mediaName: 'Medya Adı',
  mediaPrintSettingsSaveSuccess: 'Medya Yazdırma Ayarları kaydedildi',
  mediaWhereUsed: 'Kullanıldığı Ortam',
  menu: 'Menü',
  merge: 'Birleştir',
  mergeOrOverrideMessage: 'Bu ögelerin üzerine yazmak veya ögeleri birleştirmek ister misiniz?',
  mergeWarning: 'İçeri veri aktarmak mevcut parçaları günceller ve yeni girişler ekler',
  message: 'İleti',
  messageLevel: 'Mesaj Düzeyi',
  microsite: 'Mikrosite | Mikrositeler',
  micrositeAdmin: {
    cardSubtitle: 'Sıkıştırılmış içerik dosyası ve HTML giriş bilgileri',
    cardTitle: 'Site İçeriği',
    errorRequiredFile: 'Bir Site İçeriği ZIP dosyası gereklidir',
    fieldFile: 'Site İçeriği ZIP Dosyası',
    fieldPoint: 'HTML Giriş Noktası',
  },
  minArea: 'Minimum Alan',
  minAreaMessage: 'Minimum değer gerekiyor. Değer 1 ila 1500 aralığını aşamaz.  Bir tamsayı değeri olmalıdır.',
  moreInfo: 'Daha Fazla Bilgi',
  multipleAlternatingPages: 'Birden Çok Değişimli Sayfa',
  multipleAlternatingPagesMessage: '1. ve 3. sayfalarda diyagram, 2. ve 4. sayfalarda parça listesi',
  mToM: 'M2M',
  multiplePages: 'Birden Fazla Sayfa',
  multiplePagesMessage: 'Diyagram kendi sayfasında ve ardından parça listesi sayfaları',
  requiredField: 'Gerekli Alan',
  messageMessage: 'Bir ileti gerekli',
  metadata: 'Meta Veri',
  mine: 'Benim',
  miscellaneous: 'Çeşitli',
  mon: 'Pzt',
  myCart: 'Sepetim | Sepetlerim',
  navigation: 'Navigasyon',
  nItemsCreated: '{n} {items} Oluşturuldu',
  nItemsDeleted: '{n} {items} Silindi',
  nOfMItems: '{n} / {m} {items}',
  name: 'Ad',
  nameDescriptionHelpText: 'İsim ve isteğe bağlı açıklama',
  new: 'Yeni',
  newAPIKey: 'Yeni Uygulama Programı Arabirimi Anahtarı',
  newERPSystem: 'Yeni ERP Sistemi',
  newItem: 'Yeni {item}',
  newKeyGenerated: 'Yeni Entegrasyon Anahtarı Oluşturuldu, gelecekte kullanmak üzere Kopyalayın ve Saklayın',
  newMediaCategory: 'Yeni Medya Kategorisi',
  newPassword: 'Yeni Parola',
  newsItem: 'Haber Ögesi | Haber Ögeleri',
  newTenant: 'Yeni Kiracı',
  newWidgetKey: 'Yeni Widget Anahtarı',
  newWidgetKeyGenerated: 'Yeni Widget Anahtarı oluşturuldu, sonraki kullanım için Kopyala ve Sakla',
  noTenantSuppliers: 'Kiracıya atanmış Sağlayıcı yok.',
  noAPIKeys: 'Tanımlı Uygulama Programı Arabirimi Anahtarı yok.',
  node: 'Düğüm',
  noData: 'Veri Yok',
  noFilesSelected: 'Dosya Seçilmedi',
  noIllustrationFound: 'Çizim Bulunamadı',
  noImageFound: 'Resim Bulunamadı',
  noItemAvailable: '{item} yok',
  noItemFound: '{item} öge bulunamadı.',
  noItemSelected: 'Hiçbir {item} seçilmedi',
  noItemAddedToTarget: '{target} için eklenen {item} yok. ',
  noItemsFound: 'Öge Bulunamadı',
  noMoreResults: 'Başka sonuç yok',
  noFormsMessage: 'Tanımlı Form yok.',
  noMediaDefined: 'Tanımlı Medya yok',
  nonSearchableFieldsSuccessfullyUpdated: 'Aranamaz Alanlar Başarıyla Güncellendi.',
  noPartsSelected: 'Hiçbir parça seçilmedi.',
  noPrice: 'Fiyat İste',
  noResults: 'Sonuç yok',
  norwegianDescription: 'Norveççe Açıklama',
  norwegianName: 'Norveççe Ad',
  noSearchableTags: 'Aranabilir etiket yok',
  noSuggestions: 'Arama terimi için öneri bulunamadı',
  notOrderable: 'Sipariş Verilemez',
  notSelected: 'Seçili değil',
  noTagsFound: 'Etiket Bulunamadı',
  noUnsavedChanges: 'Kaydedilmemiş Değişiklik Yok',
  none: 'Hiçbiri',
  notImplemented: 'Uygulanmadı',
  notification: 'Bildirim | Bildirimler',
  notificationsMessage: 'Önemli duyurular ve haberler',
  notMapped: 'Eşlenmedi',
  november: 'Kasım',
  noWidgetKeys: 'Tanımlanmış Widget Anahtarı yok.',
  numeric: 'Sayısal',
  numberOf: '# / {item}',
  october: 'Ekim',
  of: '/',
  off: 'Kapalı',
  offlineContent: 'Çevrim Dışı İçerik',
  ok: 'Tamam',
  oldPassword: 'Eski Parola',
  on: 'Açık',
  opacity: 'Opaklık',
  open: 'Aç',
  openItem: '{item} ögesini aç',
  optimizeIndexJob: 'Dizini İyileştir',
  options: 'Seçenekler',
  openTab: 'Bağlantıyı başka bir sekmede aç',
  order: 'Sipariş | Siparişler',
  orderable: 'Sipariş edilebilir',
  orderableDescription: 'Sepetinize parça eklenebilir ve satın alınabilir',
  orderableLocal: 'Bu sayfadan sipariş edilebilir',
  orderableLocalBlocked: 'Parça Global Olarak Sipariş Edilemiyor',
  orderDisable: 'Sipariş Tarihçesini Devre Dışı Bırakın',
  orderEmailRecipient: 'Sipariş E-postası Alıcısı | Sipariş E-postası Alıcıları',
  orderEntity: 'Sipariş Varlığı | Sipariş Varlıkları',
  orderFieldUneditableRequired: 'Bir Sipariş/Teklif Alanının Gerekli olarak ayarlanması ve Kullanıcı Tarafından Düzenlenebilir olmaması, Sipariş/Teklif Formunuzun doldurulmasını imkansız hâle getirebilir. Lütfen Sipariş/Fiyat Teklifi Formlarınızın doldurulabildiğini doğrulayın.',
  orderFieldsEmptyMessage: 'Tanımlı Sipariş/Teklif Alanı yok.',
  orderFieldsEmptyPrompt: 'Sipariş/Teklif Alanı Ekle',
  orderField: {
    nameMessage: 'Sipariş/Teklif Alanı adını değiştirirseniz bu alanı kullanan tüm Sipariş/Teklif Formlarını veya E-posta Biçimlerini güncellediğinizden emin olun.',
  },
  orderFieldCopyMessage: 'Yeni Sipariş/Teklif Alanı için benzersiz bir ad girin',
  orderForm: {
    uniqueNameInputHint: 'Yeni Form için benzersiz bir ad girin.'
  },
  orderFormField: 'Sipariş/Fiyat Teklifi Alanı | Sipariş/Fiyat Teklifi Alanları',
  orderFormLayout: 'Sipariş/Teklif Formu Düzeni | Sipariş/Teklif Formu Düzenleri',
  orderFormSetDefault: 'Varsayılan Sipariş/Teklif Formu ayarlandı',
  orderFormType: 'Sipariş Formu',
  orderLayout: 'Sipariş Düzeni | Sipariş Düzenleri',
  orderManagementApprover: 'Sipariş Yönetimi Onaylayıcısı',
  orderManagementDisable: 'Sipariş Yönetimi Yöneticisini Devre Dışı Bırak',
  orderManagementViewer: 'Sipariş Yönetimi Görüntüleyicisi',
  orderNumber: 'Sipariş Numarası',
  orderQuoteEmail: 'Sipariş/Teklif E-postası | Sipariş/Teklif E-postaları',
  orderQuoteEmailFormat: 'Sipariş/Teklif E-posta Formatı | Sipariş/Teklif E-posta Formatları',
  orderQuoteEmailFormatCopyMessage: 'Yeni Sipariş/Teklif E-posta Formatı için bir dil seçin.',
  orderQuoteFetchError: 'Sipariş/Teklif Formu verileri alınırken hata oluştu',
  orderQuoteForm: 'Sipariş/Fiyat Teklifi Formu | Sipariş/Fiyat Teklifi Formları',
  orderQuoteSaveConfirmation: 'Organizasyon Sipariş/Teklif formlarında yaptığınız değişiklikleri kaydet?',
  orderQuoteSaveError: 'Sipariş/Teklif Formu verileri kaydedilirken hata oluştu',
  orderQuoteFormSaved: 'Sipariş/Teklif Formu Kaydedildi',
  orderStatus: 'Sipariş/Teklif Durumu | Sipariş/Teklif Durumları',
  orderStatusDeleteMessage: 'Bu durum siparişlerde kullanılmıştır ve silinemez.',
  orderSubmittedMessage: 'Başarılı Şekilde Gönderildi',
  orderSuggestion: 'Sipariş Önerisi | Sipariş Önerileri',
  orderSuggestionEnable: 'Sipariş Önerilerini Etkinleştir',
  orderSuggestionRemovalWarning: 'Parça artık bir sipariş önerisi olarak gösterilmeyecek. | Parçalar artık sipariş önerileri olarak gösterilmeyecek.',
  orderSuggestionThreshold: 'Sipariş Önerisi Eşiği',
  ordersLogo: 'Siparişler Logosu',
  ordersMessage: 'Sipariş gönderme geçmişi',
  orientation: 'Yön',
  org: 'Organizasyon',
  orgEmailRecipients: 'Kuruluş E-posta Alıcıları',
  organization: 'Kuruluş| Kuruluşlar',
  organizationAdmin: 'Kuruluş Yöneticisi',
  organizationAdminBrowseFlow: 'Bir Kuruluş, varsayılan göz atma akışını kullanabilir veya siz diğer herhangi bir göz atma akışını geçersiz kılabilir ve kullanabilirsiniz.',
  organizationAdminMessage: 'Kullanıcı yönetimi ve kuruluş ayarlarını kontrol eder',
  organizationEmpty: 'Kuruluş seçilmelidir',
  organizationEmailRecipients: 'Kuruluş E-posta Alıcıları',
  organizationEnforceAccessControlsForQuickAdd: 'Hızlı Ekleme için Erişim Denetimlerini Zorla',
  organizationKey: 'Kuruluş Anahtarı',
  organizationLibrarySettingsDescription: 'Bu ayarlar, Kuruluş düzeyindeki çeşitli Kitaplık ögelerini ve seçeneklerini kontrol eder. Değişiklik yaparken dikkatli olun. Daha fazla bilgi için Documoto Hizmetleri ile iletişime geçin.',
  organizationMoveToRoot: 'Kök dizine taşımak için buraya bırakın',
  organizationName: 'Kuruluş Adı',
  organizationRearrange: 'Kuruluşları Yeniden Düzenleyin',
  organizationRearrangeDescription: 'Hiyerarşi bakımından alt ve üst Kuruluş yapısı, Yazdırma Ayarları, Stiller ve diğerleri gibi bir Kuruluşun miras alınabilen birçok özelliğini tanımlar. İstenen hiyerarşiyi yansıtmak için Kuruluşları sürükleyip bırakın.',
  organizationReportSettingsSuccessMessage: 'Rapor ayarları kaydedildi',
  organizationSelect: 'Kuruluş Seçin',
  organizationTags: 'Kuruluş Etiketleri',
  organizationsEmpty: 'Görünüşe göre henüz bir kuruluş oluşturmadınız',
  orgNameOnly: 'Yalnızca Organizasyon Adı',
  other: 'Diğer',
  otherSettings: 'Diğer Ayarlar',
  outboundCustomerIdParameter: 'Giden Müşteri Kimliği Parametre Adı',
  outboundSessionIdParameter: 'Giden Oturum Kimliği Parametre Adı',
  outlineMode: 'Dış Hat Modu (Shift O)',
  output: 'Çıkış',
  outputCatalog: 'Çıkış Kataloğu',
  outputDefinition: 'Çıkış Tanımı | Çıkış Tanımları',
  outputDefinitionDeleted: 'Çıkış Tanımı silindi',
  outputDefinitionSaved: 'Çıkış Tanımı Kaydedildi',
  outputFileName: 'Çıkış Dosyası Adı',
  outputName: 'Çıkış Adı',
  outputDestination: 'Çıktı Hedefi',
  outputSpecification: 'Çıkış Belirtimi',
  outputSubType: 'Çıkış Alt Türü',
  outputType: 'Çıkış Türü',
  overridden: 'Geçersiz Kılındı',
  overriddenByOrg: 'Kuruluş tarafından geçersiz kılındı',
  override: 'Geçersiz Kıl',
  overrideBrowseFlow: 'Göz Atma Akışını Geçersiz Kıl',
  overrideFacetLimitValue: 'Özellik Limit Değerini Geçersiz Kıl',
  overrideHotpointScale: 'Hotpoint Ölçeğini Geçersiz Kıl',
  overridePdfNamingStandard: 'PDF Dosya Adlandırma Standardını geçersiz kıl',
  overridePrintSettings: 'Yazdırma Ayarlarını Geçersiz Kıl',
  overrideFontSettings: 'Font Ayarlarını Geçersiz Kıl',
  overrideOrderForm: 'Sipariş Formunu Geçersiz Kıl',
  overrideQuoteForm: 'Teklif Formunu Geçersiz Kıl',
  overrideSvgOpacity: 'SVG Opaklığını Geçersiz Kıl',
  overrideWarning: 'İçeri veri aktarmak mevcut Malzeme Listenizi değiştirir',
  owner: 'Sahibi | Sahipleri',
  page: 'Sayfa | Sayfalar',
  pageBulkInsertConfirmTitle: 'Toplu ekleme işlemini tamamlamak istiyor musunuz?',
  pageBulkInsertConfirmInsertAfter: 'Bu işlem {whereUsedBookCount} {bookUnits} ve {whereUsedTemplateCount} {templateUnits} içinde {original} ögesinden sonra {replacement} ögesini ekleyecektir.',
  pageBulkInsertConfirmInsertBefore: 'Bu işlem {whereUsedBookCount} {bookUnits} ve {whereUsedTemplateCount} {templateUnits} içinde {original} ögesinden önce {replacement} ögesini ekleyecektir.',
  pageBulkInsertConfirmInsertReplace: 'Bu işlem {whereUsedBookCount} {bookUnits} ve {whereUsedTemplateCount} {templateUnits} içinde {original} ögesini kaldırıp {replacement} ögesini ekleyecektir.',
  pageBulkInsertBooksConfirmInsertAfter: 'Bu işlem {whereUsedBookCount} {bookUnits} içinde {original} ögesinden sonra {replacement} ögesini ekleyecektir.',
  pageBulkInsertBooksConfirmInsertBefore: 'Bu işlem {whereUsedBookCount} {bookUnits} içinde {original} ögesinden önce {replacement} ögesini ekleyecektir.',
  pageBulkInsertBooksConfirmInsertReplace: 'Bu işlem {whereUsedBookCount} {bookUnits} içinde {original} ögesini kaldırıp {replacement} ögesini ekleyecektir.',
  pageBulkInsertTemplatesConfirmInsertAfter: 'Bu işlem {whereUsedTemplateCount} {templateUnits} içinde {original} ögesinden sonra {replacement} ögesini ekleyecektir.',
  pageBulkInsertTemplatesConfirmInsertBefore: 'Bu işlem {whereUsedTemplateCount} {templateUnits} içinde {original} ögesinden önce {replacement} ögesini ekleyecektir.',
  pageBulkInsertTemplatesConfirmInsertReplace: 'Bu işlem {whereUsedTemplateCount} {templateUnits} içinde {original} ögesini kaldırıp {replacement} ögesini ekleyecektir.',
  pageBulkInsertDiscard: 'Toplu girişiniz işleme alınmayacaktır.',
  pageBulkInsertHelpText: 'Bu özellik, bu kaynak sayfayı {thisDisplayName}, zaten var olan başka bir sayfanın {originalDisplayName} seçili parça kitaplarına eklemenizi sağlar. İsteğe bağlı olarak {originalDisplayName} o kitaplardan çıkarılabilir.',
  pageBulkInsertInsertAfter: '{original} SONRASINDA {replacement} ekle',
  pageBulkInsertInsertBefore: '{original} ÖNCESİNDE {replacement} ekle',
  pageBulkInsertInsertReplace: '{replacement} ekle ve {original} olanı KALDIR',
  pageBulkInsertScopeAll: '{originalDisplayName} ({whereUsedCount} {units}) hedef sayfasına sahip tüm {type} ögeleri.',
  pageBulkInsertScopeNone: '{originalDisplayName} hedef sayfasına sahip hiçbir {type} ögesi',
  pageBulkInsertScopeSome: '{originalDisplayName} hedef sayfasına sahip bazı {type} ögeleri',
  pageBulkInsertStep1: '1. Adım: İstenilen bir hedef sayfayı bulun ',
  pageBulkInsertStep2: '2. Adım: Sayfaların nasıl ekleneceğine veya değiştirileceğine karar verin',
  pageBulkInsertStep3: '3. Adım: Toplu eklemenin işlenmesini istediğiniz kitapları seçin.',
  pageBulkInsertStep4: '4. Adım: Toplu eklemenin işlenmesini istediğiniz şablonları seçin.',
  pageBulkInsertSuccessTitle: 'Toplu giriş işi gönderildi',
  pageBulkInsertSuccessBody: 'Kitap içindekiler tablosu değişiklikleri hemen yapılacaktır. Endeksleme tamamlandığında arama sonuçları yansıtılacaktır.',
  pageBulkErrorTooManyBooks: 'Seçili kitap sayısını en fazla 1000 ile sınırlayın',
  pageBuilder: 'Sayfa Oluşturucu',
  pageComposer: 'Page Composer',
  pageCreated: 'Sayfa Oluşturuldu',
  pageDeleteMessage: 'Sayfa silmeleri, Kütüphane ve İçerik Yöneticisi içinde anında gerçekleşir. Silinen sayfalar, birkaç dakika içinde tekrar dizinlenene kadar aranabilir.',
  pageDeleteMessage2: 'Bu eylem, Documoto Destek yardımı olmadan geri alınamaz. 90 gün sonra silinen kayıt ve tüm bileşenleri kalıcı olarak silinir.',
  pageDescription: 'Sayfa Tanımı',
  pageFile: 'Sayfa Dosyası',
  pageFilename: 'Sayfa Dosya Adı',
  pageFilenameDeleted: '{filename} silinecek.',
  pageFileRequired: 'Bir sayfa dosyası gerekli.',
  pageBomPartsDeleted: 'Malzeme Listesi sayfası üzerindeki {count} parça {filename} ile erişilebilir olmayacak.',
  pageDraftsDeleted: 'Bu sayfayla ilişkili taslak sayfalar kalıcı olarak silinir.',
  pageFit: 'Sayfaya Sığdır',
  pageHeader: 'Sayfa Başlığı',
  pageLabel: 'Sayfa | Sayfalar',
  pageLayout: 'Sayfa Düzeni',
  pageName: 'Sayfa Adı',
  pagePart: 'Sayfa Bölümü | Sayfa Bölümleri',
  pagePartTag: 'Sayfa Parça Etiketi | Sayfa Parça Etiketleri',
  pagePartData: 'Sayfa Bölümü Verileri',
  pageUsageDataCouldNotBeLoaded: 'Sayfa Kullanım Verileri Yüklenemedi',
  pagePartLevelDetails: 'Sayfa Bölümü Sipariş Edilebilir/Görünür, Sayfa Bölümü Etiketleri',
  pagePartNumberVisible: 'Sayfa Parça Numarası Görülebilir',
  pagePartOrderable: 'Sayfa Parçası Sipariş Edilebilir',
  pagePartOrderableDescription: 'Parça bu sayfadan Sipariş Edilebilir',
  pagePartVisibleDescription: 'Parça Numarası bu sayfada görünebilir',
  pageUpdated: 'Sayfa Güncellendi',
  pageViewer: 'Sayfa Görüntüleyici',
  pageWidth: 'Sayfa Genişliği',
  pagesWhereUsed: 'Kullanıldığı Sayfalar',
  parentName: 'Ana Kuruluş Adı | Ana Kuruluş Adları',
  part: 'Parça | Parçalar',
  partCode: 'Parça Kodu | Parça Kodları',
  partCodeAddError: 'Parça Kodu/Kodları eklenemiyor',
  partCodeErrorEmpty: 'Kaydedilemedi: Parça kodu boş olamaz',
  partCodeErrorInUse: 'Bu parça kodu Documoto`da parçalar tarafından kullanılıyor ve silinemez. Yardım için support{\'@\'}documoto.com adresinden Documoto Destek Merkezi ile iletişime geçin.',
  partCodeErrorNotFound: 'Bulunamadığı için bu parça kodları silinemedi, lütfen tüm değişikliklerin kaydedildiğinden emin olun.',
  partCodesManager: 'Parça Kodları Yöneticisi',
  partCodesMessage: 'Parça kodları parça numaraları yerine kullanılabilir ve genellikle ürün ağacı ögelerinin ne zaman kullanılmadığını, kullanılamadığını veya yalnızca referans ögeleri olduğunu gösterir (örn. \'PNNA\', \'-\', \'REF\').',
  partCreated: 'Parça Oluşturuldu',
  partData: 'Parça Verileri',
  partUpdated: 'Bölüm Güncellendi',
  partDebouncerEmailQueueJob: 'Eposta Kuyruğu',
  partDescription: 'Parça Tanımı',
  partLabel: 'Parça | Parçalar',
  partLevelDetails: 'Bölüm Fiyatlandırması, Sipariş Edilebilir/Görünür, Ek Çeviriler, Bölüm Etiketleri',
  partName: 'Parça Adı',
  partnerApiKey: 'Partner Uygulama Programı Arabirimi Anahtarı',
  partnerRESTRequestLimit: 'Partner REST Talebi Limiti',
  partNumber: 'Parça Numarası',
  partNumberDescription: 'Son kullanıcıların araması için dahili parça numarası',
  partNumberVisible: 'Görülebilir Parça Numarası',
  partNumberVisibleDescription: 'Parça numarası son kullanıcılar tarafından görülebilir',
  partNumberVisibleLocal: 'Bu sayfada görülebilir',
  partNumberVisibleLocalBlocked: 'Parça Numarası Global Olarak Görünmüyor',
  partOrderable: 'Parça Sipariş Edilebilir',
  partRequired: 'En az bir parça gerekli',
  partTagProperty: 'Parça Etiketi Özelliği | Parça Etiketi Özellikleri',
  partTag: 'Parça Etiketi | Parça Etiketleri',
  partThumbnailDescription: '',
  partialSupport: 'Kısmi Destek',
  partsListColumn: 'Parça Listesi Sütunu | Parça Listesi Sütunları',
  partsBook: 'Parça Defteri | Parça Defterleri',
  partsColumns: 'Parça Sütunları',
  partsNotSelected: 'Parça küçük resimleri için kullanılan varsayılan resim. Özel bir küçük resim uygulanmazsa, Documoto standart bir varsayılan küçük resim görüntüler.',
  partsPage: 'Parçalar Sayfası | Parçalar Sayfaları',
  partsPages: 'Parça Sayfaları',
  partsPageGroup: {
    excludePartCodesFromPartsLists: 'Parça Kodlarını Parça Listelerinden Çıkar',
    highlightAlternateRows: 'Değişimli Satırları Vurgula',
    rotateImagesAutomatically: 'Görüntüleri Otomatik Olarak Döndür',
    printBorderAroundImages: 'Görüntülerin Çevresinde Yazdırma Kenarı',
    keepImagesOnLeft: 'Görüntüleri Solda Tut',
    keepBOMOnRight: 'Malzeme Listesini Sağda Tut',
    columnsUpdateErrorMsg: 'Parça Sütunlarını güncellemeye çalışırken bir hata oluştu',
    columnsUpdateSuccessMsg: 'Parça Sütunları güncellendi',
    columnsLoadErrorMsg: 'Parça sütunları yüklenemedi',
    printHotpointsOnImages: 'Resimlere Etkin Noktaları Bas'
  },
  partsList: 'Parçalar Listesi',
  partsListData: 'Parça Listesi Verileri',
  partsListGridHeader: 'Parça Listesi Tablo Başlığı',
  password: 'Parola',
  passwordExpiredHeader: 'Parola Süresi Doldu',
  passwordExpiredInfo: 'Parolanızın süresi dolmuştur. Yeni parolanız şu kriterleri karşılamalıdır:',
  passwordCriteriaInfo: 'Yeni parolanız şu kriterleri karşılamalıdır:',
  passwordBlankMessage: 'Parola boş bırakılamaz',
  passwordCannotBeSame: 'Mevcut parolanızla aynı olamaz',
  passwordCasingMassage: 'En az bir küçük harf ve bir büyük harf içermelidir',
  passwordLowerCaseMessage: 'Parola en az bir küçük harf karakter içermelidir',
  passwordMinLengthMessage: 'Parola en az 8 karakter uzunluğunda olmalıdır',
  passwordNotMatchingMessage: 'Parolalar aynı değil',
  passwordSpecialCharactersMessage: 'Parola en az bir özel karakter içermelidir',
  passwordUpperCaseMessage: 'Parola en az bir büyük harf karakter içermelidir',
  passwordWillExpire1Day: 'Giriş Parolasının süresi 1 günden daha kısa süre içinde dolacaktır',
  passwordWillExpire5Days: 'Giriş Parolasının süresi 5 günden daha kısa süre içinde dolacaktır',
  passwordsMatch: 'Şifreler Eşleşiyor',
  permission: 'İzin | İzinler',
  phoneNumber: 'Telefon Numarası',
  phoneNumberExt: 'Dahili',
  phonePrimary: 'Birincil Telefon Numarası',
  phoneSecondary: 'İkincil Telefon Numarası',
  pinFilter: 'Arama sonuçları sayfasına filtreyi sabitle',
  pinned: 'Sabitlenmiş',
  plusMoreOrgs: '+ {totalOrgCount} ek Kuruluş',
  polishDescription: 'Lehçe Açıklama',
  polishName: 'Lehçe Ad',
  populatePdfNameWith: 'PDF dosya adını şununla doldur:',
  populatePdfNameSelection: 'PDF Dosya Adı Seçimini doldur',
  port: 'Port',
  portrait: 'Portre',
  portugueseDescription: 'Portekizce Açıklama',
  portugueseName: 'Portekizce Ad',
  postComment: 'Yorum Gönder',
  postalCode: 'Posta Kodu',
  poweredBy: 'Destekleyen',
  preferences: 'Tercihler',
  preferencesDisable: 'Kullanıcı Tercihlerini Devre Dışı Bırak',
  prefix: 'Önek | Önekler',
  preview: 'Ön İzleme',
  previewOfName: '{name} önizlemesi',
  pricing: 'Fiyatlandırma',
  pricingDiscountEnabled: 'İndirimli Fiyatı Görüntüle',
  pricingDisplayInCart: 'Alışveriş Sepetinde Fiyatları Göster',
  pricingDisplayInPartsList: 'Parça Listesinde Fiyatları Göster',
  pricingGlobal: 'Global Fiyatlandırma',
  pricingGlobalDescription: 'Kuruluştan geçersiz kılma olmadığında normal fiyat',
  pricingHidePricingButton: 'Fiyatlandırma Butonunu Gizleyi Etkinleştir',
  pricingHidePricingButtonDisable: 'Fiyatlandırma Butonunu Gizleyi Devre Dışı Bırak',
  pricingOrganization: 'Kuruluş Fiyatlandırması',
  pricingOrganizationDeleteConfirm: 'Bu Kuruluş fiyatlandırmasını silmek istediğinizden emin misiniz?',
  pricingOrganizationDescription: 'Kuruluş fiyatlandırması, bir Kuruluş ve onun alt Kuruluşları içindeki tüm kullanıcılar için global fiyatlandırmayı geçersiz kılar.',
  pricingOrganizationDuplicate: 'Bu kuruluş zaten geçersiz kılınıyor',
  pricingOrganizationEmptyState: 'Herhangi bir kuruluş geçersiz kılması tanımlanmadı. Başlamak için {add} ögesine tıklayın.',
  pricingRetailEnabled: 'Perakende Fiyatını Görüntüle',
  pricingWholesaleEnabled: 'Toptan Fiyatı Görüntüle',
  primary: 'Birincil',
  print: 'Yazdır',
  printerLane: 'Yazıcı Yolu',
  printJob: 'İşi Yazdır',
  printLogo: 'Yazdırma Logosu',
  printSetting: 'Yazdırma Ayarı',
  printSettingDeleteFail: 'Yazdırma Ayarı Silinemedi',
  printSettingSaveFail: 'Yazdırma Ayarı Kaydedilemedi',
  printSettingEmptyMessage: 'Tanımlanmış Yazdırma Ayarı Yok. Başlamak için bir Yazdırma Ayarı ekleyin.',
  printSettingSetDefaultSuccess: 'Varsayılan Yazdırma Ayarı seti',
  printSettingUpdateFail: 'Yazdırma Ayarı Güncellenemedi',
  printSettingCopyMessage: 'Yeni Yazdırma Ayarı için benzersiz bir ad girin.',
  printSettingCoverLoadErrorMsg: '',
  printSettingCreated: 'Yazdırma Ayarı oluşturuldu',
  printSettings: 'Yazdırma Ayarları',
  printSettingsLogo: 'Yazdırma Ayarları Logosu',
  printing: 'Yazdırılıyor',
  printingDisabled: 'Tüm Yazdırmaları Devre Dışı Bırak',
  privacyPolicy: 'Gizlilik Politikası',
  proceed: 'Devam Et',
  process: 'İşlem',
  processContent: 'Süreç İçeriği',
  processing: 'İşleniyor',
  processToDraft: 'Taslağa İşle',
  processedPlzToDraft: 'Taslağa İşlenmiş PLZ(ler)',
  production: 'Üretim',
  professional: 'Profesyonel',
  profile: 'Profil',
  prohibitExportDownloadOfContent: 'İçeriği Dışa Aktarmayı/İndirmeyi Yasakla',
  prohibitExportDownloadOfContentHelpText: '\'Etkinleştir\' olarak ayarlamak, (bu ayrıcalıklara sahip) kullanıcıların bu içeriği dışa aktarmasını veya indirmesini engelleyecektir.',
  property: 'Özellik | Özellikler',
  province: 'İl',
  public: 'Halka Açık',
  publish: 'Yayınla',
  publisherLane: 'Yayıncı Yolu',
  publisherManualLane: 'Yayıncı Manuel Yolu',
  publishThumbnailJob: 'Küçük Resmi Yayınla',
  publishDocuments: 'Belgeleri Yayınla',
  publishDraft: 'Taslağı Yayınla',
  publisher: 'Yayıncı | Yayıncılar',
  publishingErrors: 'Yayımlama Hataları',
  publishingErrorsWithName: 'Yayınlama Hataları {name}',
  publishingInProgress: 'Yayımlama devam ediyor, gönderilemez',
  publisherJob: 'Yayınlayıcı İşi',
  publisherMessage: 'İçerik oluşturma, yönetme ve yazdırma ayarları',
  purchaseOrder: 'Satın Alma Emri',
  purchaseOrderTemplateEnabled: 'Sipariş Şablonlarını Etkinleştir',
  purchaseOrderTemplateDisabled: 'Sipariş Şablonlarını Devre Dışı Bırak',
  qty: 'Mkt',
  quantity: 'Miktar',
  query: 'Sorgu',
  quickAdd: 'Hızlı Ekle',
  quickAddHelpText: 'Alışveriş sepetine ekleyeceğiniz parçaları bulmak için yazmaya başlayın.',
  quickAddInCartEnabled: 'Sepete Hızlı Eklemeyi Etkinleştir',
  quickLinks: 'Hızlı Bağlantılar',
  quote: 'Teklif | Teklifler',
  quoteFormType: 'Teklif Formu',
  quoteTemplateDisabled: 'Teklif Şablonlarını Devre Dışı Bırak',
  quoteTemplateEnabled: 'Teklif Şablonlarını Etkinleştir',
  range: 'Aralık',
  rearrange: 'Yeniden düzenle',
  rebuildIndexJob: 'Dizini Yeniden Oluştur',
  recentSearches: 'Son Aramalar',
  recentlyAdded: 'Son Eklenenler',
  recentlyEdited: 'Son Düzenlenenler',
  recentlyViewed: 'Son Görüntülenenler',
  rectangle: 'Dikdörtgen',
  redirect: 'Yeniden Yönlendir',
  refresh: 'Yenile',
  related: 'İlgili',
  relatedDescription: 'Kütüphanede bulunan medyayı bu {type} ile ilişkilendirin. Kullanıcıların \'İlgili\' sekmesinde görebilmesi için ilgili medyaya erişim kontrolleri uygulanmış olmalıdır.',
  relatedInfo: 'İlgili Bilgi',
  relatedView: 'İlgili Medyayı Görüntüle',
  relationship: 'İlişki | İlişkiler',
  releaseNotes: 'Sürüm Notları',
  rememberMe: 'Beni hatırla',
  remainingWidth: 'Kalan Genişlik',
  remove: 'Kaldır',
  removeAll: 'Tümünü Kaldır',
  removeItemConfirmation: '{item} ögesini silmek istediğinizden emin misiniz?',
  removeItemCount: ' {count} {type} ögesini silmek istediğinizden emin misiniz?',
  replace: 'Değiştir',
  replaceExisting: 'Var Olanı Değiştir',
  report: 'Rapor | Raporlar',
  reportAnIssue: 'Sorun Bildir',
  reportAnIssueMessage: 'E-posta başarılı şekilde gönderildi.',
  reportCatalogName: 'Rapor Kataloğu Adı',
  reportCatalogNotSet: 'Rapor için rapor kataloğu tanımlanmadı.',
  reportDefinition: 'Rapor Tanımı | Rapor Tanımları',
  reportDefinitionDeleteError: 'Rapor tanımı silinemedi',
  reportsEmptyState: 'Rapor yok',
  reportExportTimeWarning: 'Kayıt sayısına ve seçili biçime bağlı olarak dışarı aktarmanın tamamlanması birkaç dakika sürebilir.',
  reporting: 'Raporlama',
  reportingMessage: 'Çeşitli raporlara ve metriklere erişim',
  reportSettings: 'Rapor Ayarları',
  reportsLibrary: 'Rapor Kitaplığı',
  requestForQuote: 'Fiyat Teklifi İste',
  requestFormType: 'Teklif Formu',
  required: 'Gerekli',
  requiredItem: '{item} gereklidir.',
  requiredMessage: 'Bir {item} gerekli.',
  requiredMessageAndUnique: 'Bir {type} gereklidir ve benzersiz olmalıdır.',
  requireAuthentication: 'Kimlik Doğrulama İste',
  requiredReading: 'Gerekli Okuma',
  requiredReadingMessage: 'Lütfen şunları gözden geçirin:',
  requiredUrlMessage: 'İyi biçimlendirilmiş bir URL gerekli.',
  reset: 'Sıfırla',
  resetPassword: 'Parolayı Sıfırla',
  resetPasswordError: 'Parolanız sıfırlanırken bir sorun oluştu, lütfen tekrar deneyin.',
  resetPasswordTokenError: 'Parola sıfırlama belirteci geçerli değil.',
  resetZoom: 'Yakınlaştırmayı Sıfırla',
  resource: 'Kaynak | Kaynaklar',
  resize: 'Yeniden Boyutlandır',
  resizeThumbnails: 'Küçük resimleri otomatik olarak yeniden boyutlandır',
  result: 'Sonuç | Sonuçlar',
  resultFor: '{item} için Sonuç | {item} için Sonuçlar',
  retail: 'Perakende',
  retailPrice: 'Perakende Fiyatı',
  retailTotal: 'Perakende Toplamı',
  return: 'Dön',
  returnToItem: '{item} ögesine dön',
  returnToPartList: 'Parça Listesine Dön',
  returnToMedia: 'Medyaya Dön',
  returnToTemplate: 'Şablona Dön',
  returnToViewer: 'Görüntüleyiciye Dön',
  revertChanges: 'Değişiklikleri Geri Al',
  right: 'Sağ',
  romanianDescription: 'Romence Açıklama',
  romanianName: 'Romence Ad',
  rootLevelBrowseFlow: 'Kök Seviyesi',
  row: 'Sıra',
  russianDescription: 'Rusça Açıklama',
  russianName: 'Rusça Ad',
  sampleData: 'Örnek Veriler',
  samplePreview: 'Örnek Önizlemesi',
  sandbox: 'Sandbox',
  sat: 'Cmt',
  save: 'Kaydet',
  saveChanges: 'Değişiklikleriniz {item} ögesine kaydedilsin mi? {name}',
  saveChangesToItem: 'Değişiklikleriniz bu {item} ögesine kaydedilsin mi?',
  saved: 'Kaydedildi',
  saveNewAPIKey: 'Yeni Entegrasyon Anahtarı Kaydedilmedi, Kurulumu tamamlamak üzere dönün',
  saveNewWidgetKey: 'Yeni Widget Anahtarı kaydedilmedi. Kurulumu tamamlamak için geri dön.',
  search: 'Ara',
  searchableFieldsSuccessfullyUpdated: 'Aranabilir Alanlar Başarıyla Güncellendi.',
  searchAllContent: 'Tüm İçeriği Ara',
  searchConfiguration: 'Arama Yapılandırması',
  searchCriteria: 'Arama Kriteri',
  searchDocumoto: 'Documoto’da Ara',
  searchFeatures: 'Arama Özellikleri',
  searchFieldConfigDescription: 'Aşağıdaki sistem alanı arama ayarları yalnızca Kütüphane genel araması, arama sonuçları sayfası ve Sepet alanındaki Hızlı Ekle bölümlerinde uygulanır. Diğer arama özellikleri (ör. Yönetici Merkezi araması ve Kullanıldığı Kütüphane) etkilenmez.',
  searchInContent: 'Şuradaara {contentName}...',
  searchingInLanguage: 'Sadece {lang} dilinde aranıyor',
  searchingInTwoLanguages: 'Sadece {lang1} ve {lang2} dillerinde aranıyor',
  searchPlaceholder: 'Tüm içeriği ara…',
  searchPrompt: 'Tüm içeriği ara…',
  searchProperty: 'Özellik Ara | Özellikleri Ara',
  searchResultsConfiguration: 'Arama Sonuçları Yapılandırması',
  searchSettings: 'Arama Ayarları',
  searchSettingsDescription: 'Bu ayarlar, çeşitli Kütüphane arama tarzını, özelliklerini ve sonuçlarını kontrol eder.',
  searchTerm: 'Arama Terimi',
  searchTips: 'Arama İpuçları:',
  searchable: 'Aranabilir',
  searchableTag: 'Aranabilir Etiket',
  section: 'Bölüm',
  select: 'Seç',
  selectAField: 'Bir {field} seçin',
  selectAll: 'Tümünü Seç',
  selectAndClose: 'Seç ve Kapat',
  selected: 'Seçildi',
  selectedTargetPage: 'Seçilmiş Hedef Sayfa',
  selectChapters: 'Örnek Bölümler',
  selectChildren: 'Alt Kuruluşları Seçin',
  selectFields: 'Bir {field} seçin',
  selectItem: '{item} öğesini seç',
  selectMedia: 'Medya Seç',
  selectPage: 'Sayfa Seçin',
  selectPages: 'Sayfaları Seç',
  selectTargetPage: 'Hedef Sayfa Seçin',
  send: 'Gönder',
  september: 'Eylül',
  serbianDescription: 'Sırpça Açıklama',
  serbianName: 'Sırpça Ad',
  serviceUri: "Hizmet URI'si",
  sessionLog: 'Oturum Günlüğü',
  set: 'Ayarla',
  setAsDefault: 'Varsayılan Olarak Ayarla',
  settings: 'Ayarlar',
  settingsMessage: 'Kullanıcı tercihlerinizi ve parolanızı güncelleyin',
  sequence: 'Sıralama',
  shape: 'Şekil',
  shapeTool: 'Şekil Aracı',
  shared: 'Paylaşımlı',
  sharedCart: 'Paylaşılan Sepet | Paylaşılan Sepetler',
  sharedCartDeleteContent: 'Bu, bu Paylaşılan Sepeti, paylaşıldığı tüm Kuruluşlar için kaldıracaktır.',
  sharedCartOrganizationContent: 'Bu Paylaşılan Sepeti Kullanabilecek Kuruluşları Seçin.',
  sharedShoppingCartsEnabled: 'Paylaşılan Alışveriş Sepetlerini Etkinleştir',
  shoppingCartExportJob: 'Alışveriş Sepeti Dışarı Aktarma',
  shoppingCartLoadingLongTime: 'Alışveriş sepeti yükleniyor. Alışveriş sepeti simgesi, işlem tamamlandıktan sonra tekrar kullanılabilir olacaktır. Sorun devam ederse destek için lütfen Documoto Yöneticileri ile iletişime geçin.',
  showExportFromSearch: 'Arama Sonuçlarından Dışa Aktarmaya İzin Ver',
  showExportFromSearchHelp: 'Bir aramadan kaynaklanan kitap listesinin dışa aktarılmasına izin ver',
  showHelp: 'Yardımı Göster',
  showIdentifierInSearch: 'Aramada Medya Tanımlayıcıyı Göster',
  showItem: '{item} Göster',
  showLess: 'Daha Az Göster',
  showMediaDescriptionFilter: 'Medya Açıklama Filtresini Göster',
  showMediaIdentifierFilter: 'Medya Tanımlayıcı Filtresini Göster',
  showMediaNameFilter: 'Medya Adı Filtresini Göster',
  showMore: 'Daha Fazla Göster',
  showOnlyExactMatches: 'Sadece Tam Eşleşmeleri Göster',
  showOrganizationFilter: 'Kuruluş Filtresini Göster',
  showPartNameFilter: 'Parça Adı Filtresini Göster',
  showPartNumberFilter: 'Parça Numarası Filtresini Göster',
  showRecentlyAdded: 'Son Eklenenleri Göster',
  showRecentlyViewed: 'Son Görüntülenenleri Göster',
  showSearchButton: 'Ana Sayfa Arama Butonunu Göster',
  showSearchButtonHelp: 'Kitaplık başlığında \'Aramaya Git\' `butonunu göster',
  signature: 'İmza',
  size: 'Boyut',
  snapshot: 'Anlık Görüntü | Anlık Görüntüler',
  solrSlaveSvrOverride: 'SOLR Bağımlı Sunucu Temel URL Geçersiz Kılma',
  sortAsc: '{value} A-Z',
  sortDesc: '{value} Z-A',
  sortRelevant: 'En İlgili',
  sortType: 'Sıralama Türü',
  spanishDescription: 'İspanyolca Açıklama',
  spanishName: 'İspanyolca Ad',
  square: 'Kare',
  ssoConfiguration: 'SSO Yapılandırması',
  ssoIdpMetadataUrl: 'SSO IDP Meta Veri URL‘si',
  ssoLoginMaxAuthenticationAgeSeconds: 'SSO Giriş Maksimum Kimlik Doğrulama Yaşı Saniye',
  ssoSamlDnAttributeName: 'SSO SAML DN Öznitelik Adı',
  ssoSamlEmailAttributeName: 'SSO SAML E-posta Öznitelik Adı',
  ssoSamlMemberofAttributeName: 'SSO SAML Üyesi Öznitelik Adı',
  ssoSniForSsoMetadataRetrieval: 'SSO Meta Veri Alımı İçin SNI Kullan',
  standard: 'Standart',
  standardUserGroup: 'Standart Kullanıcı Grubu',
  startDate: 'Başlangıç Tarihi',
  startDateRequired: 'Başlama tarihi gerekli.',
  state: 'Eyalet',
  static: 'Statik',
  status: 'Durum',
  statusCode: 'Durum Kodu | Durum Kodları',
  stitchPages: 'Dikiş Sayfaları',
  storeFrontAdmin: 'Vitrin Yöneticisi',
  storefront: 'Vitrin',
  storefrontIntegration: 'Vitrin Entegrasyonu | Vitrin Entegrasyonları',
  storefrontIntegrationSubtitle: 'Bu ayarlar, çeşitli Vitrin Entegrasyonu ögelerini ve seçeneklerini kontrol eder. Değişiklik yaparken dikkatli olun. Daha fazla bilgi için Documoto Destek Merkezi ile iletişime geçin.',
  storefrontSettings: 'Vitrin Ayarları',
  storefrontSettingsDescription: 'Bu ayarlar, çeşitli Vitrin ögelerini ve seçeneklerini kontrol eder. Değişiklik yaparken dikkatli olun. Daha fazla bilgi için Documoto Hizmetleri ile iletişime geçin.',
  storefrontSettingsOrgDescription: 'Bu ayarlar, Kuruluş düzeyindeki çeşitli Vitrin ögelerini ve seçeneklerini kontrol eder. Değişiklik yaparken dikkatli olun. Daha fazla bilgi için Documoto Hizmetleri ile iletişime geçin.',
  storeFilterValues: 'Filtre Değerlerini Depola',
  storeFilterValuesDescription: 'Bu ayarı etkinleştirmek, bu etiket için kullanıcılar tarafından arama sayfası filtrelerinde ayarlanan değerleri depolar.',
  stroke: 'Fırça Darbesi',
  style: 'Stil | Stiller',
  styles: {
    colorHotpoint: 'Etkin Nokta Varsayılan',
    invalidHexcode: 'Geçersiz onaltılı kod',
    inheritedFromOrg: 'Kuruluştan devralındı {org}',
    inheritedFromSystem: 'Sistem Varsayılanından devralındı',
    inheritedFromTenant: 'Kiracıdan devralındı',
    organization: 'Kuruluş Stili | Kuruluş Stilleri',
    organizationSaved: 'Kuruluş Stilleri Kaydedildi',
    sampleNavigation: 'Navigasyon Sekmeleri',
    samplePrimary: 'Navigasyon/Düğmeler',
    sampleSelectedHotpoint: 'Seçili Etkin Noktalar',
    tenant: 'Kiracı Stili | Kiracı Stilleri',
    tenantSaved: 'Kiracı Stilleri Kaydedildi'
  },
  subChapter: 'Alt Bölüm | Alt Bölümler',
  subject: 'Konu',
  subjectMessage: 'Konu gerekli',
  submit: 'Gönder',
  submitPurchaseOrder: 'Satın Alma Emri',
  submitRequestForQuote: 'Fiyat Teklifi İste',
  submitted: 'Gönderildi',
  submitter: 'Gönderen',
  submittedBy: 'Gönderen kişi',
  submitterEmail: 'Gönderen E-postası',
  submitterFirstName: 'Gönderen Adı',
  submittedFilesMessage: '{fileCount} dosya gönderildi.',
  submitterLastName: 'Gönderen Soyadı',
  submitterOrgName: 'Gönderen Kuruluş Adı',
  submitterUserName: 'Gönderen Kullanıcı Adı',
  success: 'Başarılı',
  imageSaveSuccess: 'Resimler Başarıyla Güncellendi',
  successNotificationDuration: 'Sepete Ekleyin - Başarılı Toast Bildirim Süresi',
  suffix: 'Son Ek | Son Ekler',
  suggestedKeywords: 'Önerilen Anahtar Kelimeler',
  suggestedResults: 'Önerilen Sonuçlar',
  suggestion: 'Öneri | Öneriler',
  sun: 'Paz',
  superadmin: 'Süper Yönetici',
  superAdminSettings: "Süper Yönetici Ayarları",
  supersede: 'Yerini Al',
  supersedeComplete: 'Yerini alma işlemini tamamlamak istiyor musunuz?',
  supersedeDiscardChanges: 'Yerini alma işlemini göz ardı et?',
  supersedeMainText: 'Eski parçanın bulunduğu her Parça Sayfasında, mevcut bir parçayı bir veya daha fazla başka parçayla değiştirir. Eski parça numarası silinir ancak etiketler aracılığıyla aranabilir, böylece içerik eski numara veya yeni numara(lar) kullanılarak bulunabilir.',
  supersedeMessage: 'Parça güncellemeleri anında etkili olur. Arama birkaç dakika içinde güncellemeleri yansıtacaktır.',
  supersedeNotProcessed: 'Yerini alma işleminiz işleme alınmayacak.',
  supersedeReplacePart: 'Parçanın yerini alacak bir veya daha fazla parça bulun',
  supersedeSuccessful: 'Yer Değiştirme Başarılı',
  supersedeWith: 'Parçayı ... ile Yer Değiştir',
  supplier: 'Sağlayıcı | Sağlayıcılar',
  supplierKey: 'Sağlayıcı Anahtarı',
  supplierDescription: 'Bu parçayı tedarik eden firma',
  supportedImageFormats: 'Desteklenen resim formatları: GIF, JPG, JPEG, PNG',
  supportedDraftArchives: 'Desteklenmeyen dosya; desteklenen taslak dosya türleri: MDZ, PLZ',
  surroundWithQuotes: 'Tam eşleşmeleri görmek için birden fazla arama teriminin başına ve sonuna tırnak işareti koyun.',
  svgFinder: 'Ölçeklenebilir Vektör Grafikleri (SVG) Sorgulayıcı',
  swedishDescription: 'İsveççe Açıklama',
  swedishName: 'İsveççe Ad',
  system: 'Sistem',
  systemAdmin: 'Sistem Yöneticisi',
  systemFields: 'Sistem Alanları',
  systemFieldSearchAndFiltering: 'Sistem Alanı Araması ve Filtreleme',
  systemStatus: 'Sistem Durumu',
  systemName: 'Sistem Adı',
  systemVersion: 'Sistem Sürümü',
  tabDelimited: 'Sekme sınırlandırıldı',
  tableOfContents: 'İçindekiler',
  tabloid: 'Tabloid',
  tag: 'Etiket | Etiketler',
  tagName: 'Etiket Adı',
  tagEditor: {
    rangeBoundLengthInvalid: 'Cilt, {limit} rakamdan fazla olamaz.',
    tagEditorModal: 'Etiket Düzenleyici Modeli',
    filterPrompt: 'İlk 100 değer görüntüleniyor. Daha fazlasını görüntülemek için filtreyi kullanın.'
  },
  tagSearchAndFiltering: 'Etiket Araması ve Filtreleme',
  tagSearchAndFilteringHelpText: 'İpucu: Bazı arama ve filtre ayarları, burada bulunan Etiket Yönetimi etiket düzenleyicileri kısmından da yapılandırılabilir: ',
  tagSubmitAffected: 'Herhangi bir {type} içeriği, değişikliklere ek olarak {tag} etiketini otomatik olarak bırakacaktır.',
  tagDeleteAffectedCount: 'Bu, etiketi siler ve etiket bilgilerini {count} ögeden kaldırır.',
  tagDeleteAffectedNone: 'Şu anda bu etiketi kullanan hiçbir öge yok.',
  tagManagerDeleteWarning: '{deleteCategoryName} ögesine sahip bir mevcut medya, silindikten sonra bu kategoriyi kaybedecektir.',
  tagsDisplayMore: 'İlk 100 değer görüntüleniyor. Daha fazlasını görüntülemek için filtreyi kullanın.',
  tagsManager: 'Etiket Yöneticisi',
  tagValue: 'Etiket Değeri | Etiket Değerleri',
  tagValueMissing: 'Bir etiket değeri girmeniz gerekiyor',
  tagValueMultiple: 'Birden çok giriş için ayrılmış noktalı virgül',
  tagValueTooLong: 'Etiket değeri 25 karakter sınırını aşıyor | Etiket değerleri 25 karakter sınırını aşıyor',
  taxon: 'Takson',
  taxonomy: 'Taksonomi',
  template: 'Şablon | Şablonlar',
  templateEmailRecipients: 'Şablon E-posta Alıcıları',
  templateLayout: {
    addRow: 'Satır Ekle',
    addItem: 'Öge Ekle',
    deleteRow: 'Satır Sil',
    deleteColumn: 'Sütun Sil',
    fullWidth: 'Tam Genişlik',
    oneColumn: 'Tek Sütun',
    threeColumns: 'Üç Sütun',
    twoColumns: 'İki Sütun'
  },
  tenant: 'Kiracı | Kiracılar',
  tenantAdmin: 'Kiracı Yöneticisi',
  tenantAdminMessage: 'Genel kiracı ayarlarını kontrol eder',
  tenantCleanerJob: 'Kiracı Bakımı',
  tenantConfiguration: 'Kiracı Yapılandırması',
  tenantEmailRecipients: 'Kiracı E-posta Alıcıları',
  tenantEmailRecipientsOverrideMessage: 'Kuruluş E-posta Alıcıları belirtilirse bunlar geçersiz kılınır',
  tenantIndexQueueJob: 'Kiracı Dizin Kuyruğu',
  tenantKey: 'Kiracı Anahtarı',
  tenantKeyError: 'Kiracı Anahtarı benzersiz değil',
  tenantKeyMessage: 'Kiracı Anahtarı benzersiz olmalıdır',
  tenantLabelDeleteMessage: 'Bu etiket silindiğinde, varsayılan Documoto adına geri dönecektir.',
  tenantReindexModulus: 'Kiracı Yeniden Dizinleme Modülü',
  tenantSettings: {
    collapseTOCLabel: 'Eşleşen Sayfada İçindekiler`i Daralt',
    defaultLocaleLabel: 'Varsayılan Yerel Ayar',
    defaultLocaleMessage: 'Kiracı varsayılan yerel ayarı.',
    deleteMissingTranslationsOnPublishLabel: 'Yayınlama Esnasında Eksik Çeviriler Silinsin mi?',
    deleteMissingTranslationsOnPublishMessage: 'Yayınlama Esnasında Bölüm Çevirilerini Silmeyi Etkinleştir',
    defaultSupplierLabel: 'Varsayılan Tedarikçi',
    defaultSupplierMessage: 'Kiracı varsayılan tedarikçisi.',
    enableChapterIndexingMessage: 'İndeks Bölümleri',
    enableChapterIndexingLabel: 'Medya Bölümleri için indekslemeyi açar',
    enableIndexingLabel: 'Yayımlarken Dizin Oluştur',
    enableIndexingMessage: 'Yayımlama için dizin oluşturmayı etkinleştirir.',
    helpUrlLabel: 'Varsayılan Yardım URL`si',
    helpUrlMessage: 'Alternatif bir yardım sisteminin URL`si.',
    hotPointLinkPageInBookOnlyLabel: 'Sayfa Etkin Nokta Bağlantıları Yalnızca Kitapta',
    hotPointLinkPageInBookOnlyMessage: 'Sayfalardan Etkin Nokta Bağlantıları Yalnızca Kitapta',
    mediaTimestampLabel: 'Erişim Kontrolü Değişikliğinde Medya Zaman Damgasını Güncelle',
    mediaTimestampMessage: 'Erişim kontrollerindeki bir değişiklik medya zaman damgasının güncellenmesini gerektiriyorsa etkinleştirin.',
    publisherDefaultUom: 'Yayımcı Ölçü Birimi Öndeğeri',
    publishingContactLabel: 'İletişim E-postası Yayınlanıyor',
    publishingContactMessage: 'Bir içerik sorunu bildirildiğinde e-postaları teslim alacak varsayılan e-posta adresi.',
    replaceHotpointLabel: 'Yayınlarken Daima Hotpoint Bağlantılarını Değiştirin',
    replaceHotpointMessage: 'Yalnızca Docustudio yayını için.',
    ssoLoginSystemLabel: 'Tek Adımlı Oturum Açma Sistemi',
    ssoLoginSystemMessage: 'Tek Adımlı Oturum Açma için Kimlik Tanımlayıcı sistemi.',
    userPasswordExpirationLabel: 'Kullanıcı Parolası Süre Dolumu Gün',
    userPasswordExpirationMessage: 'Parolaların sıfırlanması için geçmesi gereken Gün Sayısı; sürenin hiçbir zaman dolmaması için boş bırakın',
    lockPartTrOnPublishLabel: 'Yayınlama Esnasında Bölüm Çevirilerini Kilitle',
    lockPartTrOnPublishMessage: 'Yayınlama esnasında Bölüm Çevirilerinin değiştirilmesini önler',
    exportPgAsHashLabel: 'Sayfa Dosya Adını Hash Anahtarı Olarak Dışa Aktar',
    exportPgAsHashMessage: 'Dışa Aktarılan İçerikte Sayfa Dosyası Adı Olarak Sayfa Hash Anahtarlarını Kullan'
  },
  tenantSettingsTitle: 'Geçici Kullanıcı Ayarları',
  tenantSettingsSaved: 'Geçici Kullanıcı Ayarları Kaydedildi',
  tenantSettingsSubtitle: 'Bu ayarlar, geçici kullanıcı çapındaki nitelikleri ve davranışları kontrol eder. Değişiklik yaparken dikkatli olun. Daha fazla bilgi için Documoto Hizmetleri ile iletişim kurun.',
  tenantSuppliers: 'Kiracı Sağlayıcıları',
  tenantLabelsPageText: 'Etiketler, Documoto\' nun Kitaplık ve Yönetim ekranlarında bulunan birçok nesnenin isimlerini özelleştirmek için kullanılır.',
  tenantPrintSettingsDescription: 'Kiracı genelinde varsayılan yazdırma ayarını seçin. Bu, Kuruluş veya Medyada geçersiz kılınmadıkça tüm kitap baskıları için kullanılacaktır.',
  tenantType: 'Kiracı Türü',
  tenantWebserviceUser: 'Kiracı Web Hizmeti Kullanıcısı',
  text: 'Metin',
  textArea: 'Metin Alanı',
  textField: 'Metin Alanı',
  textFile: 'Metin Dosyası (CSV, TXT)',
  textTool: 'Metin Aracı',
  threeD: {
    addFile: 'Bir 3D Parçası Dosyası Ekleyin',
    beforeSaveModal: {
      title: 'Documoto içerisinde aynı dosya adını taşıyan bir CAD dosyası zaten mevcut.',
      message: 'Bu dosyayı bulunduğu her yerde mi yoksa sadece bu kısımda mı değiştirmek istiyorsunuz? Dosyayı değiştirmeyi seçtiğinizde şunlar olacaktır:',
      messageBulletOne: '{count} parça(lar), bu yeni CAD dosyasıyla güncellenecektir',
      messageBulletTwo: 'Bu dosyanın önceki sürümü, Documoto dosya sisteminden kaldırılacaktır',
      tip: 'İpucu: Ekleme işlemi esnasında herhangi bir dosyanın üzerine yazmayı önlemek için içe aktarmayı denemeden önce eklemek istediğiniz dosyanın adını değiştirebilirsiniz.'
    },
    deleteTitle: 'Bu 3D görünümü silmek istediğinizden emin misiniz?',
    drawMode: {
      label: 'Çizim Modu',
      hiddenLines: 'Gizli Çizgiler',
      shaded: 'Gölgeli',
      wireframe: 'Şema',
      wireframeShaded: 'Gölgeli Şema',
    },
    explode: 'Genişlet',
    fileSizeModal: {
      message: 'Documoto içerisinde izin verilen maksimum dosya boyutu 25 megabayttır. Karşıya yüklemeye çalıştığınız dosya en büyük boyutu aştı ve yüklenemiyor. Devam etmek için “Tamam” seçeneğine tıklayın.',
      title: 'En Fazla Dosya Boyutu Aşıldı',
    },
    orientationAndProjection: {
      back: 'Geri',
      bottom: 'Alt',
      front: 'Ön',
      isometric: 'İzometrik',
      left: 'Sol',
      orthographic: 'Ortografik',
      projection: 'Projeksiyon',
      perspective: 'Perspektif',
      right: 'Sağ',
      top: 'Üst'
    },
    resetCamera: 'Kamerayı Sıfırla',
    slice: {
      label: 'Dilim',
      planeX: 'Kesen Düzlem X',
      planeY: 'Kesen Düzlem Y',
      planeZ: 'Kesen Düzlem Z',
      reset: 'Kesen Düzlemi Sıfırla',
      section: 'Kesen Düzlem Bölümü Anahtarı',
      visibility: 'Kesme Düzlemi Görünürlüğü Anahtarı',
    },
    supportedFilesMessage: 'Documoto, birçok CAD dosyası formatını desteklemektedir. CAD dosyası formatınızın desteklenip desteklenmediğinden emin değilseniz Destek Merkezi’ni kontrol edin.',
    toolsLabel: '3D Araçları',
    topAssemblyFilename: 'Üst Kurulum Dosya Adı',
    topAssemblyFilenameRequired: 'Üst Kurulum Dosya Adı gerekli',
    viewerTitle: '3D Görüntüleyici',
  },
  thu: 'Prş',
  thumbnail: 'Küçük Resim',
  thumbnailDeleted: 'Küçük Resim Başarıyla Silindi',
  thumbnailDeletedError: 'Küçük Resim Silinemedi',
  thumbnailUpdated: 'Küçük Resim Başarıyla Güncellendi',
  thumbnailUpdatedError: 'Küçük Resim Güncellemesi Başarısız Oldu',
  thumbnailReapplyWarning: 'Sistem, medyanın içeriğine bağlı olarak yeni bir küçük resim uygulayabilir. Bu işlem birkaç dakika alabilir.',
  thumbnailResizeTooltip: 'İşaretlendiği takdirde küçük resimleri 500x500 pikselden daha büyük olmayacak şekilde yeniden boyutlandırır',
  title: 'Başlık',
  to: 'Kime',
  toastNotifications: 'Toast Bildirimleri',
  toDate: 'Şimdiye Kadar',
  toc: 'İçindekiler',
  tocClipBoardSuccessTitle: 'Panoya kopyalandı',
  tocClipBoardWarningText: 'UYARI: Kaydedilmemiş değişiklikler panonuza kopyalanamaz',
  tocClipBoardWarningTitle: 'Panoya kopyalandı',
  tocDisplayingFirstValues: 'İlk 100 değer görüntüleniyor. Daha fazlasını görüntülemek için filtreyi kullanın.',
  tocDuplicateWarningText: 'Bir bölümde yinelenen sayfa algılandı. Yinelenenler kaldırıldı.',
  tocEmptyMessage: 'Görünüşe göre henüz bir İçindekiler Tablosu oluşturmadınız.',
  toGetStarted: 'E-posta Formatı Ekle.',
  tocMissingChapterName: 'Eksik Bölüm Adı',
  tocModalTableCaption: 'Etiket Düzenleyici Modeli',
  tocNothingSelected: 'Seçilen Öge Yok',
  tocOpenEditor: 'Düzenleyiciyi Aç',
  tocSaveRequired: 'Bölüm ayrıntılarını düzenlemeden önce kaydetmek gereklidir',
  tocState: 'İçindekiler Tablosunun Başlangıç Durumu',
  tocThumbnailDeleteFailTitle: 'Küçük Resim Silinemedi',
  tocThumbnailDeleteSuccessTitle: 'Küçük Resim Silme Başarılı',
  tocThumbnailUploadFailTitle: 'Küçük Resim Yüklenemedi',
  tocThumbnailUploadSuccessTitle: 'Küçük Resim Yükleme Başarılı',
  tocTranslationMessage: 'Bu bölümün adı ve isteğe bağlı açıklaması',
  tocUntitledPage: 'İsimsiz Sayfa',
  tooManySelections: 'Liste {limit} seçim sınırını aşıyor',
  togglePage: 'Sayfayı Aç Kapat',
  top: 'Üst',
  total: 'Toplam',
  totalItems: 'Toplam Öge',
  translation: 'Çeviri | Çeviriler',
  translationHelpTextItem: 'Bu {item} ögesinin adı ve isteğe bağlı açıklaması',
  true: 'Doğru',
  tryAgain: 'Lütfen tekrar deneyin.',
  tue: 'Sal',
  turkishDescription: 'Türkçe Açıklama',
  turkishName: 'Türkçe Ad',
  twoSided: '2 taraflı',
  type: 'Tür | Türler',
  typeAtLeastTwoChars: 'Eşleşme listesini görüntülemek için en az 2 karakter yazın.',
  typeToConfirm: 'Onaylamak için {value} yazın',
  typography: 'Tipografi',
  ukraineDescription: 'Ukraynaca Açıklama',
  ukraineName: 'Ukraynaca Ad',
  unableToRetrieveResults: 'Sorgunuza/aramanıza ilişkin sonuçlar alınamıyor',
  unauthorized: 'Yetkisiz',
  unknownError: 'Bilinmeyen hata, lütfen yardım için support{\'@\'}documoto.com adresinden Documoto destek merkezi ile iletişime geçin',
  unknownPart: 'Bilinmeyen Parça',
  unknownType: 'Bilinmeyen Tür',
  unsavedAPIKeyWarning: 'Dönmezseniz Uygulama Programı Arabirimi Anahtarınız kaybolacak',
  unsavedChanges: 'Kaydedilmemiş değişiklikleriniz var. Devam edilsin mi?',
  unsavedChangesWarning: 'Kaydetmezseniz değişiklikleriniz kaybolacak.',
  unsavedWidgetKeyWarning: 'Geri dönmemeniz durumunda Widget Anahtarınız kaybolur.',
  unsupportedBrowserMessage: 'Bu uygulamayı en son teknoloji ile oluşturduk. Bu sayede uygulama hızlı ve kullanımı kolaydır. Üzgünüz, tarayıcınız bu teknolojileri desteklemiyor.',
  uom: 'Ölçü Birimi',
  update: 'Güncelle',
  updateFailed: 'Güncelleme başarısız',
  updateIndex: 'İndeksi Güncelle',
  updatePassword: 'Parolayı Güncelle',
  updatePasswordMessage: 'Parola başarılı şekilde güncellendi',
  updatePasswordPending: 'Parola değişikliği kaydedilmeyi bekliyor.',
  updated: 'Güncellendi',
  upgradeBrowser: 'Lütfen tarayıcınızı yenileyin.',
  upload: 'Yükle',
  uploadComplete: 'Yükleme Tamamlandı',
  uploadInProgress: 'Yükleme Devam Ediyor',
  uponSavingDisabledTenant: 'Bu sayfa, bu ayar etkinleştirilmiş olarak kaydedildikten sonra şunlar olacaktır:',
  uponSavingThisLicense: 'Bu sayfa, bu ayarlarla kaydedildikten sonra ŞUNLARI YAPMANIZ GEREKMEKTEDİR:',
  upperBound: 'Üst Sınır',
  untitledOrg: 'Başlıksız Kuruluş',
  usCurrency: 'ABD Doları',
  useDefaultCartColumns: 'Varsayılan Sepet Sütunlarını Kullan',
  useDefaultPartsListColumns: 'Varsayılan Parça Listesi Sütunlarını Kullan',
  user: 'Kullanıcı',
  userAddress: 'Kullanıcı Adresleri | Kullanıcı Adresleri',
  userCount: 'Kullanıcı Sayısı',
  userEditable: 'Kullanıcı Tarafından Düzenlenebilir',
  userExpirationDate: 'Kullanıcı Sona Erme Tarihi',
  userExpirationDateInvalid: 'Kullanıcı Sona Erme Tarihi Girilmeli',
  userGroup: 'Kullanıcı Grubu | Kullanıcı Grupları',
  userGroupEmpty: 'Kullanıcı Grupları boş olamaz',
  userGroupType: 'Kullanıcı Grubu Türü',
  userGroupUniqueMessage: 'Bu ad, bu kiracı için diğer tüm Kullanıcı Grubu adlarından farklı olmalıdır',
  userGroupUsers: 'Kullanıcı Grubu Kullanıcıları',
  username: 'Kullanıcı Adı',
  usernameEmpty: 'Kullanıcı adı boş olamaz',
  users: 'Kullanıcı',
  usersWillReceiveAnUnauthorizedError: 'Kullanıcılar, kiracıda oturum açmayı denediklerinde “Yetkisiz” hatası alacaktır.',
  useSniForSsoMetadataRetrieval: 'SSO Meta Veri Alımı İçin SNI Kullan',
  value: 'Değer',
  validate: 'Doğrula',
  validateTenantDirectories: {
    buttonLabel: 'İçerik Dizinlerini Doğrula',
    error: 'Dizinler doğrulanamadı',
    success: 'Kiracı dizinleri doğrulandı'
  },
  variantSKU: 'Varyant Stok Tutma Birimi',
  validationMessage: 'Doğrulama Mesajı | Doğrulama Mesajları',
  valueGteError: 'Değer, en fazla {max} olmalıdır',
  valueLteError: 'Değer, en az {min} olmalıdır',
  vendorName: 'Satıcı Adı',
  verifyPassword: 'Parolayı Doğrula',
  vertical: 'Dikey',
  version: 'Versiyon',
  video: 'Video | Videolar',
  view: 'Görünüm',
  viewAll: 'Tümünü Görüntüle',
  viewAllPagesAndBooks: 'Görüntüle: Tüm Sayfalar ve Kitaplar',
  viewAllResults: 'Tüm Sonuçları Görüntüle',
  viewBooks: 'Görüntüle: Kitaplar',
  viewMore: 'Daha Fazla Görüntüle',
  viewPages: 'Görüntüle: Sayfalar',
  viewModeList: 'Liste Görünümü',
  viewModeTable: 'Tablo Görünümü',
  visibility: 'Görülebilirlik',
  visibilityDescription: 'Bu {entity} varlığını kim görüntüleyebilir',
  visible: 'Görülebilir',
  waitForUpload: 'Lütfen yüklemenin tamamlanmasını bekleyin.',
  watermark: 'Filigran',
  watermarkFont: 'Filigran Yazı Tipi',
  watermarkOpacityPercentage: 'Filigran Opaklığı %',
  watermarkOrientation: 'Filigran Yönü',
  watermarkSaved: 'Filigran Kaydedildi',
  watermarkSaveFailed: 'Filigran kaydedilemedi.',
  watermarkSelectionRequired: 'Kullanıcı adı veya Metin seçimi gerekli.',
  watermarkText: 'Filigran Metni',
  wed: 'Çrş',
  whereUsed: 'Kullanım Yeri',
  whoCanViewComment: 'Bu yorumu kimler görüntüleyebilir?',
  wholesale: 'Toptan Satış',
  wholesalePrice: 'Toptan Satış Fiyatı',
  wholesaleTotal: 'Toptan Satış Toplamı',
  widget: 'Widget',
  widgetAuthHeader: 'Widget Yetki Değeri',
  widgetKey: 'Widget Anahtarı | Widget Anahtarları',
  widgetKeyAdmin: 'Widget Anahtarı Yönetici',
  widgetKeyCopied: 'Widget Yetki değeri Panoya kopyalandı',
  widgetKeyDeleted: 'Widget Anahtarı Silindi',
  widgetKeyDeletedError: 'Widget Anahtarı silinirken hata oluştu',
  widgetKeyDeleteMsg: 'Widget Anahtarı silme işlemi, kullanımını devre dışı bırakır',
  widgetKeyManager: 'Widget Anahtarı Yöneticisi',
  widgetKeyNameEmpty: 'Ad gereklidir ve benzersiz olmalıdır. Bu değer, herhangi bir Widget etkinliği için kullanıcı adı olarak raporlama ve günlüğe kaydetme amacıyla da kullanılır.',
  widgetKeyRegenError: 'Widget Anahtarı yeniden oluşturulurken hata',
  widgetKeyRegenMsg: 'Bu Widget Anahtarını yeniden oluşturmak var olan birleşmeleri devre dışı bırakabilir',
  widgetKeyRegenTitle: 'Bu Widget Anahtarını yeniden oluşturmak istediğinize emin misiniz?',
  widgetKeySaved: 'Widget Anahtarı Kaydedildi',
  widgetPermissions: 'Widget kullanıcıları salt okunur biçimdedir ve özellik erişimi Widget seviyesi yapılandırması tarafından yönetilir. Standart kullanıcı grubu izinleri Widget kullanıcılarına uygulanmaz Ancak Medya Kategorisi erişim ayrıcalıklarına uygulanır. Lütfen medya kategorisi erişim kontrollerini atamaya devam edin.',
  widgetRefreshErrorMsg: "Talep edilen içeriği görmek için lütfen sayfanızı yenileyin",
  widgetUserGroup: 'Widget Kullanıcı Grubu',
  widgetUserGroupDeleteMsg: 'Kullanıcıları olan Widget grubu silinemez',
  willBeAffected: 'etkilenecek.',
  willBeDeleted: '{content} silinecek.',
  willBeInsertedIntoItem: '{name}, {item} ögesi içine eklenecek',
  willBeUpdated: 'güncellenecek.',
  zipCode: 'Posta Kodu',
  zoomHotpointToCanvas: 'Hotpoint’i Tuval boyutuna Yakınlaştır',
  zoomIn: 'Yakınlaştır',
  zoomOut: 'Uzaklaştır'
}
/* eslint-enable max-len */
